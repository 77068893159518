const style = {
  wrapper: {
    // overflow: "scroll",
  },
  containerWrapper: {
    width: "100%",
    height: "calc(100vh - 80px)",
    display: "flex",
    justifyContent: "center",
    background: "#F9F9FF",
    // minWidth: "1200px",
    // paddingRight: "8px",
    overflowY: "scroll",
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 80px)",
  },
  container: {
    width: "1200px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "56px 0 40px",
    cursor: "default",

    "& > p": {
      color: "rgba(1, 21, 51, 1)",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "32px",
      lineHeight: "40px",
    },
  },

  cardsWrapepr: {
    display: "flex",
    justifyContent: "space-evenly",
  },

  additionalText: {
    fontStyle: "italic",
    color: "#EE4360",
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  popupWrapper: {
    width: "492px",
  },
  input: { display: "flex", flexDirection: "column", alignItems: "flex-start" },
  inputLabel: {
    fontSize: "14px",
    color: "#011533",
  },
};

export default style;
