import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { preventEvent } from "pages/flyer/builder/drawer/components/preventEvent";
import { Divider } from "pages/flyer/builder/drawer/components/Primitives/Divider";
import {
  DMContent,
  DMDivider,
  DMItem,
  DMSubMenu,
  DMTriggerIcon,
} from "pages/flyer/builder/drawer/components/Primitives/DropdownMenu";
import { useFileSystemHandlers, useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { TDExportTypes, TDSnapshot } from "pages/flyer/builder/drawer/types";
import { SnackBarContext } from "components/snack-bar";
import * as React from "react";

interface MenuProps {
  showSponsorLink: boolean;
  readOnly: boolean;
}

const numberOfSelectedIdsSelector = (s: TDSnapshot) => {
  return s.document.pageStates[s.appState.currentPageId].selectedIds.length;
};

const disableAssetsSelector = (s: TDSnapshot) => {
  return s.appState.disableAssets;
};

export const Menu = React.memo(function Menu({ showSponsorLink, readOnly }: MenuProps) {
  const app = useTldrawApp();
  const runSnackBar = React.useContext(SnackBarContext);

  const numberOfSelectedIds = app.useStore(numberOfSelectedIdsSelector);

  const disableAssets = app.useStore(disableAssetsSelector);

  const [, setForce] = React.useState(0);

  React.useEffect(() => setForce(1), []);

  const { onNewProject, onOpenProject, onSaveProject, onSaveProjectAs } = useFileSystemHandlers();

  const handleExportPNG = React.useCallback(async () => {
    await app.exportAllShapesAs(TDExportTypes.PNG);
  }, [app]);

  const handleExportJPG = React.useCallback(async () => {
    await app.exportAllShapesAs(TDExportTypes.JPG);
  }, [app]);

  const handleExportWEBP = React.useCallback(async () => {
    await app.exportAllShapesAs(TDExportTypes.WEBP);
  }, [app]);

  // const handleExportPDF = React.useCallback(async () => {
  //   await app.exportAllShapesAs(TDExportTypes.PDF);
  // }, [app]);

  const handleExportSVG = React.useCallback(async () => {
    await app.exportAllShapesAs(TDExportTypes.SVG);
  }, [app]);

  const handleExportJSON = React.useCallback(async () => {
    await app.exportAllShapesAs(TDExportTypes.JSON);
  }, [app]);

  const handleCut = React.useCallback(() => {
    app.cut();
  }, [app]);

  const handleCopy = React.useCallback(() => {
    app.copy();
  }, [app]);

  const handlePaste = React.useCallback(() => {
    app.paste();
  }, [app]);

  // const handleCopySvg = React.useCallback(() => {
  //   app.copySvg();
  // }, [app]);

  const handleCopyJson = React.useCallback(() => {
    app.copyJson();
  }, [app]);

  const handleSelectAll = React.useCallback(() => {
    app.selectAll();
  }, [app]);

  const handleSelectNone = React.useCallback(() => {
    app.selectNone();
  }, [app]);

  const handleUploadMedia = React.useCallback(() => {
    app.openAsset().then((res: any) => {
      if (res.error) {
        runSnackBar({
          type: "error",
          msg: `Error while uploading the file: ${res.error.message}`,
          vertical: "bottom",
          horizontal: "right",
        });
      }
    });
  }, [app]);

  const showFileMenu =
    app.callbacks.onNewProject ||
    app.callbacks.onOpenProject ||
    app.callbacks.onSaveProject ||
    app.callbacks.onSaveProjectAs ||
    app.callbacks.onExport;

  const hasSelection = numberOfSelectedIds > 0;

  return (
    <DropdownMenu.Root dir="ltr">
      <DMTriggerIcon isSponsor={showSponsorLink}>
        <HamburgerMenuIcon />
      </DMTriggerIcon>
      <DMContent variant="menu">
        {showFileMenu && (
          <DMSubMenu label="File...">
            {app.callbacks.onNewProject && (
              <DMItem onClick={onNewProject} kbd="#N">
                New Project
              </DMItem>
            )}
            {app.callbacks.onOpenProject && (
              <DMItem onClick={onOpenProject} kbd="#O">
                Open...
              </DMItem>
            )}
            {app.callbacks.onSaveProject && (
              <DMItem onClick={onSaveProject} kbd="#S">
                Save
              </DMItem>
            )}
            {app.callbacks.onSaveProjectAs && (
              <DMItem onClick={onSaveProjectAs} kbd="#⇧S">
                Save As...
              </DMItem>
            )}
            {app.callbacks.onExport && (
              <>
                <Divider />
                <DMSubMenu label="Export" size="small">
                  <DMItem onClick={handleExportPNG}>PNG</DMItem>
                  <DMItem onClick={handleExportJPG}>JPG</DMItem>
                  <DMItem onClick={handleExportWEBP}>WEBP</DMItem>
                  <DMItem onClick={handleExportSVG}>SVG</DMItem>
                  <DMItem onClick={handleExportJSON}>JSON</DMItem>
                </DMSubMenu>
              </>
            )}
            {!disableAssets && (
              <>
                <Divider />
                <DMItem onClick={handleUploadMedia} kbd="#U">
                  Upload Media
                </DMItem>
              </>
            )}
          </DMSubMenu>
        )}
        {!readOnly && (
          <>
            <DMSubMenu label="Edit...">
              <DMItem onSelect={preventEvent} onClick={app.undo} kbd="#Z">
                Undo
              </DMItem>
              <DMItem onSelect={preventEvent} onClick={app.redo} kbd="#⇧Z">
                Redo
              </DMItem>
              <DMDivider dir="ltr" />
              <DMItem onSelect={preventEvent} disabled={!hasSelection} onClick={handleCut} kbd="#X">
                Cut
              </DMItem>
              <DMItem onSelect={preventEvent} disabled={!hasSelection} onClick={handleCopy} kbd="#C">
                Copy
              </DMItem>
              <DMItem onSelect={preventEvent} onClick={handlePaste} kbd="#V">
                Paste
              </DMItem>
              <DMDivider dir="ltr" />
              {/* <DMItem
                onSelect={preventEvent}
                disabled={!hasSelection}
                onClick={handleCopySvg}
                kbd="#⇧C"
              >
                Copy as SVG
              </DMItem> */}
              <DMItem onSelect={preventEvent} disabled={!hasSelection} onClick={handleCopyJson}>
                Copy as JSON
              </DMItem>
              <DMDivider dir="ltr" />
              <DMItem onSelect={preventEvent} onClick={handleSelectAll} kbd="#A">
                Select All
              </DMItem>
              <DMItem onSelect={preventEvent} onClick={handleSelectNone}>
                Select None
              </DMItem>
            </DMSubMenu>
          </>
        )}
        <a href="https://tldraw.com/r">
          <DMItem>Create a Multiplayer Room</DMItem>
        </a>
      </DMContent>
    </DropdownMenu.Root>
  );
});
