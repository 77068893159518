import { makeStyles, Box, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Icon } from "components/icons";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type ServiceAreaLimitationPopUpProps = {
  open?: boolean;
  onClickGetInTouch?: () => void;
  closeModal?: () => void;
};
export const ServiceAreaLimitationPopUp = ({
  open = false,
  onClickGetInTouch = () => {},
  closeModal = () => {},
}: ServiceAreaLimitationPopUpProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open} className={classes.wrapper} onClose={closeModal}>
      {/* @ts-ignore */}
      <Box cypress_id={`service-area-limitation-popup`}>
        <Box className={classes.header}>
          <Icon type="Attention" size="s" />
          {/* @ts-ignore */}
          <Typography
            className={classes.closeButton}
            onClick={closeModal}
            cypress_id={`close-service-area-limitation-popup`}
          >
            <Icon type="Close" size="m" />
          </Typography>
        </Box>
        <Box className={classes.txtWrapper}>
          <Typography className={classes.headerTxt}>Service Area Limitation</Typography>
          <Typography className={classes.infoTxt}>
            Our service is limited to the distribution zone (highlighted area) only.
          </Typography>
          <Typography className={classes.expTxt}>
            Should you wish to target specific areas or locations beyond our service area, kindly get in touch with our
            customer success team.
          </Typography>
          <Box className={classes.getInTouchBtn} onClick={onClickGetInTouch}>
            <Typography className={classes.getInTouchTxt}>Get in touch</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
