/* eslint-disable react/no-unknown-property */
import cn from "classnames";
import React, { memo, useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import { IfElse, If } from "components/logic";
import { useJsApiLoader, LoadScriptProps } from "@react-google-maps/api";
import { LinearProgress, CircularProgress, withStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import Box from "@material-ui/core/Box";
import { usePostHog } from "posthog-js/react";

import { DeleteFile } from "shared/graphql/mutations/deleteFile";
import { gtagWrapper, checkIsNeedUpdateLastActiveStepState } from "utils";
import { isValidFlyerType, getFlyerWeightOptions } from "utils/isValidFlyerType";

import Header from "components/header";
import { Badge } from "components/header/components/badge";
import BounceLoader from "components/loaders/bounce-loader";
import { LightTooltip } from "components/light-tooltip";
import { CustomTooltipContent } from "components/tooltip-content";
import ConfirmPopup from "pages/flyer/builder/drawer/components/ConfirmPopup";

import ROUTES, { DM_ROUTING } from "config/routing";
import CONFIG from "config/config";

import { NAVIGATION, EDDM_NAVIGATION } from "pages/constants";
import SelectMenu from "pages/flyer/builder/components/Inspector/components/SelectMenu";
import { FORMATS } from "pages/flyer/builder/constants";
import { GtagOptions, TemplateConfig, PossibleFlyerType } from "pages/flyer/builder/drawer";
import { Icon } from "components/icons";
import { FeatureFlagContext } from "context/featureFlagContext";
import { FlyerStatus } from "pages/flyer/flyerTypePage/useGetDataForPage";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { getRedirectedCampaigns } from "utils/getRedirectedCampaigns";
import DragAndDropArea from "../flyerTypePage/components/DragAndDropArea";
import { useDragAndDrop } from "./hooks/useDragAndDrop";
import LinkToFile from "../flyerTypePage/components/LinkToFile";
import del from "./assets/Delete.svg";
import paperclip from "./assets/paperclip.svg";
import questionIcon from "./assets/questionIcon.svg";
import downloadIcon from "./assets/Download.svg";
import { FlyerSizePreview } from "./components/FlyerSizePreview";
import style from "./style";
import { useStore } from "../../../store";
import { StoreType } from "../../../store/types";
import useCalculating from "../../../Hooks/useCalculating";
import { useEDDMCostsCalculation } from "../../../Hooks/useEDDMCostsCalculation";
import { updateCampaignExtraData } from "../../../graphQL";
import { getFlyerSizeDisplay, displayInInch } from "../builder/utils";
import { insertCampaignLogs } from "../../../Logs/campaign/gql";
import { CAMPAIGN_ENUM_TYPES, CAMPAIGN_LOG_ENUM_TYPES } from "../../../shared/constants";
import { replaceNullValue } from "../../../Logs/campaign/utils";
import { ButtonsUploadLater } from "./components/ButtonsUploadLater/ButtonsUploadLater";

const libraries: LoadScriptProps["libraries"] = ["geometry", "places", "drawing"];

const UploadDesignPage: React.FC<{ classes: any }> = ({ classes }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY as string,
    libraries: libraries,
    language: "en",
  });
  const posthog = usePostHog();
  const [deleteFile] = useMutation(DeleteFile);
  const [deletingFile, setDeletingFile] = useState(false);
  const {
    user: { id: userId, isVerified, email, phone },
    client: { id: clientId },
    campaign: { id: campaignId, channel, campaignName, isSubmitted, flyerType, flyerWeight, lastActiveStep, isDM },
    country,
    costsCalculationData: { printingCosts, detailedCost },
    updateCampaign,
    updateErrors,
    errors: { notExistDesignFile },
  }: StoreType = useStore();

  const { recalculateCampaignCost } = useCalculating();
  const { recalculateEDDMCampaignCost } = useEDDMCostsCalculation();

  const { isSkipEnabled } = useContext(FeatureFlagContext);
  const readyToUpload = getRedirectedCampaigns(campaignId);
  const history = useHistory();
  const { hideUploadedFile } = useParams();
  const flyerInfo = useGetFlyerInfo();
  const [files, setFiles] = useState<Array<any>>([]);
  const [flyerTypeOption, setFlyerTypeOption] = useState<TemplateConfig>(FORMATS.A6);
  // const [isShowPrintRuleModal, setIsShowPrintRuleModal] = useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [{ oldFlyerType, oldFlyerWeight }] = useState({
    oldFlyerType: flyerType,
    oldFlyerWeight: flyerWeight,
  });

  const { isUploading, fileError, fileUploadProgress, tempFiles, onDrop, onDropRejected } = useDragAndDrop({
    files,
    setFiles,
    flyerInfo,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  /**
   * For US, CA D2D, Door Hanger
   * For others, A6
   * we could get exact flyer type already
   */
  // const flyerTypeOptions = useMemo(() => {
  //   return Object.entries(FORMATS)
  //     .filter(([key, value]) => {
  //       if (Object.keys(printingCosts).some((flyerType) => flyerType.includes(key))) {
  //         return flyerType === value.format;
  //       }

  //       return false;
  //     })
  //     .map(([, value]) => value);
  // }, [printingCosts, FORMATS]);

  const flyerTypeOptions = useMemo(
    () =>
      Object.entries(FORMATS)
        .filter(([key]) =>
          isValidFlyerType({
            countryCode: country.code,
            channel,
            printingCosts,
            flyerType: key,
            flyerWeight: undefined,
          })
        )
        .map(([, value]) => value),
    [channel, country.code, printingCosts]
  );
  const flyerWeightOptions: string[] = useMemo(
    () => getFlyerWeightOptions({ flyerType, printingCosts }),
    [flyerType, printingCosts]
  );

  const format = FORMATS[flyerType as PossibleFlyerType];
  const printingRulesLink = displayInInch(country.code) ? format.printingRulesInches : format.printingRulesMm;
  const eddmFlyerTemplate = format.flyerTemplate;

  useEffect(() => {
    if (!files.length) {
      updateErrors({ notExistDesignFile: true });
    } else {
      updateErrors({ notExistDesignFile: false });
    }
  }, [files.length]);

  useEffect(() => {
    if (isDM) {
      recalculateEDDMCampaignCost();
    } else if (flyerWeightOptions.includes(flyerWeight)) {
      recalculateCampaignCost();
    } else if (flyerWeightOptions.length) {
      updateCampaign({ flyerWeight: flyerWeightOptions[0] });
    }
  }, [flyerType, flyerWeight, flyerWeightOptions, isDM]);

  useEffect(() => {
    if (flyerType) {
      setFlyerTypeOption(FORMATS[flyerType]);
    }
  }, [flyerType]);

  useEffect(() => {
    if (flyerInfo.pageState === FlyerStatus.flyerUploaded && !hideUploadedFile) {
      setFiles([{ name: flyerInfo.flyerInfo.flyerName }]);
    }
  }, [flyerInfo.pageState]);

  const saveQRCode = async () => {
    if (isDM) {
      history.push(generatePath(DM_ROUTING.DM_CAMPAIGN_DETAILS, { campaignId, clientId }));
    } else {
      history.push(generatePath(ROUTES.CAMPAIGN_DETAILS, { campaignId, clientId }));
    }
  };

  const headerActions = {
    BACK: {
      action: () => {
        if (isDM) {
          history.push(generatePath(DM_ROUTING.DM_CAMPAIGN_SEGMENTS, { campaignId, clientId }));
        } else {
          history.push(generatePath(ROUTES.FLYER, { campaignId, clientId }));
        }
      },
    },
  };

  const disabled = !!fileError || !flyerTypeOptions.length;

  const handleClickNext = async () => {
    if (disabled || !files.length) {
      return;
    }

    const payloadUpdateExtraData = { campaignId, flyerType, flyerWeight };

    const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
      stateLastActiveStep: lastActiveStep,
      newLastActiveStep: "design",
      isDM: isDM,
    });
    if (isNeedUpdateLastActiveStep) {
      //@ts-ignore
      payloadUpdateExtraData.lastActiveStep = "design";
    }

    if (isDM) {
      //@ts-ignore
      payloadUpdateExtraData.totalCosts = detailedCost.totalCost;
    }
    await updateCampaignExtraData(payloadUpdateExtraData);

    const logs = [];

    if (oldFlyerType !== flyerType) {
      logs.push({
        campaignId,
        type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_TYPE,
        additionalInfo: `[CB] CAMPAIGN EDIT from ${replaceNullValue(oldFlyerType)} to ${replaceNullValue(flyerType)}`,
      });
    }
    if (oldFlyerWeight !== flyerWeight) {
      logs.push({
        campaignId,
        type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_WEIGHT,
        additionalInfo: `[CB] CAMPAIGN EDIT from ${replaceNullValue(oldFlyerWeight)} to ${replaceNullValue(
          flyerWeight
        )}`,
      });
    }

    await insertCampaignLogs(logs);

    const gtagOptions: GtagOptions = {
      event: "ss_design_submitted",
      client_id: clientId?.toString(),
      user_id: userId,
      campaign_id: campaignId,
      flyer_format: flyerType,
      flyer_weight: flyerWeight,
      flyer_submitted_type: "upload",
      nr_of_elements_created: 0,
    };
    gtagWrapper(gtagOptions);
    if (posthog) {
      posthog.capture("Uploaded Flyer Design", {
        event_category: "Flyer Builder",
        campaign_flyer_type: flyerType,
        campaign_flyer_weight: flyerWeight,
        campaign_submitted_type: "upload",
      });
    }

    if (isSkipEnabled) {
      if (notExistDesignFile) {
        updateErrors({ notExistDesignFile: false });
      }
      updateCampaign({ lastActiveStep: "design" });
    } else {
      if (isNeedUpdateLastActiveStep) {
        updateCampaign({ lastActiveStep: "design" });
      }
    }

    await saveQRCode();
  };

  const handleUploadLater = async () => {
    const payloadUpdateExtraData = { campaignId, flyerType, flyerWeight, lastActiveStep: "design" };

    if (isDM) {
      //@ts-ignore
      payloadUpdateExtraData.totalCosts = detailedCost.totalCost;
    }
    await updateCampaignExtraData(payloadUpdateExtraData);

    const logs = [];

    if (oldFlyerType !== flyerType) {
      logs.push({
        campaignId,
        type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_TYPE,
        additionalInfo: `[CB] CAMPAIGN EDIT from ${replaceNullValue(oldFlyerType)} to ${replaceNullValue(flyerType)}`,
      });
    }
    if (oldFlyerWeight !== flyerWeight) {
      logs.push({
        campaignId,
        type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_WEIGHT,
        additionalInfo: `[CB] CAMPAIGN EDIT from ${replaceNullValue(oldFlyerWeight)} to ${replaceNullValue(
          flyerWeight
        )}`,
      });
    }

    if (posthog) {
      posthog.capture("Skiped flyer design", {
        clientId,
        pageName: "Design",
        country: country.code,
        email,
        phone,
      });
    }

    await insertCampaignLogs(logs);
    updateErrors({ notExistDesignFile: true });
    updateCampaign({ lastActiveStep: "design" });

    await saveQRCode();
  };

  const sendPosthogEvent = useCallback(
    ({ eventName, userId, campaignId, clientId }) => {
      if (posthog) {
        posthog.capture(eventName, {
          user_id: userId,
          campaign_id: campaignId,
          company_id: clientId,
        });
      }
    },
    [posthog]
  );

  const getDisplaySize = (format: TemplateConfig) =>
    getFlyerSizeDisplay(format.widthInMm, format.heightInMm, displayInInch(country.code));

  const displayFlyerValue = `${flyerTypeOption.name} (${getDisplaySize(flyerTypeOption)})`;

  // const handleOpenPrintRuleModal = useCallback(() => {
  //   setIsShowPrintRuleModal(true);
  // }, []);

  // const handleClosePrintRuleModal = useCallback(() => {
  //   setIsShowPrintRuleModal(false);
  // }, []);

  const handleDeleteFile = async () => {
    try {
      setDeletingFile(true);
      setIsDeleteConfirmationPopupOpen(false);

      const flyerKey = flyerInfo?.flyerInfo?.flyerKey;
      if (flyerKey) {
        await deleteFile({
          variables: {
            fileKey: flyerKey,
            campaignId: `${campaignId}`,
          },
        });
        await flyerInfo.getFlyerInfo();
        setFiles([]);
        const arrayPath = flyerKey.split("/");
        const fileName = arrayPath[arrayPath.length - 1];

        await insertCampaignLogs([
          {
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.DELETE_PDF_DESIGN,
            additionalInfo: `[CB] CAMPAIGN EDIT File name: ${fileName}`,
          },
        ]);
      } else {
        throw new Error("There was an error while deleting the file");
      }
      setDeletingFile(false);
      const uploadFlyerParams: Record<string, any> = { campaignId, clientId };
      if (isDM) {
        history.push(generatePath(DM_ROUTING.DM_UPLOAD_FLYER, uploadFlyerParams));
      } else {
        history.push(generatePath(ROUTES.UPLOAD_FLYER, uploadFlyerParams));
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  // const isVerifiedIsLoaded = typeof isVerified === "boolean";

  return (
    <div>
      <ConfirmPopup
        isOpen={isDeleteConfirmationPopupOpen}
        onClose={() => setIsDeleteConfirmationPopupOpen(false)}
        loading={false}
        onSave={handleDeleteFile}
        title="Delete file?"
        body="Are you sure you want to delete the uploaded file?"
        customConfirmText="Confirm"
        centerBody
      />
      <div className={classes.wrapper}>
        <Helmet>
          <title>Upload Flyer</title>
        </Helmet>
        <Header
          tabs={isDM ? EDDM_NAVIGATION : NAVIGATION}
          activeTabIndex={2}
          headerActions={headerActions}
          isUploadPage
          hasError={disabled}
          isDM={isDM}
        />
        <IfElse condition={!isLoaded || deletingFile}>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <BounceLoader />
          </Box>
          <div className={cn(classes.page, "mob-page", { [classes.pageNotVerified]: !isVerified })}>
            <div className={cn(classes.contentWrapper, "mob-content-wrapper")}>
              <div className={cn(classes.leftSide, "mob-left-side", { [classes.leftSideDM]: isDM })}>
                <FlyerSizePreview
                  flyerTypeOption={flyerTypeOption}
                  countryCode={country.code}
                  file={flyerInfo.pageState === FlyerStatus.flyerUploaded ? flyerInfo?.flyerInfo?.s3Link : ""}
                  files={files}
                  cypressId="flyerPreview-box"
                  isDM={isDM}
                />
              </div>
              <div className={cn(classes.rightSide, "mob-right-side")}>
                <div className={cn(classes.header)}>
                  <div className={classes.title} cypress_id={"uploadPageNameTitle"}>
                    Upload design
                  </div>
                  <div className="mob-header">
                    <Badge />
                  </div>
                </div>
                <p className={cn(classes.campaignName, "mob-campaign-name")} cypress_id={"campaignNameTitle"}>
                  {campaignName}
                </p>
                <div className={classes.message}>
                  Upload flyer design in PDF format. PDF file must:
                  <div>
                    <div className={classes.marker} />
                    Be in CMYK color profile
                  </div>
                  <div>
                    <div className={classes.marker} />
                    Contain both front & back sides of the flyer
                  </div>
                  <div>
                    <div className={classes.marker} />
                    Contain bleed lines, indicating where the edges are cut
                  </div>
                </div>
                <If condition={!isDM}>
                  <LinkToFile
                    href={printingRulesLink}
                    linkText=""
                    icon={false}
                    cypressId="printingRulesBtn"
                    onClick={() => {
                      sendPosthogEvent({ eventName: "Clicked on printing rules button", userId, campaignId, clientId });
                    }}
                  >
                    <span className={classes.flyerSettingsTip}>
                      <img className={classes.downloadIcon} src={questionIcon} alt="icon" />
                      What are the printing rules?
                    </span>
                  </LinkToFile>
                </If>
                <div className={cn(classes.flyerSettings, "flex-direction")}>
                  <div className={cn(classes.settings_row, "mob-settings-row")}>
                    <label className={classes.label}>Flyer type</label>
                    <SelectMenu
                      isDisabled={isSubmitted}
                      items={flyerTypeOptions.map((format) => {
                        return {
                          label: `${format.name} (${getDisplaySize(format)})`,
                          helperText: `${getDisplaySize(format)}`,
                          action: async () => {
                            setFlyerTypeOption(format);
                            updateCampaign({ flyerType: format.format as PossibleFlyerType });
                          },
                          isChecked: flyerType === format?.format,
                        };
                      })}
                      displayValue={displayFlyerValue}
                      cypressId="flyerTypeDropdown"
                    />
                  </div>
                  {channel !== CAMPAIGN_ENUM_TYPES.DIRECTMAIL ? (
                    <div className={cn(classes.settings_row, "mob-settings-row")}>
                      <label className={classes.label}>Flyer weight</label>
                      <SelectMenu
                        isDisabled={isSubmitted}
                        items={flyerWeightOptions.map((w) => {
                          return {
                            label: `${w} GSM`,
                            helperText: "",
                            action: async () => {
                              updateCampaign({ flyerWeight: w });
                            },
                            isChecked: flyerWeight === w,
                          };
                        })}
                        displayValue={`${flyerWeight} GSM`}
                        cypressId="flyerWeightDropdown"
                      />
                    </div>
                  ) : null}
                </div>
                <If condition={isDM}>
                  <LinkToFile href={eddmFlyerTemplate} linkText="" icon={false} cypressId="printingRulesBtn">
                    <span
                      className={classes.flyerSettingsTip}
                      onClick={() => {
                        if (posthog) {
                          posthog.capture("Clicked Download Templates", {
                            user_id: userId,
                            campaign_id: campaignId,
                            client_id: clientId,
                          });
                        }
                      }}
                    >
                      <img className={classes.downloadIconEddm} src={downloadIcon} alt="icon" />
                      Download {displayFlyerValue} template
                    </span>
                  </LinkToFile>
                </If>
                <div className={classes.uploadDesign}>
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                    <span data-cy={"designFileText"}>Design file: </span>
                    <LightTooltip
                      title={
                        "Make sure that the design follows the selected format. For additional information see our printing rules guide"
                      }
                      placement="top"
                    >
                      <div
                        style={{ display: "flex" }}
                        onMouseEnter={() => {
                          sendPosthogEvent({ eventName: "Hover on design tool tip", userId, campaignId, clientId });
                        }}
                      >
                        <Icon type="Attention" size="s" stroke="black" style={{ marginLeft: "8px" }} />
                      </div>
                    </LightTooltip>
                  </div>
                  {files && files[0] ? (
                    <>
                      <div className={classes.uplgile}>
                        <div>
                          <img className={classes.paprlc} src={paperclip}></img>
                        </div>
                        <div>{files[0]?.name}</div>
                        <div>
                          {(!isUploading || fileError) && !isSubmitted ? (
                            <img
                              className={classes.infoIcon}
                              src={del}
                              onClick={() => setIsDeleteConfirmationPopupOpen(true)}
                              cypress_id="deleteBtn"
                            ></img>
                          ) : null}
                        </div>
                      </div>
                      {isUploading && !fileError && (
                        <div className={classes.uploading}>
                          <LinearProgress
                            variant="determinate"
                            value={fileUploadProgress}
                            className={classes.linearProgress}
                          ></LinearProgress>
                          <p className={classes.uploadingText}>Uploading...</p>
                        </div>
                      )}
                    </>
                  ) : flyerInfo.isLoading || isUploading ? (
                    <CircularProgress size={20} color="secondary" style={{ marginTop: 10 }} />
                  ) : (
                    <div className={classes.vlock}>
                      <div className={classes.dragAndDropWrapper}>
                        <DragAndDropArea
                          files={tempFiles}
                          newVersion
                          onDrop={onDrop}
                          onDropRejected={onDropRejected}
                          fileType="application/pdf"
                          error={fileError}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {isSkipEnabled ? (
                  <ButtonsUploadLater
                    files={files}
                    handleClickNext={handleClickNext}
                    handleUploadLater={handleUploadLater}
                    disabled={disabled || !files.length}
                    readyToUpload={readyToUpload}
                    isMobile={isMobile}
                  />
                ) : (
                  <>
                    {!files.length ? (
                      <LightTooltip
                        title={
                          <CustomTooltipContent
                            title="PDF file missing!"
                            content="Please, upload PDF with flyer design."
                          />
                        }
                        placement="top"
                      >
                        <div
                          className={cn(classes.nextBtn, "mob-next-btn-not-preview", {
                            [cn(classes.nextBtnDisabled)]: disabled || !files.length,
                          })}
                          onClick={handleClickNext}
                        >
                          Next
                        </div>
                      </LightTooltip>
                    ) : (
                      <div
                        className={cn(classes.nextBtn, "mob-next-btn", {
                          [cn(classes.nextBtnDisabled)]: disabled,
                        })}
                        onClick={handleClickNext}
                        cypress_id="nextBtn"
                      >
                        Next
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </IfElse>
      </div>
    </div>
  );
};

export default memo(withStyles(style as unknown as any)(UploadDesignPage));
