import * as React from "react";

export function StrikeThroughIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={20}
      height={20}
      viewBox="-30 -22 100 100"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.782 21.452c-.968-1.79-1.451-3.842-1.451-6.23 0-2.276.483-4.328 1.488-6.23.968-1.866 2.345-3.47 4.132-4.813 1.787-1.306 3.908-2.35 6.328-3.097C22.736.373 25.453 0 28.393 0c3.015 0 5.733.41 8.226 1.268 2.457.821 4.579 2.015 6.291 3.507a15.2 15.2 0 0 1 4.02 5.336c.93 2.052 1.414 4.29 1.414 6.752H37.141c0-1.156-.187-2.2-.559-3.17-.335-1.008-.893-1.829-1.638-2.538-.744-.709-1.675-1.231-2.791-1.641-1.117-.373-2.457-.597-3.946-.597-1.451 0-2.754.149-3.834.485-1.08.336-1.972.783-2.68 1.343-.707.597-1.265 1.268-1.637 2.052a5.67 5.67 0 0 0-.559 2.462c0 1.791.93 3.283 2.755 4.514 1.414.933 2.866 1.791 5.248 2.612H10.34c-.186-.298-.409-.634-.558-.933ZM56 33.578v-7.462H0v7.462h29.808c.67.26 1.489.522 2.047.746 1.377.634 2.457 1.268 3.238 1.903.782.634 1.303 1.343 1.6 2.126.261.746.41 1.604.41 2.574 0 .858-.186 1.68-.52 2.463a5.268 5.268 0 0 1-1.564 1.977c-.707.56-1.563.97-2.643 1.306-1.08.298-2.345.485-3.76.485-1.6 0-3.089-.15-4.392-.485-1.302-.336-2.456-.858-3.387-1.567-.93-.709-1.675-1.642-2.196-2.798-.521-1.157-.93-2.836-.93-4.514H6.656c0 2.051.297 4.215.893 5.894.595 1.68 1.377 3.171 2.42 4.514 1.042 1.306 2.233 2.463 3.647 3.433 1.377.97 2.903 1.79 4.541 2.425 1.638.634 3.35 1.12 5.137 1.455 1.787.298 3.573.485 5.36.485 2.978 0 5.695-.336 8.114-1.045 2.42-.709 4.504-1.678 6.216-2.947a13.182 13.182 0 0 0 3.983-4.738c.93-1.866 1.415-3.992 1.415-6.38 0-2.238-.373-4.253-1.154-6.007-.186-.41-.41-.858-.633-1.23H56v-.075Z"
        fill="#010101"
      />
    </svg>
  );
}
