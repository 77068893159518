import { Utils } from "@tldraw/core";
import Vec from "@tldraw/vec";
import { EASINGS } from "pages/flyer/builder/drawer/constants/constants";
import { getShapeStyle } from "pages/flyer/builder/drawer/state/shapes/shared";
import type { Decoration, ShapeStyles } from "pages/flyer/builder/drawer/types";
import * as React from "react";
import {
  getArcLength,
  getArrowArcPath,
  getCtp,
  getCurvedArrowHeadPoints,
  renderCurvedFreehandArrowShaft,
} from "../arrowHelpers";
import { Arrowhead } from "./ArrowHead";

interface ArrowSvgProps {
  id: string;
  style: ShapeStyles;
  start: number[];
  bend: number[];
  end: number[];
  arrowBend: number;
  decorationStart: Decoration | undefined;
  decorationEnd: Decoration | undefined;
  isDarkMode: boolean;
  isDraw: boolean;
}

export const CurvedArrow = React.memo(function CurvedArrow({
  id,
  style,
  start,
  bend,
  end,
  arrowBend,
  decorationStart,
  decorationEnd,
  isDraw,
  isDarkMode,
}: ArrowSvgProps) {
  const arrowDist = Vec.dist(start, end);
  if (arrowDist < 2) return null;
  const styles = getShapeStyle(style, isDarkMode);
  const { strokeWidth } = styles;
  // Calculate a path as a segment of a circle passing through the three points start, bend, and end
  const circle = getCtp(start, bend, end);
  const center = [circle[0], circle[1]];
  const radius = circle[2];
  const length = getArcLength(center, radius, start, end);
  const getRandom = Utils.rng(id);
  const easing = EASINGS[getRandom() > 0 ? "easeInOutSine" : "easeInOutCubic"];
  const path = isDraw
    ? renderCurvedFreehandArrowShaft(
        id,
        style,
        start,
        end,
        decorationStart,
        decorationEnd,
        center,
        radius,
        length,
        easing
      )
    : getArrowArcPath(start, end, circle, arrowBend);
  const { strokeDasharray, strokeDashoffset } = Utils.getPerfectDashProps(
    Math.abs(length),
    strokeWidth!,
    style.dash!,
    2,
    false
  );
  // Arrowheads
  const arrowHeadLength = Math.min(arrowDist / 3, strokeWidth! * 8);
  const startArrowHead = decorationStart
    ? getCurvedArrowHeadPoints(start, arrowHeadLength, center, radius, length < 0)
    : null;
  const endArrowHead = decorationEnd
    ? getCurvedArrowHeadPoints(end, arrowHeadLength, center, radius, length >= 0)
    : null;
  return (
    <>
      <path className="tl-stroke-hitarea" d={path} />
      <path
        d={path}
        fill={isDraw ? styles.stroke : "none"}
        stroke={styles.stroke}
        strokeWidth={isDraw ? 0 : strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        strokeLinejoin="round"
        pointerEvents="none"
      />
      {startArrowHead && (
        <Arrowhead
          left={startArrowHead.left}
          middle={start}
          right={startArrowHead.right}
          stroke={styles.stroke!}
          strokeWidth={strokeWidth!}
        />
      )}
      {endArrowHead && (
        <Arrowhead
          left={endArrowHead.left}
          middle={end}
          right={endArrowHead.right}
          stroke={styles.stroke!}
          strokeWidth={strokeWidth!}
        />
      )}
    </>
  );
});
