import { CircularProgress } from "@material-ui/core";
import cn from "classnames";
import { LightTooltip } from "components/light-tooltip";
import { CustomTooltipContent } from "components/tooltip-content";
import { CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";

interface SkipDesignSubmitAndPayButtonProps {
  internalStatus: keyof typeof CAMPAIGN_INTERNAL_STATUSES;
  loading: boolean;
  disabled: boolean;
  handleClickSubmitAndPay: () => void;
  isPaymentSkipped: boolean;
  classes: { [key: string]: string };
}

const TooltipContent = () => {
  return (
    <CustomTooltipContent title="Flyer design needed" content="Please upload your flyer design before continue." />
  );
};

export const SkipDesignSubmitAndPayButton = ({
  internalStatus,
  loading,
  disabled,
  handleClickSubmitAndPay,
  isPaymentSkipped,
  classes,
}: SkipDesignSubmitAndPayButtonProps) => {
  return internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT ? (
    <LightTooltip title={TooltipContent()} placement="top">
      <button
        onClick={handleClickSubmitAndPay}
        className={cn(classes.button, "mob-button-wrapper", {
          [classes.disabled]: disabled,
        })}
        disabled={disabled}
      >
        <p className={classes.buttonText}>{!isPaymentSkipped ? "Submit and Pay" : "Submit"}</p>
        {loading && isPaymentSkipped && <CircularProgress size={20} className={classes.progress} color="secondary" />}
      </button>
    </LightTooltip>
  ) : null;
};
