import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import type { TldrawCommand } from "pages/flyer/builder/drawer/types";

export function renamePage(app: TldrawApp, pageId: string, name: string): TldrawCommand {
  const { page } = app;

  return {
    id: "rename_page",
    before: {
      document: {
        pages: {
          [pageId]: { name: page.name },
        },
      },
    },
    after: {
      document: {
        pages: {
          [pageId]: { name: name },
        },
      },
    },
  };
}
