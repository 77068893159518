import React from "react";
import HintError from "static/media/hint-error.svg";
import { makeStyles } from "@material-ui/core";
import { styles } from "./styles";
const useStyles = makeStyles(() => styles);

export const MissionsLimitHint = ({ cityMissionsLimitError, isH2H }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.hintWrapper}
      style={cityMissionsLimitError.message ? { background: "#FCF4F4", border: "1px solid #FFC7C7" } : {}}
    >
      <div className={classes.hintTitle}>
        <div className={classes.hintTitleIconName}>
          <img src={HintError} />
          <span>Not enough {isH2H ? "areas" : "distributors"}!</span>
        </div>
      </div>
      <span>{cityMissionsLimitError.message}</span>
    </div>
  );
};
