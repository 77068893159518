/* eslint-disable @typescript-eslint/no-unused-vars */
import { FORMATS, GAP_BETWEEN_PAGES } from "pages/flyer/builder/constants";
import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import {
  DashStyle,
  DocumentOrientation,
  Patch,
  TDShape,
  TDShapeType,
  TldrawCommand,
} from "pages/flyer/builder/drawer/types";

export function showGuidelines(app: TldrawApp, pageId = app.currentPageId): TldrawCommand {
  const { currentPageId, document } = app;

  const beforeShapes: Record<string, Patch<TDShape> | undefined> = {};
  const afterShapes: Record<string, Patch<TDShape> | undefined> = {};
  const currentFormat = document.format;
  const currentOrientation = document.orientation;

  const isPortraitOrientation = currentOrientation === DocumentOrientation.Portrait;

  // const isTearaway = FORMATS[currentFormat] === FORMATS.TEARAWAY;

  const CANVAS_WIDTH = FORMATS[currentFormat].width;
  const CANVAS_HEIGHT = FORMATS[currentFormat].height;

  const OTHER_FORMATS_GL_WIDTH = isPortraitOrientation ? CANVAS_WIDTH - 30 : CANVAS_HEIGHT - 30;
  const OTHER_FORMATS_GL_HEIGHT = isPortraitOrientation ? CANVAS_HEIGHT - 30 : CANVAS_WIDTH - 30;

  //   const TEARAWAY_FIRST_GL_WIDTH = isPortraitOrientation
  //   ? FORMATS[currentFormat].width - 30
  //   : ((FORMATS[currentFormat].height - 30) / 3) * 2;
  // const TEARAWAY_FIRST_GL_HEIGHT = isPortraitOrientation
  //   ? ((FORMATS[currentFormat].height - 30) / 3) * 2
  //   : FORMATS[currentFormat].width - 30;
  // const TEARAWAY_SECOND_GL_WIDTH = isPortraitOrientation
  //   ? FORMATS[currentFormat].width - 30
  //   : (FORMATS[currentFormat].height - 30) / 3;
  // const TEARAWAY_SECOND_GL_HEIGHT = isPortraitOrientation
  //   ? (FORMATS[currentFormat].height - 30) / 3
  //   : FORMATS[currentFormat].width - 30;

  const ZERO_POINT_X_FIRST_PAGE_GL1 = isPortraitOrientation ? 15 : 15 + (CANVAS_WIDTH - CANVAS_HEIGHT) / 2;
  const ZERO_POINT_Y_FIRST_PAGE_GL1 = isPortraitOrientation ? 15 : 15 + (CANVAS_HEIGHT - CANVAS_WIDTH) / 2;
  const ZERO_POINT_X_SECOND_PAGE_GL1 = isPortraitOrientation
    ? FORMATS[currentFormat].width + GAP_BETWEEN_PAGES + 15
    : ZERO_POINT_X_FIRST_PAGE_GL1 + CANVAS_HEIGHT + GAP_BETWEEN_PAGES;
  const ZERO_POINT_Y_SECOND_PAGE_GL1 = isPortraitOrientation ? 15 : ZERO_POINT_Y_FIRST_PAGE_GL1;

  //   const ZERO_POINT_X_FIRST_PAGE_GL2 = isPortraitOrientation
  //   ? 15
  //   : 16 + (CANVAS_WIDTH - CANVAS_HEIGHT) / 2 + ((CANVAS_HEIGHT - 30) / 3) * 2;
  // const ZERO_POINT_Y_FIRST_PAGE_GL2 = isPortraitOrientation
  //   ? 16 + ((FORMATS[currentFormat].height - 30) / 3) * 2
  //   : (CANVAS_HEIGHT - CANVAS_WIDTH) / 2 + 15;
  // const ZERO_POINT_X_SECOND_PAGE_GL2 = isPortraitOrientation
  //   ? FORMATS[currentFormat].width + GAP_BETWEEN_PAGES + 15
  //   : ZERO_POINT_X_FIRST_PAGE_GL2 + CANVAS_HEIGHT + GAP_BETWEEN_PAGES;
  // const ZERO_POINT_Y_SECOND_PAGE_GL2 = ZERO_POINT_Y_FIRST_PAGE_GL2;

  const beforeAssets = app.document.assets;
  const afterAssets = { ...beforeAssets };

  const shapes: TDShape[] = [
    {
      id: "frontGL1",
      type: TDShapeType.Rectangle,
      name: "Rectangle",
      parentId: "page",
      childIndex: 100000,
      point: [ZERO_POINT_X_FIRST_PAGE_GL1, ZERO_POINT_Y_FIRST_PAGE_GL1],
      // size: isTearaway
      // ? [TEARAWAY_FIRST_GL_WIDTH, TEARAWAY_FIRST_GL_HEIGHT]
      // : [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      size: [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      rotation: 0,
      isTemplate: true,
      isLocked: true,

      style: {
        fillColor: "#fff",
        strokeColor: "#EE4360",
        strokeWidth: 1,
        // rounded: isTearaway ? 11 : 0,
        rounded: 0,
        transparency: 100,
        fontStyle: [],
        isStroked: true,
        isRoundingVisible: true,
        // size: SizeStyle.Small,
        fontSize: 12,
        isFilled: false,
        dash: DashStyle.Solid,
        scale: 1,
      },
    },
    {
      id: "frontGL2",
      type: TDShapeType.Rectangle,
      name: "Rectangle",
      parentId: "page",
      childIndex: 100000,
      //   point: isTearaway
      //   ? [ZERO_POINT_X_FIRST_PAGE_GL2, ZERO_POINT_Y_FIRST_PAGE_GL2]
      //   : [ZERO_POINT_X_FIRST_PAGE_GL1, ZERO_POINT_Y_FIRST_PAGE_GL1],
      // size: isTearaway
      //   ? [TEARAWAY_SECOND_GL_WIDTH, TEARAWAY_SECOND_GL_HEIGHT]
      //   : [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      point: [ZERO_POINT_X_FIRST_PAGE_GL1, ZERO_POINT_Y_FIRST_PAGE_GL1],
      size: [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      rotation: 0,
      isTemplate: true,
      isLocked: true,
      style: {
        fillColor: "#fff",
        strokeColor: "#EE4360",
        strokeWidth: 1,
        // rounded: isTearaway ? 11 : 0,
        rounded: 0,
        transparency: 100,
        fontStyle: [],
        isStroked: true,
        isRoundingVisible: true,
        // size: SizeStyle.Small,
        fontSize: 12,
        isFilled: false,
        dash: DashStyle.Solid,
        scale: 1,
      },
    },

    {
      id: "backGL1",
      type: TDShapeType.Rectangle,
      name: "Rectangle",
      parentId: "page",
      childIndex: 100000,
      point: [ZERO_POINT_X_SECOND_PAGE_GL1, ZERO_POINT_Y_SECOND_PAGE_GL1],
      // size: isTearaway
      //   ? [TEARAWAY_FIRST_GL_WIDTH, TEARAWAY_FIRST_GL_HEIGHT]
      //   : [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      size: [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      rotation: 0,
      isTemplate: true,
      isLocked: true,
      style: {
        fillColor: "#fff",
        strokeColor: "#EE4360",
        strokeWidth: 1,
        // rounded: isTearaway ? 11 : 0,
        rounded: 0,
        transparency: 100,
        fontStyle: [],
        isStroked: true,
        isRoundingVisible: true,
        // size: SizeStyle.Small,
        fontSize: 12,
        isFilled: false,
        dash: DashStyle.Solid,
        scale: 1,
      },
    },

    {
      id: "backGL2",
      type: TDShapeType.Rectangle,
      name: "Rectangle",
      parentId: "page",
      childIndex: 100000,
      //   point: isTearaway
      //   ? [ZERO_POINT_X_SECOND_PAGE_GL2, ZERO_POINT_Y_SECOND_PAGE_GL2]
      //   : [ZERO_POINT_X_SECOND_PAGE_GL1, ZERO_POINT_Y_SECOND_PAGE_GL1],
      // size: isTearaway
      //   ? [TEARAWAY_SECOND_GL_WIDTH, TEARAWAY_SECOND_GL_HEIGHT]
      //   : [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      point: [ZERO_POINT_X_SECOND_PAGE_GL1, ZERO_POINT_Y_SECOND_PAGE_GL1],
      size: [OTHER_FORMATS_GL_WIDTH, OTHER_FORMATS_GL_HEIGHT],
      rotation: 0,
      isTemplate: true,
      isLocked: true,
      style: {
        fillColor: "#fff",
        strokeColor: "#EE4360",
        strokeWidth: 1,
        // rounded: isTearaway ? 11 : 0,
        rounded: 0,
        transparency: 100,
        fontStyle: [],
        isStroked: true,
        isRoundingVisible: true,
        // size: SizeStyle.Small,
        fontSize: 12,
        isFilled: false,
        dash: DashStyle.Solid,
        scale: 1,
      },
    },
  ];

  shapes.forEach((shape) => {
    beforeShapes[shape.id] = undefined;
    afterShapes[shape.id] = shape;
  });

  return {
    id: "create",
    before: {
      document: {
        assets: beforeAssets,
        isShowGuidelines: app.state.document.isShowGuidelines,
        pages: {
          [currentPageId]: {
            shapes: beforeShapes,
          },
        },
      },
    },
    after: {
      document: {
        isShowGuidelines: !app.state.document.isShowGuidelines,
        assets: afterAssets,
        pages: {
          [currentPageId]: {
            shapes: afterShapes,
          },
        },
      },
    },
  };
}
