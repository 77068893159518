import { memo, useCallback, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

import style from "./style.js";

const Input = (props) => {
  const {
    value = "",
    onChange,
    onKeyDown = () => {},
    variant,
    type,
    classesMap = undefined,
    hidePlaceholderOnFocus,
    placeholder,
    label,
    disabled,
    onBlur,
    inputProps,
    error,
    cypressID,
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef();

  const handleChange = useCallback(
    (e) => {
      onChange && onChange(e.target.value);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    onBlur && onBlur(true);
    setIsFocus(false);
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  return (
    <Box width="100%" position="relative">
      <TextField
        className={classesMap}
        color="secondary"
        inputRef={inputRef}
        fullWidth
        size="small"
        placeholder={hidePlaceholderOnFocus && isFocus ? "" : placeholder}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={value}
        label={!value && !isFocus ? label : ""}
        variant={variant}
        type={type}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={!!error && !isFocus}
        helperText={!isFocus && error}
        disabled={disabled}
        inputProps={inputProps}
        cypress_id={cypressID}
      />
    </Box>
  );
};

export default memo(withStyles(style)(Input));
