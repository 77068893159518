import { useParams } from "react-router-dom";
import { authorization } from "module/auth";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { IfElse } from "components/logic";

import style from "./style";
import ROUTING from "../../../config/routing";

const AccessDenied = ({ classes }) => {
  const { clientId: clientIdParam } = useParams();
  const { clientId } = authorization.getUser();

  const replaceUrl = () => {
    window.location.replace(`client/${clientId}/campaign/channel`);
  };

  const logout = () => {
    window.location.replace(ROUTING.LOGIN);
    authorization.logout();
  };

  return (
    <IfElse condition={!clientId && !clientIdParam}>
      <Box className={classes.wrapper}>
        <Typography variant="h5" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Please log in as a client.
        </Typography>
        <Button size="large" className={classes.logoutBtn} onClick={logout}>
          Log out
        </Button>
      </Box>
      <Box className={classes.wrapper}>
        <Typography variant="h5" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          You don’t have permission to access this company.
        </Typography>
        <Box className={classes.btnsWrapper}>
          <Button size="large" className={classes.logoutBtn} onClick={replaceUrl}>
            New campaign
          </Button>
          <Button size="large" className={classes.logoutBtn} onClick={logout}>
            Log out
          </Button>
        </Box>
      </Box>
    </IfElse>
  );
};

export default withStyles(style)(AccessDenied);
