import { withStyles } from "@material-ui/core";
import cn from "classnames";
import style from "pages/flyer/flyerTypePage/components/DragAndDropArea/style";
import React, { memo, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as FileStick } from "static/media/file-stick.svg";
import { ReactComponent as UploadDesign2 } from "static/media/upload-icon.svg";

interface FileRejection {
  errors: { code: string }[];
}
interface IProps {
  classes: any;
  files: Array<File>;
  newVersion?: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  onDropRejected: (fileRejections: FileRejection[]) => void;
  fileType?: string;
  error?: string | null;
}

const DragAndDropArea: React.FC<IProps> = ({
  classes,
  files,
  newVersion,
  fileType,
  onDrop = () => {},
  onDropRejected = () => {},
  error,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    accept: fileType || undefined,
    multiple: true,
  });

  return (
    <>
      <div className={cn(classes.container, "mob-hidden")} data-cy="uploadDesignBlock">
        <div
          {...getRootProps()}
          className={cn(newVersion ? classes.dropBoxV2 : classes.dropBox, isDragActive ? classes.dropBoxActive : null)}
        >
          <input {...getInputProps()} data-cy="uploadDesignInput" />
          {files.length ? (
            <div className={classes.fileName}>
              <FileStick></FileStick>
              {files[0].name}
            </div>
          ) : (
            <>
              <UploadDesign2 />
              <p className={classes.textTitle} data-cy="uploadDesignTextInBlock">
                Click or move your file(s) here
              </p>
              {newVersion ? (
                <>
                  <p className={classes.text} data-cy="uploadDesignTextInBlock">
                    Max file size is 25 Mb{" "}
                  </p>
                  <p className={classes.text} data-cy="uploadDesignTextInBlock">
                    Two sides in one PDF
                  </p>
                </>
              ) : null}

              {error ? (
                <div className={classes.error} data-cy="uploadDesignErrorMessage">
                  {error}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>

      {/* // mob-version */}

      <div style={{ width: "100%" }} className={cn(classes.container, "desk-hidden")}>
        <div
          {...getRootProps()}
          className={cn(
            newVersion ? (classes.dropBoxV2, "mob-attach-btn") : classes.dropBox,
            isDragActive ? classes.dropBoxActive : null
          )}
        >
          <input {...getInputProps()} />
          {files.length ? (
            <div className={classes.fileName}>
              <FileStick></FileStick>
              {files[0].name}
            </div>
          ) : (
            <>
              {/* <UploadDesign2 /> */}
              <p className={cn(classes.textTitle, "mob-text-title")}>+ Attach file</p>
              {newVersion ? (
                <>
                  {/* <p className={classes.text}>Max file size is 25 Mb </p>
            <p className={classes.text}>Two sides in one PDF</p> */}
                </>
              ) : null}

              {error ? <div className={classes.error}>{error}</div> : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(withStyles(style)(DragAndDropArea));
