const style = {
  modalWrapper: {
    width: "100vw",
    height: "100vh",
    position: "absolute" as const,
    top: "-80px",
    left: 0,
    zIndex: 20,
  },
  backdropFilter: {
    width: "100%",
    height: "calc(100% + 80px)",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modal: {
    maxWidth: 632,
    width: "100%",
    backgroundColor: "#fff",
    zIndex: 1,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px 58px 50px",
    borderRadius: 8,
  },
  closeButton: {
    position: "absolute" as const,
    top: 30,
    right: 30,
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
    cursor: "pointer",
  },
  header: {
    fontFamily: "Proxima Nova Alt",
    fontSize: 24,
    fontWeight: 700,
    color: "#011533",
    alignSelf: "center",
    marginBottom: 10,
    marginTop: 22,
  },
  info: {
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: 35,
    color: "#00112B",
  },
  centered: {
    textAlign: "center" as const,
  },
  deepPink: {
    color: "#DE2F4C",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    width: 152,
    height: 48,
    textTransform: "none" as const,
    borderColor: "#273754",
    borderRadius: 16,
    padding: "14px 20px",
    fontSize: 16,
    color: "#00112B",
    fontWeight: "bold" as const,
    "&:disabled": {
      backgroundColor: "#C2C8D1",
    },
  },
  submitButton: {
    backgroundColor: "#EE4360",
    border: "none",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#EE4360",
    },
    "&:disabled": {
      backgroundColor: "#C2C8D1",
    },
  },
  skipButton: {
    border: "none",
  },
  autoWidth: {
    width: "auto",
  },
};

export default style;
