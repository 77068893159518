import * as React from "react";

export function VerticalAlignBottomIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={20} height={20} viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.2 2.3a.28.28 0 0 0-.27-.3.27.27 0 0 0-.27.27v4.78l-.89-.89a.27.27 0 0 0-.39 0 .28.28 0 0 0 0 .38l1.35 1.35a.3.3 0 0 0 .2.08.27.27 0 0 0 .19-.08l1.35-1.35a.28.28 0 0 0 0-.38.26.26 0 0 0-.38 0l-.89.89Zm3.1 5.06a.34.34 0 0 1 0-.67h4.06a.34.34 0 1 1 0 .67Zm0-2a.34.34 0 0 1 0-.68h4.06a.34.34 0 0 1 0 .68ZM5 3a.34.34 0 0 0 .33.34h4.03a.34.34 0 1 0 0-.67H5.3A.33.33 0 0 0 5 3Z"
        style={{
          fill: "#010101",
          fillRule: "evenodd",
        }}
      />
    </svg>
  );
}
