import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const createSSCampaign = async (input) =>
  callAuthorizedGraphQLEndpoint(
    `mutation createSSCampaign($input: CreateSSCampaignInput!){
        createSSCampaign(input: $input){
          campaignId
          clientId
        }
    }`,
    {
      input,
    }
  );

export const updateCampaign = (payload) =>
  callAuthorizedGraphQLEndpoint(
    `mutation updateCampaign(
    $input: UpdateCampaignInput
  ) {
    updateCampaign(
      input: $input,
    ) { 
      campaign { 
        id 
      }
    }
  }`,
    {
      input: {
        ...payload,
      },
    }
  );
