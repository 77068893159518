import { styled } from "pages/flyer/builder/drawer/styles/index";

export const StyledCustomInput = styled("button", {
  display: "flex",
  alignItems: "center",
  gap: "7px",
  padding: "6px 8px",
  border: "1px solid",
  borderColor: "$lightBlue",
  borderRadius: "4px",
  color: "white",
  backgroundColor: "#EE4360",
  fontWeight: 700,
  cursor: "pointer",
});

export default StyledCustomInput;
