import React, { useState } from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

const ChatView = ({ setRef, classes }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className={classnames(classes.root, "display-none")}>
      <div style={{ height: "calc(80vh - 126px)", display: isOpen ? "none" : "initial" }} ref={(c) => setRef(c)} />
      {isOpen ? (
        <div
          className={classnames(classes.wrapper, classes.message)}
          onClick={() => setIsOpen((prev) => !prev)}
          // eslint-disable-next-line react/no-unknown-property
          cypress_id={"chatBtn"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="420px"
            height="420px"
            viewBox="0 0 420 420"
            version="1.1"
          >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Shape">
                <path
                  d="M187.402765,324.536547 L305.950138,417.398656 C307.890958,418.88487 309.079928,419.636719 310.233929,419.636719 C313.521083,419.636719 314.500236,417.416141 314.500236,413.726835 L314.500236,325.900367 C314.500236,319.69324 318.574209,314.762509 324.081941,314.762509 L367.182129,314.727539 C405.981041,314.727539 419.636719,295.599099 419.636719,279.757812 L419.636719,34.9697266 C419.636719,17.4848633 406.558041,0 366.937341,0 L52.2622563,0 C14.0228604,0 0,16.9428325 0,34.9697266 L0,279.757812 C0,296.700645 14.4774668,314.727539 52.4545898,314.727539 L157.36377,314.727539 C157.36377,314.727539 179.307273,315.339509 187.402765,324.536547 L187.402765,324.536547 Z"
                  fill="#FFFFFF"
                />
                <ellipse id="Oval-1" fill="#EE4360" cx="115.347661" cy="162.184556" rx="28.3476608" ry="28.1845557" />
                <ellipse id="Oval-1" fill="#EE4360" cx="210.698884" cy="162.184556" rx="28.3476608" ry="28.1845557" />
                <ellipse id="Oval-1" fill="#EE4360" cx="306.050106" cy="162.184556" rx="28.3476608" ry="28.1845557" />
              </g>
            </g>
          </svg>
        </div>
      ) : (
        <div
          className={classnames(classes.wrapper, classes.close)}
          onClick={() => setIsOpen((prev) => !prev)}
          // eslint-disable-next-line react/no-unknown-property
          cypress_id={"chatBtn"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="312px"
            height="312px"
            viewBox="0 0 312 312"
            version="1.1"
          >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M178.500682,155.862614 L306.579682,28.4831591 C312.720136,22.4048864 312.720136,12.5646136 306.579682,6.50188636 C300.454773,0.423613636 290.505682,0.423613636 284.380773,6.50188636 L156.410591,133.772523 L27.2589545,4.60534091 C21.1340455,-1.53511364 11.1849545,-1.53511364 5.06004545,4.60534091 C-1.06486364,10.7613409 -1.06486364,20.7259773 5.06004545,26.8664318 L134.118409,155.940341 L4.59368182,284.749977 C-1.53122727,290.82825 -1.53122727,300.668523 4.59368182,306.73125 C10.7185909,312.809523 20.6676818,312.809523 26.7925909,306.73125 L156.2085,178.030432 L284.847136,306.684614 C290.972045,312.825068 300.921136,312.825068 307.046045,306.684614 C313.170955,300.528614 313.170955,290.563977 307.046045,284.423523 L178.500682,155.862614 L178.500682,155.862614 Z"
                id="Close"
                fill="#FFFFFF"
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ChatView);
