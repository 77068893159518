import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { styles } from "./styles";

const TIMER_KEY = "resend_timer";
const useStyles = makeStyles(styles);

const ResendEmailTimer = ({ sendVerificationEmail }) => {
  const classes = useStyles();
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const expireTime = localStorage.getItem(TIMER_KEY);
    const currentTime = Date.now();

    if (expireTime) {
      const timeDiff = Math.floor((expireTime - currentTime) / 1000);
      if (timeDiff > 0) {
        setCounter(timeDiff);
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [counter]);

  const handleResendClick = async (e) => {
    if (e) e.preventDefault();

    if (counter > 0) {
      return;
    }

    const success = await sendVerificationEmail(e);
    if (success) {
      const currentTime = Date.now();
      const expireTime = currentTime + 120000;

      localStorage.setItem(TIMER_KEY, expireTime);
      setCounter(120);
    }
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  return (
    <React.Fragment>
      {counter === 0 ? (
        <a
          onClick={(e) => {
            handleResendClick(e);
          }}
          className={classes.resendLink}
        >
          <b>Resend verification email</b>
        </a>
      ) : (
        <b className={classes.resendLabel}>Wait {formatTime(counter)} to resend</b>
      )}
    </React.Fragment>
  );
};

export default ResendEmailTimer;
