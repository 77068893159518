import { styled } from "pages/flyer/builder/drawer/styles/index.ts";

export const StyledLayout = styled("div", {
  position: "absolute",
  height: "100%",
  width: "100%",
  minHeight: 0,
  minWidth: 0,
  maxHeight: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  boxSizing: "border-box",
  outline: "none",

  "& .tl-container": {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
  },

  "& input, textarea, button, select, label, button": {
    webkitTouchCallout: "none",
    webkitUserSelect: "none",
    "-webkit-tap-highlight-color": "transparent",
    "tap-highlight-color": "transparent",
  },
});

export const StyledUI = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  padding: "8px 8px 0 8px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  pointerEvents: "none",
  zIndex: 2,
  "& > *": {
    pointerEvents: "all",
  },
});

export const StyledSpacer = styled("div", {
  flexGrow: 2,
});

export const StyledLoader = styled("div", {
  zIndex: 100,
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff90",
});
