import { convertArrayToStringSeperatedBySpecial, convertStringSeperatedBySpecialToArray } from "utils/index";
import moment from "moment";
import { VisibilityIcon } from "static/media/visibility-icon";
import { DeleteIcon } from "static/media/delete-icon";
import { HideIcon } from "static/media/hide-icon";
import { apiClient } from "module/api";
import { addBusinessDays } from "components/date-picker/utils/controlDate";
import { getCityLocations } from "utils/getCityLocations";
import { getDistributionZones, checkIsCampaignNameExist, updateSelfServeCampaignData } from "../../../../graphQL";

const updateSelectedCitiesLocations = ({ address, cityLocationsInsideDZ, shouldDeleteLocation }) => {
  return cityLocationsInsideDZ.reduce((acc, curr) => {
    if (curr.id === address.id) {
      if (shouldDeleteLocation) {
        return acc;
      } else {
        acc.push({ ...curr, selected: false });
      }
    } else {
      acc.push({ ...curr });
    }
    return acc;
  }, []);
};

const handleAddressDelete = async ({
  city,
  address,
  setAnchorEl,
  updateCity,
  campaignId,
  setDeletingLocation,
  clientId,
}) => {
  setDeletingLocation(true);
  const updatedAddresses = city.addresses.filter((item) => item.id !== address.id);
  if (setAnchorEl) {
    setAnchorEl(null);
  }

  // need to fetch again locations in case a custom one was saved recently and not in cityLocationsInsideDZ
  const campaignCustomLocations = await getCityLocations({ cityId: city.id, campaignId, isSelfServe: true, clientId });
  const currentLocation = campaignCustomLocations.find((location) => location.id === address.id);

  // if the location saved has campaignId but does not have
  // cityId it means that it should be deleted from the both db
  // and not fetch it again
  const shouldDeleteLocation = !!currentLocation && !!currentLocation.campaignId && !currentLocation.clientId;
  if (shouldDeleteLocation) {
    await apiClient.deleteLocation({ id: address.id });
    await updateSelfServeCampaignData({
      campaignId: currentLocation.campaignId,
      selectedCities: [city].reduce((acc, city) => {
        if (!city.id) return acc;
        const { id, isVisible, lat, lng, name, nameWithState, isAddressesPopupOpened } = city;
        return [
          ...acc,
          {
            addresses: updatedAddresses,
            id,
            isVisible,
            lat,
            lng,
            name,
            nameWithState,
            isAddressesPopupOpened,
          },
        ];
      }, []),
    });
  }
  const selectedCitiesLocations = updateSelectedCitiesLocations({
    address,
    cityLocationsInsideDZ: city.cityLocationsInsideDZ,
    shouldDeleteLocation,
  });
  updateCity({ addresses: updatedAddresses, cityLocationsInsideDZ: selectedCitiesLocations });
  setDeletingLocation(false);
};

const fetchCityDistributionZones = async (cityId) => {
  const request = await getDistributionZones(cityId);
  if (request.status === 200) {
    const {
      data: { distributionZones },
    } = await request.json();
    return distributionZones;
  } else return { h2h: [], d2d: [] };
};

const handlePopoverClose = ({ setAnchorEl, updateCity, city }) => {
  setAnchorEl(null);
  const updatedCity = {
    addresses: city.addresses.map((selectedAddress) => ({
      ...selectedAddress,
      isAddressOptionsOpened: false,
    })),
    isCityOptionsOpened: false,
  };

  updateCity(updatedCity);
};

const showHideDeleteCityPopup = ({ value, updateCity }) => {
  updateCity({ showDeleteCityPopup: value, isCityOptionsOpened: false });
};

const showHideDeleteAddressPopup = ({ city, addressId, value, setAddresses }) => {
  const updatedAddresses = city.addresses.map((address) =>
    address.id === addressId
      ? {
          ...address,
          showDeleteAddressPopup: value,
          isAddressOptionsOpened: false,
        }
      : address
  );

  setAddresses(updatedAddresses);
};

// const changeVisibilityOnArea = ({ city, isVisible, addressId, updateCity }) => {
//   const updatedCity = {
//     isVisible: true,
//     addresses: city.addresses.map((address) =>
//       address.id === addressId
//         ? {
//             ...address,
//             isVisible,
//             isAddressOptionsOpened: false,
//           }
//         : address
//     ),
//   };
//   updateCity(updatedCity);
// };

// const handleMenuItemClick = ({ menuItem, city, addressId, setAnchorEl, updateCity, setAddresses }) => {
//   handlePopoverClose({ setAnchorEl, city, updateCity });
//   setTimeout(() => {
//     switch (menuItem) {
//       case "select_entire_city":
//         return console.log("select_entire_city");
//       case "delete_city":
//         return showHideDeleteCityPopup({ value: true, updateCity });
//       case "delete_area":
//         return showHideDeleteAddressPopup({ city, addressId, value: true, setAddresses });
//       // case "delete_location":
//       //   return handleAddressDelete({
//       //     city,
//       //     address,
//       //     setAnchorEl,
//       //     updateCity,
//       //   });
//       case "hide_area": {
//         return changeVisibilityOnArea({ isVisible: false, addressId, city, updateCity });
//       }
//       case "show_area":
//         return changeVisibilityOnArea({ isVisible: true, city, addressId, updateCity });
//       default:
//         break;
//     }
//   }, 200);
// };

const handleAddressOptionsClick = ({ event, city, address, setAnchorEl, setAddresses }) => {
  setAnchorEl(event.currentTarget);

  const updatedAddresses = city.addresses.map((selectedAddress) =>
    selectedAddress.id === address.id
      ? { ...selectedAddress, isAddressOptionsOpened: true, editMode: true }
      : { ...selectedAddress, isAddressOptionsOpened: false, editMode: false }
  );
  setAddresses(updatedAddresses);
};

const getAreaPopupOptions = (isVisible) => {
  const popupOptions = [];

  if (isVisible) {
    popupOptions.push(
      {
        label: "Hide Area",
        value: "hide_area",
        Icon: HideIcon,
      } /*, { label: "Select Location", value: "select_area" }*/
    );
  } else {
    popupOptions.push({ label: "Show Area", value: "show_area", Icon: VisibilityIcon });
  }

  popupOptions.push({ label: "Delete Area", value: "delete_area", Icon: DeleteIcon });

  return popupOptions;
};

const getLocationPopupOptions = (isVisible) => {
  const popupOptions = [];

  if (isVisible) {
    popupOptions.push(
      {
        label: "Hide Location",
        value: "hide_area",
        Icon: HideIcon,
      } /*, { label: "Select Location", value: "select_area" }*/
    );
  } else {
    popupOptions.push({ label: "Show Location", value: "show_area", Icon: VisibilityIcon });
  }

  popupOptions.push({ label: "Delete Location", value: "delete_location", Icon: DeleteIcon });

  return popupOptions;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getCityPopupOptions = ({ isVisible, isEmpty }) => {
  const popupOptions = [{ label: "Delete city", value: "delete_city" }];
  // COMMENTING THIS SINCE NOW ONLY ONE CITY CAN BE SELECTED
  // if (!isEmpty) {
  //   if (isVisible) {
  //     popupOptions.unshift({ label: "Hide city", value: "hide_city" });
  //   } else {
  //     popupOptions.unshift({ label: "Show city", value: "show_city" });
  //   }
  // }

  return popupOptions;
};

const nameValidation = (name) => {
  if (name.length > 32) return name.slice(0, 32);
  return name.replace(/[^a-z0-9 +-]/gi, "");
};

const isValidCampaignName = async ({ campaignName, clientId, campaignId }) => {
  if (campaignName) {
    const { campaignIds } = await checkIsCampaignNameExist(clientId, campaignName.trim());
    if (campaignIds.length && (!campaignId || !campaignIds.includes(campaignId))) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

const handleMissionDelete = ({ city, location, setAddresses, newStartDates, newTimes, setAnchorEl }) => {
  const updatedAddresses = city.addresses.reduce((acc1, address) => {
    if (address.id !== location.id) return [...acc1, address];

    return [
      ...acc1,
      {
        ...address,
        startDate: convertArrayToStringSeperatedBySpecial(newStartDates),
        time: convertArrayToStringSeperatedBySpecial(newTimes),
      },
    ];
  }, []);

  if (setAnchorEl) {
    setAnchorEl(null);
  }

  setAddresses(updatedAddresses);
};

const isInvalidMission = (startDate, timezone, defaultCampaignLimits = 14) => {
  const minStartDateTZ = addBusinessDays(new Date(), defaultCampaignLimits, timezone);
  const startDateWithoutHours = moment(startDate).format("DD/MM/YYYY");
  const minDateWithoutHours = moment(minStartDateTZ).format("DD/MM/YYYY");
  const daysDiff = moment(startDateWithoutHours, "DD/MM/YYYY", true).diff(
    moment(minDateWithoutHours, "DD/MM/YYYY", true),
    "days"
  );

  return daysDiff < 0;
};

const deleteInvalidMissionsInPreciseLocation = ({
  city,
  setAddresses,
  setAnchorEl,
  timezone,
  defaultCampaignLimits,
}) => {
  const updatedAddresses = city.addresses.map((address) => {
    const startDates = convertStringSeperatedBySpecialToArray(address.startDate);
    const times = convertStringSeperatedBySpecialToArray(address.time);
    const newStartDates = [];
    const newTimes = [];

    startDates.forEach((startDate, index) => {
      if (isInvalidMission(startDate, timezone, defaultCampaignLimits)) return;
      newStartDates.push(startDate);
      newTimes.push(times[index]);
    });

    return {
      ...address,
      startDate: convertArrayToStringSeperatedBySpecial(newStartDates),
      time: convertArrayToStringSeperatedBySpecial(newTimes),
    };
  });

  if (setAnchorEl) {
    setAnchorEl(null);
  }

  setAddresses(updatedAddresses);
};

const displayAddressName = (addresses) => {
  const addresesByType = addresses.reduce(
    (acc, curr, i) => {
      if (curr.value?.toLowerCase().includes("area")) {
        acc.areas.push({ value: `Area ${acc.areas.length + 1}`, index: i });
      } else if (curr.value?.toLowerCase().includes("polygon")) {
        acc.polygons.push({ value: `Polygon ${acc.areas.length + 1}`, index: i });
      } else if (curr.value?.toLowerCase().includes("circle")) {
        acc.circles.push({ value: `Circle ${acc.areas.length + 1}`, index: i });
      }
      return acc;
    },
    { areas: [], polygons: [], circles: [] }
  );

  return Object.values(addresesByType)
    .reduce((acc, curr) => {
      return [...acc, ...curr];
    }, [])
    .sort((a, b) => a.index - b.index);
};

export {
  handleAddressDelete,
  handlePopoverClose,
  showHideDeleteCityPopup,
  showHideDeleteAddressPopup,
  // handleMenuItemClick,
  handleAddressOptionsClick,
  getAreaPopupOptions,
  getCityPopupOptions,
  getLocationPopupOptions,
  nameValidation,
  isValidCampaignName,
  handleMissionDelete,
  isInvalidMission,
  deleteInvalidMissionsInPreciseLocation,
  updateSelectedCitiesLocations,
  displayAddressName,
  fetchCityDistributionZones,
};
