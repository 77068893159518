const SvgLocation = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.6673 8.00716C16.6673 10.3965 14.2428 13.9625 12.318 16.417C11.3138 17.6975 10.8117 18.3378 10.0007 18.3378C9.18962 18.3378 8.68752 17.6975 7.68332 16.417C5.75853 13.9625 3.33398 10.3965 3.33398 8.00716C3.33398 4.04512 6.31875 0.833252 10.0007 0.833252C13.6825 0.833252 16.6673 4.04512 16.6673 8.00716Z"
      stroke="#1D2943"
      strokeWidth="1.5"
    />
    <path
      d="M7.50065 8.33325C7.50065 9.71396 8.61994 10.8333 10.0007 10.8333C11.3814 10.8333 12.5007 9.71396 12.5007 8.33325C12.5007 6.95254 11.3814 5.83325 10.0007 5.83325C8.61994 5.83325 7.50065 6.95254 7.50065 8.33325Z"
      stroke="#1D2943"
      strokeWidth="1.5"
    />
  </svg>
);

export default SvgLocation;
