import { createContext, useCallback, useState, useContext, useEffect, useMemo } from "react";

export { default as Input } from "./input";
export { default as NumericInput } from "./numericInput";
export { default as Autocomplete } from "./autocomplete";
export { default as Button } from "./button";
export { default as Select } from "./select";

export const FormContext = createContext();

const checkIsFormValid = (data) =>
  Object.entries(data)
    .map(([, item]) => !item.runValidation())
    .every((item) => item);

export default function Form({ children, onUpdate, onSubmit }) {
  const [data, setData] = useState({});

  const deleteItem = useCallback((item) => {
    setData((data) => {
      const newData = { ...data };
      if (!item && newData[item]) {
        delete newData[item];
        return newData;
      }

      if (newData[item.name]) {
        if (item.group) {
          delete newData[item.group][item.name];
        } else {
          delete newData[item.name];
        }
      }
      return newData;
    });
  }, []);

  const updateItem = useCallback(
    (item) => {
      setData((data) => {
        const newState = {
          ...data,
        };

        if (item.group) {
          newState[item.group] = {
            ...data[item.group],
            [item.name]: { ...data[item.name], ...item },
          };
        } else {
          newState[item.name] = { ...data[item.name], ...item };
        }

        onUpdate &&
          onUpdate(
            item,
            newState,
            (item) => setData({ ...newState, ...item }),
            (item) => deleteItem(item),
            () => checkIsFormValid(newState)
          );

        return newState;
      });
    },
    [onUpdate, deleteItem]
  );

  const submit = useCallback(() => {
    onSubmit && onSubmit(data, checkIsFormValid(data));
  }, [onSubmit, data]);

  return (
    <FormContext.Provider value={{ updateItem, submit, deleteItem, data }}>
      {typeof children === "function" ? children(data) : children}
    </FormContext.Provider>
  );
}

export const FormHOC = (Component) => (props) =>
  (
    <Form>
      <Component {...props} />
    </Form>
  );

const findError = (value, validators) => {
  if (validators) {
    var error;
    for (let i = 0; i < validators.length; i++) {
      error = validators[i](value);
      if (error) break;
    }
    return error;
  } else {
    return true;
  }
};

const formatValue = (value, formators) => formators.reduce((acc, fu) => fu(acc), value);

/*eslint-disable */
export function useFormChildLogic(props) {
  const { name, initValue, validators, formators, group } = props;
  const form = useContext(FormContext);
  const [value, setValue] = useState(initValue);
  const [valueFormatted, setValueFormatted] = useState(formators && formatValue(value, formators));
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(validators ? !findError(initValue, validators) : true);
  const [error, setError] = useState(validators && findError(initValue, validators));

  const updateForm = useCallback(
    (value) => {
      if (validators) {
        const error = findError(value, validators);
        setError(error);
        setIsValid(!error);
      } else {
        setIsValid(true);
      }

      if (formators) {
        setValueFormatted(formatValue(value, formators));
      }

      setValue(value);
      setIsDirty(true);
    },
    [validators, formators]
  );

  useEffect(() => {
    form?.updateItem({
      name,
      value,
      valueFormatted,
      isDirty,
      isValid,
      group,
      update: (value) => updateForm(value),
      delete: () => form.deleteItem(name),
      runValidation: () => setIsDirty(true),
    });
  }, [name, value, valueFormatted, isDirty, isValid]);

  useEffect(() => () => form?.deleteItem(name), []);

  return useMemo(
    () => ({
      updateForm,
      findError,
      valueFormatted,
      value,
      isDirty,
      isValid,
      error,
    }),
    [valueFormatted, value, isDirty, isValid, error]
  );
}
/*eslint-enable */
