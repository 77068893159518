import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import type { TDShape, TldrawCommand } from "pages/flyer/builder/drawer/types";

export function updateShapes(
  app: TldrawApp,
  updates: ({ id: string } & Partial<TDShape>)[],
  pageId: string
): TldrawCommand {
  const ids = updates.map((update) => update.id);

  const change = TLDR.mutateShapes(
    app.state,
    ids.filter((id) => !app.getShape(id, pageId).isLocked),
    (_shape, i) => updates[i],
    pageId
  );

  return {
    id: "update",
    before: {
      document: {
        pages: {
          [pageId]: {
            shapes: change.before,
          },
        },
      },
    },
    after: {
      document: {
        pages: {
          [pageId]: {
            shapes: change.after,
          },
        },
      },
    },
  };
}
