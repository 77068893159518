import fetch from "node-fetch";

import CONFIG from "config/config";

import { authorization } from "module/auth";

/**
 * Call Graph QL Endpoint
 * @param {string} query
 * @param {object} variables
 * @param {string} gqlConfigKey
 * @returns {Promise<object>}
 */
export const callGraphQLEndpoint = function (authToken, query, variables, graphQLEndpoint, fetchOptions = {}) {
  const body = JSON.stringify({
    query,
    variables,
  });

  return new Promise((resolve, reject) => {
    fetch(graphQLEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(authToken ? { Authorization: "Bearer " + authToken } : {}),
      },
      body,
      ...fetchOptions,
    })
      .then(async (response) => {
        const result = await response.clone();
        const jsonResult = await result.json();
        if (jsonResult.errors) {
          const error = jsonResult.errors[0];

          if (
            error.message == "Failed to authenticate token" ||
            error.message == "Token expired. Please login again."
          ) {
            authorization.logout();
            window.location.reload(true);
          }
          reject(error);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const callAuthorizedGraphQLEndpoint = (
  query,
  variables,
  graphQLEndpoint = CONFIG.GRAPHQL_API_BASE_URL,
  fetchOptions
) => {
  const authToken = authorization.getAPIKey();

  return callGraphQLEndpoint(authToken, query, variables, graphQLEndpoint, fetchOptions);
};

export const callNoAuthorizedGraphQLEndpoint = (query, variables, graphQLEndpoint = CONFIG.GRAPHQL_API_BASE_URL) => {
  return callGraphQLEndpoint("", query, variables, graphQLEndpoint);
};
