export const AddressIconSelected = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.3334 6.40588C13.3334 8.31736 11.3938 11.1701 9.85394 13.1337C9.05058 14.1582 8.6489 14.6704 8.00008 14.6704C7.35126 14.6704 6.94958 14.1582 6.14622 13.1337C4.60638 11.1701 2.66675 8.31736 2.66675 6.40588C2.66675 3.23624 5.05456 0.666748 8.00008 0.666748C10.9456 0.666748 13.3334 3.23624 13.3334 6.40588Z"
        fill="#EE4360"
      />
      <path
        d="M6.00008 6.66675C6.00008 7.77132 6.89551 8.66675 8.00008 8.66675C9.10465 8.66675 10.0001 7.77132 10.0001 6.66675C10.0001 5.56218 9.10465 4.66675 8.00008 4.66675C6.89551 4.66675 6.00008 5.56218 6.00008 6.66675Z"
        fill="#EE4360"
      />
      <ellipse cx="8" cy="6.66675" rx="2" ry="2" fill="#E2E2FC" />
    </svg>
  );
};
