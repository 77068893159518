export default function LineToolIcon({ className }) {
  return (
    <svg
      className={className}
      width="10px"
      height="10px"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="10 10 512 512"
    >
      <g>
        <path
          d="M497,0h-62c-8.284,0-15,6.716-15,15v55.787L68.787,422H15c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h60
          c8.284,0,15-6.716,15-15v-53.787L441.213,92H497c8.284,0,15-6.716,15-15V15C512,6.716,505.284,0,497,0z M60,482H30v-30h30V482z
          M482,62h-32V30h32V62z"
        />
      </g>
    </svg>
  );
}
