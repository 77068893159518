import { useState, useMemo } from "react";
import { TIMELIST } from "../utils";

export const useControlDate = (oldDates: Date[]) => {
  const [oldSelectedDates, setOldSelectedDates] = useState<Date[]>(oldDates);
  const [newSelectedDates, setNewSelectedDates] = useState<Date[]>([]);

  const [selectedTime, setSelectedTime] = useState(TIMELIST[0]);

  const updatedOldDates = useMemo(
    () =>
      oldSelectedDates.reduce((arr: Date[], date) => {
        const timeOfDate = date.getTime();
        const id = newSelectedDates.findIndex((d) => d.getTime() === timeOfDate);

        if (id === -1) arr.push(date);

        return arr;
      }, []),
    [oldSelectedDates, newSelectedDates]
  );

  return useMemo(() => {
    const onChangeSelectedDate = (date: Date) => {
      const timeOfDate = date.getTime();

      setNewSelectedDates((prev) => {
        const newIndex = [...prev].findIndex((d) => d.getTime() === timeOfDate);
        if (newIndex !== -1) {
          return [...prev.slice(0, newIndex), ...prev.slice(newIndex + 1)];
        } else {
          return [...prev, date];
        }
      });
    };

    return {
      updatedOldDates,
      newSelectedDates,
      selectedTime,
      setOldSelectedDates,
      setNewSelectedDates,
      setSelectedTime,
      onChangeSelectedDate,
    };
  }, [oldSelectedDates, newSelectedDates, selectedTime]);
};

export default useControlDate;
