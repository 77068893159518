export const ShapesIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.5002 4.875C21.5002 6.18668 20.4369 7.25 19.1252 7.25C17.8136 7.25 16.7502 6.18668 16.7502 4.875C16.7502 3.56332 17.8136 2.5 19.1252 2.5C20.4369 2.5 21.5002 3.56332 21.5002 4.875Z"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M10.1252 18H12.5627H15.0002V9H6.00024V13.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M1.72437 9.75L14.4744 22.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
