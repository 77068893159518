import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";

const style = {
  root: {
    width: "12px",
    height: "18px",
    fill: "none",
  },
};

const SelectLocationIcon = ({ classes, ...props }) => (
  <SvgIcon {...props} className={classes.root} viewBox="0 0 12 18">
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.32125 10.2483L9.44792 16.0933L5.53292 17.5183L3.40542 11.6733L0.15625 13.7108L1.50625 0.36084L11.1204 9.72084L7.32125 10.2483ZM7.31208 15.0967L5.04958 8.88001L7.51625 8.53834L2.81625 3.96334L2.15792 10.4883L4.26625 9.16584L6.52875 15.3825L7.31208 15.0967Z"
        fill="#1D2943"
      />
    </svg>
  </SvgIcon>
);

export default withStyles(style)(SelectLocationIcon);
