import * as turf from "@turf/turf";
import { debounce } from "lodash";
import { getCarrierRoutesByCity } from "graphQL/query/searchFeatures";
import { formatFeaturesToOverlays } from "Hooks/useOverlaysFetch";
import preciseLocationSelected from "../../static/media/precise-location-selected.svg";
import preciseLocationSelectedFocused from "../../static/media/precise-location-selected-focused.svg";
import selfServepreciseLocationSelectedFocused from "../../static/media/precise-location-ss-selected-focused.svg";
import selfServeLocationSelected from "../../static/media/precise-location-ss-selected.svg";
import { AREA_TYPE, TURF_REWIND_OUTER_COORDS } from "./options";
import { MeetingLinkMap, DefaultMeetingLink } from "./constant";
import { MAP_TYPES } from "../../pages/constants";
const typeMarker = (isEditMode, isFixedLocation) => {
  if (isFixedLocation) {
    return isEditMode
      ? {
          url: preciseLocationSelectedFocused,
          anchor: new window.google.maps.Point(16, 43),
        }
      : {
          url: preciseLocationSelected,
        };
  } else {
    return isEditMode
      ? {
          url: selfServepreciseLocationSelectedFocused,
          anchor: new window.google.maps.Point(16, 43),
        }
      : {
          url: selfServeLocationSelected,
        };
  }
};

const getOptions = ({ editMode, isDistributionZone, draggable, args }) => ({
  ...args,
  strokeColor: isDistributionZone ? "#000000" : "#ee4360",
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: "#ee4360",
  fillOpacity: 0,
  clickable: !isDistributionZone,
  draggable: draggable || false,
  editable: isDistributionZone ? false : editMode,
  visible: true,
  zIndex: 1,
});

const drawCircle = (bounds, point, radius, dir) => {
  const d2r = Math.PI / 180; // degrees to radians
  const r2d = 180 / Math.PI; // radians to degrees
  const earthsradius = 6371000; // 3963 is the radius of the earth in miles use 6371 if using kilometers
  const points = 64;
  // find the raidus in lat/lon
  const rlat = (radius / earthsradius) * r2d;
  const rlng = rlat / Math.cos(point.lat * d2r);

  const extp = [];
  let start = 0;
  let end = 0;
  if (dir === 1) {
    end = points + 1;
  } // one extra here makes sure we connect the ends
  else {
    start = points + 1;
  }
  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    const theta = Math.PI * (i / (points / 2));
    const ey = point.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    const ex = point.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push(new window.google.maps.LatLng(ex, ey));
    bounds.extend(extp[extp.length - 1]);
  }
  return extp;
};

const createFigure = ({ figure, figureObj, activeSelectedCity, updateCity }) => {
  // let addressInEditMode = null;

  // // get address which has no missions and be in edit mode
  // const addressInEditModeAndNoMission = activeSelectedCity.addresses.filter(
  //   (address) => !address.startDate || !address.startDate.length
  // )[0];

  // if (addressInEditModeAndNoMission) {
  //   addressInEditMode = { ...addressInEditModeAndNoMission };
  // }

  // const selectedAddresses = activeSelectedCity.addresses.filter(
  //   (address) => !addressInEditMode || address.id != addressInEditMode.id
  // );
  const selectedAddresses = activeSelectedCity.addresses;

  const updatedCity = {
    isVisible: true,
    addresses:
      selectedAddresses.length === 1
        ? [
            ...[figureObj],
            ...selectedAddresses.map((address) => ({
              ...address,
              editMode: false,
            })),
          ]
        : [...selectedAddresses.map((address) => ({ ...address, editMode: false })), ...[figureObj]],
  };

  updateCity(updatedCity);
  if (figure.setMap) {
    figure.setMap(null);
  }

  // return addressInEditMode;
};

const mapPolygonBounds = (bounds) =>
  bounds?.reduce((acc, { lat, lng }) => [...acc, [turf.round(lng, 6), turf.round(lat, 6)]], []);

const getUnionPolygon = (area, newZone) => {
  let bounds;
  let poly2;

  if (newZone.type === MAP_TYPES.POLYGON) {
    bounds = mapPolygonBounds(newZone.paths);
    poly2 = turf.polygon([[...bounds, ...[bounds[0]]]]);
  } else {
    const center = [newZone.center.lng, newZone.center.lat];
    const options = { steps: 64, units: "kilometers" };
    poly2 = turf.circle(center, newZone.radius / 1000, options);
  }

  if (!Object.keys(area).length) {
    return poly2;
  }
  const union = turf.union(area, poly2);

  return union;
};

const mapAndRewindPoly = ({ zone, reverse = false }) => {
  let newPolygons = [];
  turf.geomEach(zone, (geom) =>
    geom.coordinates.forEach((linearRing) => {
      let newLinearRing = [];
      if (linearRing[0].length === 2) {
        newLinearRing = linearRing.map((ring) => [turf.round(ring[0], 6), turf.round(ring[1], 6)]);
      } else {
        newLinearRing = linearRing[0].map((ring) => [turf.round(ring[0], 6), turf.round(ring[1], 6)]);
      }
      const poly = turf.rewind(turf.polygon([newLinearRing]), { reverse });
      newPolygons.push(turf.getCoords(poly));
    })
  );
  return newPolygons;
};

const getDistributionZonePath = ({ operationZone }) => {
  const { distribution, exclusion } = operationZone.reduce(
    (acc, distributionZone) => {
      if (distributionZone.paths?.length <= 1) {
        return acc;
      }
      switch (distributionZone.areaType) {
        case AREA_TYPE.DISTRIBUTION:
          return {
            ...acc,
            [AREA_TYPE.DISTRIBUTION]: getUnionPolygon(acc[AREA_TYPE.DISTRIBUTION], distributionZone),
          };
        case AREA_TYPE.EXCLUSION:
          return {
            ...acc,
            [AREA_TYPE.EXCLUSION]: getUnionPolygon(acc[AREA_TYPE.EXCLUSION], distributionZone),
          };
        default:
          return acc;
      }
    },
    { [AREA_TYPE.DISTRIBUTION]: {}, [AREA_TYPE.EXCLUSION]: {} }
  );

  const polygon = turf.polygon([TURF_REWIND_OUTER_COORDS]);

  if (!Object.keys(distribution).length) {
    return polygon;
  }

  let mappedDistributionZone = [];
  let mappedExclusionZone = [];

  if (Object.keys(distribution).length) {
    mappedDistributionZone = [...mappedDistributionZone, ...mapAndRewindPoly({ zone: distribution, reverse: true })];
  }

  let zonesIntersection = null;
  if (Object.keys(exclusion).length) {
    zonesIntersection = turf.intersect(distribution, exclusion);
    if (zonesIntersection) {
      mappedExclusionZone = [...mappedExclusionZone, ...mapAndRewindPoly({ zone: zonesIntersection, reverse: false })];
    }
  }

  const outerRingsPoly = turf.multiPolygon(mappedDistributionZone);
  const maskedOuterRingsPoly = turf.mask(outerRingsPoly, polygon);
  const maskedOuterRings = turf.getCoords(maskedOuterRingsPoly);

  if (Object.keys(distribution).length && !zonesIntersection) {
    return maskedOuterRingsPoly;
  }

  const allRings = maskedOuterRings.concat(mappedExclusionZone);
  const finalPoly = turf.multiPolygon(allRings);

  return finalPoly;
};

const removeEditMode = ({ city, setAddresses }) => {
  let isChanged = false;
  const updatedAddresses = city.addresses.map((address) => {
    if (address.editMode) isChanged = true;
    return {
      ...address,
      editMode: false,
    };
  });

  if (isChanged) {
    setAddresses(updatedAddresses);
  }
};

const handleMapItemClick = ({ item, activeSelectedCity, updateMap, setAddresses }) => {
  const updatedAddresses = item.deleteOnClick
    ? activeSelectedCity.addresses.filter((a) => a.id !== item.id)
    : activeSelectedCity.addresses.map((address) =>
        address.id === item.id ? { ...item, editMode: true } : { ...address, editMode: false }
      );
  setAddresses(updatedAddresses);
  if (item?.position?.lat || item?.paths?.[0][0]) {
    updateMap({
      center: item?.position?.lat
        ? { lat: item.position.lat, lng: item.position.lng }
        : getPolygonCenterPoint(item?.paths?.[0]) || {},
    });
  }
};

const formatCoords = (point) => {
  return [point.lng, point.lat];
};

const formatToPolygon = (zones) => {
  const firstPoint = formatCoords(zones[0]);

  return [...zones.map((point) => formatCoords(point)), firstPoint];
};

const removeLatLngFromPolygon = (path) => {
  return path.map((coordinates) => coordinates.map((coordinate) => formatCoords(coordinate)));
};

const getZone = (city, type) => {
  let acc = {};

  if (city.distributionZones) {
    city.distributionZones.forEach((zone) => {
      if (zone.areaType === type) {
        if (zone.center) {
          const center = [zone.center.lng, zone.center.lat];
          const options = { steps: 64, units: "kilometers" };
          const circlePolygon = turf.circle(center, zone.radius / 1000, options);
          acc = Object.keys(acc).length ? turf.union(acc, circlePolygon) : circlePolygon;
        } else {
          if (zone.paths && zone.paths.length < 4) return; // polygons with less than 4 coords break turf
          const poly = turf.polygon([formatToPolygon(zone.paths)]);
          acc = Object.keys(acc).length ? turf.union(acc, poly) : poly;
        }
      }
    });
  }

  return acc;
};

const mapBoundaries = ({ data, cityId }) =>
  Object.entries(data).reduce((acc, [key, value]) => ({ ...acc, ...{ [key]: { ...value, cityId } } }), {});

const onMapInit = ({ newMap, mapInit, setMapInit }) => {
  if (!newMap || mapInit) return;
  setMapInit(newMap);
};

const renderDistributionZones = ({
  mapInit,
  activeSelectedCityDistributionZones,
  distributionZones,
  setDistributionZonePolygons,
  isD2D,
}) => {
  if (!mapInit || !activeSelectedCityDistributionZones) return;
  mapInit.setZoom(12);
  mapInit.data.forEach((feature) => mapInit.data.remove(feature)); //clear prev distribution zones
  const distributionZonesPaths = getDistributionZonePath({
    operationZone: activeSelectedCityDistributionZones,
  });

  const allCitiesDistributionZonesPaths = getDistributionZonePath({
    operationZone: distributionZones,
  });

  const convertToCoordinates = (coordinates) =>
    coordinates?.reduce((acc, bounds) => {
      return [
        ...acc,
        bounds.reduce((accumulator, points, index) => {
          if (points.length > 2) {
            return points.reduce((accumulator2, point, index) => {
              return index === points.length - 1 ? accumulator2 : [...accumulator2, { lat: point[1], lng: point[0] }];
            }, accumulator);
          } else {
            return index === bounds.length - 1 ? accumulator : [...accumulator, { lat: points[1], lng: points[0] }];
          }
        }, []),
      ];
    }, []);

  const coordinates = turf.getCoords(distributionZonesPaths);
  const unionCoords = convertToCoordinates(coordinates);

  const allCitiesDistributionZonesCoordinates = turf.getCoords(allCitiesDistributionZonesPaths);
  const allCitiesDistributionZonesUnionCoords = convertToCoordinates(allCitiesDistributionZonesCoordinates);

  const geometry = new window.google.maps.Data.Polygon(unionCoords);

  setDistributionZonePolygons([new window.google.maps.Polygon({ paths: allCitiesDistributionZonesUnionCoords })]);

  if (!isD2D) {
    mapInit.data.add({
      geometry: geometry,
    });
    mapInit.data.setStyle({
      strokeWeight: 0,
    });
  }
};

const removeSelectedPostCode = ({ mapInit, geoJsonForSelected }) => {
  if (!geoJsonForSelected || !Array.isArray(geoJsonForSelected)) {
    return;
  }

  geoJsonForSelected.forEach((feature) => {
    mapInit.data.remove(feature);
  });
};

const renderSelectedPostCode = ({ mapInit, selectedPostCode, geoJsonForSelected, cityId, updateMap }) => {
  if (!selectedPostCode || !selectedPostCode.paths) {
    return;
  }

  removeSelectedPostCode({ mapInit, geoJsonForSelected });

  const paths = Array.isArray(selectedPostCode.paths[0]) ? selectedPostCode.paths : [selectedPostCode.paths];
  const mappedPostCodeCoordinates = removeLatLngFromPolygon(paths);
  const postCodesAsGeoJsonFeatures = [
    {
      ...turf.polygon(mappedPostCodeCoordinates),
      properties: {
        id: selectedPostCode.id,
        cityId: cityId,
        clickable: true,
        name: selectedPostCode.name,
        type: "postCode",
        selected: false,
        draggable: false,
        deleteOnClick: false,
        paths: paths,
      },
    },
  ];

  const geoJsonObject = {
    type: "FeatureCollection",
    features: postCodesAsGeoJsonFeatures,
  };

  const features = mapInit.data.addGeoJson(geoJsonObject);
  updateMap({ geoJsonForSelected: features });

  mapInit.data.setStyle((feature) => {
    return setDataLayerStyles(feature);
  });
};

const renderPolygonsOnMap = ({
  mapInit,
  activeCityMissionBoundaries,
  handleMissionSelect,
  activeSelectedCityAddresses,
  isSubmitted,
  setHoveredMission,
}) => {
  const missionsAsGeoJsonFeatures = Object.values(activeCityMissionBoundaries).map((mission) => {
    const mappedMissionCoordinates = removeLatLngFromPolygon(mission.paths);
    const missionIsSaved = !!activeSelectedCityAddresses.find(
      (a) => String(a.id) === String(mission.id) && a.isVisible
    );
    const missionPolygonAsGeoJson = {
      ...turf.polygon(mappedMissionCoordinates),
      properties: {
        id: mission.id,
        cityId: mission.cityId,
        clickable: true,
        name: mission.name,
        type: "mission",
        selected: missionIsSaved,
        draggable: false,
        deleteOnClick: true,
        paths: mission.paths,
      },
    };
    return missionPolygonAsGeoJson;
  });

  const geoJsonObject = {
    type: "FeatureCollection",
    features: missionsAsGeoJsonFeatures,
  };

  mapInit.data.addGeoJson(geoJsonObject);

  mapInit.data.setStyle((feature) => {
    return setDataLayerStyles(feature);
  });

  if (!isSubmitted) {
    mapInit.data.addListener("click", (event) => {
      if (event.feature.getProperty("selected") === false && event.feature.getProperty("type") === "mission") {
        const paths = event.feature.getProperty("paths");
        const missionId = event.feature.getProperty("id");
        event.feature.setProperty("selected", true);
        handleMissionSelect({ missionId, paths, selected: true, isVisible: true });
      } else if (event.feature.getProperty("selected") && event.feature.getProperty("type") === "mission") {
        const paths = event.feature.getProperty("paths");
        const missionId = event.feature.getProperty("id");
        event.feature.setProperty("selected", false);
        handleMissionSelect({ missionId, paths, selected: false });
      }
    });

    mapInit.data.addListener("mouseover", (event) => {
      if (event.feature.getProperty("type") === "mission") {
        setHoveredMission(event.feature.getProperty("id"));
        event.feature.setProperty("hovered", true);
      }
    });

    mapInit.data.addListener("mouseout", (event) => {
      if (event.feature.getProperty("type") === "mission") {
        setHoveredMission("");
        event.feature.setProperty("hovered", false);
      }
    });
  }
};

const renderPolygonsOnEddmMap = ({
  googleMap,
  routes,
  handleRouteSelect,
  selectedRoutes,
  isSubmitted,
  setHoveredRoute,
  setIsCalculatingMultipleSelection,
  intersectedRoutesRef,
}) => {
  const routesAsGeoJsonFeatures = Object.values(routes).map((route) => {
    const mappedRouteCoordinates = removeLatLngFromPolygon(route.paths);
    const routeIsSelected = !!selectedRoutes.find((r) => String(r.id) === String(route.id));
    const routePolygonAsGeoJson = {
      ...turf.polygon(mappedRouteCoordinates),
      properties: {
        id: route.id,
        clickable: true,
        name: route.name,
        type: "mission",
        selected: routeIsSelected,
        draggable: false,
        deleteOnClick: true,
        paths: route.paths,
        geometry: route.geometry,
        metadata: { ...(route.metadata || {}) },
      },
    };
    return routePolygonAsGeoJson;
  });

  const geoJsonObject = {
    type: "FeatureCollection",
    features: routesAsGeoJsonFeatures,
  };

  googleMap.data.addGeoJson(geoJsonObject);

  googleMap.data.setStyle((feature) => {
    return setDataLayerStyles(feature);
  });

  if (!isSubmitted) {
    googleMap.data.addListener("click", (event) => {
      if (event.feature.getProperty("selected") === false && event.feature.getProperty("type") === "mission") {
        const paths = event.feature.getProperty("paths");
        const routeId = event.feature.getProperty("id");
        const metadata = event.feature.getProperty("metadata");
        event.feature.setProperty("selected", true);
        handleRouteSelect({ routeId, paths, selected: true, metadata });
      } else if (event.feature.getProperty("selected") && event.feature.getProperty("type") === "mission") {
        const paths = event.feature.getProperty("paths");
        const routeId = event.feature.getProperty("id");
        event.feature.setProperty("selected", false);
        handleRouteSelect({ routeId, paths, selected: false });
      } else if (event.feature.getProperty("isCircle")) {
        setIsCalculatingMultipleSelection(true);

        setTimeout(() => {
          const circleGeometry = getCircleGeometry(event);

          const circleGeoJson = turf.polygon([circleGeometry]);

          let intersectedRoutesMap = new Map();
          if (intersectedRoutesRef.current?.size) {
            intersectedRoutesMap = intersectedRoutesRef.current;
          } else {
            let intersectedRoutes = routesAsGeoJsonFeatures.filter((routeFeature) => {
              const isMultiPolygon = routeFeature.properties.geometry.type == "MultiPolygon";

              return isMultiPolygon
                ? calculateMultiPolygonIntercection(routeFeature.geometry.coordinates, circleGeoJson)
                : calculatePolygonIntercection(routeFeature.geometry.coordinates, circleGeoJson);
            });
            intersectedRoutes.forEach((route) => intersectedRoutesMap.set(route.properties.id, route));
          }

          googleMap.data.forEach((feature) => {
            const routeId = feature.getProperty("id");
            if (feature.getProperty("type") === "mission" && !!intersectedRoutesMap.get(routeId)) {
              const paths = feature.getProperty("paths");
              const metadata = feature.getProperty("metadata");
              feature.setProperty("selected", true);
              handleRouteSelect({ routeId, paths, selected: true, metadata });
            }
          });

          setIsCalculatingMultipleSelection(false);
        }, 0);
      }
    });

    googleMap.data.addListener("mouseover", (event) => {
      if (event.feature.getProperty("type") === "mission") {
        setHoveredRoute(event.feature.getProperty("metadata"));
        event.feature.setProperty("hovered", true);
      }
    });

    googleMap.data.addListener("mouseout", (event) => {
      if (event.feature.getProperty("type") === "mission") {
        setHoveredRoute(null);
        event.feature.setProperty("hovered", false);
      }
    });
  }
};

const getCircleGeometry = (event) => {
  const circleGeometry = event.feature
    .getGeometry()
    .getAt(0)
    .getArray()
    .map((latLng) => [latLng.lng(), latLng.lat()]);

  circleGeometry.push(circleGeometry[0]);
  return circleGeometry;
};

const calculateIntersections = debounce(
  (circleGeoJson, routesAsGeoJsonFeatures, intersectedRoutesRef, setIsCalculatingRoutesIntercection) => {
    const intersectedRoutes = routesAsGeoJsonFeatures.filter((routeFeature) => {
      const isMultiPolygon = routeFeature.properties.geometry.type === "MultiPolygon";

      return isMultiPolygon
        ? calculateMultiPolygonIntercection(routeFeature.geometry.coordinates, circleGeoJson)
        : calculatePolygonIntercection(routeFeature.geometry.coordinates, circleGeoJson);
    });

    const lastIntersectedRoutes = new Map();
    intersectedRoutes.forEach((route) => lastIntersectedRoutes.set(route.properties.id, route));
    intersectedRoutesRef.current = lastIntersectedRoutes;
    setIsCalculatingRoutesIntercection(false);
  },
  300
); // Adjust debounce delay as needed

const renderCircleSelectorOnMap = ({
  googleMap,
  circleSelectorRadius,
  circleFeatureRef,
  intersectedRoutesRef,
  setIsCalculatingRoutesIntercection,
  routes,
  selectedRoutes,
}) => {
  if (googleMap) {
    const dataLayer = googleMap.data;
    let circleFeature;

    const updateCirclePosition = (latLng) => {
      const centerCoords = [latLng.lng(), latLng.lat()];

      const newCircle = turf.circle(centerCoords, circleSelectorRadius, {
        steps: 64,
        units: "miles",
      });
      newCircle.properties = { isCircle: true };

      if (circleFeatureRef.current) {
        dataLayer.remove(circleFeatureRef.current);
      }

      circleFeatureRef.current = dataLayer.addGeoJson(newCircle)[0];
    };

    window.google.maps.event.clearListeners(googleMap.data, "mouseover");
    window.google.maps.event.clearListeners(googleMap.data, "mouseout");

    googleMap.addListener("mousemove", (event) => {
      if (event.latLng) {
        updateCirclePosition(event.latLng);
      }
    });

    dataLayer.addListener("mousemove", (event) => {
      if (event.latLng) {
        updateCirclePosition(event.latLng);
      }

      if (event.feature.getProperty("isCircle")) {
        calculateIntersections.cancel();
        intersectedRoutesRef.current = null;
        const routesAsGeoJsonFeatures = Object.values(routes).map((route) => {
          const mappedRouteCoordinates = removeLatLngFromPolygon(route.paths);
          const routeIsSelected = !!selectedRoutes.find((r) => String(r.id) === String(route.id));
          const routePolygonAsGeoJson = {
            ...turf.polygon(mappedRouteCoordinates),
            properties: {
              id: route.id,
              clickable: true,
              name: route.name,
              type: "mission",
              selected: routeIsSelected,
              draggable: false,
              deleteOnClick: true,
              paths: route.paths,
              geometry: route.geometry,
              metadata: { ...(route.metadata || {}) },
            },
          };
          return routePolygonAsGeoJson;
        });

        const circleGeometry = getCircleGeometry(event);
        const circleGeoJson = turf.polygon([circleGeometry]);

        setIsCalculatingRoutesIntercection(true);
        calculateIntersections(
          circleGeoJson,
          routesAsGeoJsonFeatures,
          intersectedRoutesRef,
          setIsCalculatingRoutesIntercection
        );
      }
    });

    googleMap.data.forEach((feature) => {
      if (feature.getProperty("type") === "mission") {
        feature.setProperty("clickable", false);
      }
    });
  }
};

const removeCircleFromMap = ({ googleMap, circleFeatureRef, setHoveredRoute }) => {
  if (googleMap) {
    googleMap.data.forEach((feature) => {
      if (feature.getProperty("isCircle")) {
        googleMap.data.remove(feature);
        window.google.maps.event.clearListeners(googleMap, "mousemove");
        window.google.maps.event.clearListeners(googleMap.data, "mousemove");
      } else if (feature.getProperty("type") === "mission") {
        feature.setProperty("clickable", true);
      }
    });
    circleFeatureRef.current = null;
    googleMap.data.addListener("mouseover", (event) => {
      if (event.feature.getProperty("type") === "mission") {
        setHoveredRoute(event.feature.getProperty("metadata"));
        event.feature.setProperty("hovered", true);
      }
    });

    googleMap.data.addListener("mouseout", (event) => {
      if (event.feature.getProperty("type") === "mission") {
        setHoveredRoute(null);
        event.feature.setProperty("hovered", false);
      }
    });
  }
};

const handleD2DMissionDelete = ({ deletedMissions, handleMissionSelect, mapInit }) => {
  deletedMissions.forEach((mission) => {
    const { id: missionId, paths, isVisible } = mission;
    handleMissionSelect({ missionId, paths, selected: false, isVisible });
    if (mapInit?.data) {
      mapInit.data.forEach((feature) => {
        if (feature.getProperty("id") === missionId) {
          feature.setProperty("selected", false);
        }
      });
    }
  });
};

const handleD2DMissionUpdate = ({ updatedMissions, setSelectedMissions, mapInit }) => {
  updatedMissions.forEach((mission) => {
    const { id: missionId } = mission;
    setSelectedMissions((missions) => {
      const currentMissionIndex = missions.findIndex((m) => m.id === missionId);
      if (currentMissionIndex !== -1) {
        return missions.reduce((acc, curr) => {
          if (curr.id === missionId) {
            acc.push({ ...curr, isVisible: mission.isVisible });
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);
      } else {
        return missions;
      }
    });

    mapInit.data.setStyle((feature) => {
      if (feature.getProperty("id") === missionId) {
        feature.setProperty("selected", mission.isVisible);
      }
      return setDataLayerStyles(feature);
    });
  });
};

const handleHoverSelectedMissionUpdate = ({ mapInit, hoveredMission }) => {
  if (mapInit) {
    if (mapInit.data) {
      mapInit.data.forEach((feature) => {
        if (feature.getProperty("id") === hoveredMission) {
          feature.setProperty("hovered", true);
        } else if (feature.getProperty("hovered")) {
          feature.setProperty("hovered", false);
        }
      });
    }
  }
};

const setDataLayerStyles = (feature) => {
  let styles = {
    strokeWeight: 0,
  };

  if (feature.getProperty("isCircle")) {
    return {
      strokeColor: "#ee4360",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#FF0000",
      fillOpacity: 0.2,
      visible: true,
      zIndex: 11,
    };
  }
  if (feature.getProperty("type") === "mission") {
    styles = {
      strokeColor: "#000000",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#1e990b", // green
      // fillColor: "#000000", //grey
      fillOpacity: 0.1,
      zIndex: 10,
    };
  }
  if (feature.getProperty("type") === "postCode") {
    styles = {
      strokeColor: "#EE4360",
      strokeOpacity: 1,
      strokeWeight: 4,
      fillColor: "#000000",
      fillOpacity: 0,
      zIndex: 1,
    };
  }
  if (feature.getProperty("selected") && !feature.getProperty("hovered")) {
    styles.fillColor = "#ff798d";
    styles.fillOpacity = 0.35;
  }

  if (feature.getProperty("selected") && feature.getProperty("hovered")) {
    styles.fillColor = "#ff798d";
    styles.fillOpacity = 0.7;
  }

  if (!feature.getProperty("selected") && feature.getProperty("hovered")) {
    styles.fillColor = "#000000";
    styles.fillOpacity = 0.35;
  }
  return styles;
};

const onDrawingManagerLoad = ({ loadedDrawingManager, setDrawingManager }) => {
  setDrawingManager(loadedDrawingManager);
};

const getBounds = (polygon) => {
  const polygonBounds = polygon.getPath();
  let bounds = [];
  for (let i = 0; i < polygonBounds.length; i++) {
    bounds.push({
      lat: polygonBounds.getAt(i).lat(),
      lng: polygonBounds.getAt(i).lng(),
    });
  }
  return bounds;
};

const onAutocompleteSelect = ({ newMarker, updateMap, isD2D, isEDDM }) => {
  if (newMarker) {
    const { geometry } = newMarker;
    const lat = geometry.location.lat();
    const lng = geometry.location.lng();

    updateMap({ center: { lat, lng }, shouldShowDefaultPin: true, zoom: isEDDM ? 10 : isD2D ? 15 : 17 });
  }
};

const cleanCoordsAndCreatePolygon = (polygonCoords) => {
  const closedPolygonCoordinates = [...polygonCoords];
  if (
    closedPolygonCoordinates[0][0] !== closedPolygonCoordinates[closedPolygonCoordinates.length - 1][0] ||
    closedPolygonCoordinates[0][1] !== closedPolygonCoordinates[closedPolygonCoordinates.length - 1][1]
  ) {
    closedPolygonCoordinates.push(closedPolygonCoordinates[0]); // Close the ring by adding the first point to the end
  }
  const poly = turf.polygon([closedPolygonCoordinates]);
  return poly;
};

const calculateMultiPolygonArea = (routeMultiPolygon, circleGeoJson) => {
  return routeMultiPolygon.geometry.coordinates.reduce((acc, curr) => {
    const poly = cleanCoordsAndCreatePolygon([...curr]);
    const area = turf.area(poly);
    return acc + area;
  }, 0);
};

const calculateMultiPolygonIntersectedArea = (routeMultiPolygon, circleGeoJson) => {
  return routeMultiPolygon.geometry.coordinates.reduce((acc, curr) => {
    const poly = cleanCoordsAndCreatePolygon([...curr]);
    const intersectedAreaOfSubpolygon = turf.intersect(circleGeoJson, poly);

    if (intersectedAreaOfSubpolygon) {
      const area = turf.area(intersectedAreaOfSubpolygon);
      return acc + area;
    }
    return acc;
  }, 0);
};

const calculatePolygonIntercection = (featureCoordinates, circleGeoJson) => {
  const routePolygon = turf.polygon(featureCoordinates);
  const intersection = turf.intersect(circleGeoJson, routePolygon);

  if (intersection) {
    const intersectedArea = turf.area(intersection);
    const intersectedRouteArea = turf.area(routePolygon);
    return Math.abs(intersectedArea) > Math.abs(0.5 * intersectedRouteArea);
  }

  return false;
};

const calculateMultiPolygonIntercection = (featureCoordinates, circleGeoJson) => {
  const routeMultiPolygon = turf.multiPolygon(featureCoordinates);
  const intersectedArea = calculateMultiPolygonIntersectedArea(routeMultiPolygon, circleGeoJson);

  if (intersectedArea) {
    const intersectedRouteArea = calculateMultiPolygonArea(routeMultiPolygon);
    return Math.abs(intersectedArea) > Math.abs(0.5 * intersectedRouteArea);
  }

  return false;
};

const getPolygonCenterPoint = (path) => {
  const bounds = new window.google.maps.LatLngBounds();
  path.map((item) => {
    bounds.extend(item);
    return item.id;
  });

  const pathCenterPoint = bounds.getCenter();
  return {
    lat: pathCenterPoint.lat(),
    lng: pathCenterPoint.lng(),
  };
};

const isPositionInsideDistributionZone = (distributionZonePolygons, { lat, lng }) =>
  distributionZonePolygons.reduce((acc, distributionZonePolygon) => {
    const isContainsInDistributionZone = window.google.maps.geometry.poly.containsLocation(
      { lat, lng },
      distributionZonePolygon
    );
    if (isContainsInDistributionZone) {
      acc = isContainsInDistributionZone;
    }
    return acc;
  }, false);

const handleScheduleMeeting = (countryCode) => {
  window.open(MeetingLinkMap[countryCode] || DefaultMeetingLink, "_blank");
};

const fetchAndUpdatePostCodes = async (distributionZones, loadData, exclusionZones, updateMap) => {
  const includeAddressCount = false;

  const postCodes = await loadData({
    layer: "Postcodes",
    area: distributionZones.geometry,
    includeAddressCount,
    exclusionZones: exclusionZones.geometry,
  });

  const visiblePostCodes = [];
  const flag = {};
  Object.values(postCodes.data).forEach((postcode) => {
    if (postcode.name && !flag[postcode.name]) {
      visiblePostCodes.push(postcode);
      flag[postcode.name] = true;
    }
  });

  updateMap({ visiblePostCodes });
};

const fetchCarrierRoutes = async ({ after, cityName, cityId, previousRoutes = {}, iteration = 0, updateMap }) => {
  const iterationCount = !iteration ? 1 : iteration + 1;
  const routesResponse = await getCarrierRoutesByCity({
    cityName,
    cityId,
    iterationCount,
    after,
  });
  if (!routesResponse) {
    return {};
  }
  const { data: rawAreasData, errors } = await routesResponse.json();
  if (errors) throw errors[0];
  if (!rawAreasData || !rawAreasData.getCarrierRoutesByCity) return {};

  const totalCount = rawAreasData.getCarrierRoutesByCity.totalCount;
  const formattedOverlays = formatFeaturesToOverlays(rawAreasData.getCarrierRoutesByCity.collection.features);
  const overlaysAsHashMap = formattedOverlays.reduce((acc, item) => {
    acc[item.id] = { ...item, cityId };
    return acc;
  }, {});

  updateMap({ loadingPercentage: ((Object.keys(previousRoutes).length * 100) / totalCount).toFixed() });

  if (rawAreasData.getCarrierRoutesByCity.pageInfo.hasNextPage) {
    return await fetchCarrierRoutes({
      after: rawAreasData.getCarrierRoutesByCity.pageInfo.endCursor,
      cityName,
      cityId,
      previousRoutes: { ...overlaysAsHashMap, ...previousRoutes },
      iteration: iterationCount,
      updateMap,
    });
  } else {
    updateMap({ loading: false, loadingPercentage: null });
    return { ...overlaysAsHashMap, ...previousRoutes };
  }
};

const calculateCircleRadius = (radius) => {
  switch (radius) {
    case 1:
      return 0.25;
    case 2:
      return 0.25;
    case 5:
      return 1;
    case 10:
      return 2;
    default:
      return 0.25;
  }
};

const calculateMinCircleRadius = (radius) => {
  switch (radius) {
    case 1:
      return 0.25;
    case 2:
      return 0.25;
    case 5:
      return 0.5;
    case 10:
      return 1;
    default:
      return 0.25;
  }
};

const calculateMaxCircleRadius = (radius) => {
  switch (radius) {
    case 1:
    case 2:
    case 5:
      return radius * 0.7;
    case 10:
      return 4;
    default:
      return 1;
  }
};

export {
  typeMarker,
  getOptions,
  drawCircle,
  createFigure,
  getDistributionZonePath,
  removeEditMode,
  handleMapItemClick,
  getZone,
  mapBoundaries,
  onMapInit,
  renderDistributionZones,
  onDrawingManagerLoad,
  getBounds,
  removeLatLngFromPolygon,
  removeSelectedPostCode,
  renderSelectedPostCode,
  renderPolygonsOnMap,
  renderPolygonsOnEddmMap,
  handleD2DMissionDelete,
  handleD2DMissionUpdate,
  onAutocompleteSelect,
  getPolygonCenterPoint,
  isPositionInsideDistributionZone,
  handleScheduleMeeting,
  fetchAndUpdatePostCodes,
  fetchCarrierRoutes,
  handleHoverSelectedMissionUpdate,
  renderCircleSelectorOnMap,
  removeCircleFromMap,
  calculateCircleRadius,
  calculateMinCircleRadius,
  calculateMaxCircleRadius,
};
