/* eslint-disable react/no-unknown-property */
import { useEffect, useState } from "react";
import { Box, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import cn from "classnames";
import { TemplateConfig } from "pages/flyer/builder/drawer";
import {
  getFlyerSizeDisplay,
  getFlyerWidthDisplay,
  getFlyerHeightDisplay,
  displayInInch,
} from "pages/flyer/builder/utils";
import { PDFPreview } from "./PDFPreview/PDFPreview";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type FlyerSizePreviewProps = {
  flyerTypeOption: TemplateConfig;
  countryCode?: string;
  file?: string;
  files?: any[];
  cypressId: string;
  isDM?: boolean;
};

export const FlyerSizePreview = ({
  flyerTypeOption,
  cypressId,
  countryCode = "US",
  file = "",
  files,
  isDM,
}: FlyerSizePreviewProps) => {
  const classes = useStyles();
  const {
    name,
    widthInMm,
    heightInMm,
    gapForArrow = 0,
    gapForHorizontalArrow,
    placeholder,
    horizontalArrow,
    verticalArrow,
    displayWidth: defaultDisplayWidth,
    displayHeight: defaultDisplayHeight,
    previewPadding,
    verticalArrowHeight: defaultVerticalArrowHeight,
    horizontalArrowWidth: defaultHorizontalArrowWidth,
  } = flyerTypeOption;

  const isInInch = displayInInch(countryCode);
  const displaySize = getFlyerSizeDisplay(widthInMm, heightInMm, isInInch);

  const hasFile = !!file && !!files && !!files.length;
  const [isMobile, setIsmobile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(768));

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      );
    setIsmobile(isMobile);
    if (!openModal && !userAgent.match(/chrome|chromium|crios/i) && !isMobile) {
      setOpenModal(true);
    }
  }, []);

  const displayWidth = isSmallScreen ? defaultDisplayWidth / 2.4 : defaultDisplayWidth;
  const displayHeight = isSmallScreen ? defaultDisplayHeight / 2.4 : defaultDisplayHeight;
  const verticalArrowHeight = isSmallScreen ? defaultVerticalArrowHeight / 2.4 : defaultVerticalArrowHeight;
  const horizontalArrowWidth = isSmallScreen ? defaultHorizontalArrowWidth / 2.4 : defaultHorizontalArrowWidth;

  return (
    <Box className={cn(classes.previewWrapper)} cypress_id={cypressId} style={{ scale: 0.4 }}>
      <Box className={cn(classes.previewText, "mob-preview-text")}>{hasFile ? "Preview" : "Flyer size"}</Box>
      {hasFile ? (
        <PDFPreview
          file={file}
          width={displayWidth}
          height={displayHeight}
          padding={previewPadding}
          isSmallScreen={isSmallScreen}
        />
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box display="flex" paddingTop={"30px"}>
            <Box className={cn(classes.verticalArrowWrapper, "mob-vertical-arrow-wrapper")}>
              <img
                src={verticalArrow}
                style={{
                  paddingTop: `${gapForArrow}px`,
                  height: `${verticalArrowHeight}px`,
                }}
              />
              <Typography className={`${classes.dimensionText} ${classes.heightText}`}>
                {getFlyerHeightDisplay(heightInMm, isInInch)}
              </Typography>
            </Box>
            <Box className={cn(classes.imageWrapper)} style={{ width: displayWidth, height: displayHeight }}>
              <div
                style={{
                  width: displayWidth,
                  height: displayHeight,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  border: "1px solid gray",
                }}
              />
              <img
                src={placeholder}
                style={{
                  width: displayWidth,
                  height: displayHeight,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  border: "1px solid gray",
                }}
                className="mob-display-wh"
              />
              <Box className={cn(classes.nameWrapper, "mob-name-wrapper", { [classes.dmNameWrapper]: isDM })}>
                <Typography>{name}</Typography>
                <Typography>{displaySize}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={cn(classes.horizontalArrowWrapper, "mob-horizontal-arrow-wrapper")}>
            <img
              src={horizontalArrow}
              style={{
                paddingLeft: `${gapForArrow}px`,
                width: `${horizontalArrowWidth}px`,
              }}
            />
            <Typography className={`${classes.dimensionText} ${classes.widthText}`}>
              {getFlyerWidthDisplay(widthInMm, isInInch)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FlyerSizePreview;
