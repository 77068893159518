import { ArrowUtil } from "pages/flyer/builder/drawer/state/shapes/ArrowUtil";
import { CodeUtil } from "pages/flyer/builder/drawer/state/shapes/CodeUtil";
import { DrawUtil } from "pages/flyer/builder/drawer/state/shapes/DrawUtil";
import { EllipseUtil } from "pages/flyer/builder/drawer/state/shapes/EllipseUtil";
import { GroupUtil } from "pages/flyer/builder/drawer/state/shapes/GroupUtil";
import { ImageUtil } from "pages/flyer/builder/drawer/state/shapes/ImageUtil";
import { QRCodeUtil } from "pages/flyer/builder/drawer/state/shapes/QRCodeUtil";
import { RectangleUtil } from "pages/flyer/builder/drawer/state/shapes/RectangleUtil";
import { TextUtil } from "pages/flyer/builder/drawer/state/shapes/TextUtil";
import { TriangleUtil } from "pages/flyer/builder/drawer/state/shapes/TriangleUtil";
import { TDShapeType } from "pages/flyer/builder/drawer/types";

type ShapeUtilsType = {
  [TDShapeType.Rectangle]: RectangleUtil;
  [TDShapeType.Triangle]: TriangleUtil;
  [TDShapeType.Ellipse]: EllipseUtil;
  [TDShapeType.Draw]: DrawUtil;
  [TDShapeType.Arrow]: ArrowUtil;
  [TDShapeType.TextArea]: TextUtil;
  [TDShapeType.Group]: GroupUtil;
  [TDShapeType.Image]: ImageUtil;
  [TDShapeType.Code]: CodeUtil;
  [TDShapeType.QRCode]: QRCodeUtil;
};

export class ShapeUtilsSingleton {
  private static instance: ShapeUtilsSingleton;
  private shapeUtils: ShapeUtilsType;

  constructor() {
    this.shapeUtils = {
      [TDShapeType.Rectangle]: new RectangleUtil(),
      [TDShapeType.Triangle]: new TriangleUtil(),
      [TDShapeType.Ellipse]: new EllipseUtil(),
      [TDShapeType.Draw]: new DrawUtil(),
      [TDShapeType.Arrow]: new ArrowUtil(),
      [TDShapeType.TextArea]: new TextUtil(),
      [TDShapeType.Group]: new GroupUtil(),
      [TDShapeType.Image]: new ImageUtil(),
      [TDShapeType.Code]: new CodeUtil(),
      [TDShapeType.QRCode]: new QRCodeUtil(),
    };
  }

  public static getInstance(): ShapeUtilsSingleton {
    if (!ShapeUtilsSingleton.instance) {
      ShapeUtilsSingleton.instance = new ShapeUtilsSingleton();
    }

    return ShapeUtilsSingleton.instance;
  }

  public getShapeUtils() {
    return this.shapeUtils;
  }
}
