import Vec from "@tldraw/vec";
import { getShapeStyle } from "pages/flyer/builder/drawer/state/shapes/shared";
import type { Decoration, ShapeStyles } from "pages/flyer/builder/drawer/types";
import * as React from "react";
import { getStraightArrowHeadPoints, renderFreehandArrowShaft } from "../arrowHelpers";
import { Arrowhead } from "./ArrowHead";

interface ArrowSvgProps {
  id: string;
  style: ShapeStyles;
  start: number[];
  bend: number[];
  end: number[];
  arrowBend: number;
  decorationStart: Decoration | undefined;
  decorationEnd: Decoration | undefined;
  isDarkMode: boolean;
  isDraw: boolean;
}

export const StraightArrow = React.memo(function StraightArrow({
  id,
  style,
  start,
  end,
  decorationStart,
  decorationEnd,
  isDraw,
  isDarkMode,
}: ArrowSvgProps) {
  const arrowDist = Vec.dist(start, end);
  if (arrowDist < 2) return null;
  const styles = getShapeStyle(style, isDarkMode);
  const { strokeWidth } = styles;
  // Path between start and end points
  const path = isDraw
    ? renderFreehandArrowShaft(id, style, start, end, decorationStart, decorationEnd)
    : "M" + Vec.toFixed(start) + "L" + Vec.toFixed(end);

  // Arrowheads
  const arrowHeadLength = Math.min(arrowDist / 3, strokeWidth! * 8);
  const startArrowHead = decorationStart ? getStraightArrowHeadPoints(start, end, arrowHeadLength) : null;
  const endArrowHead = decorationEnd ? getStraightArrowHeadPoints(end, start, arrowHeadLength) : null;
  return (
    <>
      <path className="tl-stroke-hitarea" d={path} />
      <path
        d={path}
        fill={styles.stroke}
        stroke={styles.stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        pointerEvents="stroke"
      />

      {startArrowHead && (
        <Arrowhead
          left={startArrowHead.left}
          middle={start}
          right={startArrowHead.right}
          stroke={styles.stroke!}
          strokeWidth={strokeWidth!}
        />
      )}
      {endArrowHead && (
        <Arrowhead
          left={endArrowHead.left}
          middle={end}
          right={endArrowHead.right}
          stroke={styles.stroke!}
          strokeWidth={strokeWidth!}
        />
      )}
    </>
  );
});
