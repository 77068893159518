import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const updateCampaignCityMetrics = async ({ saverId, data, campaignId }) => {
  const query = `
    mutation updateCampaignCityMetrics($input: UpdateCampaignCityMetricsInput!){
      updateCampaignCityMetrics(input: $input){
        success
      }
    }
  `;
  const vars = {
    input: {
      campaignId,
      saverId,
      campaignCitiesMetrics: data,
    },
  };
  const response = await callAuthorizedGraphQLEndpoint(query, vars);
  const res = await response.json();

  return res;
};
