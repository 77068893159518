const style = {
  spinner: {
    padding: "0 8px",
    width: "70px",
    textAlign: "center",

    "& > div": {
      width: "8px",
      height: "8px",
      backgroundColor: "#f50057",

      borderRadius: "100%",
      display: "inline-block",
      animation: "$sk-bouncedelay 1.4s infinite ease-in-out both",
    },
  },

  bounce1: {
    animationDelay: "-0.32s !important",
  },

  bounce2: {
    animationDelay: "-0.16s !important",
  },

  "@keyframes sk-bouncedelay": {
    "0%, 80%, 100%": {
      transform: "scale(0)",
    },
    "40%": {
      transform: "scale(1.0)",
    },
  },
};

export default style;
