export default function InfoIcon({
  size = 16, // or any default size of your choice
  color = "black", // or any color of your choice
  className = "",
}) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 1.757c3.442 0 6.233 2.777 6.233 6.202s-2.791 6.2-6.234 6.2c-3.442 0-6.233-2.775-6.233-6.2 0-3.425 2.79-6.202 6.233-6.202ZM8 .663C3.955.663.665 3.936.665 7.96c0 4.022 3.29 7.295 7.333 7.295 4.044 0 7.334-3.273 7.334-7.295 0-4.023-3.29-7.296-7.334-7.296Z"
        fill={color}
      />
      <path
        d="M9.448 9.728c-.64 1.74-3.084 2.207-2.63-.048l.534-2.553h1.117c-.26 1.361-.446 2.19-.632 3.14-.186.967 1.02.25 1.296-.539h.315ZM7.72 6.182a.736.736 0 0 1-.2-.533c.009-.21.075-.38.22-.532a.76.76 0 0 1 .544-.22c.22 0 .383.077.526.22.153.152.23.323.23.532a.7.7 0 0 1-.24.533.77.77 0 0 1-.545.21.729.729 0 0 1-.535-.21Z"
        fill={color}
      />
    </svg>
  );
}
