import * as React from "react";

export function BoldFontIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={20}
      height={20}
      viewBox="-30 -22 100 100"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.105 31.423H7.601L7.524 23.5H19.7c2.146 0 3.904-.27 5.274-.808 1.37-.564 2.392-1.371 3.064-2.423.698-1.077 1.047-2.384 1.047-3.923 0-1.744-.336-3.154-1.008-4.23-.647-1.077-1.668-1.86-3.064-2.347-1.37-.487-3.141-.73-5.313-.73h-8.067V56H0V0h19.7c3.284 0 6.219.308 8.804.923 2.611.615 4.822 1.551 6.632 2.808 1.81 1.256 3.193 2.846 4.15 4.769.956 1.898 1.434 4.154 1.434 6.77 0 2.307-.53 4.435-1.59 6.384-1.034 1.948-2.676 3.538-4.925 4.77-2.223 1.23-5.132 1.91-8.726 2.038l-3.374 2.961ZM21.601 56H4.421l4.537-9h12.643c2.043 0 3.71-.32 5.003-.962 1.293-.666 2.25-1.564 2.87-2.692.62-1.128.93-2.423.93-3.884 0-1.642-.284-3.065-.853-4.27-.543-1.205-1.422-2.128-2.637-2.769-1.215-.667-2.818-1-4.809-1H10.897l.078-7.923h13.961l2.676 3.116c3.439-.052 6.205.55 8.3 1.807 2.12 1.23 3.658 2.833 4.614 4.808C41.51 35.205 42 37.32 42 39.577c0 3.59-.788 6.615-2.366 9.077-1.577 2.436-3.89 4.27-6.941 5.5C29.668 55.384 25.97 56 21.6 56Z"
        fill="#000"
      />
    </svg>
  );
}
