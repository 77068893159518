export const styles = {
  locationCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 2px 5px rgba(39, 55, 84, 0.08)",
    width: "500px",
    margin: "4px 0",
    padding: "15px 15px 10px",
    cursor: "pointer",
    boxSizing: "border-box",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid",
    },
  },
  hovered: {
    border: "1px solid",
  },
  locationTitle: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  locationIcon: {
    marginRight: "5px",
    height: "17px",
  },
  locationOptionsBtn: {
    fill: "#C4C4C4",
    cursor: "pointer",
    "& img": {
      height: "20px",
    },
  },
};
