const style = {
  wrapper: {
    height: "100vh",
  },
  content: {
    width: "100%",
    height: "calc(100vh - 80px)",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "stretch",
    alignItems: "center",
    color: "#3C3D51",
    background: "#F9F9FF",
    overflow: "scroll",
  },
  contentNotVerified: {
    height: "calc(100vh - 118px)",
  },
  title: {
    fontWeight: 800,
    fontSize: 32,
    lineHeight: "40px",
    marginTop: 44,
    color: "#011533",
  },
  firstLevelHeader: {
    width: 650,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center" as const,
    marginTop: 24,
    color: "#011533",
  },
  secondLevelHeader: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "22px",
    textAlign: "center" as const,
    marginTop: 16,
    color: "#011533",
  },
  thirdLevelHeader: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "22px",
    textAlign: "center" as const,
    color: "#011533",
  },
  buttons: {
    display: "flex",
    margin: "70px 0",
    flexWrap: "wrap" as const,
    justifyContent: "center",

    "& > div": {
      border: "none",
    },
  },
  link: {
    fontWeight: 700,
    textDecoration: "underline",
    color: "#DE2F4C",
    "&:visited": { color: "#DE2F4C" },
  },
  or: {
    paddingTop: "14%",
    margin: "0 35px 0 5px",
    color: "#E2E2FC",
    fontSize: "24px",
    fontWeight: "700",
    LineHeight: "30px",
  },
};

export default style;
