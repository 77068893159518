import * as Components from "./components";

// svgr key value map - [key: string]: svgrComponent
export const SVG = {
  ...Components,
};

// map all the keys for props 'type' completion
export type SVGKey = keyof typeof SVG;

export type SVGProps = {
  type: SVGKey;
  size?: "xs" | "xs1" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl";
  stroke?: string;
} & React.SVGProps<SVGSVGElement>;

const sizeMap = {
  xs: 16,
  xs1: 18,
  s: 20,
  m: 24,
  l: 28,
  xl: 32,
  xxl: 40,
  xxxl: 72,
};

export const Icon = ({ type, size = "m", ...otherProps }: SVGProps) => {
  const SVGComponent = SVG[type];
  const dimentions = sizeMap[size];

  return (
    <SVGComponent
      preserveAspectRatio="none"
      width={dimentions}
      height={dimentions}
      viewBox={`0 0 ${dimentions} ${dimentions}`}
      {...otherProps}
    />
  );
};
