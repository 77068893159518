import { useState, useEffect, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { UploadFile } from "shared/graphql/mutations/uploadFile";
import { uploadFile as uploadFileToS3 } from "react-s3";
import CONFIG from "config/config";
import { insertCampaignLogs } from "Logs/campaign/gql";
import { CAMPAIGN_LOG_ENUM_TYPES } from "shared/constants";

interface UseDragAndDropProps {
  files: File[];
  setFiles: (files: File[]) => void;
  flyerInfo: { getFlyerInfo: () => Promise<void> };
}

interface FileRejection {
  errors: { code: string }[];
}

export const useDragAndDrop = ({ files, setFiles, flyerInfo }: UseDragAndDropProps) => {
  const [uploadFile] = useMutation(UploadFile);
  const [tempFiles, setTempFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const { campaignId } = useParams();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.some((f: File) => f.size > 50 * 1048576)) {
        setTempFiles([]);
        setFileError("Error: The file exceeded the 50mb limit");
      } else {
        setFileError(null);
        setTempFiles(acceptedFiles);
      }
    },
    [setTempFiles, setFileError]
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          setFileError("Error: You can upload only PDF file");
        }
      });
    });
  };

  const uploadHandler = async () => {
    const file = tempFiles[0];
    setFileError(null);
    setIsUploading(true);
    setFileUploadProgress(0);

    try {
      const { key } = await uploadFileToS3(file, {
        bucketName: CONFIG.PDF_FLYER_BUCKET,
        dirName: "campaign-builder/" + `${Date.now().toString()}`,
        region: CONFIG.PDF_FLYER_BUCKET_REGION,
        accessKeyId: CONFIG.AWS.KEY,
        secretAccessKey: CONFIG.AWS.SECRET,
      });
      setFileUploadProgress(60);

      await uploadFile({
        variables: {
          file: key,
          fileName: file.name,
          fileType: file.type,
          campaignId: `${campaignId}`,
        },
      });

      await insertCampaignLogs([
        {
          campaignId: `${campaignId}`,
          type: CAMPAIGN_LOG_ENUM_TYPES.UPLOAD_PDF_DESIGN,
          additionalInfo: `[CB] CAMPAIGN EDIT File name: ${file.name}`,
        },
      ]);

      await flyerInfo.getFlyerInfo();
      setFiles([file]);
      setTempFiles([]);
    } catch (error) {
      setFileUploadProgress(0);
      console.error({ error }, "uploadFile error");
      return;
    }

    setFileUploadProgress(100);
    setIsUploading(false);
  };

  useEffect(() => {
    if (tempFiles.length > 0) {
      uploadHandler();
    }
  }, [tempFiles]);

  return {
    isUploading,
    setIsUploading,
    fileUploadProgress,
    setFileUploadProgress,
    tempFiles,
    setTempFiles,
    fileError,
    onDrop,
    onDropRejected,
  };
};
