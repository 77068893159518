import {
  STATUS_CAMPAIGN_ENUM_COMPLETED,
  STATUS_CAMPAIGN_ENUM_ARCHIVE,
  STATUS_CAMPAIGN_ENUM_ACTIVE,
} from "shared/constants";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getCampaign = async (campaignId, clientId) => {
  const query = `query 
    ($campaignId:ID!, $clientId:ID) {
        campaignFlyerInfo(campaignId: $campaignId) {
          s3Link
          flyerName
          isFlyerUploaded
          activeRoomId
        }
        campaign(id:$campaignId, clientId:$clientId){
            quote
            type
            name
            comments
            subtype
            printingCostPerFlyer
            client {
              id
              name
              logo
            }
            country {
                id
            }
            selfServeData {
                startDate
                updatedAt
                flyerType
                flyerWeight
                campaignDuration
                missionsCount
                totalCosts         
                taxes
                lastActiveStep
                internalStatus
                submittedBy
                qrCodeLink
                stripeCouponCode
                amountOff
                percentOff
                isPaymentSkipped
                internalStatus
                baPerLocation
                isTrackableCoupon
                couponType
                staticCouponCode
                dynamicCouponCode
            }
        }
    }`;

  const request = await callAuthorizedGraphQLEndpoint(query, {
    campaignId,
    clientId,
  });

  if (request.status === 200) {
    const {
      data: {
        campaignFlyerInfo,
        campaign: { type, name, client, comments, selfServeData, quote, country, subtype, printingCostPerFlyer },
      },
    } = await request.json();

    const campaignExtraData = selfServeData.length ? selfServeData[0] : {};

    const campaignData = {
      ...campaignExtraData,
      channel: type,
      printingCostPerFlyer,
      subtype,
      campaignName: name.trim(),
      campaignComments: comments,
      country,
      client,
      campaignFlyerInfo,
      quote,
    };

    return campaignData;
  }
};

export const getCampaignCost = async ({ id, cityId }) => {
  const query = `
    query ($id: ID!, $cityId: ID) {
     campaign(id: $id){
         detailedCost(cityId: $cityId) {
            missionCostWithoutPrinting
            distribution {
                  quantity
                  rate
                  total
                  }
            travel {
                  quantity
                  rate
                  total
                  }
            permit {
                  quantity
                  rate
                  total
                  }
            printing {
                  quantity
                  rate
                  total
                  }
            outfit {
                  quantity
                  rate
                  total
                  }
            subtotal
            tax
            totalCost
            amountOff
            percentOff
          }
        }
    }`;
  const params = { id, cityId };
  const response = await callAuthorizedGraphQLEndpoint(query, params);

  const {
    data: {
      campaign: { detailedCost },
    },
  } = await response.json();

  return detailedCost;
};

export const getEddmCampaignCost = async ({ id, cityId }) => {
  const query = `
    query ($id: ID!, $cityId: ID) {
     campaign(id: $id){
         detailedCost(cityId: $cityId) {
            subtotal
            tax
            totalCost
            amountOff
            percentOff
          }
        }
    }`;
  const params = { id, cityId };
  const response = await callAuthorizedGraphQLEndpoint(query, params);

  const {
    data: {
      campaign: { detailedCost },
    },
  } = await response.json();

  return detailedCost;
};

export const checkIsCampaignNameExist = async (clientId, campaignName) => {
  const query = `
  query (
    $clientId: ID, $campaignName: String
  ) {
    campaigns(
      clientId:$clientId, campaignName: $campaignName
    ){
      edges {
        node {
          id
        }
      }
    }
  }
`;

  const response = await callAuthorizedGraphQLEndpoint(query, {
    clientId,
    campaignName,
  });
  const {
    data: {
      campaigns: { edges },
    },
  } = await response.json();

  const campaignIds = edges.map((edge) => edge.node.id);
  return { campaignIds };
};

export const getCampaignDynamicCode = async (campaignId, clientId) => {
  const query = `query 
    ($campaignId:ID!, $clientId:ID) {
        campaignFlyerInfo(campaignId: $campaignId) {
          s3Link
          flyerName
          isFlyerUploaded
          activeRoomId
        }
        campaign(id:$campaignId, clientId:$clientId){
            selfServeData {
                dynamicCouponCode
            }
        }
    }`;

  const request = await callAuthorizedGraphQLEndpoint(query, {
    campaignId,
    clientId,
  });

  if (request.status === 200) {
    const {
      data: {
        campaign: { selfServeData },
      },
    } = await request.json();
    const { dynamicCouponCode } = selfServeData[0];
    return dynamicCouponCode;
  }
};
