import { GuidelinesIds } from "pages/flyer/builder/drawer/constants/constants";
import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import type { TldrawCommand } from "pages/flyer/builder/drawer/types";
import { removeShapesFromPage } from "../shared/removeShapesFromPage";

export function hideGuidelines(app: TldrawApp, pageId = app.currentPageId): TldrawCommand {
  const { pageState, selectedIds } = app;

  const { before, after } = removeShapesFromPage(app.state, GuidelinesIds, pageId);

  return {
    id: "hideGuidelines",
    before: {
      document: {
        isShowGuidelines: app.state.document.isShowGuidelines,
        pages: {
          [pageId]: before,
        },
        pageStates: {
          [pageId]: { selectedIds: [...app.selectedIds] },
        },
      },
    },
    after: {
      document: {
        isShowGuidelines: !app.state.document.isShowGuidelines,
        pages: {
          [pageId]: after,
        },
        pageStates: {
          [pageId]: {
            selectedIds: selectedIds.filter((id) => !GuidelinesIds.includes(id as any)),
            hoveredId:
              pageState.hoveredId && GuidelinesIds.includes(pageState.hoveredId as any)
                ? undefined
                : pageState.hoveredId,
          },
        },
      },
    },
  };
}
