const style = {
  select: {
    padding: "10.5px 10px",
  },
  root: {
    width: "100%",
  },
  placeholder: {
    color: "#8f8f8fde",
  },
};

export default style;
