import { CAMPAIGN_ENUM_TYPES, DocumentFormats, DMDocumentFormats } from "shared/constants";

export const isValidFlyerType = ({
  countryCode,
  channel,
  printingCosts,
  flyerType,
  flyerWeight,
  isUploadDesign = true,
}) => {
  const printingCostKey = flyerWeight ? `${flyerType}#${flyerWeight}` : flyerType;
  const countOfPrintingCosts = Object.entries(printingCosts).reduce((acc, [key, costs]) => {
    if (key.includes(printingCostKey)) {
      acc += costs.length;
    }
    return acc;
  }, 0);

  if (!countOfPrintingCosts) {
    return false;
  }

  if (isUploadDesign) {
    if (flyerType === DocumentFormats.A5) {
      return true;
    }
    if (flyerType === DocumentFormats.DL) {
      return true;
    }
    if (channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL && DMDocumentFormats[flyerType]) {
      return true;
    }
  }

  if (channel === CAMPAIGN_ENUM_TYPES.HANDTOHAND) {
    if (flyerType === DocumentFormats.TEARAWAY) {
      return true;
    }
  }

  if ((countryCode === "US" || countryCode === "CA") && channel === CAMPAIGN_ENUM_TYPES.LETTERBOX) {
    if (flyerType === DocumentFormats.A6 || flyerType === DocumentFormats.DOOR_HANGER) {
      return true;
    }
  } else {
    if (flyerType === DocumentFormats.A6) {
      return true;
    }
  }

  return false;
};

export const getFlyerWeightOptions = ({ flyerType, printingCosts }) => {
  const weightOptions = Object.entries(printingCosts).reduce((acc, [key, costs]) => {
    const strs = key.split("#");

    if (costs.length && strs.length === 2 && strs[0] === flyerType && !isNaN(strs[1])) {
      acc.push(strs[1]);
    }

    return acc;
  }, []);

  weightOptions.sort((a, b) => {
    const numA = Number(a);
    const numB = Number(b);

    if (numA < numB) {
      return -1;
    } else if (numA > numB) {
      return 1;
    } else {
      return 0;
    }
  });

  return weightOptions;
};
