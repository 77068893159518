import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { CircularProgress, Box } from "@material-ui/core";
import { Icon } from "components/icons";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const DeleteButton = ({ onClick, deleting, className }) => {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.buttonContainer, { [className]: !!className })} onClick={onClick}>
      {deleting ? <CircularProgress size={24} /> : <Icon type="Delete" size="s" />}
    </Box>
  );
};
