export const CircleSelectorIcon = (props) => {
  return (
    <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="9.23529"
        cy="9.18164"
        rx="8.23529"
        ry="8"
        fill="#4F5B94"
        fillOpacity="0.3"
        stroke="#4F5B94"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="2 4"
      />
    </svg>
  );
};
