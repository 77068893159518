import { Box, CircularProgress, Typography, makeStyles } from "@material-ui/core";

import { style } from "./style";

const useStyles = makeStyles(() => style);

type AddCreateLocationPopUpProps = {
  isAdd?: boolean;
  addressName?: string;
  loading?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isFixedLocation: boolean;
};

export const AddCreateLocationPopUp = ({
  isAdd = true,
  addressName = undefined,
  loading = false,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  isFixedLocation = true,
}: AddCreateLocationPopUpProps) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapper}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      //@ts-ignore
      cypress_id={`add-create-location-popup`}
    >
      <Box className={classes.leftContainer}>
        <Typography className={classes.title}>
          {isAdd
            ? isFixedLocation
              ? "Recommended for distribution"
              : "Custom location"
            : "Create new location for distribution?"}
        </Typography>
        {addressName && <Typography className={classes.nameText}>{addressName}</Typography>}
      </Box>
      {/* @ts-ignore */}
      <Typography className={classes.button} onClick={onClick} cypress_id={`add-create-location-button`}>
        {loading && <CircularProgress size={20} color="inherit" style={{ marginRight: 10 }} />}
        {isAdd ? "Add" : "Create"}
      </Typography>
    </Box>
  );
};

export default AddCreateLocationPopUp;
