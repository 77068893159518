import { fetchSelectedCitiesLocations } from "../graphQL";

export const getCityLocations = async ({
  cityId,
  campaignId,
  clientId,
  isSelfServe,
  locations = [],
  offset = 0,
  first = 500,
}) => {
  try {
    const request = await fetchSelectedCitiesLocations({
      cityId,
      campaignId,
      clientId,
      isSelfServe,
      offset,
      first,
    });
    if (request.status === 200) {
      const {
        data: {
          city: {
            locations: {
              edges: locationEdges,
              pageInfo: { hasNextPage },
            },
          },
        },
      } = await request.json();

      const locationsResponse = [...locations, ...locationEdges];
      if (hasNextPage) {
        return getCityLocations({
          cityId,
          campaignId,
          clientId,
          isSelfServe,
          locations: locationsResponse,
          offset: offset + first,
          first,
        });
      } else {
        return locationsResponse.map(({ node = {} } = {}) => ({ ...node }));
      }
    }
  } catch (e) {
    console.log("Error while fetching locations: ", e);
  }
};
