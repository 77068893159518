const getPrintingCostByFlyer = ({ printingCosts, flyerType, flyerWeight, flyersAmount }) => {
  if (printingCosts[`${flyerType}#${flyerWeight}`]) {
    const { value } = printingCosts[`${flyerType}#${flyerWeight}`].reduce((acc, printingCost) =>
      Math.abs(printingCost.flyersCount - flyersAmount) < Math.abs(acc.flyersCount - flyersAmount) ? printingCost : acc
    );

    return +value;
  } else {
    return 0;
  }
};

const getDMPrintingCostByFlyer = ({ printingCosts, flyerType, flyersAmount }) => {
  if (printingCosts[flyerType]) {
    const { value } = printingCosts[flyerType].reduce((acc, printingCost) =>
      Math.abs(printingCost.flyersCount - flyersAmount) < Math.abs(acc.flyersCount - flyersAmount) ? printingCost : acc
    );

    return +value;
  } else {
    return 0;
  }
};

const calculateSubtotalAndPrintingCosts = ({
  missionCostWithoutPrinting,
  printingCosts,
  flyerType,
  flyerWeight,
  missionsCount = 0,
  flyerPack,
  outfitTotal,
}) => {
  const flyersAmount = missionsCount * flyerPack;

  const printingCost = getPrintingCostByFlyer({
    printingCosts,
    flyersAmount,
    flyerType,
    flyerWeight,
  });

  const subtotal = printingCost * flyersAmount + missionCostWithoutPrinting * missionsCount + outfitTotal;
  return { subtotal, printingCost };
};

const formatPrintingCosts = (printingCosts) =>
  printingCosts.reduce((acc, item) => {
    if (!acc[item.label]) {
      acc[item.label] = [];
    }
    acc[item.label].push(item);

    return acc;
  }, {});

const getCorrectSettingsObject = ({ countrySettings, citySetting, isD2D }) => {
  const settings = Object.entries(countrySettings).reduce((acc, [key, value]) => {
    acc[key] =
      citySetting[key] === 0 || citySetting[key] === "0" || +citySetting[key] ? +citySetting[key] : +value || 0;

    return acc;
  }, {});

  settings.distributionCosts = isD2D ? settings.d2dDistributionCosts : settings.h2hDistributionCosts;
  settings.flyerPack = isD2D ? settings.flyerPackD2d : settings.flyerPackH2h;
  settings.permitCosts = isD2D
    ? settings.permitCostsD2d
    : settings.permitCostsH2h
  settings.countryTaxRate = countrySettings.countryTaxRate;

  delete settings.d2dDistributionCosts;
  delete settings.h2hDistributionCosts;

  delete settings.flyerPackD2d;
  delete settings.flyerPackH2h;

  delete settings.enabledChannelPreciseLocations;
  delete settings.enabledChannelBroadAreas;
  delete settings.enabledChannelLetterbox;

  return settings;
};

const specialCharacter = "#";

const convertStringSeperatedBySpecialToArray = (str) => (str ? str.split(specialCharacter) : []);

const multiplyMissionsByBA = (missions, baPerLocation) => {
  let totalMissions = [];
  for (let index = 0; index < baPerLocation; index++) {
    totalMissions = [...totalMissions, ...missions];
  }
  return totalMissions;
};

const calculateTotalMissionsH2H = (addresses, baPerLocation = 1) => {
  return addresses.reduce((acc, address) => {
    if (address.isVisible) {
      const startDates = convertStringSeperatedBySpecialToArray(address.startDate || "");
      const times = convertStringSeperatedBySpecialToArray(address.time || "");
      if (startDates.length !== times.length) return acc;

      const newMissions = multiplyMissionsByBA(
        startDates.map((d, index) => ({
          ...address,
          startDate: d,
          endDate: d,
          time: times[index],
        })),
        baPerLocation
      );

      return [...acc, ...newMissions];
    } else {
      return acc;
    }
  }, []);
};

export {
  calculateSubtotalAndPrintingCosts,
  getPrintingCostByFlyer,
  getDMPrintingCostByFlyer,
  formatPrintingCosts,
  getCorrectSettingsObject,
  calculateTotalMissionsH2H,
};
