export const ImageIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.99967 15.1666H5.99967C2.37967 15.1666 0.833008 13.6199 0.833008 9.99992V5.99992C0.833008 2.37992 2.37967 0.833252 5.99967 0.833252H9.99967C13.6197 0.833252 15.1663 2.37992 15.1663 5.99992V9.99992C15.1663 13.6199 13.6197 15.1666 9.99967 15.1666ZM5.99967 1.83325C2.92634 1.83325 1.83301 2.92659 1.83301 5.99992V9.99992C1.83301 13.0733 2.92634 14.1666 5.99967 14.1666H9.99967C13.073 14.1666 14.1663 13.0733 14.1663 9.99992V5.99992C14.1663 2.92659 13.073 1.83325 9.99967 1.83325H5.99967Z"
        fill="#1A2547"
        stroke="#1A2547"
        strokeWidth="0.4"
      />
      <path
        d="M6.00033 7.16667C4.98699 7.16667 4.16699 6.34667 4.16699 5.33333C4.16699 4.32 4.98699 3.5 6.00033 3.5C7.01366 3.5 7.83366 4.32 7.83366 5.33333C7.83366 6.34667 7.01366 7.16667 6.00033 7.16667ZM6.00033 4.5C5.54033 4.5 5.16699 4.87333 5.16699 5.33333C5.16699 5.79333 5.54033 6.16667 6.00033 6.16667C6.46033 6.16667 6.83366 5.79333 6.83366 5.33333C6.83366 4.87333 6.46033 4.5 6.00033 4.5Z"
        fill="#1A2547"
        stroke="#1A2547"
        strokeWidth="0.4"
      />
      <path
        d="M1.78017 13.1333C1.62017 13.1333 1.46017 13.0533 1.36684 12.9133C1.21351 12.6866 1.27351 12.3733 1.50684 12.22L4.79351 10.0133C5.51351 9.52662 6.50684 9.58662 7.16017 10.14L7.38017 10.3333C7.71351 10.62 8.28017 10.62 8.60684 10.3333L11.3802 7.95329C12.0868 7.34662 13.2002 7.34662 13.9135 7.95329L15.0002 8.88662C15.2068 9.06662 15.2335 9.37996 15.0535 9.59329C14.8735 9.79996 14.5602 9.82662 14.3468 9.64662L13.2602 8.71329C12.9268 8.42662 12.3602 8.42662 12.0268 8.71329L9.25351 11.0933C8.54684 11.7 7.43351 11.7 6.72017 11.0933L6.50017 10.9C6.19351 10.64 5.68684 10.6133 5.34684 10.8466L2.06017 13.0533C1.97351 13.1066 1.87351 13.1333 1.78017 13.1333Z"
        fill="#1A2547"
        stroke="#1A2547"
        strokeWidth="0.4"
      />
    </svg>
  );
};
