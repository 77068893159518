export const LayersIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 4L3 8.06354L12 12.1271L21 8.06354L12 4Z" stroke="white" strokeWidth="1.4" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76251 11.8704L3.36923 14.2281C3.14202 14.35 3.00024 14.587 3.00024 14.8449C3.00024 15.1027 3.14202 15.3397 3.36923 15.4617L11.6698 19.9163C11.8766 20.0272 12.1251 20.0272 12.3319 19.9163L20.6325 15.4617C20.8597 15.3397 21.0014 15.1027 21.0014 14.8449C21.0014 14.587 20.8597 14.35 20.6325 14.2281L16.2392 11.8704L14.7589 12.6648L18.8211 14.8449L12.0008 18.5051L5.18056 14.8449L9.24283 12.6648L7.76251 11.8704Z"
        fill="white"
      />
    </svg>
  );
};
