import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";

const style = {
  root: {
    width: "20px",
    height: "20px",
    fill: "none",
  },
};

const PrintIcon = ({ classes, ...props }) => (
  <SvgIcon {...props} className={classes.root} viewBox="0 0 20 20">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9596 6.45817H6.04297C5.7013 6.45817 5.41797 6.17484 5.41797 5.83317V4.1665C5.41797 2.03317 6.40964 1.0415 8.54297 1.0415H11.4596C13.593 1.0415 14.5846 2.03317 14.5846 4.1665V5.83317C14.5846 6.17484 14.3013 6.45817 13.9596 6.45817ZM6.66797 5.20817H13.3346V4.1665C13.3346 2.74984 12.8763 2.2915 11.4596 2.2915H8.54297C7.1263 2.2915 6.66797 2.74984 6.66797 4.1665V5.20817Z"
        fill="#011533"
      />
      <path
        d="M10.8346 18.9583H9.16797C7.1513 18.9583 6.04297 17.85 6.04297 15.8333V12.5C6.04297 12.1583 6.3263 11.875 6.66797 11.875H13.3346C13.6763 11.875 13.9596 12.1583 13.9596 12.5V15.8333C13.9596 17.85 12.8513 18.9583 10.8346 18.9583ZM7.29297 13.125V15.8333C7.29297 17.15 7.8513 17.7083 9.16797 17.7083H10.8346C12.1513 17.7083 12.7096 17.15 12.7096 15.8333V13.125H7.29297Z"
        fill="#011533"
      />
      <path
        d="M15 15.6252H13.3333C12.9917 15.6252 12.7083 15.3418 12.7083 15.0002V13.1252H7.29167V15.0002C7.29167 15.3418 7.00833 15.6252 6.66667 15.6252H5C2.98333 15.6252 1.875 14.5168 1.875 12.5002V8.3335C1.875 6.31683 2.98333 5.2085 5 5.2085H15C17.0167 5.2085 18.125 6.31683 18.125 8.3335V12.5002C18.125 14.5168 17.0167 15.6252 15 15.6252ZM13.9583 14.3752H15C16.3167 14.3752 16.875 13.8168 16.875 12.5002V8.3335C16.875 7.01683 16.3167 6.4585 15 6.4585H5C3.68333 6.4585 3.125 7.01683 3.125 8.3335V12.5002C3.125 13.8168 3.68333 14.3752 5 14.3752H6.04167V12.5002C6.04167 12.1585 6.325 11.8752 6.66667 11.8752H13.3333C13.675 11.8752 13.9583 12.1585 13.9583 12.5002V14.3752Z"
        fill="#011533"
      />
      <path
        d="M14.1654 13.125H5.83203C5.49036 13.125 5.20703 12.8417 5.20703 12.5C5.20703 12.1583 5.49036 11.875 5.83203 11.875H14.1654C14.507 11.875 14.7904 12.1583 14.7904 12.5C14.7904 12.8417 14.507 13.125 14.1654 13.125Z"
        fill="#011533"
      />
      <path
        d="M8.33203 9.7915H5.83203C5.49036 9.7915 5.20703 9.50817 5.20703 9.1665C5.20703 8.82484 5.49036 8.5415 5.83203 8.5415H8.33203C8.6737 8.5415 8.95703 8.82484 8.95703 9.1665C8.95703 9.50817 8.6737 9.7915 8.33203 9.7915Z"
        fill="#011533"
      />
    </svg>
  </SvgIcon>
);

export default withStyles(style)(PrintIcon);
