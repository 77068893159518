export const QrCheckbox = (props) => {
  const { active } = props;
  return (
    <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.665 3.635C5.135 3.635 3.5 5.368 3.5 8.05v8.168c0 2.683 1.635 4.416 4.165 4.416h8.668c2.531 0 4.167-1.733 4.167-4.416V8.05c0-2.683-1.636-4.416-4.166-4.416H7.665Zm8.668 18.5H7.665C4.276 22.135 2 19.757 2 16.219V8.05c0-3.538 2.276-5.916 5.665-5.916h8.669C19.723 2.135 22 4.513 22 8.05v8.168c0 3.538-2.277 5.916-5.667 5.916Z"
        fill="#00112B"
      />
      {active && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.349 16.135a1.02 1.02 0 0 1-.738-.317l-3.305-3.42a1.106 1.106 0 0 1 0-1.527 1.018 1.018 0 0 1 1.476 0l2.567 2.654 5.87-6.074a1.018 1.018 0 0 1 1.475 0 1.106 1.106 0 0 1 0 1.528l-6.607 6.839c-.204.212-.47.317-.738.317Z"
          fill="#EE4360"
        />
      )}
    </svg>
  );
};
