import { getShapeStyle } from "pages/flyer/builder/drawer/state/shapes/shared";
import type { ShapeStyles } from "pages/flyer/builder/drawer/types";
import * as React from "react";

interface RectangleSvgProps {
  id: string;
  style: ShapeStyles;
  isSelected: boolean;
  size: number[];
  isDarkMode: boolean;
}

export const DashedRectangle = React.memo(function DashedRectangle({
  id,
  style,
  size,
  isSelected,
  isDarkMode,
}: RectangleSvgProps) {
  const {
    stroke,
    fill,
    transparency,
    rounded,
    strokeWidth,
    isStroked,
    isFilled,
    isOpacityVisible,
    isRoundingVisible,
    isInnerShadowVisible,
    isOuterShadowVisible,
    innerShadowColor,
    innerShadow,
    outerShadow,
    outerShadowColor,
  } = getShapeStyle(style, isDarkMode);
  // const sw = 1 + strokeWidth * 1.618;
  const sw = strokeWidth!;

  const w = Math.max(0, size[0]);
  const h = Math.max(0, size[1]);

  // const strokes: [number[], number[], number][] = [
  //   [[sw / 2, sw / 2], [w, sw / 2], w - sw / 2],
  //   [[w, sw / 2], [w, h], h - sw / 2],
  //   [[w, h], [sw / 2, h], w - sw / 2],
  //   [[sw / 2, h], [sw / 2, sw / 2], h - sw / 2],
  // ];

  // const paths = strokes.map(([start, end, length], i) => {
  //   const { strokeDasharray, strokeDashoffset } = Utils.getPerfectDashProps(
  //     length,
  //     strokeWidth * 1.618,
  //     style.dash
  //   );

  //   return (
  //     <line
  //       key={id + "_" + i}
  //       x1={start[0]}
  //       y1={start[1]}
  //       x2={end[0]}
  //       y2={end[1]}
  //       strokeDasharray={strokeDasharray}
  //       strokeDashoffset={strokeDashoffset}
  //     />
  //   );
  // });

  return (
    <>
      <defs>
        {outerShadow && isOuterShadowVisible ? (
          <filter id={`shadow_${id}`} x="-400%" y="-400%" height="1000%" width="1000%">
            <feGaussianBlur in="SourceGraphic" stdDeviation={outerShadow.blur} />
          </filter>
        ) : null}
        {innerShadow && isInnerShadowVisible ? (
          <filter id={`inset-shadow_${id}`} x="-50%" y="-50%" width="200%" height="200%">
            <feComponentTransfer in="SourceAlpha">
              <feFuncA type="table" tableValues="1 0" />
            </feComponentTransfer>
            <feGaussianBlur stdDeviation={innerShadow.blur} />
            <feMorphology operator="dilate" radius={innerShadow.spread} />

            <feOffset dx={innerShadow.x} dy={innerShadow.y} result="offsetblur" />
            <feFlood floodColor={innerShadowColor} result="color" />
            <feComposite in2="offsetblur" operator="in" />
            <feComposite in2="SourceAlpha" operator="in" />
            <feMerge>
              <feMergeNode in="SourceGraphic" />
              <feMergeNode />
            </feMerge>
          </filter>
        ) : null}
      </defs>

      {outerShadow && isOuterShadowVisible ? (
        <rect
          x={outerShadow?.x - outerShadow.spread / 2 - sw / 2}
          y={outerShadow?.y - outerShadow.spread / 2 - sw / 2}
          width={w + sw + outerShadow.spread}
          height={h + sw + outerShadow.spread}
          fill={outerShadowColor}
          stroke={outerShadowColor}
          strokeWidth={isStroked ? sw : undefined}
          pointerEvents="none"
          opacity={isOpacityVisible ? transparency! / 100 : 1}
          rx={isRoundingVisible && rounded ? rounded + sw / 2 : 0}
          filter={outerShadow && isOuterShadowVisible ? `url(#shadow_${id})` : undefined}
        />
      ) : null}
      <rect
        className={isSelected || style.isFilled ? "tl-fill-hitarea" : "tl-stroke-hitarea"}
        x={0}
        y={0}
        width={w}
        height={h}
        strokeWidth={0}
        rx={isRoundingVisible && rounded ? rounded + sw / 2 : 0}
      />

      <rect
        x={0}
        y={0}
        width={w}
        height={h}
        fill={isFilled ? fill : "rgba(0,0,0,0)"}
        pointerEvents="none"
        opacity={isOpacityVisible ? transparency! / 100 : 1}
        rx={isRoundingVisible ? rounded : 0}
        filter={innerShadow && isInnerShadowVisible ? `url(#inset-shadow_${id})` : undefined}
      />
      {typeof fill === "string" && ["#fff", "#ffffff"].includes(fill) ? (
        <rect
          x={0}
          y={0}
          width={w}
          height={h}
          fill={"rgba(0,0,0,0)"}
          stroke="grey"
          strokeWidth={0.1}
          pointerEvents="none"
          opacity={0.8}
          rx={0}
        />
      ) : null}

      <rect
        x={-sw / 2}
        y={-sw / 2}
        width={w + sw}
        height={h + sw}
        fill={"rgba(0,0,0,0)"}
        stroke={isStroked ? stroke : undefined}
        strokeWidth={isStroked ? sw : undefined}
        pointerEvents="none"
        opacity={isOpacityVisible ? transparency! / 100 : 1}
        rx={isRoundingVisible && rounded ? rounded + sw / 2 : 0}
      />
    </>
  );
});
