export const styles = {
  summaryBlockContainer: {
    position: "absolute",
    right: "24px",
    top: "16px",
    borderRadius: "8px",
    background: "#FFFFFF",
    width: "380px",
    height: "100px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
  },
  summaryHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: 700,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    marginBottom: "5px",
  },
  amountsWrapper: {
    display: "flex",
    flex: 1,
  },
  amountSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    fontFamily: "'Proxima Nova', 'sans-serif'",
  },
  amount: {
    fontWeight: 700,
    fontFamily: "'Proxima Nova', 'sans-serif'",
  },
  separator: {
    height: "100%",
    width: "1px",
    background: "#E2E2FC",
  },
  settingsButton: {
    cursor: "pointer",
  },
};
