import { makeStyles } from "@material-ui/core";
import DownArrowIcon from "pages/flyer/builder/components/SideBar/Aside/NodesList/components/GroupItem/icons/DownArrowIcon";
import NodeItem from "pages/flyer/builder/components/SideBar/Aside/NodesList/components/NodeItem";
import RenderIcon from "pages/flyer/builder/components/SideBar/Aside/NodesList/components/RenderIcon";
import { GroupShape, TDShape, TDShapeType, TDSnapshot } from "pages/flyer/builder/drawer";
import { ContextMenu } from "pages/flyer/builder/drawer/components/ContextMenu";
import Tree from "rc-tree";
import { DataNode, Key } from "rc-tree/lib/interface";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import { SelectTool } from "pages/flyer/builder/drawer/state/tools/SelectTool";
import { useTldrawApp } from "../../../../drawer/hooks";
import "../../../../drawer/styles/treeStyles.css";

const nodesSelector = (s: TDSnapshot) => {
  const allShapesOfPage = s.document.pages[s.appState.currentPageId].shapes;

  const nodesForLayersList = Object.values(allShapesOfPage)
    .filter((layer) => !layer.isTemplate)
    .sort((a, b) => b.childIndex - a.childIndex)
    .map((node) => ({
      id: node.id,
      name: node.name,
      children: node.children,
      type: node.type,
      parentId: node.parentId,
    }));
  return JSON.stringify(nodesForLayersList);
};

const selectedIdsSelector = (s: TDSnapshot) =>
  JSON.stringify(s.document.pageStates[s.appState.currentPageId].selectedIds);

const NodesList = () => {
  const classes = useStyles();
  const app = useTldrawApp();
  const nodesFromState: Array<TDShape> = JSON.parse(app.useStore(nodesSelector));
  const nodesForCheckOnly: Array<TDShape> = JSON.parse(app.useStore(nodesSelector));

  const selectedNodes: Array<string> = JSON.parse(app.useStore(selectedIdsSelector));

  interface LayerNode extends DataNode {
    type: TDShapeType;
  }

  const generateChildren = (ids: Array<string>): LayerNode[] => {
    const nodesInGroup = nodesFromState.filter((node) => ids.includes(node.id));
    const children = nodesInGroup.reduce<any>((prev, node) => {
      if (ids.includes(node.id)) {
        const element: LayerNode = {
          title: node.name,
          key: node.id,
          type: node.type,
        };
        if (node.type === TDShapeType.Group) {
          const children: LayerNode[] = generateChildren(node.children);
          element.children = children;
        }
        return [...prev, element];
      }
      return prev;
    }, []);
    return children;
  };

  function makeTree(): LayerNode[] {
    const tree: LayerNode[] = [];

    const nodesOnPage = nodesFromState.filter(
      (node) => node.parentId === app.currentPageId && node.type !== TDShapeType.QRCode
    );

    nodesOnPage.forEach((node) => {
      const element: LayerNode = {
        title: node.name,
        key: node.id,
        type: node.type,
      };
      if (node.type === TDShapeType.Group) {
        const children: LayerNode[] = generateChildren(node.children);
        element.children = children;
      }
      tree.push(element);
    });
    return tree;
  }

  const nodesInTree = makeTree();

  const sortedNodes: Array<any> = [];

  const mapNodeGroup = (node: GroupShape, currentLevel: number) => {
    const childNodes = node.children
      .map((childId) => {
        const currentNode = nodesForCheckOnly.find((node) => node.id === childId)!;
        const displayedNode = { ...currentNode, level: currentLevel + 1 };
        return displayedNode;
      })
      .sort((a, b) => b.childIndex - a.childIndex);

    childNodes.forEach((node) => {
      sortedNodes.push(node);
      if (node.type === TDShapeType.Group) {
        mapNodeGroup(node, currentLevel + 1);
      }
    });
  };

  nodesForCheckOnly.forEach((node) => {
    if (node.parentId === app.currentPageId) {
      const displayedNode = { ...node, level: 0 };
      sortedNodes.push(displayedNode);
      if (node.type === TDShapeType.Group) {
        mapNodeGroup(node, 0);
      }
    }
  });
  function allowDrop({ dropNode, dropPosition }: any) {
    if (!dropNode.children) {
      if (dropPosition === 0) return false;
    }
    return true;
  }

  const onDragStart = (info: any) => {
    if (app.selectedIds.includes(info.node.key)) {
      return;
    }
    app.onSelectElement(info.node.key as string);
  };

  const onDrop = (info: any) => {
    // app.onSelectElement(info.dragNode.key as string);
    const nodeIdToDrag = info.node.key;
    const isBefore = info.dropPosition === -1;
    const isDropToGap = info.dropToGap;
    const idsToRemove: Array<string> = [];
    app.selectedIds.forEach((id) => {
      const currentShape = app.getShape(id);
      if (currentShape.type === TDShapeType.Group) {
        idsToRemove.push(...currentShape.children);
      }
      if (currentShape.isLocked) {
        idsToRemove.push(id);
      }
    });

    const shapesIdsToDrop = app.selectedIds.filter((id) => !idsToRemove.includes(id));

    app.moveToPosition(shapesIdsToDrop, nodeIdToDrag, isBefore, isDropToGap);
    return;
  };

  return (
    <DragDropContext onDragEnd={() => {}}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={classes.list}>
            <ContextMenu>
              <Tree
                multiple
                allowDrop={allowDrop}
                defaultExpandedKeys={[]}
                selectable
                selectedKeys={selectedNodes}
                onSelect={(
                  selectIds: Key[],
                  info: {
                    event: "select";
                    selected: boolean;
                    node: DataNode;
                    selectedNodes: LayerNode[];
                    nativeEvent: MouseEvent;
                  }
                ) => {
                  app.selectTool("select");
                  const nodeKey = info.node.key as string;
                  const shape = app.getShape(nodeKey);
                  if (shape.isTemplate) {
                    return;
                  }
                  if (shape.isLocked) {
                    app.select(nodeKey);
                    return;
                  }
                  if (
                    TLDR.getShapeUtil(shape.type).canEdit &&
                    (shape.parentId === app.currentPageId ||
                      shape.parentId === (app.currentTool as SelectTool).selectedGroupId)
                  ) {
                    app.setEditingId(nodeKey);
                  }
                  if (shape.parentId !== app.currentPageId) {
                    (app.currentTool as SelectTool).selectedGroupId = shape.parentId;
                  }
                  // if (app.shiftKey) {
                  if (app.ctrlKey) {
                    if (app.selectedIds.includes(nodeKey)) {
                      app.select(...app.selectedIds.filter((id) => id !== nodeKey));
                    } else {
                      app.select(...app.selectedIds, nodeKey);
                    }
                  } else {
                    app.select(nodeKey);
                  }
                }}
                onMouseEnter={(key) => {
                  app.setHoveredId(key.node.key as string);
                }}
                onMouseLeave={() => {
                  app.setHoveredId();
                }}
                draggable
                onDragStart={onDragStart}
                onDrop={onDrop}
                treeData={nodesInTree}
                icon={(obj: any) => {
                  const type = obj?.data?.type;
                  return <RenderIcon type={type} shape={app.getShape(obj?.data?.key)} />;
                }}
                switcherIcon={(obj: any) => {
                  if (obj.data?.children) {
                    return <DownArrowIcon />;
                  }
                }}
                titleRender={(node) => <NodeItem id={node.key} name={node.title} />}
              />
            </ContextMenu>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default React.memo(NodesList);

const useStyles = makeStyles({
  dropItem: {
    cursor: "default !important",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    background: "var(--color-surface-panel)",
    overflow: "scroll",
    height: "100%",
  },
  layers: {
    overflow: "scroll",
  },
});
