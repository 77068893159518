import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import { Icon } from "components/icons";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type NonGoogleAlertModalProps = {
  open?: boolean;
  closeModal?: () => void;
};

export const NonGoogleAlertModal = ({ open = false, closeModal = () => {} }: NonGoogleAlertModalProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={closeModal} className={classes.wrapper}>
      <Typography>To improve your experience on our website, we suggest using the</Typography>
      <Typography className={classes.belowText}>
        <Icon type="Google" size="m" />
        Google Chrome browser for the best performance.
      </Typography>
    </Dialog>
  );
};
