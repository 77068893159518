import { makeStyles } from "@material-ui/core";
import { If } from "components/logic";
import { useCallback, useState } from "react";
import PrintRuleModal from "../PrintRuleModal/PrintRuleModal";
import Aside from "./Aside";
import Toolbar from "./Toolbar/NewToolBar";
import { SECTIONS } from "../../constants";

const SideBar = ({ loading }) => {
  const [isShowPrintRuleModal, setIsShowPrintRuleModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(SECTIONS.SETTINGS);
  const classes = useStyles();

  const handleOpenPrintRuleModal = useCallback(() => {
    setIsShowPrintRuleModal(true);
  }, []);

  const handleClosePrintRuleModal = useCallback(() => {
    setIsShowPrintRuleModal(false);
  }, []);

  return (
    <div className={classes.root}>
      <Toolbar
        handleOpenPrintRuleModal={handleOpenPrintRuleModal}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />
      <If condition={!!selectedSection}>
        <Aside loading={loading} selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
      </If>
      <If condition={isShowPrintRuleModal}>
        <PrintRuleModal onCancel={handleClosePrintRuleModal} />
      </If>
    </div>
  );
};

export default SideBar;

const useStyles = makeStyles({
  root: {
    zIndex: 10,
    display: "flex",
    alignItems: "stretch",
    height: "100%",
    flex: 1,
    position: "absolute",
  },
});
