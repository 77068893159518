import CONFIG from "../../config/config";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getSelfServeCampaignData = async (campaignId) => {
  const gql = `query($campaignId: ID!) {
        selfServeCampaignData(campaignId: $campaignId) {
          selectedCities {
            addresses {
              center
              paths
              editMode
              id
              isVisible
              radius
              type
              value
              isCustomLocationName
              isFixedLocation
              position
              startDate
              endDate
              time
              missionCount
            }
            distributionZones {
              h2h {
                areaType
                center {
                  lat
                  lng
                }
                editMode
                id
                imported
                radius
                type
                value
                paths
              }
              d2d {
                areaType
                center {
                  lat
                  lng
                }
                editMode
                id
                imported
                radius
                type
                value
                paths
              }
            }
            id
            isAddressesPopupOpened
            isVisible
            lat
            lng
            name
            nameWithState
          }
        }
      }`;

  try {
    const request = await callAuthorizedGraphQLEndpoint(
      gql,
      {
        campaignId,
      },
      CONFIG.PATHFINDER_API_BASE_URL
    );

    if (request.status === 200) {
      const {
        data: {
          selfServeCampaignData: { selectedCities },
        },
      } = await request.json();

      return {
        selectedCities,
      };
    }
  } catch (error) {
    return error;
  }
};

export const getEDDMSelfServeCampaignData = async (campaignId) => {
  const gql = `query($campaignId: ID!) {
        eddmSelfServeCampaignData(campaignId: $campaignId) {
          distributionLocations {
            isFocused
            lat
            lng
            name
            radius
            selectedRoutes
          }
        }
      }`;

  try {
    const request = await callAuthorizedGraphQLEndpoint(
      gql,
      {
        campaignId,
      },
      CONFIG.PATHFINDER_API_BASE_URL
    );

    if (request.status === 200) {
      const {
        data: {
          eddmSelfServeCampaignData: { distributionLocations },
        },
      } = await request.json();

      return {
        distributionLocations,
      };
    }
  } catch (error) {
    return error;
  }
};
