export const DeleteIcon = ({ disabled, width, height, ...restProps }) => (
  <svg
    width={width || "21"}
    height={height || "20"}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M16.6071 7.89017C16.6071 7.89017 16.1546 13.5027 15.8921 15.8668C15.7671 16.996 15.0696 17.6577 13.9271 17.6785C11.7529 17.7177 9.57625 17.7202 7.40292 17.6743C6.30375 17.6518 5.61792 16.9818 5.49542 15.8727C5.23125 13.4877 4.78125 7.89017 4.78125 7.89017"
      stroke={disabled ? "#AEAFC7" : "#1D2943"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7567 5.19975H3.625"
      stroke={disabled ? "#AEAFC7" : "#1D2943"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0374 5.19975C14.3832 5.19975 13.8199 4.73725 13.6916 4.09642L13.4891 3.08308C13.3641 2.61558 12.9407 2.29225 12.4582 2.29225H8.93073C8.44823 2.29225 8.0249 2.61558 7.8999 3.08308L7.6974 4.09642C7.56906 4.73725 7.00573 5.19975 6.35156 5.19975"
      stroke={disabled ? "#AEAFC7" : "#1D2943"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
