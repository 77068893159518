export const QRIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.125 3H3.875C3.39175 3 3 3.39175 3 3.875V9.125C3 9.60825 3.39175 10 3.875 10H9.125C9.60825 10 10 9.60825 10 9.125V3.875C10 3.39175 9.60825 3 9.125 3Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.125 14H3.875C3.39175 14 3 14.3918 3 14.875V20.125C3 20.6082 3.39175 21 3.875 21H9.125C9.60825 21 10 20.6082 10 20.125V14.875C10 14.3918 9.60825 14 9.125 14Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.125 3H14.875C14.3918 3 14 3.39175 14 3.875V9.125C14 9.60825 14.3918 10 14.875 10H20.125C20.6082 10 21 9.60825 21 9.125V3.875C21 3.39175 20.6082 3 20.125 3Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 14V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 21H18V14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 16H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 19V21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
