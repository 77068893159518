import ReactDatePicker from "react-datepicker";
import { styled } from "pages/flyer/builder/drawer/styles/index";

export const StyledDatePickerContainer = styled("div", {
  position: "fixed",
  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 !important",
  zIndex: 10,
  background: "rgba(39, 55, 84, 0.16)",
  "@media screen and (max-width: 768px)": {
    top: "-40px",
    padding: "16px",
  },
});

export const StyledDatePicker = styled(ReactDatePicker, {});

export default StyledDatePickerContainer;
