import { useMutation } from "@apollo/client";
import { CircularProgress, Box } from "@material-ui/core";
import { Renderer } from "@tldraw/core";
import Header from "components/header/index";
import { SnackBarContext } from "components/snack-bar";
import CONFIG from "config/config";
import ROUTES from "config/routing";
import SideBar from "pages/flyer/builder/components/SideBar";
import { ContextMenu } from "pages/flyer/builder/drawer/components/ContextMenu";
import { FocusButton } from "pages/flyer/builder/drawer/components/FocusButton";
import { Loading } from "pages/flyer/builder/drawer/components/Loading";
import { ToolsPanel } from "pages/flyer/builder/drawer/components/ToolsPanel";
import TopPanel from "pages/flyer/builder/drawer/components/TopPanel/TopPanel";
import { GRID_SIZE } from "pages/flyer/builder/drawer/constants/constants";
import { TldrawContext, useKeyboardShortcuts, useStylesheet, useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { TDCallbacks, TldrawApp } from "pages/flyer/builder/drawer/state";
import { shapeUtils } from "pages/flyer/builder/drawer/state/shapes";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import { StyledLayout, StyledLoader, StyledSpacer, StyledUI } from "pages/flyer/builder/drawer/styled";
import { dark } from "pages/flyer/builder/drawer/styles";
import { GtagOptions, TDDocument, TDShapeType, TDStatus } from "pages/flyer/builder/drawer/types";
import useStyles from "pages/flyer/builder/drawer/useStyles";
import React, { useContext, MouseEvent } from "react";
import { DialogModalContext } from "components/dialog-modal";
import { gtagWrapper, checkIsNeedUpdateLastActiveStepState } from "utils";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { GenerateAndSaveDocument } from "shared/graphql/mutations/useGenerateAndSaveDocument";
import isValidUrl from "utils/isValidurl";
import ConfirmPopup from "pages/flyer/builder/drawer/components/ConfirmPopup";

import WebFont from "webfontloader";
import { updateCampaignExtraData, getCampaign } from "../../../../graphQL";

import { CAMPAIGN_INTERNAL_STATUSES } from "../../../constants";
import BlockingOverlay from "../../../../components/BlockingOverlay";
import { useStore } from "../../../../store";
import { StoreType } from "../../../../store/types";
import { authorization } from "../../../../module/auth";

// import WebFont from "webfontloader";

const bodyMutationCallback = (mutationList: any) => {
  for (const mutation of mutationList) {
    if (mutation.type === "attributes" && mutation.attributeName === "data-scroll-locked") {
      window.document.body.removeAttribute("data-scroll-locked");
    }
  }
};

export interface TldrawProps extends TDCallbacks {
  /**
   * (optional) If provided, the component will load / persist state under this key.
   */
  id?: string;

  /**
   * (optional) The document to load or update from.
   */
  document?: TDDocument;

  /**
   * (optional) The current page id.
   */
  currentPageId?: string;

  /**
   * (optional) Whether the editor should immediately receive focus. Defaults to true.
   */
  autofocus?: boolean;

  /**
   * (optional) Whether to show the menu UI.
   */
  showMenu?: boolean;

  /**
   * (optional) Whether to show the pages UI.
   */
  showPages?: boolean;

  /**
   * (optional) Whether to show the styles UI.
   */
  showStyles?: boolean;

  /**
   * (optional) Whether to show the zoom UI.
   */
  showZoom?: boolean;

  /**
   * (optional) Whether to show the tools UI.
   */
  showTools?: boolean;

  /**
   * (optional) Whether to show a sponsor link for Tldraw.
   */
  showSponsorLink?: boolean;

  /**
   * (optional) Whether to show the UI.
   */
  showUI?: boolean;

  /**
   * (optional) Whether to the document should be read only.
   */
  readOnly?: boolean;

  /**
   * (optional) Whether to to show the app's dark mode UI.
   */
  darkMode?: boolean;

  /**
   * (optional) If provided, image/video componnets will be disabled.
   *
   * Warning: Keeping this enabled for multiplayer applications without provifing a storage
   * bucket based solution will cause massive base64 string to be written to the liveblocks room.
   */
  disableAssets?: boolean;

  loading: boolean;
}

export function Tldraw({
  id,
  loading,
  document,
  currentPageId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  darkMode = false,
  autofocus = true,
  showMenu = false,
  showPages = false,
  showTools = false,
  showZoom = true,
  showStyles = true,
  showUI = true,
  readOnly = false,
  showSponsorLink = false,
  disableAssets = false,
  onMount,
  onChange,
  onChangePresence,
  onNewProject,
  onSaveProject,
  onSaveProjectAs,
  onOpenProject,
  onOpenMedia,
  onSignOut,
  onSignIn,
  onUndo,
  onRedo,
  onPersist,
  onPatch,
  onCommand,
  onChangePage,
  onChangeDocumentFormat,
  onAssetCreate,
  onAssetDelete,
  onExport,
  saveFlyerBuilderStates,
}: TldrawProps) {
  const [sId, setSId] = React.useState(id);
  const {
    campaign: { flyerType },
    flyerBuilder: { loading: flyerBuilderLoading },
  }: StoreType = useStore();

  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["Bebas Neue", "Inconsolata"],
      },
    });
  }, []);

  React.useEffect(() => {
    const observer = new MutationObserver(bodyMutationCallback);
    const targetNode = window.document.body;
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  const [app, setApp] = React.useState(() => {
    const app = new TldrawApp(
      id,
      {
        onMount,
        onChange,
        onChangePresence,
        onNewProject,
        onSaveProject,
        onSaveProjectAs,
        onOpenProject,
        onOpenMedia,
        onSignOut,
        onSignIn,
        onUndo,
        onRedo,
        onPersist,
        onPatch,
        onCommand,
        onChangePage,
        onChangeDocumentFormat,
        onAssetDelete,
        onAssetCreate,
      },
      {
        format: flyerType,
      }
    );
    return app;
  });

  // Create a new app if the `id` prop changes.
  React.useEffect(() => {
    if (id === sId) return;

    const newApp = new TldrawApp(id, {
      onMount,
      onChange,
      onChangePresence,
      onNewProject,
      onSaveProject,
      onSaveProjectAs,
      onOpenProject,
      onOpenMedia,
      onSignOut,
      onSignIn,
      onUndo,
      onRedo,
      onPersist,
      onPatch,
      onCommand,
      onChangePage,
      onChangeDocumentFormat,
      onAssetDelete,
      onAssetCreate,
      onExport,
    });
    setSId(id);
    setApp(newApp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sId, id]);

  // Update the document if the `document` prop changes but the ids,
  // are the same, or else load a new document if the ids are different.
  React.useEffect(() => {
    if (!document) return;
    if (document.id === app.document.id) {
      app.updateDocument(document);
    } else {
      app.loadDocument(document);
    }
  }, [document, app]);

  // Disable assets when the `disableAssets` prop changes.
  React.useEffect(() => {
    app.setDisableAssets(disableAssets);
  }, [app, disableAssets]);

  // Change the page when the `currentPageId` prop changes.
  React.useEffect(() => {
    if (!currentPageId) return;
    app.changePage(currentPageId);
  }, [currentPageId, app]);

  // Toggle the app's readOnly mode when the `readOnly` prop changes.
  React.useEffect(() => {
    app.readOnly = readOnly;
  }, [app, readOnly]);

  // Update the app's callbacks when any callback changes.
  React.useEffect(() => {
    app.callbacks = {
      onMount,
      onChange,
      onChangePresence,
      onNewProject,
      onSaveProject,
      onSaveProjectAs,
      onOpenProject,
      onOpenMedia,
      onSignOut,
      onSignIn,
      onUndo,
      onRedo,
      onPersist,
      onPatch,
      onCommand,
      onChangePage,
      onChangeDocumentFormat,
      onAssetDelete,
      onAssetCreate,
      onExport,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onMount,
    onChange,
    onChangePresence,
    onNewProject,
    onSaveProject,
    onSaveProjectAs,
    onOpenProject,
    onOpenMedia,
    onSignOut,
    onSignIn,
    onUndo,
    onRedo,
    onPersist,
    onPatch,
    onCommand,
    onChangePage,
    onChangeDocumentFormat,
    onAssetDelete,
    onAssetCreate,
    onExport,
  ]);

  // Use the `key` to ensure that new selector hooks are made when the id changes
  return (
    <TldrawContext.Provider value={app}>
      {(loading || flyerBuilderLoading) && (
        <StyledLoader>
          <CircularProgress color="secondary" />
        </StyledLoader>
      )}
      <InnerTldraw
        key={sId || "Tldraw"}
        id={sId}
        autofocus={autofocus}
        showPages={showPages}
        showMenu={showMenu}
        showStyles={showStyles}
        showZoom={showZoom}
        showTools={showTools}
        showUI={showUI}
        showSponsorLink={showSponsorLink}
        readOnly={readOnly}
        loading={loading}
        saveFlyerBuilderStates={saveFlyerBuilderStates}
      />
    </TldrawContext.Provider>
  );
}

interface InnerTldrawProps {
  id?: string;
  autofocus: boolean;
  showPages: boolean;
  showMenu: boolean;
  showZoom: boolean;
  showStyles: boolean;
  showUI: boolean;
  showTools: boolean;
  showSponsorLink: boolean;
  readOnly: boolean;
  loading: boolean;
  saveFlyerBuilderStates?: () => void;
}

const InnerTldraw = React.memo(function InnerTldraw({
  id,
  autofocus,
  showPages,
  showMenu,
  showZoom,
  showStyles,
  showTools,
  showSponsorLink,
  readOnly,
  showUI,
  loading,
  saveFlyerBuilderStates,
}: InnerTldrawProps) {
  const runDialogModal = useContext(DialogModalContext);
  const app = useTldrawApp();
  const {
    campaign: { id: campaignId, qrCodeLink, isSubmitted, lastActiveStep },
    user: { id: userId },
    client: { id: clientId },
    updateCampaign,
    updateErrors,
  }: StoreType = useStore();

  const styles = useStyles();

  const rWrapper = React.useRef<HTMLDivElement>(null);

  const state = app.useStore();

  const { document, settings, appState, room } = state;

  const [onExport] = useMutation(GenerateAndSaveDocument);

  const isSelecting = state.appState.activeTool === "select";

  const page = document.pages[appState.currentPageId];
  const pageState = document.pageStates[page.id];
  const assets = document.assets;
  const { selectedIds } = pageState;

  const isHideBoundsShape =
    selectedIds.length === 1 &&
    page.shapes[selectedIds[0]] &&
    TLDR.getShapeUtil(page.shapes[selectedIds[0]].type).hideBounds;

  const isHideResizeHandlesShape =
    selectedIds.length === 1 &&
    page.shapes[selectedIds[0]] &&
    TLDR.getShapeUtil(page.shapes[selectedIds[0]].type).hideResizeHandles;

  // Custom rendering meta, with dark mode for shapes
  const meta = React.useMemo(() => {
    return { isDarkMode: settings.isDarkMode };
  }, [settings.isDarkMode]);

  // Custom theme, based on darkmode
  const theme = React.useMemo(() => {
    if (settings.isDarkMode) {
      return {
        brushFill: "rgba(180, 180, 180, .05)",
        brushStroke: "rgba(180, 180, 180, .25)",
        selected: "rgba(38, 150, 255, 1.000)",
        selectFill: "rgba(38, 150, 255, 0.05)",
        background: "#212529",
        foreground: "#49555f",
      };
    }

    return {
      background: "#d9d9d9",
    };
  }, [settings.isDarkMode]);

  const isInSession = app.session !== undefined;

  // Hide bounds when not using the select tool, or when the only selected shape has handles

  const hideBounds =
    isHideBoundsShape ||
    (isInSession &&
      (app.session?.constructor.name === "BrushSession" || app.session?.constructor.name === "DrawSession"));

  // Hide bounds when not using the select tool, or when in session
  const hideHandles = isInSession || !isSelecting;

  const isClient = authorization.isClient();
  const isDev = process.env.NODE_ENV === "development";

  // Hide indicators when not using the select tool, or when in session
  const hideIndicators = (isInSession && state.appState.status !== TDStatus.Brushing) || !isSelecting;

  const hideCloneHandles = isInSession || !isSelecting || !settings.showCloneHandles || pageState.camera.zoom < 0.2;

  const [isExporting, setIsExporting] = React.useState(false);
  const [isOpenPopup, setIsOpenPopup] = React.useState(false);
  const [shapesAfterRender, setShapesAfterRender] = React.useState<any>(null);
  const [qrCodeLinkAfterRender, setQrCodeLinkAfterRender] = React.useState<string | undefined>(undefined);
  const history = useHistory();
  const onDone = React.useCallback(async () => {
    history.push(generatePath(ROUTES.CAMPAIGN_DETAILS, { campaignId, clientId }));
  }, [history]);
  const [isBlocked, setIsBlocked] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShapesAfterRender((prev: any) => {
      if (!prev && Object.keys(page.shapes).length) {
        return page.shapes;
      } else {
        return prev;
      }
    });
  }, [page.shapes]);

  React.useEffect(() => {
    // @ts-ignore
    setQrCodeLinkAfterRender((prev: string | undefined) => {
      if (!prev && isValidUrl(qrCodeLink)) {
        return qrCodeLink;
      } else {
        return prev;
      }
    });
  }, [qrCodeLink]);

  const isChanged = () => {
    if (page.shapes && JSON.stringify(page.shapes) !== JSON.stringify(shapesAfterRender)) {
      return true;
    } else {
      if (isValidUrl(qrCodeLink)) {
        if (!isValidUrl(qrCodeLinkAfterRender)) {
          return true;
        } else {
          return qrCodeLink !== qrCodeLinkAfterRender;
        }
      } else {
        if (isValidUrl(qrCodeLinkAfterRender)) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const onPdfExport = React.useCallback(async () => {
    //TODO Implement On Print
    alert("This feature in development");
  }, []);
  const runSnackBar = React.useContext(SnackBarContext);

  const onBack = () => {
    history.push(generatePath(ROUTES.FLYER, { campaignId, clientId }));
  };

  const handleSave = async ({ withBack = false }) => {
    if (app.isLoading) return;

    const isValid = isValidUrl(qrCodeLink);
    const isOnCanvas = app.isQrCodesOnCanvas();

    if (!(!!app.shapes.find((shape) => shape.type === TDShapeType.QRCode) && (withBack || (isValid && isOnCanvas)))) {
      return runSnackBar({
        type: "error",
        msg: "Please make sure that QRCode link is valid and it is located inside the paper.",
        vertical: "top",
        horizontal: "right",
      });
    }

    const { internalStatus } = await getCampaign(campaignId);

    if (internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      return runSnackBar({
        type: "error",
        msg: "Campaign already submitted.",
        vertical: "top",
        horizontal: "right",
      });
    }

    app.setIsLoading(true);

    await updateCampaignExtraData({
      campaignId: campaignId,
      qrCodeLink: isValid && isOnCanvas ? qrCodeLink : "",
    });

    saveFlyerBuilderStates?.();

    setShapesAfterRender(page.shapes);
    app.setIsLoading(false);

    runSnackBar({
      type: "success",
      msg: `Saved.`,
      vertical: "top",
      horizontal: "right",
    });

    if (withBack) {
      onBack();
      setIsOpenPopup(false);
    }
  };

  const handleFileDrop = async (file: React.DragEvent<Element>) => {
    try {
      await app.onDrop(file);
    } catch (error) {
      runSnackBar({
        type: "error",
        msg: `Error while uploading the file: ${error}`,
        vertical: "bottom",
        horizontal: "right",
      });
    }
  };

  const handleNext = async () => {
    setIsBlocked(true);

    const { internalStatus, flyerType, flyerWeight } = await getCampaign(campaignId);
    if (internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      return runSnackBar({
        type: "error",
        msg: "Campaign already submitted.",
        vertical: "top",
        horizontal: "right",
      });
    }

    setIsExporting(true);

    const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
      stateLastActiveStep: lastActiveStep,
      newLastActiveStep: "design",
      isDM: false,
    });

    const payloadUpdateExtraData = {
      campaignId: campaignId,
      qrCodeLink: qrCodeLink,
      lastActiveStep: lastActiveStep,
    };

    if (isNeedUpdateLastActiveStep) {
      payloadUpdateExtraData.lastActiveStep = "design";
    }

    await updateCampaignExtraData(payloadUpdateExtraData);

    saveFlyerBuilderStates?.();
    console.log("before export looooog");
    await onExport({
      variables: {
        document: app.state,
        campaignId: campaignId,
      },
    });
    console.log("after export looooog");
    updateErrors({ notExistDesignFile: false });

    const gtagOptions: GtagOptions = {
      event: "ss_design_submitted",
      client_id: clientId?.toString(),
      user_id: userId,
      campaign_id: campaignId,
      flyer_format: flyerType,
      flyer_weight: flyerWeight,
      flyer_submitted_type: "design",
      nr_of_elements_created: Object.keys(app.state.document.assets).length,
    };
    gtagWrapper(gtagOptions);

    await onDone();

    updateCampaign({
      lastActiveStep: payloadUpdateExtraData.lastActiveStep,
    });

    setIsBlocked(false);
  };

  const homeUrl = `${CONFIG.PLATFORM_BASE_URL}${
    clientId && isClient ? `/dashboard/client-campaigns/${clientId}/campaign` : ""
  }`;

  const mainNavActions = React.useMemo(
    () => {
      const isValid = isValidUrl(qrCodeLink);
      const isOnCanvas = app.isQrCodesOnCanvas();
      return [
        ...(isDev
          ? [
              {
                title: "For Dev",
                variant: "active",
                isActive: !!app.shapes.find((shape) => shape.type === TDShapeType.QRCode) && isValid && isOnCanvas,
                action: async () => {
                  saveFlyerBuilderStates?.();
                  const resp = await onExport({
                    variables: {
                      document: app.state,
                      campaignId: campaignId,
                    },
                  });
                  window.open(resp.data.generateAndSaveDocument.link, "_blank");
                },
              },
            ]
          : []),
        {
          title: "Save",
          isActive: isValid,
          cypressId: "saveBtn",
          action: async () => {
            await handleSave({ withBack: false });
          },
        },
        {
          title: (
            <div className={styles.nextButton}>
              {isExporting ? <CircularProgress className={styles.progress} size={20} /> : ""} Next
            </div>
          ),
          variant: "active",
          cypressId: "goToNextStepBtn",
          isActive: !!app.shapes.find((shape) => shape.type === TDShapeType.QRCode) && isValid && isOnCanvas,
          isValidUrl: isValid,
          isQrCodeOnCanvas: isOnCanvas,
          action: async () => {
            await handleNext();
          },
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDone, onPdfExport, app.shapes, app.document.orientation, app.state.document.assets?.qrCode?.src, homeUrl]
  );

  const onSave = async () => {
    await handleSave({ withBack: true });
  };

  const onSkip = () => {
    app.restoreToOriginal();

    /**
     * in the above function, we used 'debouncedOnChange' function of ;useMultiplayerState' hook.
     * so 'onChangePage' function will be entered callback queue and will be executed after 500ms
     * We should execute 'onBack' after the 'onChangePage' function, so we need to insert 'onBack' function into the callback queue
     * after 'onChangePage' function
     */
    setTimeout(() => {
      onBack();
    }, 1000);
  };

  const headerActions = {
    BACK: {
      cypressId: "backToCampBuilderBtn",
      action: () => {
        if (isChanged()) {
          setIsOpenPopup(true);
        } else {
          onBack();
        }
      },
    },
  };

  return (
    <StyledLayout tabIndex={-0} className={settings.isDarkMode ? dark : ""}>
      {isBlocked ? <BlockingOverlay /> : null}
      <Loading />
      <div className={styles.page}>
        <ConfirmPopup
          isOpen={isOpenPopup}
          onClose={() => setIsOpenPopup(false)}
          onSave={onSave}
          onSkip={onSkip}
          loading={app.isLoading}
          title="Save your progress?"
          body="If you quit without saving, all your progress will be lost. Do you want to save campaign progress in draft so
          you can finish it later?"
          showSkipButton
        />
        <div className={styles.headerWrapper}>
          <Header
            maxWidth={false}
            headerActions={headerActions}
            flyerActions={mainNavActions}
            isFlyerBuilderPage
            //TODO Need to optimize header becaute it rerender a lot
            // rewritedClasses={{ header: styles.header }}
          />
        </div>
        <div className={styles.drawerWrapper}>
          <SideBar loading={loading} />
          <div className={styles.drawer} ref={rWrapper}>
            <OneOff focusableRef={rWrapper} autofocus={autofocus} />
            <ContextMenu>
              <Renderer
                id={id}
                containerRef={rWrapper}
                shapeUtils={shapeUtils()}
                page={page}
                pageState={pageState}
                assets={assets}
                snapLines={appState.snapLines}
                grid={GRID_SIZE}
                users={room?.users}
                userId={room?.userId}
                theme={theme}
                meta={meta}
                hideBounds={hideBounds}
                hideHandles={hideHandles}
                hideResizeHandles={isHideResizeHandlesShape}
                hideIndicators={hideIndicators}
                hideBindingHandles={!settings.showBindingHandles}
                hideCloneHandles={hideCloneHandles}
                hideRotateHandles={!settings.showRotateHandles}
                hideGrid={!settings.showGrid}
                performanceMode={app.session?.performanceMode}
                onPinchStart={app.onPinchStart}
                onPinchEnd={app.onPinchEnd}
                onPinch={app.onPinch}
                onPan={app.onPan}
                onZoom={app.onZoom}
                onPointerDown={app.onPointerDown}
                onPointerMove={app.onPointerMove}
                onPointerUp={app.onPointerUp}
                onPointCanvas={app.onPointCanvas}
                onDoubleClickCanvas={app.onDoubleClickCanvas}
                onRightPointCanvas={app.onRightPointCanvas}
                onDragCanvas={app.onDragCanvas}
                onReleaseCanvas={app.onReleaseCanvas}
                onPointShape={app.onPointShape}
                onDoubleClickShape={app.onDoubleClickShape}
                onRightPointShape={app.onRightPointShape}
                onDragShape={app.onDragShape}
                onHoverShape={app.onHoverShape}
                onUnhoverShape={app.onUnhoverShape}
                onReleaseShape={app.onReleaseShape}
                onPointBounds={app.onPointBounds}
                onDoubleClickBounds={app.onDoubleClickBounds}
                onRightPointBounds={app.onRightPointBounds}
                onDragBounds={app.onDragBounds}
                onHoverBounds={app.onHoverBounds}
                onUnhoverBounds={app.onUnhoverBounds}
                onReleaseBounds={app.onReleaseBounds}
                onPointBoundsHandle={app.onPointBoundsHandle}
                onDoubleClickBoundsHandle={app.onDoubleClickBoundsHandle}
                onRightPointBoundsHandle={app.onRightPointBoundsHandle}
                onDragBoundsHandle={app.onDragBoundsHandle}
                onHoverBoundsHandle={app.onHoverBoundsHandle}
                onUnhoverBoundsHandle={app.onUnhoverBoundsHandle}
                onReleaseBoundsHandle={app.onReleaseBoundsHandle}
                onPointHandle={app.onPointHandle}
                onDoubleClickHandle={app.onDoubleClickHandle}
                onRightPointHandle={app.onRightPointHandle}
                onDragHandle={app.onDragHandle}
                onHoverHandle={app.onHoverHandle}
                onUnhoverHandle={app.onUnhoverHandle}
                onReleaseHandle={app.onReleaseHandle}
                onError={app.onError}
                onRenderCountChange={app.onRenderCountChange}
                onShapeChange={app.onShapeChange}
                onShapeBlur={app.onShapeBlur}
                onShapeClone={app.onShapeClone}
                onBoundsChange={app.updateBounds}
                onKeyDown={app.onKeyDown}
                onKeyUp={app.onKeyUp}
                onDragOver={app.onDragOver}
                onDrop={handleFileDrop}
              />
            </ContextMenu>
            {showUI && (
              <StyledUI>
                {settings.isFocusMode ? (
                  <FocusButton onSelect={app.toggleFocusMode} />
                ) : (
                  <>
                    <TopPanel
                      readOnly={readOnly}
                      showPages={showPages}
                      showMenu={showMenu}
                      showStyles={showStyles}
                      showZoom={showZoom}
                      showSponsorLink={showSponsorLink}
                    />
                    <StyledSpacer />
                    {showTools && !readOnly && <ToolsPanel />}
                  </>
                )}
              </StyledUI>
            )}
          </div>
        </div>
      </div>
    </StyledLayout>
  );
});

const OneOff = React.memo(function OneOff({
  focusableRef,
  autofocus,
}: {
  autofocus?: boolean;
  focusableRef: React.RefObject<HTMLDivElement>;
}) {
  useKeyboardShortcuts(focusableRef);
  useStylesheet();

  React.useEffect(() => {
    if (autofocus) {
      focusableRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autofocus]);

  return null;
});
