import { Attention } from "components/icons/components";
import ROUTING, { DM_ROUTING } from "config/routing";
import { generatePath, useHistory } from "react-router";
import { useParams } from "react-router-dom";

interface UploadFlyerSectionProps {
  classes: { [key: string]: string };
  isDM: boolean;
}

export const UploadFlyerSection = ({ classes, isDM }: UploadFlyerSectionProps) => {
  const history = useHistory();
  const { campaignId, clientId } = useParams();

  const handleClick = (campaignId: string, clientId: number) => {
    if (isDM) {
      history.push(generatePath(DM_ROUTING.DM_UPLOAD_FLYER, { campaignId, clientId }));
    } else {
      const existSkipDesignCampaigns = localStorage.getItem("skipDesignCampaigns");
      const skipCampaign = {
        wasRedirected: true,
        campaignId,
      };

      if (existSkipDesignCampaigns) {
        const parsed = JSON.parse(existSkipDesignCampaigns);
        const alreadyExisting = parsed.find(
          (campaign: { campaignId: string; wasRedirected: boolean }) => campaign.campaignId === campaignId
        );
        if (!alreadyExisting) {
          localStorage.setItem("skipDesignCampaigns", JSON.stringify([...parsed, skipCampaign]));
        }
      } else {
        localStorage.setItem("skipDesignCampaigns", JSON.stringify([skipCampaign]));
      }

      const path = generatePath(ROUTING.FLYER, { campaignId, clientId });
      history.push(path);
    }
  };

  return (
    <div className={classes.skipContainer}>
      <div className={classes.skipContentBox}>
        <div className={classes.skipTitleContainer}>
          <div className={classes.iconWithTitle}>
            <Attention stroke="#E00404" />
            <p className={classes.flyerTitleSkip}>Flyer design needed</p>
          </div>
          <button onClick={() => handleClick(campaignId, clientId)} className={classes.uploadFlyerButton}>
            Upload design
          </button>
        </div>
        <p className={classes.skipText}>Please upload your flyer design before scheduling your campaign.</p>
      </div>
    </div>
  );
};
