import { RadioItem } from "@radix-ui/react-dropdown-menu";
import { styled } from "pages/flyer/builder/drawer/styles/stitches.config";

export const DMRadioItem = styled(RadioItem, {
  height: "28px",
  minWidth: "28px",
  backgroundColor: "$panel",
  borderRadius: "4px",
  padding: "2px",
  margin: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  outline: "none",
  border: "none",
  pointerEvents: "all",
  cursor: "pointer",
  marginRight: "2px",

  variants: {
    isActive: {
      true: {
        backgroundColor: "#F3F3FF",
        border: "1px solid #E2E2FC",
        color: "$panel",
        "& path": {
          fill: "#212529 !important",
        },
        // "&:hover": {
        //   backgroundColor: "#F3F3FF",
        // },
      },
      false: {
        "&:hover": {
          backgroundColor: "$hover",
        },
      },
    },
    bp: {
      mobile: {},
      small: {},
    },
  },

  compoundVariants: [
    {
      isActive: false,
      bp: "small",
      css: {
        "&:focus": {
          backgroundColor: "$hover",
        },
        "&:hover:not(:disabled)": {
          backgroundColor: "$hover",
        },
      },
    },
  ],
});
