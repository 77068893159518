import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { SnackBarContext } from "components/snack-bar";

const ImageComponent = () => {
  const classes = useStyles();
  const app = useTldrawApp();
  const runSnackBar = useContext(SnackBarContext);

  const handleUploadMedia = useCallback(() => {
    app.openAsset().then((res: any) => {
      if (res.error) {
        runSnackBar({
          type: "error",
          msg: `Error while uploading the file: ${res.error.message}`,
          vertical: "bottom",
          horizontal: "right",
        });
      }
    });
  }, [app]);

  return (
    <div className={classes.imageButton} onClick={handleUploadMedia}>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.6579 7.48661H5.8804C4.18457 7.48661 2.80957 8.86161 2.80957 10.5574L2.80957 14.6199C2.80957 16.3149 4.18457 17.6899 5.8804 17.6899H15.1554C16.8512 17.6899 18.2262 16.3149 18.2262 14.6199V10.5491C18.2262 8.85827 16.8554 7.48661 15.1646 7.48661H14.3787"
          stroke="#273754"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5177 1.82545V11.8596"
          stroke="#273754"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.08862 4.26562L10.5178 1.82562L12.9478 4.26562"
          stroke="#273754"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className={classes.label}>Upload image</span>
    </div>
  );
};

const useStyles = makeStyles({
  imageButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F9F9FF",
    width: "100%",
    height: "40px",
    borderRadius: "8px",
    padding: "6px 8px",
    cursor: "pointer",
  },
  label: {
    fontFamily: "Proxima Nova, sans-serif",
    marginLeft: "10px",
    fontWeight: 400,
    fontSize: "16px",
  },
});

export const Image = React.memo(ImageComponent);
