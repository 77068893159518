export const styles = {
  cardWrapper: {
    width: "323px",
    padding: "12px",
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "20px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    boxShadow: "0px 2px 18px rgba(39, 55, 84, 0.08)",
    transition: ".3s all",

    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  image: {
    width: "300px",
    height: "180px",
  },
  titleWrapper: {
    margin: "16px 0 8px",
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "30px",
    color: "rgba(1, 21, 51, 1)",
  },

  subTitle: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(1, 21, 51, 1)",
  },

  footerWrapper: {
    height: "100px",
  },

  disabled: {
    color: "rgba(121, 132, 152, 1)",
    cursor: "default",
    boxShadow: "none",
    "&:hover": {
      transform: "scale(1)",
    },
  },

  active: {
    transform: "scale(1.05)",

    "&:hover": {
      transform: "scale(1.05)",
    },
  },

  benefitsWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-evenly",
    height: "100%",
    "& p": {
      color: "rgba(1, 21, 51, 1)",
    },
  },

  benefitItem: {
    display: "flex",

    "& > img": {
      marginRight: "12px",
    },
  },

  comingSoon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(121, 132, 152, 1)",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    flexDirection: "column" as const,
  },

  unavailable: {
    display: "flex",
    flexDirection: "column" as const,
  },

  newLabel: {
    backgroundColor: "#D4F546",
    width: "80px",
    height: "28px",
    borderRadius: "8px",
    padding: "4px 3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#011533",
    fontSize: "16px",
    fontFamily: "Proxima Nova, sans-serif",
  },
};
