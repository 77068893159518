import * as React from "react";

export function RedoIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="24" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.1084 6.25H15.724L13.4821 4.00875L14.3584 3.125L18.1084 6.875L14.3584 10.625L13.4821 9.74062L15.7221 7.5H8.1084C7.11384 7.5 6.16001 7.89509 5.45675 8.59835C4.75349 9.30161 4.3584 10.2554 4.3584 11.25C4.3584 12.2446 4.75349 13.1984 5.45675 13.9017C6.16001 14.6049 7.11384 15 8.1084 15H13.1084V16.25H8.1084C6.78232 16.25 5.51055 15.7232 4.57286 14.7855C3.63518 13.8479 3.1084 12.5761 3.1084 11.25C3.1084 9.92392 3.63518 8.65215 4.57286 7.71447C5.51055 6.77678 6.78232 6.25 8.1084 6.25Z"
        fill="#212529"
      />
    </svg>
  );
}
