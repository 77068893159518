import { memo, createContext, useState, useCallback } from "react";
import ProgressBarPlus from "react-progress-bar-plus";
import { withStyles } from "@material-ui/core/styles";

import "react-progress-bar-plus/lib/progress-bar.css";

import style from "./style.js";

export const ProgressBarContext = createContext();

const ProgressBar = ({ children, classes }) => {
  const [state, setState] = useState(0);
  const runProgressBar = useCallback((state) => setState(state), []);

  return (
    <>
      {state > 0 && <ProgressBarPlus autoIncrement className={classes.progress} percent={state} spinner={false} />}
      <ProgressBarContext.Provider value={runProgressBar}>{children}</ProgressBarContext.Provider>
    </>
  );
};

export default memo(withStyles(style)(ProgressBar));
