import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
// import { preventEvent } from "pages/flyer/builder/drawer/components/preventEvent";
// import { DMContent, DMItem } from "pages/flyer/builder/drawer/components/Primitives/DropdownMenu";
import { ToolButton } from "pages/flyer/builder/drawer/components/Primitives/ToolButton";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { styled } from "pages/flyer/builder/drawer/styles";
import type { TDSnapshot } from "pages/flyer/builder/drawer/types";
import * as React from "react";

const zoomSelector = (s: TDSnapshot) => s.document.pageStates[s.appState.currentPageId].camera.zoom;

export const ZoomMenu = React.memo(function ZoomMenu() {
  const app = useTldrawApp();

  const zoom = app.useStore(zoomSelector);

  return (
    <DropdownMenu.Root dir="ltr">
      <DropdownMenu.Trigger dir="ltr" asChild>
        <FixedWidthToolButton onDoubleClick={app.resetZoom} variant="text">
          {Math.round(zoom * 100)}%
        </FixedWidthToolButton>
      </DropdownMenu.Trigger>
      {/* <DMContent align="end">
        <DMItem onSelect={preventEvent} onClick={app.zoomIn} kbd="#+">
          Zoom In
        </DMItem>
        <DMItem onSelect={preventEvent} onClick={app.zoomOut} kbd="#−">
          Zoom Out
        </DMItem>
        <DMItem onSelect={preventEvent} onClick={app.resetZoom} kbd="⇧0">
          To 100%
        </DMItem>
        <DMItem onSelect={preventEvent} onClick={app.zoomToFit} kbd="⇧1">
          To Fit
        </DMItem>
        <DMItem onSelect={preventEvent} onClick={app.zoomToSelection} kbd="⇧2">
          To Selection
        </DMItem>
      </DMContent> */}
    </DropdownMenu.Root>
  );
});

const FixedWidthToolButton = styled(ToolButton, {
  minWidth: 49,
  "& > div": {
    fontSize: "16px !important",
  },
});
