import * as React from "react";

export function VerticalAlignCenterIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={20} height={20} viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.93 10a.28.28 0 0 0 .27-.27V7l.89.89a.26.26 0 0 0 .38 0 .28.28 0 0 0 0-.38L2.12 6.16a.27.27 0 0 0-.19-.08.3.3 0 0 0-.2.08L.38 7.51a.28.28 0 0 0 0 .38.27.27 0 0 0 .39 0L1.66 7v2.73a.27.27 0 0 0 .27.27ZM5 7a.33.33 0 0 0 .33.33h4.03a.34.34 0 1 0 0-.67H5.3A.34.34 0 0 0 5 7Zm0-2a.34.34 0 0 0 .33.34h4.03a.34.34 0 0 0 0-.68H5.3A.34.34 0 0 0 5 5Zm.3-1.69a.34.34 0 0 1 0-.67h4.06a.34.34 0 1 1 0 .67ZM2.2.27A.28.28 0 0 0 1.93 0a.27.27 0 0 0-.27.27V3l-.89-.89a.27.27 0 0 0-.39 0 .28.28 0 0 0 0 .38l1.35 1.35a.3.3 0 0 0 .2.08.27.27 0 0 0 .19-.08l1.35-1.35a.28.28 0 0 0 0-.38.26.26 0 0 0-.38 0L2.2 3Z"
        style={{
          fill: "#010101",
          fillRule: "evenodd",
        }}
      />
    </svg>
  );
}
