const DEFAULT_WEEKS_COUNT = 8;

const directMailOnlyCities = ["Canada", "USA", "Australia", "New Zealand"];

const EMPTY_ERROR = { title: "", message: "" };

const REGEX_FOR_CAMPAIGN_NAME = /[^a-z0-9 !@#$%^&*()_+ {}:”<>?±-]/gi;

// 36 - Adelaide
// 28 - Melbourne
// 33 - Perth
// 27 - Auckland
const RESTRICTED_H2H_CREATE_LOCATION_CITIES = [36, 28, 33, 27];

const DM_MIN_SELECTABLE_DATE = 1; // 1 business days from today

export {
  DEFAULT_WEEKS_COUNT,
  directMailOnlyCities,
  EMPTY_ERROR,
  REGEX_FOR_CAMPAIGN_NAME,
  RESTRICTED_H2H_CREATE_LOCATION_CITIES,
  DM_MIN_SELECTABLE_DATE,
};
