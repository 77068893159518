import { useEffect, useState, useRef } from "react";
import { GoogleMap, DrawingManager, Marker } from "@react-google-maps/api";
import { Box, makeStyles } from "@material-ui/core";
import { MAP_TYPES } from "pages/constants";
import { styles } from "./styles.js";
import { BROAD_AREAS_MAP_OPTIONS, MAP_EXTRA_STYLES } from "../../options";
import {
  getZone,
  mapBoundaries,
  onDrawingManagerLoad,
  onMapInit,
  renderDistributionZones,
  renderSelectedPostCode,
  renderPolygonsOnMap,
  handleD2DMissionDelete,
  handleD2DMissionUpdate,
  getPolygonCenterPoint,
  isPositionInsideDistributionZone,
  handleScheduleMeeting,
  fetchAndUpdatePostCodes,
  fetchCarrierRoutes,
  handleHoverSelectedMissionUpdate,
} from "../../utils.js";
import useOverlaysFetch from "../../../../Hooks/useOverlaysFetch";
import { MapHeader } from "../MapHeader";
import { ServiceAreaLimitationPopUp } from "../ServiceAreaLimitationPopUp";

import useCalculating from "../../../../Hooks/useCalculating";
import { useStore } from "../../../../store";

const useStyles = makeStyles(() => styles);

export const AudienceMapD2D = ({ isSubmitted }) => {
  const classes = useStyles();
  const {
    country,
    city,
    campaign: { isD2D },
    map: { center, zoom, shouldShowDefaultPin, loading, selectedPostCode, geoJsonForSelected },
    setAddresses,
    updateCity,
    updateMap,
    hoveredMission,
    setHoveredMission,
  } = useStore();
  const { loadData } = useOverlaysFetch({ countryCode: country.code, disabled: false });
  const { recalculateCampaignCost } = useCalculating();

  const activeSelectedCity = city;
  const activeSelectedCityDistributionZones = city?.distributionZones;
  const activeCityMissionBoundaries = city?.missionBoundaries;
  const distributionZones = city?.distributionZones;
  const activeSelectedCityAddresses = city?.addresses || [];
  const cityName = city?.name || "";
  const drawingMode = null;
  const [serviceAreaLimitationPopUpOpen, setServiceAreaLimitationPopUpOpen] = useState(false);
  const [drawingManager, setDrawingManager] = useState(null);
  const [mapInit, setMapInit] = useState(null);
  const [distributionZonePolygons, setDistributionZonePolygons] = useState([]);
  const [shouldSetDataLayer, setShouldSetDataLayer] = useState(true);
  const [selectedMissions, setSelectedMissions] = useState(activeSelectedCityAddresses);
  const [defaultPinPosition, setDefaultPinPosition] = useState(null);
  const [missionBoundaries, setMissionBoundaries] = useState({});
  useEffect(() => {
    updateMap({ selectedPostCode: null, geoJsonForSelected: null });
  }, []);

  useEffect(() => {
    if (!mapInit) {
      return;
    }

    const listener = mapInit.data.addListener("click", (event) => {
      if (event && event.latLng && event.latLng.lat && event.latLng.lng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        if (!isPositionInsideDistributionZone(distributionZonePolygons, { lat, lng })) {
          if (!isSubmitted) {
            setServiceAreaLimitationPopUpOpen(true);
          }
        }
      }
    });

    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, [mapInit, distributionZonePolygons]);

  useEffect(() => {
    recalculateCampaignCost();
  }, [city.addresses.length]);

  useEffect(() => {
    setAddresses(selectedMissions);
  }, [selectedMissions]);

  let mapRef = useRef(null);

  useEffect(() => {
    const fetchAndUpdateValues = async (cityZone, exclusionZones, cityId) => {
      if (missionBoundaries[cityId]) {
        updateMap({ loading: true, loadingPercentage: null });
        updateCity({ missionBoundaries: missionBoundaries[cityId] });
      } else {
        await fetchAndUpdateMissionBoundaries(cityZone, exclusionZones, cityId);
      }
      await fetchAndUpdatePostCodes(cityZone, loadData, exclusionZones, updateMap);
    };

    if (distributionZonePolygons && distributionZonePolygons.length && city) {
      const distributionZones = getZone(city, "distribution");
      const exclusionZones = getZone(city, "exclusion");
      fetchAndUpdateValues(distributionZones, exclusionZones, city.id);
    }
  }, [distributionZonePolygons]);

  useEffect(() => {
    if (activeSelectedCityAddresses.length) {
      setTimeout(() => {
        updateMap({
          center: getPolygonCenterPoint(activeSelectedCityAddresses[0].paths[0]),
          zoom: 15,
        });
      }, 100);
    }
  }, [activeSelectedCity?.name]);

  useEffect(() => {
    if (shouldShowDefaultPin) {
      const isInsideDistributionZone = isPositionInsideDistributionZone(distributionZonePolygons, center);

      if (!isInsideDistributionZone) {
        // we searched outside distribution zones
        if (!isSubmitted) {
          setServiceAreaLimitationPopUpOpen(true);
        }
      }

      setDefaultPinPosition(center);
      updateMap({
        shouldShowDefaultPin: false,
      });
    }
  }, [shouldShowDefaultPin]);

  useEffect(() => {
    if (drawingManager) {
      drawingManager.setDrawingMode(drawingMode);
    }
  }, [drawingMode, drawingManager]);

  useEffect(() => {
    renderDistributionZones({
      mapInit,
      activeSelectedCityDistributionZones,
      distributionZones,
      setDistributionZonePolygons,
      isD2D,
    });
  }, [mapInit, activeSelectedCityDistributionZones]);

  useEffect(() => {
    if (mapInit && activeCityMissionBoundaries && shouldSetDataLayer) {
      renderPolygonsOnMap({
        mapInit,
        activeCityMissionBoundaries,
        handleMissionSelect,
        activeSelectedCityAddresses,
        isSubmitted,
        setHoveredMission,
      });
      setShouldSetDataLayer(false);

      if (loading) {
        const waitTime = Object.values(activeCityMissionBoundaries).length / 4;
        setTimeout(() => {
          updateMap({ loading: false });
        }, waitTime);
      }
    }
  }, [mapInit, activeCityMissionBoundaries, shouldSetDataLayer, activeSelectedCityAddresses, country.code]);

  useEffect(() => {
    if (mapInit) {
      renderSelectedPostCode({ mapInit, selectedPostCode, geoJsonForSelected, cityId: city.id, updateMap });
    }
  }, [mapInit, selectedPostCode, city.id, updateMap]);

  useEffect(() => {
    const updatedMissions = selectedMissions.reduce(
      (acc, mission) => {
        const currAddress = activeSelectedCityAddresses.find((address) => String(address.id) === String(mission.id));
        if (!currAddress) {
          acc.deleted.push(mission);
        }
        if (currAddress && currAddress.isVisible !== mission.isVisible) {
          acc.updated.push(currAddress);
        }
        return acc;
      },
      { deleted: [], updated: [] }
    );

    if ([...updatedMissions.deleted, ...updatedMissions.updated].length) {
      if (updatedMissions.deleted.length) {
        handleD2DMissionDelete({ deletedMissions: updatedMissions.deleted, handleMissionSelect, mapInit });
      }
      if (updatedMissions.updated.length) {
        handleD2DMissionUpdate({ updatedMissions: updatedMissions.updated, setSelectedMissions, mapInit });
      }
    }
  }, [activeSelectedCityAddresses]);

  useEffect(() => {
    handleHoverSelectedMissionUpdate({ mapInit, hoveredMission });
  }, [hoveredMission]);

  const fetchMissionBoundariesV2 = async ({
    layer = "MissionBoundaries",
    after,
    zone,
    type,
    exclusionZones,
    cityId,
    previousBoundaries = {},
  }) => {
    const includeAddressCount = false;
    const boundaries = await loadData({
      layer,
      ...zone,
      exclusionZones: exclusionZones.geometry ? exclusionZones.geometry : exclusionZones,
      includeAddressCount,
      after,
    });

    if (!boundaries) {
      return {};
    }

    updateMap({
      loadingPercentage: ((Object.keys(previousBoundaries).length * 100) / boundaries.totalCount).toFixed(),
    });
    if (boundaries?.pageInfo?.hasNextPage) {
      const mappedBoundaries = mapBoundaries({ data: boundaries.data, cityId });

      return await fetchMissionBoundariesV2({
        layer,
        zone,
        exclusionZones,
        after: boundaries.pageInfo.endCursor,
        type,
        cityId,
        previousBoundaries: { ...previousBoundaries, ...mappedBoundaries },
      });
    } else {
      const mappedBoundaries = mapBoundaries({ data: boundaries.data, cityId });
      return { ...previousBoundaries, ...mappedBoundaries };
    }
  };
  let boundariesData = {};

  const fetchAndUpdateMissionBoundaries = async (distributionZones, exclusionZones, cityId) => {
    updateMap({ loading: true, loadingPercentage: null });

    const zone = {
      area: distributionZones.geometry,
    };

    try {
      let additionalBoundaries = {};
      let missionBoundariesV2 = {};

      if (country.code === "US") {
        const carrierRoutes = await fetchCarrierRoutes({
          cityId,
          cityName,
          updateMap,
        });
        additionalBoundaries = { ...carrierRoutes };

        // additionalBoundaries = await fetchMissionBoundariesV2({
        //   layer: "USCarrierRoutes",
        //   zone,
        //   cityId,
        // });
      } else {
        missionBoundariesV2 = await fetchMissionBoundariesV2({
          layer: "MissionBoundaries",
          zone,
          exclusionZones,
          cityId,
        });
      }

      boundariesData = {
        ...missionBoundaries,
        ...boundariesData,
        [cityId]: {
          ...boundariesData[cityId],
          ...missionBoundariesV2,
          ...additionalBoundaries,
        },
      };
    } catch (error) {
      console.error("PathFinder error.");
      throw error;
    }

    if (country.code !== "US") {
      updateMap({ loading: false });
    }

    const result = { ...activeCityMissionBoundaries, ...boundariesData };

    setMissionBoundaries(boundariesData);
    updateCity({ missionBoundaries: result[cityId] });
  };

  const handleMapClick = () => {
    setDefaultPinPosition(null);
  };

  const handleMissionSelect = ({ missionId, paths, selected, isVisible }) => {
    if (isSubmitted) {
      return;
    }
    handleMapClick();
    setSelectedMissions((missions) => {
      const currentMissionIndex = missions.findIndex((m) => m.id === missionId);
      if (selected && currentMissionIndex === -1) {
        return [
          ...missions,
          {
            id: missionId,
            paths,
            isVisible: isVisible,
            showDeleteAddressPopup: false,
            value: "Area",
            type: MAP_TYPES.POLYGON,
          },
        ];
      } else if (!selected && currentMissionIndex !== -1) {
        return missions.toSpliced(currentMissionIndex, 1);
      } else {
        return missions;
      }
    });
  };

  const handleCloseServiceAreaLimitationPopUp = () => {
    setServiceAreaLimitationPopUpOpen(false);
  };

  const handleGetInTouch = () => {
    handleScheduleMeeting(country.code);
  };

  return (
    <Box className={classes.container}>
      <ServiceAreaLimitationPopUp
        open={serviceAreaLimitationPopUpOpen}
        onClickGetInTouch={handleGetInTouch}
        closeModal={handleCloseServiceAreaLimitationPopUp}
      />
      <Box className={classes.mapWrapper} ref={(ref) => (mapRef.current = ref)}>
        <GoogleMap
          onLoad={(map) => onMapInit({ newMap: map, mapInit, setMapInit })}
          mapContainerStyle={{
            width: "100%",
            marginLeft: 0,
          }}
          center={center}
          zoom={zoom}
          onZoomChanged={() => {
            if (mapInit) {
              updateMap({ zoom: mapInit.getZoom() });
            }
          }}
          onCenterChanged={() => {}}
          options={{
            clickableIcons: false,
            zoomControl: true,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            draggable: !drawingMode,
            styles: MAP_EXTRA_STYLES,
          }}
          onClick={handleMapClick}
        >
          {!isSubmitted ? (
            <MapHeader
              city={city}
              countryCode={country.code}
              isSubmitted={isSubmitted}
              postCodeSearch={true}
              isLoadingMap={loading}
            />
          ) : null}

          {defaultPinPosition !== null && <Marker clickable={false} position={defaultPinPosition} />}

          <DrawingManager
            onLoad={(loadedDrawingManager) => onDrawingManagerLoad({ loadedDrawingManager, setDrawingManager })}
            drawingMode={drawingMode}
            options={BROAD_AREAS_MAP_OPTIONS}
          />
        </GoogleMap>
      </Box>
    </Box>
  );
};
