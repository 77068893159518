import { Utils } from "@tldraw/core";
import { getShapeStyle } from "pages/flyer/builder/drawer/state/shapes/shared";
import type { ShapeStyles } from "pages/flyer/builder/drawer/types";
import * as React from "react";

interface EllipseSvgProps {
  id: string;
  radius: number[];
  style: ShapeStyles;
  isSelected: boolean;
  isDarkMode: boolean;
}

export const DashedEllipse = React.memo(function DashedEllipse({
  id,
  radius,
  style,
  isSelected,
  isDarkMode,
}: EllipseSvgProps) {
  const {
    stroke,
    strokeWidth,
    fill,
    transparency,
    isOpacityVisible,
    isStroked,
    isInnerShadowVisible,
    isOuterShadowVisible,
    innerShadowColor,
    innerShadow,
    outerShadow,
    outerShadowColor,
  } = getShapeStyle(style, isDarkMode);
  // const sw = 1 + strokeWidth * 1.618;
  const sw = strokeWidth!;
  const rx = Math.max(0, radius[0] - sw / 2);
  const ry = Math.max(0, radius[1] - sw / 2);
  const perimeter = Utils.perimeterOfEllipse(rx, ry);
  const { strokeDasharray, strokeDashoffset } = Utils.getPerfectDashProps(
    perimeter < 64 ? perimeter * 2 : perimeter,
    strokeWidth! * 1.618,
    style.dash!,
    4
  );

  return (
    <>
      <defs>
        {outerShadow && isOuterShadowVisible ? (
          <filter id={`shadow_${id}`} x="-400%" y="-400%" height="1000%" width="1000%">
            <feGaussianBlur in="SourceGraphic" stdDeviation={outerShadow.blur} />
          </filter>
        ) : null}
        {innerShadow && isInnerShadowVisible ? (
          <filter id={`inset-shadow_${id}`}>
            <feComponentTransfer in="SourceAlpha">
              <feFuncA type="table" tableValues="1 0" />
            </feComponentTransfer>
            <feGaussianBlur stdDeviation={innerShadow.blur}></feGaussianBlur>
            <feComponentTransfer>
              <feFuncA type="linear" slope={innerShadow.spread} />
            </feComponentTransfer>

            <feOffset in="desenfoque" dx={innerShadow.x} dy={innerShadow.y} result="offsetblur" />
            <feFlood floodColor={innerShadowColor} result="color" />
            <feComposite in2="offsetblur" operator="in" />
            <feComposite in2="SourceAlpha" operator="in" />

            <feMerge>
              <feMergeNode in="SourceGraphic" />
              <feMergeNode />
            </feMerge>
          </filter>
        ) : null}
        {/* {innerShadow && isInnerShadowVisible ? (
          <filter id={`inset-shadow_${id}`}>
            <feOffset in="SourceAlpha" dx={innerShadow.x} dy={innerShadow.y} />
            <feGaussianBlur result="blurOut" stdDeviation={innerShadow.blur} />
            <feFlood flood-color={innerShadowColor} result="floodOut" />\
            <feComposite
              operator="out"
              in="floodOut"
              in2="blurOut"
              result="compOut"
            />
            <feComposite operator="in" in="compOut" in2="SourceAlpha" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="50" />
            </feComponentTransfer>
            <feBlend in2="SourceGraphic" />
          </filter>
        ) : null} */}
      </defs>

      {outerShadow && isOuterShadowVisible ? (
        <ellipse
          cx={radius[0] + outerShadow?.x}
          cy={radius[1] + outerShadow?.y}
          rx={rx + sw + outerShadow.spread / 2}
          ry={ry + sw + outerShadow.spread / 2}
          fill={outerShadowColor}
          stroke={outerShadowColor}
          strokeWidth={sw}
          opacity={transparency! / 100}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          pointerEvents="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          filter={outerShadow && isOuterShadowVisible ? `url(#shadow_${id})` : undefined}
        />
      ) : null}

      <ellipse
        className={style.isFilled || isSelected ? "tl-fill-hitarea" : "tl-stroke-hitarea"}
        opacity={transparency! / 100}
        cx={radius[0]}
        cy={radius[1]}
        rx={radius[0]}
        ry={radius[1]}
      />

      <ellipse
        cx={radius[0]}
        cy={radius[1]}
        rx={radius[0]}
        ry={radius[1]}
        fill={style.isFilled ? fill : "rgba(0,0,0,0)"}
        opacity={isOpacityVisible ? transparency! / 100 : 1}
        // strokeDasharray={strokeDasharray}
        // strokeDashoffset={strokeDashoffset}
        pointerEvents="none"
        // strokeLinecap="round"
        // strokeLinejoin="round"
        // filter={
        //   innerShadow && isInnerShadowVisible
        //     ? `url(#inset-shadow_${id})`
        //     : undefined
        // }
      />
      <ellipse
        cx={radius[0]}
        cy={radius[1]}
        rx={radius[0] + sw / 2}
        ry={radius[1] + sw / 2}
        fill={"rgba(0,0,0,0)"}
        opacity={isOpacityVisible ? transparency! / 100 : 1}
        stroke={isStroked ? stroke : undefined}
        strokeWidth={sw}
        // strokeDasharray={strokeDasharray}
        // strokeDashoffset={strokeDashoffset}
        pointerEvents="none"
        // strokeLinecap="round"
        // strokeLinejoin="round"
        // filter={
        //   innerShadow && isInnerShadowVisible
        //     ? `url(#inset-shadow_${id})`
        //     : undefined
        // }
      />
      {typeof fill === "string" && ["#fff", "#ffffff"].includes(fill) ? (
        <ellipse
          cx={radius[0]}
          cy={radius[1]}
          rx={radius[0]}
          ry={radius[1]}
          fill={"rgba(0,0,0,0)"}
          opacity={1}
          stroke="grey"
          strokeWidth={0.1}
          pointerEvents="none"
        />
      ) : null}
    </>
  );
});
