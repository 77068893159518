import cn from "classnames";
import { SavingIcon } from "components/icons/components/SavingIcon";
import { SavedIcon } from "components/icons/components/SavedIcon";
import { makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "87px",
  },
  noLabelContainer: {
    width: "22px",
  },
  label: {
    fontFamily: "'Proxima Nova', 'sans-serif'",
    marginLeft: "8px",
  },
}));

export const Saving = ({ saving, hideLabels }) => {
  const classes = useStyles();
  return (
    <div className={cn(classes.container, { [classes.noLabelContainer]: hideLabels })}>
      {saving ? (
        <>
          {hideLabels ? <CircularProgress size={18} /> : <SavingIcon />}
          {!hideLabels && <span className={classes.label}>Saving...</span>}
        </>
      ) : (
        <>
          <SavedIcon stroke={"#AEAFC7"} /> {!hideLabels && <span className={classes.label}>Saved</span>}
        </>
      )}
    </div>
  );
};
