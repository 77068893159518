import { TDShapeType } from "../../../../../../drawer/types";
import ArrowIcon from "./icons/ArrowIcon";
import CodeIcon from "./icons/CodeIcon";
import DrawingsIcon from "./icons/DrawingsIcon";
// import EllipseIcon from "./icons/EllipseIcon";
import GroupIcon from "./icons/GroupIcon";
// import ImageIcon from "./icons/ImageIcon";
import LineIcon from "./icons/LineIcon";
import QRCodeIcon from "./icons/QRCodeIcon";
// import RectangleIcon from "./icons/RectangleIcon";
// import TextIcon from "./icons/TextIcon";
import { TextIcon, EllipseIcon, ImageIcon, RectangleIcon } from "./iconsv2";

const RenderIcon = ({ type, shape }) => {
  switch (type) {
    case TDShapeType.Image:
      return <ImageIcon />;

    case TDShapeType.Code:
      return <CodeIcon />;

    case TDShapeType.QRCode:
      return <QRCodeIcon />;

    case TDShapeType.Rectangle:
      return <RectangleIcon />;

    case TDShapeType.TextArea:
      return <TextIcon />;

    case TDShapeType.Ellipse:
      return <EllipseIcon />;

    case TDShapeType.Group:
      return <GroupIcon />;

    case TDShapeType.Line:
      return <LineIcon />;

    case TDShapeType.Arrow:
      if (shape.decorations.start || shape.decorations.end) {
        return <ArrowIcon />;
      } else {
        return <LineIcon />;
      }

    case TDShapeType.Draw:
      return <DrawingsIcon />;

    default:
      return null;
  }
};

export default RenderIcon;
