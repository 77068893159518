import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApolloLink, concat } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import CampaignDataHOC from "campaign-data-hoc";
import DMCampaignDataHOC from "dm-campaign-data-hoc";
import { DialogModal } from "components/dialog-modal";
import ProgressBar from "components/progress-bar";
import SnackBar from "components/snack-bar";
import ROUTING, { DM_ROUTING } from "config/routing";
import Auth from "pages/auth";
import AccessDenied from "pages/campaign/access-denied";
import Audience from "pages/campaign/audience";
import Channel from "pages/campaign/channel";
import Details from "pages/campaign/details";
import Quote from "pages/campaign/quote";
import Builder from "pages/flyer/builder";
import SelectFlyerTypePage from "pages/flyer/flyerTypePage";
import UploadDesignPage from "pages/flyer/upload";
import { Error } from "pages/error";
import { Segments } from "pages/eddm/segments";
import ReactDOM from "react-dom";
import CONFIG from "config/config";
import { FeatureFlagContextProvider } from "context/featureFlagContext";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { EDDMSubmitContainer } from "pages/eddm/submit";
import GlobalStyles from "./global-styles";
import reportWebVitals from "./reportWebVitals";
import "./style.css";

import theme from "./theme";
import Login from "./pages/auth/components/login";

const httpLink = new HttpLink({ uri: CONFIG.FLYER_API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem("apiKey");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export const NewApp = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <DialogModal>
          <SnackBar>
            <ProgressBar>
              <Auth>
                <Router>
                  <FeatureFlagContextProvider>
                    <Switch>
                      {/* COMMON ROUTES */}
                      <Route path={ROUTING.LOGIN} component={Login} /> {/* TODO: SHOW LOGIN ONLY FOR DEVS */}
                      <Route path={[ROUTING.ERROR]} component={Error} />
                      <Route path={ROUTING.ACCESS_DENIED} component={AccessDenied} />
                      {/* EDDM ROUTES */}
                      <Route path={DM_ROUTING.DM_CAMPAIGN}>
                        <DMCampaignDataHOC>
                          <Switch>
                            {/* <Route path={ROUTING.CAMPAIGN_CHANNEL} component={Channel} /> */}
                            <Route path={DM_ROUTING.DM_CAMPAIGN_DETAILS} component={Details} />
                            <Route path={DM_ROUTING.DM_CAMPAIGN_SEGMENTS} component={Segments} />
                            <Route
                              path={[DM_ROUTING.DM_SUBMIT, DM_ROUTING.DM_SUCCESS]}
                              component={EDDMSubmitContainer}
                            />
                            <Redirect to={ROUTING.ERROR} />
                          </Switch>
                        </DMCampaignDataHOC>
                      </Route>
                      <Route path={DM_ROUTING.DM_FLYER}>
                        <DMCampaignDataHOC>
                          <Switch>
                            <Route path={DM_ROUTING.DM_FLYER_BUILDER} component={() => <div>builder</div>} />
                            <Route path={DM_ROUTING.DM_UPLOAD_FLYER} component={UploadDesignPage} />
                            <Route path={DM_ROUTING.DM_FLYER} component={SelectFlyerTypePage} />
                          </Switch>
                        </DMCampaignDataHOC>
                      </Route>
                      {/* H2H and D2D ROUTES */}
                      <Route path={ROUTING.CAMPAIGN}>
                        <CampaignDataHOC>
                          <Switch>
                            {/* UNCOMMENTED CHANNEL PAGE FOR TESTING PURPOSES, SHOULD BE REMOVED BEFORE MOVING IT TO PLATFORM*/}
                            {/* <Route path={ROUTING.CAMPAIGN_CHANNEL} component={Channel} /> */}
                            <Route path={ROUTING.CAMPAIGN_DETAILS} component={Details} />
                            <Route path={[ROUTING.CAMPAIGN_AUDIENCE]} component={Audience} />
                            <Route path={[ROUTING.SUBMIT, ROUTING.SUCCESS]} component={Quote} />
                            <Redirect to={ROUTING.ERROR} />
                          </Switch>
                        </CampaignDataHOC>
                      </Route>
                      <Route path={ROUTING.FLYER}>
                        <CampaignDataHOC>
                          <Switch>
                            <Route path={ROUTING.FLYER_BUILDER} component={Builder} />
                            <Route path={ROUTING.UPLOAD_FLYER} component={UploadDesignPage} />
                            <Route path={ROUTING.FLYER} component={SelectFlyerTypePage} />
                          </Switch>
                        </CampaignDataHOC>
                      </Route>
                      <Redirect to={ROUTING.ERROR} />
                    </Switch>
                  </FeatureFlagContextProvider>
                </Router>
              </Auth>
            </ProgressBar>
          </SnackBar>
        </DialogModal>
      </ThemeProvider>
    </ApolloProvider>
  );
};
