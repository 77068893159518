import React, { useRef, useEffect } from "react";
import Talk from "talkjs";
import CONFIG from "../../config/config";
import ChatView from "../component";
import audioSound from "../mp3/chat-notification.mp3";

const { appID } = CONFIG.TALKJS;

const Chat = ({ user, countryId, client }) => {
  let ref = useRef(null);

  const playNotificationSound = () => {
    const audio = new Audio(audioSound);
    audio.play().catch((error) => console.error("Error playing the audio", error));
  };

  const initChat = async ({ ref, user }) => {
    await Talk.ready;

    const session = new Talk.Session({
      appId: appID,
      me: new Talk.User({
        id: user.id,
        name: user.firstName,
        email: user.email,
        photoUrl: user.photoUrl,
      }),
    });

    session.on("message", (message) => {
      if (message.sender.id !== session.me.id) {
        playNotificationSound();
      }
    });

    const conversationId = Talk.oneOnOneId(session.me, session.me);

    const conversationIdWithCategory = `client-oads:${conversationId}`;

    const conversation = session.getOrCreateConversation(conversationIdWithCategory);

    conversation.setParticipant(session.me);

    conversation.setAttributes({
      welcomeMessages: [
        `Hey ${user.firstName}, thanks for chatting with Oppizi, I'm here to help!`,
        "How can I help you today?",
      ],
      custom: {
        isClientChat: "true",
        countryId,
      },
      subject: `${client.name} - ${user.firstName} ${user.lastName}`,
    });
    const chatbox = session.createChatbox({
      captureKeyboardEvents: true,
      theme: "oads",
    });

    chatbox.select(conversation);

    chatbox.mount(ref);
  };

  useEffect(() => {
    if (user) {
      initChat({
        ref,
        user,
      });
    }
  }, []);

  const setRef = (value) => {
    ref = value;
  };

  return <ChatView setRef={setRef} />;
};
export default Chat;
