import * as React from "react";

export function TextAlignJustifyIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={20} height={20} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.62 2.64a.34.34 0 1 0 0 .67h6.76a.34.34 0 1 0 0-.67ZM1.28 5a.34.34 0 0 1 .34-.34h6.76a.34.34 0 0 1 0 .68H1.62A.34.34 0 0 1 1.28 5Zm0 2a.34.34 0 0 1 .34-.34h6.76a.34.34 0 1 1 0 .67H1.62A.34.34 0 0 1 1.28 7Z"
        style={{
          fill: "#010101",
          fillRule: "evenodd",
        }}
      />
    </svg>
  );
}
