export const style = {
  previewWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    width: "100%",
  },
  imageWrapper: {
    position: "relative" as const,
  },
  verticalArrowWrapper: {
    position: "relative" as const,
    marginRight: "20px",
  },
  horizontalArrowWrapper: {
    position: "relative" as const,
    marginTop: "10px",
  },
  nameWrapper: {
    position: "absolute" as const,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& > p": {
      fontWeight: 700,
      fontSize: "18px",
      color: "#2E3860",
    },
  },
  dmNameWrapper: {
    top: "50%",
  },
  dimensionText: {
    position: "absolute" as const,
    fontSize: "12px",
    fontWeight: 400,
    color: "#707087",
    transform: "translate(-50%, -50%)",
    textWrap: "nowrap",
  },
  heightText: {
    transform: "rotate(-90deg)",
    bottom: "calc(50% + 15px)",
    marginLeft: "-30px",
  },
  widthText: {
    left: "50%",
    marginTop: "10px",
  },
  previewText: {
    paddingTop: "35px",
    paddingBottom: "20px",
    fontSize: "18px",
    fontWeight: 600,
    color: "#1D2943",
  },
};
