import { getCampaignLogInsertedParams } from "./utils";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

const insertCampaignLogs = (logs) => {
  const input = getCampaignLogInsertedParams(logs);

  return callAuthorizedGraphQLEndpoint(
    `mutation insertCampaignLogs($input: [InsertCampaignLogsInput]) {
      insertCampaignLogs(input:$input){
          success
      }
    }`,
    {
      input,
    }
  );
};

export { insertCampaignLogs };
