import { TLBoundsCorner, TLPointerEventHandler, Utils } from "@tldraw/core";
import Vec from "@tldraw/vec";
import { getShapeUtil } from "pages/flyer/builder/drawer/state/shapes";
import { SessionType, TDShapeType } from "pages/flyer/builder/drawer/types";
import { ChildIndexEnum } from "pages/flyer/builder/drawer";

import { BaseTool, Status } from "../BaseTool";

export class QRCodeTool extends BaseTool {
  type = TDShapeType.QRCode as const;

  /* ----------------- Event Handlers ----------------- */

  onPointerDown: TLPointerEventHandler = () => {
    if (this.status !== Status.Idle) return;

    const {
      currentPoint,
      currentGrid,
      settings: { showGrid },
      appState: { currentPageId, currentStyle },
    } = this.app;

    const id = Utils.uniqueId();

    const QRCode = getShapeUtil(TDShapeType.QRCode);

    const newShape = QRCode.create({
      id,
      parentId: currentPageId,
      assetId: "qrCode",
      childIndex: ChildIndexEnum.Middle,
      point: showGrid ? Vec.snap(currentPoint, currentGrid) : currentPoint,
      style: { ...currentStyle },
    });

    this.app.patchCreate([newShape]);

    this.app.startSession(SessionType.TransformSingle, newShape.id, TLBoundsCorner.BottomRight, true);

    this.setStatus(Status.Creating);
  };
}
