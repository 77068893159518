import { MenuContent } from "pages/flyer/builder/drawer/components/Primitives/MenuContent";
import { RowButton } from "pages/flyer/builder/drawer/components/Primitives/RowButton";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { styled } from "pages/flyer/builder/drawer/styles";
import type { TDSnapshot } from "pages/flyer/builder/drawer/types";
import * as React from "react";

const isEmptyCanvasSelector = (s: TDSnapshot) =>
  Object.keys(s.document.pages[s.appState.currentPageId].shapes).length > 0 && s.appState.isEmptyCanvas;

export const BackToContent = React.memo(function BackToContent() {
  const app = useTldrawApp();

  const isEmptyCanvas = app.useStore(isEmptyCanvasSelector);

  if (!isEmptyCanvas) return null;

  return (
    <BackToContentContainer>
      <RowButton onClick={app.zoomToContent}>Back to content</RowButton>
    </BackToContentContainer>
  );
});

const BackToContentContainer = styled(MenuContent, {
  pointerEvents: "all",
  width: "fit-content",
  minWidth: 0,
  gridRow: 1,
  flexGrow: 2,
  display: "block",
});
