import { DocumentFormats } from "shared/constants";
import IntegrationsService from "module/IntegrationsService/client";

const getDynamicCoupons = async () => {
  const integrations = await IntegrationsService.readIntegrations();
  const integrationsIds = integrations?.map((integration) => integration.id);
  let coupons = [];
  if (integrationsIds) {
    for (const integrationId of integrationsIds) {
      coupons = [...coupons, ...(await IntegrationsService.readCoupons({ integrationId }))];
    }
  }
  return coupons;
};

const filterCoupons = (filterCoupons, usedCouponId) =>
  filterCoupons.filter((coupon) => {
    if (!coupon.used) {
      return true;
    } else {
      return coupon.id === usedCouponId;
    }
  });

const getDMFlyersAmount = (distributionLocations) => {
  return distributionLocations && distributionLocations.length
    ? distributionLocations.reduce((acc, curr) => {
        const amountOfFlyersOnSelectedRoutes = curr.selectedRoutes.reduce((flyers, route) => {
          return flyers + route.metadata.HOME_COUNT + route.metadata.APT_COUNT;
        }, 0);
        return acc + amountOfFlyersOnSelectedRoutes;
      }, 0)
    : 0;
};

export { getDynamicCoupons, filterCoupons, getDMFlyersAmount };
