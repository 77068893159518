import { normalizeGraphQLConnection } from "../../shared/graphql/utils";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getClient = async ({ clientId } = {}) => {
  const query = `
  query($clientId: ID) {
    clients(clientId: $clientId) {
      edges{
        node {
          id
          name
          logo
          code
          status
          countryId
        }
      }
    }
  }`;

  const request = await callAuthorizedGraphQLEndpoint(query, {
    clientId,
  });

  if (request.status === 200) {
    const {
      data: { clients },
    } = await request.json();

    return normalizeGraphQLConnection(clients);
  }
};
