import { TLBounds, TLBoundsCorner, TLBoundsEdge, TLBoundsWithCenter, TLSnapLine, Utils } from "@tldraw/core";
import { Vec } from "@tldraw/vec";
import { SLOW_SPEED, SNAP_DISTANCE } from "pages/flyer/builder/drawer/constants/constants";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import {
  SessionType,
  TDShape,
  TDShapeType,
  TDStatus,
  TldrawCommand,
  TldrawPatch,
  GuidelinesIdsEnum,
} from "pages/flyer/builder/drawer/types";
import { reduceBoundsOnePixel, extractSnapLines, extractSnapBoundIds } from "pages/flyer/builder/drawer/utils";
import type { TldrawApp } from "../../internal";
import { BaseSession } from "../BaseSession";

type SnapInfo =
  | {
      state: "empty";
    }
  | {
      state: "ready";
      bounds: TLBoundsWithCenter[];
    };

export class TransformSingleSession extends BaseSession {
  type = SessionType.TransformSingle;
  status = TDStatus.Transforming;
  performanceMode = undefined;
  transformType: TLBoundsEdge | TLBoundsCorner;
  scaleX = 1;
  scaleY = 1;
  isCreate: boolean;
  initialShape: TDShape;
  initialShapeBounds: TLBounds;
  initialCommonBounds: TLBounds;
  snapInfo: SnapInfo = { state: "empty" };
  prevPoint = [0, 0];
  speed = 1;

  constructor(app: TldrawApp, id: string, transformType: TLBoundsEdge | TLBoundsCorner, isCreate = false) {
    super(app);
    this.isCreate = isCreate;
    this.transformType = transformType;

    const shape = this.app.getShape(id);
    this.initialShape = shape;
    this.initialShapeBounds = TLDR.getBounds(shape);
    this.initialCommonBounds = TLDR.getRotatedBounds(shape);
    this.app.rotationInfo.selectedIds = [shape.id];
  }

  isExtractFromSnap = (id: string): boolean => {
    const extractIds: string[] = [this.initialShape.id];
    extractIds.push(...extractSnapBoundIds());
    return extractIds.includes(id);
  };

  start = (): TldrawPatch | undefined => {
    this.snapInfo = {
      state: "ready",
      bounds: this.app.shapes
        .filter((shape) => !this.isExtractFromSnap(shape.id))
        .map((shape) => {
          let bounds = TLDR.getRotatedBounds(shape);
          if (shape.id === GuidelinesIdsEnum.FrontGL || shape.id === GuidelinesIdsEnum.BackGL) {
            bounds = reduceBoundsOnePixel(bounds);
          }
          return Utils.getBoundsWithCenter(bounds);
        }),
    };

    return void null;
  };

  update = (): TldrawPatch | undefined => {
    const {
      transformType,
      initialShape,
      initialShapeBounds,
      app: {
        settings: { isSnapping, showGrid },
        currentPageId,
        pageState: { camera },
        viewport,
        currentPoint,
        previousPoint,
        originPoint,
        currentGrid,
        shiftKey,
        //  altKey,
        metaKey,
      },
    } = this;

    if (initialShape.isLocked) return void null;

    const shapes = {} as Record<string, Partial<TDShape>>;

    // const delta = altKey ? Vec.mul(Vec.sub(currentPoint, originPoint), 2) : Vec.sub(currentPoint, originPoint);
    const delta = Vec.sub(currentPoint, originPoint);

    const shape = this.app.getShape(initialShape.id);

    const utils = TLDR.getShapeUtil(shape);

    let newBounds = Utils.getTransformedBoundingBox(
      initialShapeBounds,
      transformType,
      delta,
      shape.rotation,
      shiftKey || shape.isAspectRatioLocked || utils.isAspectRatioLocked
    );

    // if (altKey) {
    //   newBounds = {
    //     ...newBounds,
    //     ...Utils.centerBounds(newBounds, Utils.getBoundsCenter(initialShapeBounds)),
    //   };
    // }

    if (showGrid) {
      newBounds = {
        ...newBounds,
        ...Utils.snapBoundsToGrid(newBounds, currentGrid),
      };
    }

    // Should we snap?

    const speed = Vec.dist(currentPoint, previousPoint);

    const speedChange = speed - this.speed;

    this.speed = this.speed + speedChange * (speedChange > 1 ? 0.5 : 0.15);

    let snapLines: TLSnapLine[] = [];

    if (
      ((isSnapping && !metaKey) || (!isSnapping && metaKey)) &&
      !initialShape.rotation && // not now anyway
      this.speed * camera.zoom < SLOW_SPEED &&
      this.snapInfo.state === "ready"
    ) {
      const bounds = this.snapInfo.bounds;
      const EPS = SNAP_DISTANCE / camera.zoom;
      const snapResult = Utils.getSnapPoints(
        Utils.getBoundsWithCenter(newBounds),
        bounds.filter((bound) => Utils.boundsContain(viewport, bound) || Utils.boundsCollide(viewport, bound)),
        EPS
      );

      if (snapResult) {
        snapLines = extractSnapLines(snapResult.snapLines, bounds, EPS);
        // const offset = snapLines.length ? calculateOffset(snapLines, currentPoint, EPS) : snapResult.offset;

        newBounds = Utils.getTransformedBoundingBox(
          initialShapeBounds,
          transformType,
          Vec.sub(delta, snapResult.offset),
          shape.rotation,
          shiftKey || shape.isAspectRatioLocked || utils.isAspectRatioLocked
        );
      }
    }

    const afterShape =
      TLDR.getShapeUtil(shape).transformSingle &&
      TLDR.getShapeUtil(shape).transformSingle(shape, newBounds, {
        initialShape,
        type: this.transformType,
        scaleX: newBounds.scaleX,
        scaleY: newBounds.scaleY,
        transformOrigin: [0.5, 0.5],
      });

    if (afterShape) {
      shapes[shape.id] = afterShape;
    }

    if (showGrid && afterShape && afterShape.point) {
      afterShape.point = Vec.snap(afterShape.point, currentGrid);
    }

    return {
      appState: {
        snapLines,
      },
      document: {
        pages: {
          [currentPageId]: {
            shapes,
          },
        },
      },
    };
  };

  cancel = (): TldrawPatch | undefined => {
    const {
      initialShape,
      app: { currentPageId },
    } = this;

    const shapes = {} as Record<string, TDShape | undefined>;

    if (this.isCreate) {
      shapes[initialShape.id] = undefined;
    } else {
      shapes[initialShape.id] = initialShape;
    }

    return {
      appState: {
        snapLines: [],
      },
      document: {
        pages: {
          [currentPageId]: {
            shapes,
          },
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: this.isCreate ? [] : [initialShape.id],
          },
        },
      },
    };
  };

  complete = (): TldrawPatch | TldrawCommand | undefined => {
    const {
      initialShape,
      app: { currentPageId },
    } = this;

    if (initialShape.isLocked) return;

    const beforeShapes = {} as Record<string, Partial<TDShape> | undefined>;
    const afterShapes = {} as Record<string, Partial<TDShape>>;

    beforeShapes[initialShape.id] = this.isCreate ? undefined : initialShape;

    const currentShape = this.app.getShape(initialShape.id);
    afterShapes[initialShape.id] = TLDR.onSessionComplete(this.app.getShape(initialShape.id));

    // if (this.isCreate && currentShape.type === TDShapeType.Rectangle) {
    //   if (currentShape.size[0] < 15) {
    //     currentShape.size[0] = 15;
    //   }
    //   if (currentShape.size[1] < 15) {
    //     currentShape.size[1] = 15;
    //   }
    // }
    if (currentShape.type === TDShapeType.QRCode) {
      if (currentShape.size[0] < 56) {
        currentShape.size[0] = 56;
      }
      if (currentShape.size[1] < 56) {
        currentShape.size[1] = 56 * 1.18;
      }
    }
    // if (this.isCreate && currentShape.type === TDShapeType.Ellipse) {
    //   if (currentShape.radius[0] < 15) {
    //     currentShape.radius[0] = 15;
    //   }
    //   if (currentShape.radius[1] < 15) {
    //     currentShape.radius[1] = 15;
    //   }
    // }

    if (currentShape.type === TDShapeType.TextArea || currentShape.type === TDShapeType.Code) {
      if (currentShape.size[0] < 20) {
        this.isCreate ? (currentShape.size[0] = 80) : (currentShape.size[0] = 20);
      }
      if (currentShape.size[1] < 50) {
        currentShape.size[1] = 50;
      }
      currentShape.label = currentShape.label || "Text";
    }

    return {
      id: "transform_single",
      before: {
        appState: {
          snapLines: [],
        },
        document: {
          pages: {
            [currentPageId]: {
              shapes: beforeShapes,
            },
          },
          pageStates: {
            [currentPageId]: {
              selectedIds: this.isCreate ? [] : [initialShape.id],
              editingId: undefined,
              hoveredId: undefined,
            },
          },
        },
      },
      after: {
        appState: {
          snapLines: [],
        },
        document: {
          pages: {
            [currentPageId]: {
              shapes: afterShapes,
            },
          },
          pageStates: {
            [currentPageId]: {
              selectedIds: [initialShape.id],
              editingId: undefined,
              hoveredId: undefined,
            },
          },
        },
      },
    };
  };
}
