import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { LocationIcon } from "static/media/location-icon";
import { DatePicker } from "components/date-picker";
import { DeleteButton } from "components/audience-form/components/campaign-location/h2h-precise-locations/components/DeleteButton";
import { convertStringSeperatedBySpecialToArray, convertArrayToStringSeperatedBySpecial } from "utils";
import { Icon } from "components/icons";
import CustomSelect from "components/custom-select";
import { handleMapItemClick } from "components/audience-map/utils";
import { TIMELIST, getTimeRange, getDateOfFormat } from "components/date-picker/utils";
import { Mission } from "components/audience-form/components/campaign-location/h2h-precise-locations/components/PreciseLocation/Mission";
import { styles } from "./styles";
import { handleMissionDelete, isInvalidMission, handleAddressDelete } from "../../../utils";
import { useStore } from "../../../../../../../store";

const useStyles = makeStyles(() => styles);

export const PreciseLocation = ({ location, setAnchorEl, isSubmitted }) => {
  const classes = useStyles();
  const [deletingLocation, setDeletingLocation] = useState(false);

  const {
    city,
    campaign,
    client: { id: clientId },
    timezone,
    country: {
      baPerLocation: baNumsPerLocation,
      countryDefaultSettings: { defaultCampaignLimits },
    },
    costsCalculationData: { detailedCost },
    updateMap,
    setAddresses,
    updateCity,
  } = useStore();

  const { distribution, printing } = detailedCost;

  const { startDates, times } = useMemo(() => {
    const startDates = [];
    const times = [];

    const tmpDates = convertStringSeperatedBySpecialToArray(location.startDate);
    const tmpTimes = convertStringSeperatedBySpecialToArray(location.time);
    const len = tmpDates.length;

    if (tmpTimes.length === len) {
      const ID = [];
      const dateValues = tmpDates.map((date, index) => {
        ID[index] = index;
        return new Date(date).getTime();
      });

      ID.sort((a, b) => {
        if (dateValues[a] < dateValues[b]) return -1;
        if (dateValues[a] > dateValues[b]) return 1;
        return Number(tmpTimes[a]) < Number(tmpTimes[b]) ? -1 : 1;
      });

      ID.forEach((id) => {
        startDates.push(tmpDates[id]);
        times.push(tmpTimes[id]);
      });
    }

    return { startDates, times };
  }, [location.startDate, location.time]);

  const isExistMissions = useMemo(
    () => startDates && times && startDates.length && startDates.length === times.length,
    [startDates, times]
  );

  const oldDates = useMemo(
    () =>
      city.addresses.reduce((dates, address) => {
        const tmpDates = convertStringSeperatedBySpecialToArray(address.startDate);
        const tmpTimes = convertStringSeperatedBySpecialToArray(address.time);

        if (tmpDates.length === tmpTimes.length) {
          tmpDates.forEach((d) => dates.push(new Date(d)));
        }

        return dates;
      }, []),
    [city]
  );

  const existInvalidMissionOnThisLocation = useMemo(
    () =>
      startDates.reduce((acc, d) => {
        if (isInvalidMission(d, timezone, defaultCampaignLimits)) {
          acc = true;
        }
        return acc;
      }, false),
    [startDates]
  );

  const getUpdatedAddresses = ({ city, newStartDates, newTimes }) =>
    city.addresses.reduce((acc1, address) => {
      if (address.id !== location.id) return [...acc1, address];

      return [
        ...acc1,
        {
          ...address,
          startDate: convertArrayToStringSeperatedBySpecial(newStartDates),
          time: convertArrayToStringSeperatedBySpecial(newTimes),
        },
      ];
    }, []);

  const handleConfirm = useCallback(
    (selectedDates, selectedTime) => {
      const newStartDates = [...startDates, ...selectedDates];
      const newTimes = [...times, ...Array(selectedDates.length).fill(selectedTime)];

      const updatedAddresses = getUpdatedAddresses({ city, newStartDates, newTimes });

      setAddresses(updatedAddresses);
    },
    [startDates, times, city]
  );

  const handleUpdateTime = useCallback(
    (index, newTime) => {
      const newStartDates = [...startDates];
      const newTimes = [...times.slice(0, index), newTime, ...times.slice(index + 1, times.length)];

      const updatedAddresses = getUpdatedAddresses({ city, newStartDates, newTimes });
      setAddresses(updatedAddresses);
    },
    [startDates, times, location, city]
  );

  const handleDeleteMission = useCallback(
    (index) => {
      const newStartDates = [...startDates.slice(0, index), ...startDates.slice(index + 1, startDates.length)];
      const newTimes = [...times.slice(0, index), ...times.slice(index + 1, times.length)];

      handleMissionDelete({
        city,
        location,
        setAddresses,
        newStartDates,
        newTimes,
        setAnchorEl,
      });
    },
    [startDates, times, location, city]
  );

  const handleLocationCardClick = () => {
    if (!location.editMode) {
      handleMapItemClick({ item: location, activeSelectedCity: city, updateMap, setAddresses });
    }
  };

  const handleDeleteLocation = (event) => {
    event.stopPropagation();

    handleAddressDelete({
      address: location,
      city,
      setAnchorEl,
      updateCity,
      campaignId: campaign.id,
      setDeletingLocation,
      clientId,
    });
  };

  const options = TIMELIST.map((t) => ({
    value: t,
    label: getTimeRange(t),
  }));

  const flyerPack = printing.quantity / distribution.quantity;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));
  const distributors = startDates.length * baNumsPerLocation;
  const amountFlyers = startDates.length ? startDates.length * flyerPack * baNumsPerLocation : 0;

  return (
    <Box
      className={classnames(classes.locationCardContainer, "mob-location-card-container", {
        [classes.clickable]: !location.editMode,
      })}
      onClick={handleLocationCardClick}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.locationTitle}>
          {existInvalidMissionOnThisLocation || !distributors ? (
            <Icon type="Attention" size="s" />
          ) : (
            <LocationIcon className={classes.locationIcon} />
          )}
          {location.value}
        </Typography>

        {!isSubmitted && <DeleteButton onClick={handleDeleteLocation} deleting={deletingLocation} />}
      </Box>

      {location.editMode ? (
        <>
          <Box>
            <Typography className={classes.clickButtonHint}>
              Click “Add date and time” to create a distribution at this location
            </Typography>
          </Box>

          {isExistMissions === true && (
            <>
              {isMobile ? (
                // Mobile view (max-width: 768px)
                <Box className={classes.missionsListContainerMobile}>
                  {startDates.map((date, index) => (
                    <Box key={index}>
                      <Box className={classes.missionContainerMobile}>
                        <Typography className={classes.missionTitleMobile}>Distributors</Typography>
                        <Typography className={classes.missionItemMobile}>{baNumsPerLocation}</Typography>
                        <Typography className={classes.missionTitleMobile}>Flyers</Typography>
                        <Typography className={classes.missionItemMobile}>{flyerPack}</Typography>
                        {!isSubmitted && (
                          <Typography className={`${classes.missionItemMobile} ${classes.lastItemMobile}`}>
                            <DeleteButton onClick={() => handleDeleteMission(index)} />
                          </Typography>
                        )}
                      </Box>
                      <Box className={classes.missionContainerMobile1}>
                        <Typography className={classes.missionTitleMobile1}>Date</Typography>
                        <Typography className={classes.missionItemMobile1}>
                          {getDateOfFormat(date, timezone)}
                        </Typography>
                        <Typography className={classes.missionTitleMobile1}>Time</Typography>
                        <CustomSelect
                          selectedValue={Number(times[index])}
                          options={options}
                          disabled={isSubmitted}
                          onChange={(newTime) => {
                            if (newTime === Number(times[index])) return;
                            handleUpdateTime(index, newTime);
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                // Desktop view (769px and above)
                <Box className={classes.missionsListContainer}>
                  <Box className={classes.missionContainer}>
                    <Typography className={classes.missionTitle}>Date</Typography>
                    <Typography className={classes.missionTitle}>Time</Typography>
                    <Typography className={classes.missionTitle}>Distributors</Typography>
                    <Typography className={classes.missionTitle}>Flyers</Typography>
                    <Typography />
                  </Box>
                  {startDates.map((date, index) => (
                    <Mission
                      key={index}
                      startDate={date}
                      time={times[index]}
                      baNumsPerLocation={baNumsPerLocation}
                      flyersAmountByMission={flyerPack}
                      onUpdateTime={(newTime) => handleUpdateTime(index, newTime)}
                      onDeleteMission={() => handleDeleteMission(index)}
                      timezone={timezone}
                      isSubmitted={isSubmitted}
                      defaultCampaignLimits={defaultCampaignLimits}
                    />
                  ))}
                </Box>
              )}
            </>
          )}

          <Box
            className={classes.bottomContainer}
            justifyContent={isExistMissions ? "flex-start" : "center"}
            style={isSubmitted ? { display: "none" } : {}}
          >
            <DatePicker
              oldDates={oldDates}
              onConfirm={handleConfirm}
              timezone={timezone}
              defaultCampaignLimits={defaultCampaignLimits}
            />
          </Box>
        </>
      ) : (
        <Box display="flex">
          <Typography className={classnames(classes.missionsCount, { [classes.nonMissions]: !distributors })}>
            Distributors: <span className={classes.totalMissions}>{distributors}</span>
          </Typography>
          <Typography className={classnames(classes.missionsCount, { [classes.nonMissions]: !distributors })}>
            Flyers: <span className={classes.totalMissions}>{amountFlyers}</span>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
