import { makeStyles } from "@material-ui/core";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

export default function NodeItem({ id, name }) {
  const classes = useStyles();

  const app = useTldrawApp();
  const [currentName, setCurrentName] = useState(name);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const input = useRef();

  useEffect(() => {
    if (shouldUpdate) {
      input.current.blur();
    }
    setShouldUpdate(false);
  }, [shouldUpdate]);

  useLayoutEffect(() => {
    if (isEditing) {
      input.current.focus();
      input.current.select();
    }
  }, [isEditing]);

  return (
    <div>
      {isEditing ? (
        <input
          className={classes.input}
          ref={input}
          value={currentName}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={(e) => {
            setCurrentName(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setCurrentName(name);
              setShouldUpdate(true);
            }
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
          onBlur={() => {
            app.onShapeChange({ id, name: currentName });
            setIsEditing(false);
          }}
        />
      ) : (
        <span onDoubleClick={() => setIsEditing(true)}>{name}</span>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  icon: {
    width: "20px",
    display: "inline-flex",
    justifyContent: "center",
    alignContent: "center",
  },
  selected: {
    backgroundColor: "#f1c8cf6e",
  },
  grouped: {
    marginLeft: "20px",
  },
  item: {
    padding: "5px 0px",
    cursor: "default !important",
    userSelect: "none",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid #ebebeb",
    },
  },
  input: {
    backgroundColor: "transparent",
    border: "1px solid #EE4360",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  },
});
