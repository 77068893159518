export const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, 0.1)",
    zIndex: 9,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  choseLocationModal: {
    width: "556px",
    height: "174px",
    marginTop: "-150px",
    padding: "32px 24px 24px 24px",
    borderRadius: "8px",
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29.23px",
  },
  text: {
    fontSize: "16px",
  },
};
