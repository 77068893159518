import { gql } from "@apollo/client";

export const DeleteFile = gql`
  mutation deleteFile(
    $fileKey: String!
    $campaignId: ID!
  ) {
    deleteFile(
      fileKey: $fileKey
      campaignId: $campaignId
    ) {
      status
    }
  }
`;
