import posthog from "posthog-js";
import { createContext, useEffect, useState } from "react";

export const FeatureFlagContext = createContext({ isSkipEnabled: false, loading: true });

export const FeatureFlagContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSkipEnabled, setIsSkipEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkFeatureFlags = () => {
      const enabled = posthog.isFeatureEnabled("enable-skip-design");
      setIsSkipEnabled(!!enabled);
      setLoading(false);
    };
    checkFeatureFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{
        isSkipEnabled: isSkipEnabled,
        loading,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};
