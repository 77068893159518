export const styles = {
  root: {
    position: "fixed",
    bottom: 20,
    right: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: 2,
  },
  wrapper: {
    background: "#EE4360",
    height: 60,
    width: 60,
    cursor: "pointer",
    color: "#FFFFFF",
    borderRadius: "100%",
    position: "relative",
    marginTop: "20px",
  },
  message: {
    "& svg": {
      width: "32px",
      height: "32px",
      position: "absolute",
      top: "17px",
      right: "14px",
    },
  },
  close: {
    "& svg": {
      width: "20px",
      height: "20px",
      position: "absolute",
      top: "20px",
      right: "20px",
    },
  },
};
