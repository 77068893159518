import { styled } from "@stitches/react";
import { HTMLContainer, Utils } from "@tldraw/core";
import { ChildIndexEnum, QRCodeShape, StaticAssetsIdsEnum } from "pages/flyer/builder/drawer";
import { GHOSTED_OPACITY } from "pages/flyer/builder/drawer/constants/constants";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import {
  defaultQRCodeStyle,
  getBoundsQRCode,
  transformRectangle,
  transformSingleRectangle,
} from "pages/flyer/builder/drawer/state/shapes/shared";
import { TDMeta, TDShapeType } from "pages/flyer/builder/drawer/types";
import QRCode from "qrcode";
import * as React from "react";
import { TDShapeUtil } from "../TDShapeUtil";
import { useStore } from "../../../../../../../store";
import { StoreType } from "../../../../../../../store/types";

type T = QRCodeShape;
type E = HTMLDivElement;

export class QRCodeUtil extends TDShapeUtil<T, E> {
  type = TDShapeType.QRCode as const;

  canBind = true;

  canClone = true;

  showCloneHandles = true;

  isAspectRatioLocked = true;

  getShape = (props: Partial<T>): T => {
    return Utils.deepMerge<T>(
      {
        id: StaticAssetsIdsEnum.QrCode,
        type: TDShapeType.QRCode,
        name: "QRCode",
        parentId: "page",
        childIndex: ChildIndexEnum.Middle,
        point: [0, 0],
        size: [1, 1.18],
        rotation: 0,
        style: defaultQRCodeStyle,
        assetId: StaticAssetsIdsEnum.QrCode,
      },
      props
    );
  };

  Component = TDShapeUtil.Component<T, E, TDMeta>(
    (
      {
        shape,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        asset = { src: "" },
        isBinding,
        isGhost,
        meta,
        events,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isSelected,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onShapeChange,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isEditing,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...otherProps
      },
      ref
    ) => {
      const app = useTldrawApp();
      const [base64QrCode, setBase64QrCode] = React.useState("");

      const {
        campaign: { qrCodeLink },
        qrCodeSettings: { hideQrCoupon },
      }: StoreType = useStore();

      React.useEffect(() => {
        const getQrCodeBase64 = async () => {
          const base64QrCode = await QRCode.toDataURL(qrCodeLink || "oppizi.com", { margin: 0, scale: 20 });
          setBase64QrCode(base64QrCode);

          const tempDocument = { ...app.state.document };
          tempDocument.assets.qrCode.src = base64QrCode;
          app.updateDocument(tempDocument);
        };
        getQrCodeBase64();
      }, [qrCodeLink]);

      const { size, style, id } = shape;
      const { transparency, strokeWidth, isOpacityVisible } = style;
      const sw = strokeWidth!;
      const w = Math.max(0, size[0]);

      React.useEffect(() => {
        if (id && onShapeChange && size) {
          onShapeChange({ id, size: hideQrCoupon ? [size[0], size[0]] : [size[0], size[0] * 1.18] });
        }
      }, [hideQrCoupon]);

      const rImage = React.useRef<HTMLImageElement>(null);
      const rWrapper = React.useRef<HTMLDivElement>(null);

      const transparencyParamOfElement = isOpacityVisible ? transparency! / 100 : 1;
      const currentTransparencyOfElement = app.isCreating ? 0.5 : transparencyParamOfElement;

      React.useLayoutEffect(() => {
        const wrapper = rWrapper.current;
        if (!wrapper) return;
        wrapper.style.width = `${w}px`;

        const image = rImage.current;
        if (!image) return;
        image.style.width = `${w}px`;
        image.style.height = `${w}px`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [size]);

      return (
        <HTMLContainer ref={ref} {...events}>
          {isBinding && (
            <div
              className="tl-binding-indicator"
              style={{
                position: "absolute",
                top: `calc(${-this.bindingDistance}px * var(--tl-zoom))`,
                left: `calc(${-this.bindingDistance}px * var(--tl-zoom))`,
                width: `calc(100% + ${this.bindingDistance * 2}px * var(--tl-zoom))`,
                height: `calc((100% + ${this.bindingDistance * 2}px * var(--tl-zoom))*1.2)`,
              }}
            />
          )}
          <Wrapper
            ref={rWrapper}
            // @ts-ignore
            isDarkMode={meta.isDarkMode} //
            isFilled={style.isFilled}
            isGhost={isGhost}
            style={{
              width: `${size[0]}px`,
              backgroundColor: "white",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <svg width={w} height={w}>
              <rect
                x={-sw / 2}
                y={-sw / 2}
                width={w}
                height={w}
                fill={"none"}
                strokeWidth={sw}
                pointerEvents="none"
                opacity={currentTransparencyOfElement}
              />
            </svg>

            <ImageElement
              id={shape.id + "_image"}
              ref={rImage}
              src={base64QrCode}
              alt="tl_image_asset"
              draggable={false}
              style={{
                opacity: currentTransparencyOfElement,
                width: `${size[0]}px`,
                height: `${size[0]}px`,
              }}
            />
            {hideQrCoupon ? null : (
              <div
                style={{
                  opacity: currentTransparencyOfElement,
                  width: `${size[0]}px`,
                  height: `${size[0] * 0.18}px`,
                  fontSize: `${size[0] / 9}px`,
                  fontFamily: "Open Sans",
                  textAlign: "center",
                  wordWrap: "break-word",
                  userSelect: "none",
                }}
              >
                COUPON: XXXXXX
              </div>
            )}
          </Wrapper>
        </HTMLContainer>
      );
    }
  );

  Indicator = TDShapeUtil.Indicator<T>(({ shape }) => {
    const { size } = shape;

    return <rect width={Math.max(1, size[0])} height={Math.max(1, size[1])} />;
  });

  getBounds = (shape: T) => {
    return getBoundsQRCode(shape, this.boundsCache);
  };

  shouldRender = (prev: T, next: T) => {
    return next.size !== prev.size || next.style !== prev.style;
  };

  transform = transformRectangle;

  transformSingle = transformSingleRectangle;

  getSvgElement = (shape: QRCodeShape) => {
    const bounds = this.getBounds(shape);
    const elm = document.createElementNS("http://www.w3.org/2000/svg", "image");
    elm.setAttribute("width", `${bounds.width}`);
    elm.setAttribute("height", `${bounds.height}`);
    elm.setAttribute("xmlns:xlink", `http://www.w3.org/1999/xlink`);
    return elm;
  };
}

const Wrapper = styled("div", {
  pointerEvents: "all",
  position: "relative",
  fontFamily: "sans-serif",
  fontSize: "2em",
  width: "100%",
  borderRadius: "3px",
  perspective: "800px",
  zIndex: 1000,
  // overflow: "hidden",
  p: {
    userSelect: "none",
  },
  img: {
    userSelect: "none",
  },
  variants: {
    isGhost: {
      false: { opacity: 1 },
      true: { transition: "opacity .2s", opacity: GHOSTED_OPACITY },
    },
    isFilled: {
      true: {},
      false: {},
    },
    isDarkMode: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isFilled: true,
      isDarkMode: true,
      css: {
        boxShadow: "2px 3px 12px -2px rgba(0,0,0,.3), 1px 1px 4px rgba(0,0,0,.3), 1px 1px 2px rgba(0,0,0,.3)",
      },
    },
  ],
  length: 0,
});

const ImageElement = styled("img", {
  position: "absolute",
  padding: "10%",
  // paddingBottom: 0,
  top: 0,
  left: 0,
  // width: "100%",
  height: "100%",
  maxWidth: "100%",
  // minWidth: "100%",
  objectFit: "contain",
  pointerEvents: "none",
  userSelect: "none",
  zIndex: 0,
  length: 0,
});
