export const style = {
  button: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    minWidth: "104px",
    fontWeight: 700,
    fontSize: "16px",
    backgroundColor: "transparent",
    color: "#1D2943",
    border: "1px solid #1D2943",
    borderRadius: "8px",
    textTransform: "none" as const,

    "&:hover": {
      backgroundColor: "#E2E2FC",
    },

    "&:active": {
      backgroundColor: "#d0d0f5",
    },
  },
};
