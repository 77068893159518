import create from "zustand";

import {
  CampaignType,
  ClientType,
  CostsCalculationDataType,
  CountryType,
  ErrorsType,
  MapType,
  MissionD2DType,
  MissionH2HType,
  StoreType,
  UserType,
  AddressType,
  FlyerBuilderType,
  QRCodeSettingsType,
  CouponsListType,
  DistributionLocation,
} from "./types";

// @ts-ignore
const useStore = create<StoreType>((set) => ({
  campaign: {} as CampaignType,
  client: {} as ClientType,
  costsCalculationData: {} as CostsCalculationDataType,
  errors: {} as ErrorsType,
  city: null,
  country: {} as CountryType,
  user: {} as UserType,
  map: { zoom: 12 } as MapType,
  missions: [] as MissionH2HType[] | MissionD2DType,
  timezone: "",
  hoveredMission: "",
  hoveredRoute: {} as any,
  flyerBuilder: { loading: false } as FlyerBuilderType,
  qrCodeSettings: {} as QRCodeSettingsType,
  couponsList: [] as CouponsListType[],
  distributionLocations: [] as DistributionLocation[],

  initData: (data) => {
    // @ts-ignore
    set(() => data);
  },

  updateUser: (data) => {
    set((state) => ({
      user: {
        ...state.user,
        ...data,
      },
    }));
  },

  updateCostsCalculationData: (data) => {
    set((state) => ({
      costsCalculationData: {
        ...state.costsCalculationData,
        ...data,
      },
    }));
  },

  setCity: (data) => {
    // @ts-ignore
    set(() => (data ? { city: data } : { city: null, missions: [] }));
  },

  updateCity: (data) => {
    // @ts-ignore
    set((state) =>
      state.city
        ? {
            city: {
              ...state.city,
              ...data,
            },
          }
        : null
    );
  },

  updateErrors: (data) => {
    set((state) => ({
      errors: {
        ...state.errors,
        ...data,
      },
    }));
  },

  updateCampaign: (data) => {
    set((state) => ({
      campaign: {
        ...state.campaign,
        ...data,
      },
    }));
  },

  updateQrCodeSettings: (data) => {
    set((state) => ({
      qrCodeSettings: {
        ...state.qrCodeSettings,
        ...data,
      },
    }));
  },

  setAddresses: (data) => {
    // @ts-ignore
    set((state) => {
      if (state.city?.id) {
        return {
          city: {
            ...state.city,
            addresses: data,
          },
        };
      }
    });
  },

  updateAddress: (data) => {
    // @ts-ignore
    set((state) => {
      if (state.city?.id) {
        return {
          city: {
            ...state.city,
            addresses: state.city.addresses.map((address: AddressType) =>
              data.id === address.id ? { ...address, ...data } : address
            ),
          },
        };
      }
    });
  },

  setDistributionLocations: (data: DistributionLocation[]) => {
    set(() => {
      return {
        distributionLocations: [...data],
      };
    });
  },

  setFocusedLocation: (location: DistributionLocation) => {
    // @ts-ignore
    set((state) => {
      const newLocations = state.distributionLocations.reduce(
        (acc: DistributionLocation[], curr: DistributionLocation) => {
          if (curr.name === location.name) {
            acc.push({ ...curr, isFocused: true });
          } else {
            acc.push({ ...curr, isFocused: false });
          }
          return acc;
        },
        []
      );
      return {
        distributionLocations: [...newLocations],
      };
    });
  },

  onAddressClick: (data) => {
    // @ts-ignore
    set((state) => {
      // @ts-ignore
      if (state.city?.id) {
        return {
          city: {
            ...state.city,
            addresses: state.city.addresses.map((address) =>
              data.id === address.id ? { ...address, editMode: true } : { ...address, editMode: false }
            ),
          },
        };
      }
    });
  },

  updateMap: (data) => {
    set((state) => ({
      map: {
        ...state.map,
        ...data,
      },
    }));
  },

  setMissions: (data) => {
    set(() => ({
      missions: data,
    }));
  },

  setTimezone: (timezone) => {
    set(() => ({
      timezone,
    }));
  },

  setHoveredMission: (hoveredMission) => {
    set(() => ({
      hoveredMission,
    }));
  },

  setHoveredRoute: (hoveredRoute) => {
    set(() => ({
      hoveredRoute,
    }));
  },

  setAvailableCities: (data) => {
    set((state) => ({
      country: { ...state.country, citiesList: data },
    }));
  },

  updateFlyerBuilder: (data) => {
    set((state) => ({
      flyerBuilder: { ...state.flyerBuilder, ...data },
    }));
  },

  updateCouponsList: (data) => {
    set((state) => ({
      couponsList: {
        ...state.couponsList,
        ...data,
      },
    }));
  },
}));

export { useStore };
