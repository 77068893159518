import Button from "@material-ui/core/Button";
import { ClassesType } from "../type";

interface ISkipButtonProps extends ClassesType {
  onSkip: () => void;
}

export const SkipButton = ({ classes, onSkip }: ISkipButtonProps) => (
  <Button className={`${classes.button} ${classes.skipButton}`} variant="outlined" onClick={onSkip}>
    Do not save
  </Button>
);
