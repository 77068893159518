import { useCallback, memo } from "react";
import cn from "classnames";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { default as MUSelect } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import style from "./style.js";

const Select = (props) => {
  const {
    onChange,
    classes: baseClasses,
    extendClasses,
    label,
    options,
    placeholder,
    value,
    disabled,
    inputProps,
  } = props;

  const classes = { ...baseClasses, ...extendClasses };

  const handleChange = useCallback(
    (e) => {
      onChange && onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormControl classes={{ root: classes.root }} variant="outlined">
        <MUSelect
          inputProps={inputProps}
          color="secondary"
          className={cn({ [classes.placeholder]: value === placeholder })}
          classes={{ root: classes.select }}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        >
          {placeholder && (
            <MenuItem className={classes.placeholder} value={placeholder}>
              {placeholder}
            </MenuItem>
          )}
          {options?.map((item, index) => (
            <MenuItem key={"MenuItem" + index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </MUSelect>
      </FormControl>
    </>
  );
};

export default memo(withStyles(style)(Select));
