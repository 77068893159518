const style = {
  modalWrapper: {
    width: "100vw",
    height: "100vh",
    position: "absolute" as const,
    top: 0,
    left: 0,
    zIndex: 200,
  },
  backdropFilter: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modal: {
    maxWidth: 700,
    width: "100%",
    backgroundColor: "#fff",
    zIndex: 1,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column" as const,
    padding: "50px 75px 70px",
  },
  header: {
    fontFamily: "Roboto",
    fontWeight: "bold" as const,
    fontSize: "26px",
    alignSelf: "center",
    marginBottom: 36,
  },
  info: {
    fontSize: "16px",
    marginBottom: 20,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: "10px 30px",
    fontSize: 16,
    color: "#646464",
    fontWeight: "bold" as const,
    "&:not(:last-child)": { marginRight: 20 },
  },
  buttonActive: {
    backgroundColor: "#EE4360",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#EE3460",
    },
  },
};

export default style;
