import { CityType } from "store/types";
import { LOCATION_STATUS } from "shared/constants";
import { apiClient } from "../../../module/api";

const isDifferAddresses = (address1: any, address2: any) =>
  ["isVisible", "value", "paths", "startDate", "endDate", "time", "missionCount"].reduce((acc, key) => {
    if (key === "paths") {
      if (JSON.stringify(address1[key]) !== JSON.stringify(address2[key])) {
        acc = true;
      }
    } else if (address1[key] !== address2[key]) {
      acc = true;
    }

    return acc;
  }, false);

const getSortedAddress = (city: CityType) => {
  if (!city) {
    return [];
  }

  return city.addresses
    .map((address) => ({
      isVisible: address.isVisible,
      value: address.value,
      paths: address.paths,
      startDate: address.startDate,
      endDate: address.endDate,
      time: address.time,
      missionCount: address.missionCount,
    }))
    .sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
};

export const isDifferCityStates = (city1: CityType, city2: CityType): boolean => {
  if (!city1) {
    return !!city2;
  }
  if (!city2) {
    return true;
  }

  if (city1.nameWithState !== city2.nameWithState) {
    return true;
  }

  const addresses1 = getSortedAddress(city1);
  const addresses2 = getSortedAddress(city2);

  if (addresses1.length !== addresses2.length) {
    return true;
  }

  for (let i = 0, len = addresses1.length; i < len; i++) {
    if (isDifferAddresses(addresses1[i], addresses2[i])) {
      return true;
    }
  }

  return false;
};

export const handleCreateLocation = async (location: any, city: any, campaignId: string) => {
  const payload = {
    status: LOCATION_STATUS.DISABLED,
    city_id: city.id,
    name: location.value,
    type_id: 18,
    longitude: location.position.lng,
    latitude: location.position.lat,
    label: location.value,
    is_event: false,
    moments_morning: false,
    moments_lunch: false,
    moments_afternoon: false,
    require_permit: false,
    is_sheltered: false,
    campaign_id: campaignId,
    is_self_serve_location: true,
  };
  const res = await apiClient.createLocation(payload);

  if (res.status === 200) {
    const result = await res.json();
    return result;
  } else {
    throw new Error("Error while saving custom locations");
  }
};
