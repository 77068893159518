import { useEffect } from "react";

export function useOutsideAlerter(
  ref: React.MutableRefObject<any>,
  callBack: () => void,
  notHandledRefs?: Array<React.MutableRefObject<any>>
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current) {
        const isNeedToCallBack = notHandledRefs
          ? !!notHandledRefs.find((ref) => !ref.current.contains(event.target))
          : true;
        if (!ref.current.contains(event.target) && isNeedToCallBack) {
          callBack();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
