import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import type { TldrawCommand } from "pages/flyer/builder/drawer/types";

export function changePage(app: TldrawApp, pageId: string): TldrawCommand {
  return {
    id: "change_page",
    before: {
      appState: {
        currentPageId: app.currentPageId,
      },
    },
    after: {
      appState: {
        currentPageId: pageId,
      },
    },
  };
}
