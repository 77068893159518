const style = {
  h2hCampaignLocationWrapper: {
    height: "100%",
    width: "100%",
    overflowY: "auto",
    padding: "0 20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  city: {
    color: "#798498",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "40px",
    // marginBottom: "5px",
  },
  cityBox: {
    width: "100%",
    paddingTop: "5px",
    position: "relative",
  },
  customAddress: {
    display: "flex",
    position: "absolute",
    right: "-50px",
    top: "25px",
    transform: "translateY(-55%)",
    cursor: "pointer",
    color: "#2898EC",
  },
  isPostcodesKnowen: {
    margin: "8px 0",
    textAlign: "end",
    cursor: "pointer",
    color: "#2898EC",
    textDecoration: "underline",
  },
  anyDemographicsNotice: {
    padding: "16px 0",
  },
  emptyDemography: {
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f50057",
    letterSpacing: "-0.01em",
    textAlign: "center",
  },

  increaseBudgetWrapper: {
    position: "absolute",
    top: "50px",
    left: 0,
    width: "66%",
    display: "flex",
    justifyContent: "center",
    zIndex: 2,
  },

  classNameInput: {
    width: "388px",
    borderRadius: "8px",
    background: "#FFFFFF",

    "& input": {
      color: "#00112B",
    },

    "& fieldset": {
      border: "1px solid #E2E2FC",
      borderRadius: "8px",
    },
  },

  increaseBudgetBox: {
    padding: "8px",
    background: "#FEECEF",
    borderRadius: "0 0 5px 5px",

    "& > p": {
      fontSize: "12px",
    },
  },

  campaignLocationWrapper: {
    width: "100%",
  },

  input: {
    "& > .Mui-disabled": {
      color: "initial",
    },
  },

  cityOptionsBtn: {
    cursor: "pointer",
    position: "absolute",
    right: "-30px",
    fill: "#C4C4C4",
    width: "30px",
  },

  locationOptionsBtn: {
    cursor: "pointer",
    fill: "#C4C4C4",
    width: "30px",
    alignSelf: "end",
    marginLeft: "5px",
  },

  showHideCity: {
    cursor: "pointer",
    position: "absolute",
    right: "-60px",
    fill: "#C4C4C4",
    width: "30px",
  },

  citiesAutocomplete: {
    width: "388px",
    marginLeft: "10px",
    borderRadius: "8px",

    "& input": {
      color: "#00112B",
    },

    "& fieldset": {
      border: "1px solid #E2E2FC",
      borderRadius: "8px",
    },

    "& > div": {
      backgroundColor: "#ffffff",
    },
  },

  addressesWrapper: {
    // width: "80%",
    position: "relative",
    // float: "right",
    // paddingLeft: 40,
  },
  selectedCityInput: {
    height: "40px",
    alignItems: "center",
    display: "flex",
    minWidth: "150px",
    border: "0",
    borderRadius: "6px",
    maxWidth: 450,

    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },

  optionSelect: {
    "&:hover": {
      backgroundColor: "#E2E2FC !important",
    },
  },

  selectedCityInputDelete: {
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "388px",
    paddingLeft: "14px",
    borderRadius: "8px",
    color: "#011533",
    background: "#FFF1F1",
    border: "1px solid #EE4360",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
  },

  selectedCityAddressDelete: {
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 14px",
    borderRadius: "6px",
    color: "#FFFFFF",
    background: "#EE4360",
  },

  selectedCityAddressDeleteText: {
    maxWidth: "60%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  deleteAddressConfirmBtn: {
    color: "#0B2456",
    // background: "#FFFFFF",
    width: "65px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    cursor: "pointer",
  },

  deleteAddressDeclineBtn: {
    color: "#FFFFFF",
    background: "#EE4360",
    width: "65px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    cursor: "pointer",
  },

  addressNameWrapper: {
    margin: "8px 0",
  },
  addressIcon: {
    width: "20px",
    position: "absolute",
    left: "-30px",
  },
  wrongDate: {
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f50057",
    letterSpacing: "-0.01em",
  },
  columnHeader: {
    // marginLeft: 10,
    color: "#585C60",
  },
  hoveredItem: {
    outline: "1px solid #a9a9a9",
    borderRadius: "6px",
  },
  disabledCity: {
    color: "rgba(0, 0, 0, 0.38)",

    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  addCityBtn: {
    color: "#f50057",
  },
  deleteCityButton: {
    marginLeft: "10px",
  },

  campaignName: {
    marginBottom: "16px",

    "& > p": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#798498",
      marginBottom: "5px",
      cursor: "default",
    },
  },

  error: {
    color: "#F90606",
    marginTop: "8px",
  },
  required: {
    color: "#f50057",
  },
  totalsWrapper: {
    display: "flex",
    width: "590px",
    paddingLeft: "8px",
    justifyContent: "space-between",
  },

  totals: {
    color: "#707087",
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
  },

  selectLocationHint: {
    display: "grid",
    gridTemplateColumns: "0.1fr 1fr",
    maxWidth: "50%",
  },

  distributorsAndFlyers: {
    display: "flex",
    alignSelf: "flex-end",
    marginTop: "6px",
    gap: "16px",
  },

  totalValue: {
    marginLeft: "5px",
  },

  whatIsMissionTooltip_Title: {
    color: "#011533",
    fontSize: "14px",
    lineHeight: "20px",
    padding: "10px",
  },
  whatIsMissionTooltip_Content: {
    color: "#4F5B94",
    fontSize: "12px",
    display: "flex",
    gap: "6px",
    padding: "0px 10px",
    cursor: "pointer",
    alignItems: "center",
  },
  baCountsInfo: {
    color: "#4F5B94",
    fontSize: "12px",
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
};

export default style;
