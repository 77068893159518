import React from "react";
import { makeStyles } from "@material-ui/core";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);
export const EDDMEmptyState = ({
  countryCode,
  disablePlaceAutocomplete,
  handleAutocompleteChanged,
  getFilteredPostCodes,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.choseLocationModal}>
        <span className={classes.title}>Choose locations for your campaign</span>
        <span className={classes.text}>Select delivery routes to reach every doorstep with your campaign</span>
        <PlaceAutocomplete
          countryCode={countryCode}
          disabled={disablePlaceAutocomplete}
          placeholder={"Search for a specific location or address..."}
          postCodeSearch={true}
          onPlaceChanged={handleAutocompleteChanged}
          getFilteredPostCodes={getFilteredPostCodes}
        />
      </div>
    </div>
  );
};
