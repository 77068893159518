import { convertStringSeperatedBySpecialToArray } from "utils";

const openLinkInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const openLinkInSameTab = (url) => {
  const newWindow = (window.location.href = url);
  if (newWindow) newWindow.opener = null;
};

const getSoonerStartDate = (addresses) => {
  return addresses.reduce((acc, address) => {
    if (address.startDate && address.startDate.length === 10) {
      const currentDate = new Date(`${address.startDate} 00:00`);
      if (!acc) {
        acc = currentDate;
      } else if (acc > currentDate) {
        acc = currentDate;
      }
    }
    return acc;
  }, "");
};

const getEarliestStartDate = (addresses) => {
  return addresses.reduce((acc, address) => {
    if (address.id) {
      const tmpDates = convertStringSeperatedBySpecialToArray(address.startDate);
      tmpDates.forEach((d) => {
        const date = new Date(d);
        if (!acc) {
          acc = date;
        } else {
          if (acc.getTime() > date.getTime()) {
            acc = date;
          }
        }
      });
    }
    return acc;
  }, null);
};

const prepareCouponIdForDB = (coupon) =>
  JSON.stringify({
    couponId: coupon.id,
    integrationId: coupon.integrationId,
  });

export { getSoonerStartDate, getEarliestStartDate, openLinkInNewTab, openLinkInSameTab, prepareCouponIdForDB };
