export const styles = {
  mapHeader: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    position: "absolute",
    top: "17px",
    right: "20px",
    width: "70%",
    zIndex: 2,
    background: "#FFFFFF",
    borderRadius: "5px",
    padding: "12px",

    "& > p": {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  cityName: {
    color: "black",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "18px",
    marginBottom: "10px",
  },
  mapControlsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    // "& > svg": {
    //   width: "60px",
    // },
    // "& svg:nth-child(n)": {
    //   borderRight: "1px solid #BFBFBF",
    // },
    // "& svg:last-child": {
    //   border: "none",
    // },
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  separator: {
    display: "flex",
    height: "26px",
    width: "1px",
    background: "#E2E2FC",
    margin: "0px 4px",
  },
  activeUndoRedo: {
    cursor: "pointer",
  },
  mr_10: {
    marginRight: "10px",
  },
  placeAutocomplete: {
    // margin: "8px 0 8px 36px",
    flex: 1,
    paddingRight: "30px",
  },
};
