import * as React from "react";

export function BoxIcon({
  fill = "none",
  stroke = "currentColor",
  strokeWidth = 2,
  onClick,
}: {
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  onClick?: () => void;
}): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0" y="0" width="24" height="24" rx="2" />
      </svg>
    </div>
  );
}
