const style = {
  link: {
    textDecoration: "none",
    display: "flex",
    gap: 5,
    color: "#EE4360",
    fontWeight: 700,
    fontSize: 14,
    "&:hover": { textDecoration: "underline" },
  },
  inlineBlock: {
    display: "inline-block" as const,
  },
};

export default style;
