import { withStyles } from "@material-ui/core";
import React from "react";

import Menu from "../Menu";
import MenuItem from "../Menu/MenuItem";

class SelectMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      isMobile: window.innerWidth <= 768,
      selectedIndex: -1,
      isReadyForMouseUp: false,
    };
  }

  button = React.createRef();

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ isReadyForMouseUp: true });
    }, 400);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  handleWindowResize = () => {
    this.handleHideMenu;
  };

  handleShowMenu = () => {
    if (!this.button.current) {
      return;
    }

    const boundingClientRect = this.button.current.getBoundingClientRect();

    this.setState({
      showMenu: true,
      position: {
        top: boundingClientRect.top - 4,
        left: boundingClientRect.left - 2,
      },
      width: boundingClientRect.width,
    });
  };

  handleHideMenu = () => {
    this.setState({
      showMenu: false,
    });
  };

  render() {
    const { classes, isDisabled, items, displayValue, cypressId } = this.props;
    const { showMenu, isMobile, position, width } = this.state;

    return (
      <div className={classes.container}>
        {showMenu && <div className={classes.backdrop} onClick={this.handleHideMenu} />}
        <button
          ref={this.button}
          onMouseDown={this.handleShowMenu}
          className={classes.value}
          disabled={isDisabled}
          // eslint-disable-next-line react/no-unknown-property
          cypress_id={cypressId}
        >
          {displayValue}
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 10L12 15L17 10"
              stroke="#2E3860"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        {showMenu && isMobile ? (
          <div className={classes.menu}>
            <ul>
              {items.map((item, index) => {
                const handleMouseEnter = (index) => {
                  this.setState({ selectedIndex: index });
                };

                const handleMouseLeave = () => {
                  this.setState({ selectedIndex: -1 });
                };

                const { selectedIndex, isReadyForMouseUp } = this.state;
                const isSelected = !item.isDisabled && index === selectedIndex;
                return (
                  <li key={index} style={{ width: "fit-content" }}>
                    <MenuItem
                      key={index}
                      index={index}
                      isSelected={isSelected}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClose={this.handleHideMenu}
                      isReadyForMouseUp={isReadyForMouseUp}
                      width={width}
                      {...item}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          showMenu && <Menu items={items} onClose={this.handleHideMenu} position={position} width={width} />
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    position: "relative",
    display: "block",
    width: "100%",
    height: 36,
  },
  value: {
    pointerEvents: "all",
    fontFamily: "Proxima Nova, sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #E2E2FC",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: "0 10px 0 12px",

    "&:not(:disabled):hover": {
      background: "var(--color-surface-panel)",
      borderColor: "var(--color-deeper-500)",

      chevron: {
        path: {
          fill: "var(--color-foreground-600)",
        },
      },
    },

    "&:not(:disabled):focus": {
      outline: "none",
      borderColor: "var(--color-brand-400)",

      chevron: {
        path: {
          fill: "var(--color-foreground-600)",
        },
      },
    },

    "&:disabled": {
      opacity: 1,
    },
  },

  menu: {
    position: "absolute",
    background: "var(--color-surface-popover)",
    borderRadius: 6,
    boxShadow: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 12px 24px -8px rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(8px)",
    pointerEvents: "all",
    zIndex: 1302,

    "& button:hover": {
      color: "#FFFFFF",
      outline: "none",
      background: "var(--color-brand-400)",
      "& svg": {
        fill: "#FFFFFF",
      },
      "& span": {
        color: "#FFFFFF",
      },
    },
  },

  backdrop: {
    pointerEvents: "all",
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  chevron: {
    display: "inline-flex",
    marginLeft: 8,
    pointerEvents: "none",

    path: {
      fill: "var(--color-foreground-secondary)",
    },
  },
};

export default withStyles(styles)(SelectMenu);
