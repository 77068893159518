import CryptoJS from "crypto-js";
import { authorization } from "module/auth";
import CONFIG from "config/config";

const encrypt = (str) => CryptoJS.AES.encrypt(str, CONFIG.CRYPTO_SECRET_KEY).toString();

const decrypt = (encrypted) => {
  const decrypted = CryptoJS.AES.decrypt(encrypted, CONFIG.CRYPTO_SECRET_KEY);
  return decrypted.toString(CryptoJS.enc.Utf8);
};

const authorize = () => {
  const url = new URL(window.location.href);

  const cryptedApiKey = url.searchParams.get("key");
  const cryptedUser = url.searchParams.get("user");
  const channel = url.searchParams.get("channel");

  if (cryptedApiKey && cryptedUser) {
    const apiKey = decrypt(cryptedApiKey);
    const user = JSON.parse(decrypt(cryptedUser));

    authorization.login(user, apiKey);

    window.location.replace(`${url.origin}${url.pathname}${channel ? `?channel=${channel}` : ""}`);
  }
};

export { authorize, decrypt, encrypt };
