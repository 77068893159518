import * as React from "react";

export function ItalicFontIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={20}
      height={20}
      viewBox="-30 -22 100 100"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m40.273.102-1.367 5.546h-9.57L18.164 50.453h9.57L26.367 56H0l1.367-5.547h9.375L21.914 5.648H12.54L13.906.102h26.367Z"
        fill="#000"
      />
    </svg>
  );
}
