import { SVGContainer, Utils } from "@tldraw/core";
import { CodeShape } from "pages/flyer/builder/drawer";
import { GHOSTED_OPACITY } from "pages/flyer/builder/drawer/constants/constants";
import { getRectangleIndicatorPathTDSnapshot } from "pages/flyer/builder/drawer/state/shapes/RectangleUtil/rectangleHelpers";
import {
  defaultTextStyle,
  getBoundsRectangle,
  getFontStyle,
  getShapeStyle,
  getTextAlign,
  getTextVerticalAlign,
  transformRectangle,
  transformSingleRectangle,
} from "pages/flyer/builder/drawer/state/shapes/shared";
import { styled } from "pages/flyer/builder/drawer/styles";
import { DashStyle, TDMeta, TDShapeType } from "pages/flyer/builder/drawer/types";
import * as React from "react";
import { BindingIndicator } from "../RectangleUtil/components/BindingIndicator";
import { TextLabel } from "../shared/TextLabel";
import { TDShapeUtil } from "../TDShapeUtil";

type T = CodeShape;
type E = HTMLDivElement;

export class CodeUtil extends TDShapeUtil<T, E> {
  type = TDShapeType.Code as const;

  canBind = true;

  canClone = true;

  canEdit = false;

  getShape = (props: Partial<T>): T => {
    return Utils.deepMerge<T>(
      {
        id: "id",
        type: TDShapeType.Code,
        name: "Code",
        parentId: "page",
        childIndex: 1,
        point: [0, 0],
        size: [1, 1],
        rotation: 0,
        style: defaultTextStyle,
        label: "",
        labelPoint: [0.5, 0.5],
      },
      props
    );
  };

  Component = TDShapeUtil.Component<T, E, TDMeta>(
    (
      {
        shape,
        isEditing,
        isBinding,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isSelected,
        isGhost,
        meta,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        bounds,
        events,
        onShapeBlur,
        onShapeChange,
      },
      ref
    ) => {
      const { id, size, style, label = "" } = shape;
      const font = getFontStyle(style);
      const styles = getShapeStyle(style, meta.isDarkMode);

      const handleLabelChange = React.useCallback(
        (label: string) => onShapeChange?.({ id, label }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onShapeChange]
      );

      const textAlign = getTextAlign(style.textAlign);
      const verticalAlign = getTextVerticalAlign(style.verticalTextAlign);

      return (
        <FullWrapper ref={ref} {...events}>
          <TextLabel
            isEditing={isEditing}
            onChange={handleLabelChange}
            onBlur={onShapeBlur}
            font={font}
            fontStyle={style.fontStyle}
            text={label}
            color={styles.isFilled ? styles.fill : "rgba(0,0,0,0)"}
            opacity={style.isOpacityVisible ? style.transparency! / 100 : 1}
            textAlign={textAlign}
            fontStrokeWidth={styles.isStroked ? styles.strokeWidth : undefined}
            verticalAlign={verticalAlign}
            fontStrokeColor={style.strokeColor}
            outerShadow={style.outerShadow}
            outerShadowColor={style.outerShadowColor}
            isOuterShadowVisible={style.isOuterShadowVisible}
            // elementSize={size}
          />
          <SVGContainer id={shape.id + "_svg"} opacity={isGhost ? GHOSTED_OPACITY : 1}>
            {isBinding && <BindingIndicator strokeWidth={styles.strokeWidth!} size={size} />}
          </SVGContainer>
        </FullWrapper>
      );
    }
  );

  Indicator = TDShapeUtil.Indicator<T>(({ shape }) => {
    const { id, style, size } = shape;

    const styles = getShapeStyle(style, false);
    const sw = styles.strokeWidth!;

    if (style.dash === DashStyle.Draw) {
      return <path d={getRectangleIndicatorPathTDSnapshot(id, style, size)} />;
    }

    return (
      <rect x={sw / 2} y={sw / 2} rx={1} ry={1} width={Math.max(1, size[0]) - sw} height={Math.max(1, size[1] - sw)} />
    );
  });

  getBounds = (shape: T) => {
    return getBoundsRectangle(shape, this.boundsCache);
  };

  shouldRender = (prev: T, next: T) => {
    return next.size !== prev.size || next.style !== prev.style || next.label !== prev.label;
  };

  transform = transformRectangle;

  transformSingle = transformSingleRectangle;
}

const FullWrapper = styled("div", { width: "100%", height: "100%" });
