export default function DrawingsIcon({ className }) {
  return (
    <svg
      className={className}
      width="10px"
      height="10px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 10 10"
    >
      <path
        d="M10,1.88C9.98,1.96,9.96,2.05,9.93,2.14c-0.08,0.27-0.22,0.5-0.41,0.7C7.48,4.88,5.43,6.92,3.38,8.96
	C3.33,9.02,3.24,9.07,3.16,9.1C2.29,9.39,1.42,9.68,0.55,9.97c-0.25,0.08-0.48-0.02-0.54-0.26C-0.01,9.63,0,9.53,0.03,9.45
	C0.25,8.76,0.48,8.08,0.71,7.4C0.77,7.21,0.83,7.02,0.9,6.84c0.03-0.08,0.08-0.17,0.14-0.23c2.03-2.03,4.06-4.07,6.1-6.1
	C7.4,0.24,7.72,0.07,8.09,0.02C8.1,0.02,8.11,0.01,8.12,0C8.26,0,8.4,0,8.53,0c0.02,0.01,0.04,0.01,0.05,0.02
	C9.26,0.14,9.7,0.52,9.92,1.17c0.03,0.1,0.05,0.2,0.08,0.3C10,1.6,10,1.74,10,1.88z M3.08,8.13C4.81,6.4,6.55,4.66,8.28,2.94
	c-0.4-0.4-0.81-0.82-1.22-1.22C5.33,3.45,3.59,5.18,1.86,6.91C2.27,7.32,2.68,7.73,3.08,8.13z M8.86,2.38
	c0.3-0.26,0.43-0.58,0.31-0.96C9.07,1.05,8.81,0.84,8.43,0.79c-0.33-0.04-0.6,0.1-0.81,0.34C8.04,1.55,8.45,1.96,8.86,2.38z
	 M1.45,7.66C1.3,8.1,1.16,8.54,1.01,8.99c0.46-0.15,0.9-0.3,1.33-0.44C2.04,8.25,1.75,7.96,1.45,7.66z"
      />
    </svg>
  );
}
