export const style = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  wrapperText: {
    display: "flex",
    justifyContent: "center",
    width: "130px",
    marginLeft: "10px",
  },
  belowText: {
    fontSize: "12px",
    marginTop: "4px",
  },
  errorText: {
    color: "#FC5555",
    maxWidth: "300px",
  },
  amountOffText: {
    color: "#707087",
  },
  percentOffText: {
    color: "#011533",
  },
  applyButton: {
    marginLeft: "10px",
    marginRight: "20px",
  },
};
