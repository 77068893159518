export const styles = {
  dialog: {
    "& .MuiDialogContent-root, .MuiDialogTitle-root, .MuiDialogActions-root": {
      padding: 0,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",

    "& > h2": {
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  contentWrap: {
    padding: "0 32px 48px",
    width: "600px",
  },
  contentWrapDM: {
    padding: "0 20px 32px",
    width: "498px",
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    color: " #011533",
    marginBottom: "32px",
    textAlign: "center",
  },
  classNameInput: {
    width: "388px",
    borderRadius: "8px",
    background: "#FFFFFF",

    "& input": {
      color: "#00112B",
    },

    "& fieldset": {
      border: "1px solid #E2E2FC",
      borderRadius: "8px",
    },
  },
  classNameInputDM: {
    width: "428px",
    borderRadius: "8px",
    background: "#FFFFFF",

    "& input": {
      color: "#00112B",
    },

    "& fieldset": {
      border: "1px solid #E2E2FC",
      borderRadius: "8px",
    },
  },
  error: {
    color: "#F90606",
    marginTop: "8px",
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },

  nextBtnWrapper: {
    display: "flex",
    justifyContent: "end",
    margin: "26px 0 0",
  },
  navigationBtn: {
    cursor: "pointer",
    border: "1px solid #273754",
    borderRadius: "16px",
    padding: "14px 52px",
    color: "#00112B",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    margin: "0 6px",
    alignItems: "center",
    display: "flex",
    position: "relative",

    "&:focus-visible": {
      outline: "none",
      transform: "scale(1.02)",
    },
  },

  navigationActive: {
    background: "#EE4360",
    border: "none",
    paddingRight: "65px",
    color: "#FFFFFF",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },

  navigationActiveEDDM: {
    background: "#EE4360",
    border: "none",
    color: "#FFFFFF",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },

  navigationDisabled: {
    background: "#C2C8D1",
    border: "none",
    paddingRight: "60px",
    color: "#FFFFFF",
    cursor: "default",
  },

  navigationDisabledEDDM: {
    background: "#C2C8D1",
    border: "none",
    color: "#FFFFFF",
    cursor: "default",
  },

  inputLabel: {
    fontSize: "14px",
    color: "#011533",
    marginBottom: "5px",
  },
  inputWrap: {
    width: "428px",
    marginBottom: "10px",
  },
  closeIcon: {
    "& svg": {
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
  },
  warningWrapper: {
    display: "flex",
    border: "1px solid #FA9601",
    background: "#FFFAF2",
    padding: "10px 7px 10px 12px",
    borderRadius: "4px",
    fontSize: "14px",
  },
  warningIcon: {
    display: "flex",
    marginRight: "8px",
  },
  mt20: { marginTop: "20px" },
};
