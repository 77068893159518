import CONFIG from "config/config";
import { authorization } from "../auth";

/**
 * API client to communicate with OPPIZI API
 */
class APIClient {
  /**
   * API Path
   * @type Object
   */
  path = {
    payment: "/payment",
    locations: "/locations",
    locationTypes: "/location-types",
    couponCode: "/get-coupons",
    promotionCode: "/get-promotion_code_id",
    customer: "/get-customer",
  };

  /**
   * Prepare request
   * @returns {Request}
   */
  prepareRequest(url, method = "GET", body = null) {
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": authorization.getAPIKey(),
    });

    const initParam = { method, headers };

    if (body) {
      initParam["body"] = JSON.stringify(body);
    }

    /* eslint-disable */
    console.log(`Request: [${method}] ${url}`);
    /* eslint-enable */

    return new Request(url, initParam);
  }

  checkoutCampaign = ({
    campaignId,
    clientId,
    campaignName,
    grand,
    currencyCode,
    countryCode,
    promotionId,
    customerId,
    userId,
    isDM,
  }) => {
    const request = this.prepareRequest(`${CONFIG.PAYMENT_BASE_URL}${this.path.payment}`, "POST", {
      campaignId,
      clientId,
      campaignName,
      grand,
      currencyCode,
      countryCode,
      promotionId,
      customerId,
      userId,
      isDM,
    });
    return fetch(request);
  };

  getCouponCode = ({ couponCode, countryCode }) => {
    const request = this.prepareRequest(
      `${CONFIG.PAYMENT_BASE_URL}${this.path.couponCode}/?id=${couponCode}&countryCode=${countryCode}`,
      "GET"
    );
    return fetch(request);
  };

  getPromotionCode = ({ promotionCode, countryCode }) => {
    const request = this.prepareRequest(
      `${CONFIG.PAYMENT_BASE_URL}${this.path.promotionCode}/?promotionCode=${promotionCode}&countryCode=${countryCode}`,
      "GET"
    );
    return fetch(request);
  };

  getCustomer = ({ id, countryCode }) => {
    const request = this.prepareRequest(
      `${CONFIG.PAYMENT_BASE_URL}${this.path.customer}/?id=${id}&countryCode=${countryCode}`,
      "GET"
    );
    return fetch(request);
  };

  getLocationTypes = () => {
    const request = this.prepareRequest(`${CONFIG.API_BASE_URL}${this.path.locationTypes}`, "GET");
    return fetch(request);
  };

  createLocation = (body) => {
    const request = this.prepareRequest(`${CONFIG.API_BASE_URL}${this.path.locations}`, "POST", body);
    return fetch(request);
  };

  deleteLocation = (body) => {
    const request = this.prepareRequest(`${CONFIG.API_BASE_URL}${this.path.locations}`, "DELETE", body);
    return fetch(request);
  };

  updateLocation = (body, locationId) => {
    const request = this.prepareRequest(`${CONFIG.API_BASE_URL}${this.path.locations}/${locationId}`, "PUT", body);
    return fetch(request);
  };
}
export const apiClient = new APIClient();
