const style = {
  modalWrapper: {
    width: "100vw",
    height: "100vh",
    position: "absolute" as const,
    top: -80,
    left: 0,
    zIndex: 999,
  },
  disableMargin: {
    top: 0,
  },
  backdropFilter: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modal: {
    width: 1210,
    height: 752,
    backgroundColor: "#F9F9FF",
    zIndex: 1,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column" as const,
    padding: "23px",
    borderRadius: 4,
  },
  header: {
    fontFamily: "Proxima Nova Alt",
    fontWeight: "bold" as const,
    fontSize: "28px",
    alignSelf: "center",
    marginTop: 40,
    color: "#011533",
  },
  pdfPages: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  closeButton: {
    position: "absolute" as const,
    top: 23,
    right: 23,
    opacity: 0.5,
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
  additionalMargin: {
    "& img": {
      margin: 10,
    },
  },
};

export default style;
