import React from "react";

interface Props {
  className?: string;
}

const DownArrowIcon: React.FC<Props> = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="10px"
      height="10px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 255 255"
    >
      <polygon points="0,63.75 127.5,191.25 255,63.75   " />
    </svg>
  );
};

export default DownArrowIcon;
