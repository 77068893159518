export const EllipseIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5837 7.99992C12.5837 11.4324 10.3841 13.9166 8.00033 13.9166C5.61654 13.9166 3.41699 11.4324 3.41699 7.99992C3.41699 4.56741 5.61654 2.08325 8.00033 2.08325C10.3841 2.08325 12.5837 4.56741 12.5837 7.99992Z"
        stroke="#0C172C"
        strokeWidth="1.5"
      />
    </svg>
  );
};
