import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import { MoveType, TDShape, TDShapeType, TldrawCommand } from "pages/flyer/builder/drawer/types";

export function reorderShapes(
  app: TldrawApp,
  ids: string[],
  type: MoveType,
  toPosition?: string,
  isBefore?: boolean,
  isDropToGap?: boolean
): TldrawCommand {
  const { currentPageId, page } = app;
  // Get the unique parent idsToMove for the selected elements
  const parentIds = new Set(ids.map((id) => app.getShape(id).parentId));
  let idsToMove = [...ids];
  let result: {
    before: Record<string, Partial<TDShape>>;
    after: Record<string, Partial<TDShape>>;
  } = { before: {}, after: {} };

  if (type === MoveType.ToPosition && toPosition) {
    if (
      // app.getShape(toPosition!).type === TDShapeType.Group ||
      app.getShape(toPosition!).parentId !== app.currentPageId
    ) {
      idsToMove = ids.filter((id) => app.getShape(id).type !== TDShapeType.QRCode);
    }
    const makeTree = () => {
      const nodes = Object.values(app.getPage().shapes)
        .filter((layer) => !layer.isTemplate && layer.type !== TDShapeType.QRCode)
        .sort((a, b) => b.childIndex - a.childIndex);

      const tree: any = [];
      let currentIndex = 0;
      const generateTreeForGroup = (groupNode: any) => {
        groupNode.children = [...groupNode.children];
        groupNode.children.sort((nodeAId: string, nodeBId: string) => {
          const nodeA = nodes.find((node) => node.id === nodeAId)!;
          const nodeB = nodes.find((node) => node.id === nodeBId)!;
          return nodeB.childIndex - nodeA.childIndex;
        });
        groupNode.children.forEach((childId: any) => {
          const child = nodes.find((node) => node.id === childId)!;
          if (child.type === TDShapeType.Group) {
            generateTreeForGroup(child);
          }
          tree.push(childId);
          currentIndex += 1;
        });
      };

      nodes.forEach((node) => {
        if (node.parentId === app.currentPageId) {
          const element = { ...node, title: node.name, key: node.id };
          tree.push(node.id);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          currentIndex += 1;
          if (element.type === TDShapeType.Group) {
            generateTreeForGroup(element);
          }
        }
      });
      return tree;
    };

    const nodesInTree = makeTree();
    const nonMovedNodes = nodesInTree.filter((id: string) => {
      if (!idsToMove.includes(id)) {
        return true;
      }
      return false;
    });
    const indexToWherePutElements = isBefore ? 0 : nonMovedNodes.findIndex((id: string) => id === toPosition) + 1;
    nonMovedNodes.splice(indexToWherePutElements, 0, ...idsToMove);
    const nodeOnDragPosition = app.getShape(toPosition);
    result = TLDR.mutateShapes(
      app.state,
      nonMovedNodes,
      (node, index) => {
        const changedValues: Partial<TDShape> = {};

        // change parentId for nodes which moved into group
        if (
          ((nodeOnDragPosition.type === TDShapeType.Group && !isDropToGap) ||
            nodeOnDragPosition.parentId !== app.page.id) &&
          idsToMove.includes(node.id)
        ) {
          if (nodeOnDragPosition.type === TDShapeType.Group) {
            // if (isBefore) {
            //   changedValues.parentId = nodeOnDragPosition.parentId;
            // } else {
            changedValues.parentId = nodeOnDragPosition.id;
            // }
          } else {
            changedValues.parentId = nodeOnDragPosition.parentId;
          }
        }

        // change childArray if elements moved into group
        if (
          nodeOnDragPosition.parentId === node.id && // check is right node for changes?
          app.getShape(nodeOnDragPosition.parentId).children &&
          !app.getShape(nodeOnDragPosition.parentId).children?.includes(node.id)
        ) {
          const childrenArray = [...app.getShape(nodeOnDragPosition.parentId).children!];
          idsToMove.forEach((id) => {
            if (!childrenArray.includes(id)) {
              childrenArray.push(id);
            }
          });
          changedValues.children = childrenArray;
        }

        // change childArray if elements moved into group on top
        if (
          nodeOnDragPosition.type === TDShapeType.Group &&
          // isBefore &&
          !isDropToGap &&
          nodeOnDragPosition.id === node.id && // check is right node for changes?
          nodeOnDragPosition.children &&
          !nodeOnDragPosition.children.includes(node.id)
        ) {
          const childrenArray = [...nodeOnDragPosition.children];
          idsToMove.forEach((id) => {
            if (!childrenArray.includes(id)) {
              childrenArray.push(id);
            }
          });
          changedValues.children = childrenArray;
        }

        // remove item from old group
        if (
          node.type === TDShapeType.Group &&
          node.children.some((childId) => idsToMove.includes(childId)) &&
          !(node.children.includes(nodeOnDragPosition.id) || (node.id === nodeOnDragPosition.id && !isBefore))
        ) {
          const previousChild = node.children;
          const newChildArray = previousChild.filter((child) => !idsToMove.includes(child));

          changedValues.children = newChildArray;
        }

        // change parrent id  for elements which moved out from group to top level
        if (
          nodeOnDragPosition.parentId === app.page.id &&
          (nodeOnDragPosition.type !== TDShapeType.Group || isBefore) &&
          idsToMove.includes(node.id)
        ) {
          changedValues.parentId = app.page.id;
        }

        changedValues.childIndex = nonMovedNodes.length - index;
        return changedValues;
      },
      currentPageId
    );

    const afterShapesIds = Object.keys(result.after);
    const idsToMoveToDelete: Array<string> = [];

    afterShapesIds.forEach((shapeId) => {
      if (result.after[shapeId].children && result.after[shapeId].children!.length === 0) {
        idsToMoveToDelete.push(shapeId);
      }
    });

    idsToMoveToDelete.forEach((id) => {
      result.after[id] = undefined as unknown as Partial<TDShape>;
    });

    // return app
  }

  let startIndex: number;
  let startChildIndex: number;
  let step: number;

  // Collect shapes with common parents into a table under their parent id
  Array.from(parentIds.values()).forEach((parentId) => {
    let sortedChildren: TDShape[] = [];
    if (parentId === page.id) {
      sortedChildren = Object.values(page.shapes)
        .filter((layer) => !layer.isTemplate && layer.type !== TDShapeType.QRCode)
        .sort((a, b) => a.childIndex - b.childIndex);
    } else {
      const parent = app.getShape(parentId);
      if (!parent.children) throw Error("No children in parent!");

      sortedChildren = parent.children
        .map((childId) => app.getShape(childId))
        .sort((a, b) => a.childIndex - b.childIndex);
    }

    const sortedChildIds = sortedChildren.map((shape) => shape.id);

    const sortedIndicesToMove = idsToMove
      .filter((id) => sortedChildIds.includes(id))
      .map((id) => sortedChildIds.indexOf(id))
      .sort((a, b) => a - b);

    if (sortedIndicesToMove.length === sortedChildIds.length) return;

    switch (type) {
      case MoveType.ToBack: {
        //               a       b  c
        // Initial   1   2    3  4  5  6  7
        // Final   .25  .5  .75  1  3  6  7
        //           a   b    c

        // Find the lowest "open" index
        for (let i = 0; i < sortedChildIds.length; i++) {
          if (sortedIndicesToMove.includes(i)) continue;
          startIndex = i;
          break;
        }

        // Find the lowest child index that isn't in sortedIndicesToMove
        startChildIndex = sortedChildren[startIndex].childIndex;

        // Find the step for each additional child
        step = startChildIndex / (sortedIndicesToMove.length + 1);

        // Get the results of moving the selected shapes below the first open index's shape
        result = TLDR.mutateShapes(
          app.state,
          sortedIndicesToMove.map((i) => sortedChildren[i].id).reverse(),
          (_shape, i) => ({
            childIndex: startChildIndex - (i + 1) * step,
          }),
          currentPageId
        );

        break;
      }
      case MoveType.ToFront: {
        //              a     b  c
        // Initial   1  2  3  4  5  6   7
        // Final     1  3  6  7  8  9  10
        //                       a  b   c

        // Find the highest "open" index
        for (let i = sortedChildIds.length - 1; i >= 0; i--) {
          if (sortedIndicesToMove.includes(i)) continue;
          startIndex = i;
          break;
        }

        // Find the lowest child index that isn't in sortedIndicesToMove
        startChildIndex = sortedChildren[startIndex].childIndex;

        // Find the step for each additional child
        step = 1;

        // Get the results of moving the selected shapes below the first open index's shape
        result = TLDR.mutateShapes(
          app.state,
          sortedIndicesToMove.map((i) => sortedChildren[i].id),
          (_shape, i) => ({
            childIndex: startChildIndex + (i + 1),
          }),
          currentPageId
        );

        break;
      }
      case MoveType.Backward: {
        //               a           b  c
        // Initial    1  2     3     4  5  6  7
        // Final     .5  1  1.66  2.33  3  6  7
        //           a         b     c

        const indexMap: Record<string, number> = {};

        // Starting from the top...
        for (let i = sortedChildIds.length - 1; i >= 0; i--) {
          // If we found a moving index...
          if (sortedIndicesToMove.includes(i)) {
            for (let j = i; j >= 0; j--) {
              // iterate downward until we find an open spot
              if (!sortedIndicesToMove.includes(j)) {
                // i = the index of the first closed spot
                // j = the index of the first open spot

                const endChildIndex = sortedChildren[j].childIndex;
                let startChildIndex: number;
                let step: number;

                if (j === 0) {
                  // We're moving below the first child, start from
                  // half of its child index.

                  startChildIndex = endChildIndex / 2;
                  step = endChildIndex / 2 / (i - j + 1);
                } else {
                  // Start from the child index of the child below the
                  // child above.
                  startChildIndex = sortedChildren[j - 1].childIndex;
                  step = (endChildIndex - startChildIndex) / (i - j + 1);
                  startChildIndex += step;
                }

                for (let k = 0; k < i - j; k++) {
                  indexMap[sortedChildren[j + k + 1].id] = startChildIndex + step * k;
                }

                break;
              }
            }
          }
        }

        if (Object.values(indexMap).length > 0) {
          // Get the results of moving the selected shapes below the first open index's shape
          result = TLDR.mutateShapes(
            app.state,
            sortedIndicesToMove.map((i) => sortedChildren[i].id),
            (shape) => ({
              childIndex: indexMap[shape.id],
            }),
            currentPageId
          );
        }

        break;
      }
      case MoveType.Forward: {
        //             a     b c
        // Initial   1 2   3 4 5 6 7
        // Final     1 3 3.5 6 7 8 9
        //                 a     b c

        const indexMap: Record<string, number> = {};

        // Starting from the top...
        for (let i = 0; i < sortedChildIds.length; i++) {
          // If we found a moving index...
          if (sortedIndicesToMove.includes(i)) {
            // Search for the first open spot above this one
            for (let j = i; j < sortedChildIds.length; j++) {
              if (!sortedIndicesToMove.includes(j)) {
                // i = the low index of the first closed spot
                // j = the high index of the first open spot

                startChildIndex = sortedChildren[j].childIndex;

                const step =
                  j === sortedChildIds.length - 1
                    ? 1
                    : (sortedChildren[j + 1].childIndex - startChildIndex) / (j - i + 1);

                for (let k = 0; k < j - i; k++) {
                  indexMap[sortedChildren[i + k].id] = startChildIndex + step * (k + 1);
                }

                break;
              }
            }
          }
        }

        if (Object.values(indexMap).length > 0) {
          // Get the results of moving the selected shapes below the first open index's shape
          result = TLDR.mutateShapes(
            app.state,
            sortedIndicesToMove.map((i) => sortedChildren[i].id),
            (shape) => ({
              childIndex: indexMap[shape.id],
            }),
            currentPageId
          );
        }

        break;
      }
    }
  });

  return {
    id: "move",
    before: {
      document: {
        pages: {
          [currentPageId]: { shapes: result.before },
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: idsToMove,
          },
        },
      },
    },
    after: {
      document: {
        pages: {
          [currentPageId]: { shapes: result.after },
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: idsToMove,
          },
        },
      },
    },
  };
}
