const calculateTaxAndTotalCost = ({
  countryTaxRate,
  subtotal,
  amountOff,
}: {
  countryTaxRate: number;
  subtotal: number;
  amountOff: number | null;
}) => {
  const subtotalWithDiscount = amountOff && amountOff < subtotal ? subtotal - amountOff : subtotal;
  const tax = Number(((subtotalWithDiscount * countryTaxRate) / 100).toFixed(2));

  const totalCost: number = subtotalWithDiscount + tax;

  return { tax, totalCost };
};

export { calculateTaxAndTotalCost };
