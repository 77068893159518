import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import cn from "classnames";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { AddressCard } from "./AddressCard";
import { styles } from "./styles";

export const Accordion = ({ children }) => {
  return <div className="accordion">{children}</div>;
};

export const AccordionTrigger = ({ children, onClick }) => {
  return (
    <div className="accordion-trigger" onClick={onClick}>
      {children}
    </div>
  );
};

export const AccordionDetails = ({ children, isOpen }) => {
  return <div className={cn("accordion-details", { open: isOpen })}>{children}</div>;
};

const useStyles = makeStyles(() => styles);

export const AddressBlock = ({ addresses, onAddressCardClick, handleDeleteLocation }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const classes = useStyles();
  return addresses && addresses.length ? (
    <div>
      <div className={cn(classes.addressesContainer, "mob-hidden")}>
        <div className={classes.title}>Addresses</div>
        {addresses.map((address) => {
          return (
            <AddressCard
              address={address}
              key={address.name}
              onClick={() => onAddressCardClick(address)}
              handleDeleteLocation={handleDeleteLocation}
            />
          );
        })}
      </div>
      <Accordion>
        <div className={cn(classes.addressesContainer, "mob-address-container desktop-hidden")}>
          <AccordionTrigger onClick={handleToggle}>
            <div className={classes.title}>
              Addresses list
              <KeyboardArrowDownIcon fontSize="medium" className={cn({ open: isOpen })} />
            </div>
          </AccordionTrigger>
          <AccordionDetails isOpen={isOpen}>
            {addresses.map((address) => {
              return (
                <AddressCard
                  address={address}
                  key={address.name}
                  onClick={() => onAddressCardClick(address)}
                  handleDeleteLocation={handleDeleteLocation}
                />
              );
            })}
          </AccordionDetails>
        </div>
      </Accordion>
    </div>
  ) : null;
};
