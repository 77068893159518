import { Trigger } from "@radix-ui/react-dropdown-menu";
import { ToolButton, ToolButtonProps } from "pages/flyer/builder/drawer/components/Primitives/ToolButton";
import * as React from "react";

interface DMTriggerIconProps extends ToolButtonProps {
  children: React.ReactNode;
}

export function DMTriggerIcon({ children, ...rest }: DMTriggerIconProps) {
  return (
    <Trigger asChild>
      <ToolButton {...rest}>{children}</ToolButton>
    </Trigger>
  );
}
