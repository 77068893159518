export const getRedirectedCampaigns = (campaignId) => {
  const redirectedCampaigns = localStorage.getItem("skipDesignCampaigns");
  const parsedRedirectedCampaigns = redirectedCampaigns ? JSON.parse(redirectedCampaigns) : null;

  let readyToUpload = false;
  if (parsedRedirectedCampaigns) {
    const currentCampaign = parsedRedirectedCampaigns.find((campaign) => campaign.campaignId === campaignId);
    readyToUpload = currentCampaign ? currentCampaign.wasRedirected : false;
  }

  return readyToUpload;
};

export const deleteRedirectedCampaign = (campaignId) => {
  const redirectedCampaigns = localStorage.getItem("skipDesignCampaigns");
  const parsedRedirectedCampaigns = redirectedCampaigns ? JSON.parse(redirectedCampaigns) : null;
  if (parsedRedirectedCampaigns) {
    const filteredCampaigns = parsedRedirectedCampaigns.filter((campaign) => campaign.campaignId !== campaignId);
    localStorage.setItem("skipDesignCampaigns", JSON.stringify(filteredCampaigns));
  }
};
