export const RectangleIcon = (props) => {
  return (
    <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x="1.625"
        y="15.8333"
        width="11.6667"
        height="9.75"
        rx="2"
        transform="rotate(-90 1.625 15.8333)"
        stroke="#2E3860"
        strokeWidth="1.4"
      />
    </svg>
  );
};
