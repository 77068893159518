import { Item } from "@radix-ui/react-dropdown-menu";
import { RowButton, RowButtonProps } from "pages/flyer/builder/drawer/components/Primitives/RowButton";
import * as React from "react";

export function DMItem({ onSelect, ...rest }: RowButtonProps & { onSelect?: (event: Event) => void }): JSX.Element {
  return (
    <Item dir="ltr" asChild onSelect={onSelect}>
      <RowButton {...rest} />
    </Item>
  );
}
