export const styles = {
  loaderContainer: { display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" },
  pdfPreviewContainer: { height: "calc(100vh - 180px)", width: "97%", overflow: "scroll", direction: "rtl" },
  pdfPreviewContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  sideTitle: {
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    color: "rgba(112, 112, 135, 1)",
    marginBottom: "10px",
  },
  previewWrapper: { position: "relative" },
};
