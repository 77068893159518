export const style = {
  wrapper: {
    color: "#011533",
  },

  header: {
    display: "flex",
    justifyContent: "center",
    position: "relative" as const,
    paddingTop: "16px",
  },

  txtWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as const,
    width: "250px",
    margin: "0 16px",
  },

  headerTxt: {
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "8px",
    textAlign: "center" as const,
  },

  infoTxt: {
    fontSize: "12px",
    fontWeight: 600,
    marginTop: "8px",
    textAlign: "center" as const,
  },

  expTxt: {
    fontSize: "12px",
    fontWeight: 400,
    marginTop: "16px",
    textAlign: "center" as const,
  },

  closeButton: {
    position: "absolute" as const,
    top: "12px",
    right: "12px",
    cursor: "pointer",
  },

  getInTouchBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "140px",
    margin: "24px 0",
    padding: "5px 8px",
    backgroundColor: "#EE4360",
    borderRadius: "12px",
    color: "#ffffff",
    cursor: "pointer",
  },

  getInTouchTxt: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
  },
};
