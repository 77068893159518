export const Ellipse = ({ stroke, ...props }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse
        cx="7"
        cy="6.99459"
        rx="6"
        ry="5.82857"
        stroke={stroke || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="2 4"
      />
    </svg>
  );
};
