const style = {
  progress: {
    "& .react-progress-bar-percent": {
      background: "#ff2929",
      boxShadow: "0 0 10px #ff2929, 0 0 5px #ff2929",
    },
  },
};

export default style;
