import { Arrow, Content, Root, TriggerItem } from "@radix-ui/react-dropdown-menu";
import { MenuContent } from "pages/flyer/builder/drawer/components/Primitives/MenuContent";
import { RowButton } from "pages/flyer/builder/drawer/components/Primitives/RowButton";
import * as React from "react";

export interface DMSubMenuProps {
  label: string;
  size?: "small";
  disabled?: boolean;
  children: React.ReactNode;
}

export function DMSubMenu({ children, size, disabled = false, label }: DMSubMenuProps): JSX.Element {
  return (
    <Root dir="ltr">
      <TriggerItem dir="ltr" asChild>
        <RowButton disabled={disabled} hasArrow>
          {label}
        </RowButton>
      </TriggerItem>
      <Content dir="ltr" asChild sideOffset={2} alignOffset={-2}>
        <MenuContent size={size}>
          {children}
          <Arrow offset={13} />
        </MenuContent>
      </Content>
    </Root>
  );
}
