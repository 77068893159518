const SvgInfoEmpty = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.664062 8.00008C0.664062 12.0502 3.94731 15.3334 7.9974 15.3334C12.0475 15.3334 15.3307 12.0502 15.3307 8.00008C15.3307 3.94999 12.0475 0.666748 7.9974 0.666748C3.94731 0.666748 0.664062 3.94999 0.664062 8.00008ZM13.9974 8.00008C13.9974 11.3138 11.3111 14.0001 7.9974 14.0001C4.68369 14.0001 1.9974 11.3138 1.9974 8.00008C1.9974 4.68637 4.68369 2.00008 7.9974 2.00008C11.3111 2.00008 13.9974 4.68637 13.9974 8.00008ZM8.66647 9.33231H9.3327V10.6656H6.66603V9.33231H7.3327V7.99898H6.66603V6.66565H8.66647V9.33231ZM8.6645 5.33231C8.6645 5.7005 8.36593 5.99898 7.99762 5.99898C7.6293 5.99898 7.33073 5.7005 7.33073 5.33231C7.33073 4.96412 7.6293 4.66565 7.99762 4.66565C8.36593 4.66565 8.6645 4.96412 8.6645 5.33231Z"
      fill="#1D2943"
    />
  </svg>
);

export default SvgInfoEmpty;
