import cn from "classnames";
import { formatNumber } from "utils/formators";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { SettingsIcon } from "components/icons/components/Settings";
import { useStore } from "store";
import { getDMFlyersAmount } from "dm-campaign-data-hoc/utils";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const SummaryBlock = ({ selectedRoutes, campaignName }) => {
  const { costsCalculationData } = useStore();
  const { flyersAmount, detailedCost } = costsCalculationData;
  const { subtotal } = detailedCost;

  const classes = useStyles();

  return (
    <div className={cn(classes.summaryBlockContainer, "mob-summary-container")}>
      <div className={classes.summaryHeaderWrapper}>
        <span>{campaignName}</span>
        {/* <SettingsIcon className={classes.settingsButton} /> */}
      </div>
      <div className={classes.amountsWrapper}>
        <div className={classes.amountSection}>
          <span className={classes.amount}>{selectedRoutes.length}</span>
          <span>Routes</span>
        </div>
        <div className={classes.separator} />
        <div className={classes.amountSection}>
          <span className={classes.amount}>{formatNumber(flyersAmount)}</span>
          <span>Addresses</span>
        </div>
        <div className={classes.separator} />
        <div className={classes.amountSection}>
          <span className={classes.amount}>${formatNumber(subtotal)}</span>
          <span>Costs</span>
        </div>
      </div>
    </div>
  );
};
