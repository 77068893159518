import { ArrowTool } from "pages/flyer/builder/drawer/state/tools/ArrowTool";
import { DrawTool } from "pages/flyer/builder/drawer/state/tools/DrawTool";
import { EllipseTool } from "pages/flyer/builder/drawer/state/tools/EllipseTool";
import { EraseTool } from "pages/flyer/builder/drawer/state/tools/EraseTool";
import { LineTool } from "pages/flyer/builder/drawer/state/tools/LineTool";
import { RectangleTool } from "pages/flyer/builder/drawer/state/tools/RectangleTool";
import { SelectTool } from "pages/flyer/builder/drawer/state/tools/SelectTool";
import { TextAreaTool } from "pages/flyer/builder/drawer/state/tools/TextAreaTool";
import { TriangleTool } from "pages/flyer/builder/drawer/state/tools/TriangleTool";
import { TDShapeType, TDToolType } from "pages/flyer/builder/drawer/types";

export interface ToolsMap {
  select: typeof SelectTool;
  erase: typeof EraseTool;
  // [TDShapeType.Text]: typeof TextTool;
  [TDShapeType.TextArea]: typeof TextAreaTool;
  [TDShapeType.Draw]: typeof DrawTool;
  [TDShapeType.Ellipse]: typeof EllipseTool;
  [TDShapeType.Rectangle]: typeof RectangleTool;
  [TDShapeType.Triangle]: typeof TriangleTool;
  [TDShapeType.Line]: typeof LineTool;
  [TDShapeType.Arrow]: typeof ArrowTool;
  [TDShapeType.Code]: typeof TextAreaTool;
  [TDShapeType.QRCode]: typeof RectangleTool;
}

export type ToolOfType<K extends TDToolType> = ToolsMap[K];

export type ArgsOfType<K extends TDToolType> = ConstructorParameters<ToolOfType<K>>;

export const tools: { [K in TDToolType]: ToolsMap[K] } = {
  select: SelectTool,
  erase: EraseTool,
  // [TDShapeType.Text]: TextTool,
  [TDShapeType.TextArea]: TextAreaTool,
  [TDShapeType.Draw]: DrawTool,
  [TDShapeType.Ellipse]: EllipseTool,
  [TDShapeType.Rectangle]: RectangleTool,
  [TDShapeType.Triangle]: TriangleTool,
  [TDShapeType.Line]: LineTool,
  [TDShapeType.Arrow]: ArrowTool,
  [TDShapeType.Code]: TextAreaTool,
  [TDShapeType.QRCode]: RectangleTool,
};
