import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { CircleIcon, SquareIcon, VercelLogoIcon } from "@radix-ui/react-icons";
import { LineIcon } from "pages/flyer/builder/drawer/components/Primitives/icons";
import { Panel } from "pages/flyer/builder/drawer/components/Primitives/Panel";
import { ToolButton } from "pages/flyer/builder/drawer/components/Primitives/ToolButton";
import { Tooltip } from "pages/flyer/builder/drawer/components/Primitives/Tooltip";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { TDShapeType, TDToolType } from "pages/flyer/builder/drawer/types";
import * as React from "react";

interface ShapesMenuProps {
  activeTool: TDToolType;
  isToolLocked: boolean;
}

type ShapeShape = TDShapeType.Rectangle | TDShapeType.Ellipse | TDShapeType.Triangle | TDShapeType.Line;

const shapeShapes: ShapeShape[] = [TDShapeType.Rectangle, TDShapeType.Ellipse, TDShapeType.Triangle, TDShapeType.Line];

const shapeShapeIcons = {
  [TDShapeType.Rectangle]: <SquareIcon />,
  [TDShapeType.Ellipse]: <CircleIcon />,
  [TDShapeType.Triangle]: <VercelLogoIcon />,
  [TDShapeType.Line]: <LineIcon />,
};

export const ShapesMenu = React.memo(function ShapesMenu({ activeTool, isToolLocked }: ShapesMenuProps) {
  const app = useTldrawApp();

  const [lastActiveTool, setLastActiveTool] = React.useState<ShapeShape>(TDShapeType.Rectangle);

  React.useEffect(() => {
    if (shapeShapes.includes(activeTool as ShapeShape) && lastActiveTool !== activeTool) {
      setLastActiveTool(activeTool as ShapeShape);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTool]);

  const selectShapeTool = React.useCallback(() => {
    app.selectTool(lastActiveTool);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTool, app]);

  const handleDoubleClick = React.useCallback(() => {
    app.toggleToolLock();
  }, [app]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === " ") {
        if (app.shiftKey) {
          e.preventDefault();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isActive = shapeShapes.includes(activeTool as ShapeShape);

  return (
    <DropdownMenu.Root dir="ltr" onOpenChange={selectShapeTool}>
      <DropdownMenu.Trigger dir="ltr" asChild>
        <ToolButton
          disabled={isActive && app.shiftKey} // otherwise this continuously opens and closes on "SpacePanning"
          variant="primary"
          onDoubleClick={handleDoubleClick}
          isToolLocked={isActive && isToolLocked}
          isActive={isActive}
          onKeyDown={handleKeyDown}
        >
          {shapeShapeIcons[lastActiveTool]}
        </ToolButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content asChild dir="ltr" side="top" sideOffset={12}>
        <Panel side="center">
          {shapeShapes.map((shape, i) => (
            <Tooltip key={shape} label={shape[0].toUpperCase() + shape.slice(1)} kbd={(4 + i).toString()}>
              <DropdownMenu.Item asChild>
                <ToolButton
                  variant="primary"
                  onClick={() => {
                    app.selectTool(shape);
                    setLastActiveTool(shape);
                  }}
                >
                  {shapeShapeIcons[shape]}
                </ToolButton>
              </DropdownMenu.Item>
            </Tooltip>
          ))}
        </Panel>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
});
