import CONFIG from "config/config";
import { authorization } from "module/auth";

class IntegrationsServiceClass {
  prepareRequest(url, method = "GET", body = null) {
    const key = authorization.getAPIKey();
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${key}`,
    });

    const initParam = { method, headers };

    if (!url.includes("https:")) {
      url = `${
        process.env.NODE_ENV === "development" ? "http://localhost:4200/dev" : CONFIG.INTEGRATIONS_SERVICE_BASE_URL
      }${url}`;
    }

    if (body) {
      initParam.body = JSON.stringify(body);
    }

    /* eslint-disable */
    console.log(`Request: [${method}] ${url}`);
    /* eslint-enable */

    return new Request(url, initParam);
  }

  async readIntegrations(payload) {
    // const clientId = authorization.getUser().client.id
    const query = payload ? new URLSearchParams(JSON.parse(JSON.stringify(payload))).toString() : null;

    const request = this.prepareRequest(`/integrations${query ? `?${query}` : ""}`, "GET");
    const res = await fetch(request);
    const data = await res.json();
    return data.message;
  }

  async createIntegration(form) {
    const request = this.prepareRequest("/integrations", "POST", {
      form,
    });
    const res = await fetch(request);
    const data = await res.json();
    return data.message;
  }

  async updateIntegration({ integrationId, status }) {
    const request = this.prepareRequest("/integrations", "PUT", {
      integrationId,
      status,
    });
    const res = await fetch(request);
    const data = await res.json();
    return data.message;
  }

  async readForm({ integrationId, type }) {
    if (!integrationId || !type) {
      throw new Error("Integration and type are mandatory");
    }
    const request = this.prepareRequest(`/forms?integrationId=${integrationId}&type=${type}`, "GET");
    const res = await fetch(request);
    const data = await res.json();
    return data.message;
  }

  async readCoupons({ integrationId }) {
    const request = this.prepareRequest(`/coupons?integrationId=${integrationId}`, "GET");
    const res = await fetch(request);
    const data = await res.json();
    return data.message;
  }

  async createCoupons({ integrationId, form }) {
    const request = this.prepareRequest("/coupons", "POST", {
      form,
      integrationId,
    });
    const res = await fetch(request);
    const data = await res.json();
    return data.message;
  }
  async updateCoupons({ integrationId, clientId, payload }) {
    const request = this.prepareRequest("/coupons", "PUT", {
      integrationId,
      clientId,
      payload,
    });
    const res = await fetch(request);
    const data = await res.json();
    return data.message;
  }
}
const IntegrationsService = new IntegrationsServiceClass();
export default IntegrationsService;
