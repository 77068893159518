const style = {
  paper: {
    background: "#FFFFFF",
    height: "calc(100vh - 80px)",
    overflow: "auto",
  },

  notVerifiedContainer: {
    height: "calc(100vh - 128px)",
  },
  scrollWrap: {
    margin: "auto",
  },
  container: {
    padding: "45px 0",
  },
  caption: {
    fontStyle: "italic",
    paddingTop: "5px",
    color: "#B6B6B6",
    fontSize: "0.70rem",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "30px",
    fontFamily: "Proxima Nova",
  },
  nextBtnWrapper: {
    display: "flex",
    justifyContent: "end",
    margin: "26px 0 0",
    width: "100%",
  },
  navigationBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    cursor: "pointer",
    border: "1px solid #273754",
    borderRadius: "16px",
    padding: "14px 52px",
    color: "#00112B",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    margin: "0 6px",

    position: "relative",
  },

  navigationActive: {
    background: "#EE4360",
    border: "none",
    paddingRight: "65px",
    color: "#FFFFFF",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },

  navigationDisabled: {
    background: "#C2C8D1",
    border: "none",
    paddingRight: "60px",
    color: "#FFFFFF",
    cursor: "auto",
  },
};

export default style;
