const SvgSelectionTool = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_11525_76660)">
      <path
        d="M12.8232 11.2483L14.9499 17.0933L11.0349 18.5183L8.90737 12.6733L5.6582 14.7108L7.0082 1.36084L16.6224 10.7208L12.8232 11.2483ZM12.814 16.0967L10.5515 9.88001L13.0182 9.53834L8.3182 4.96334L7.65987 11.4883L9.7682 10.1658L12.0307 16.3825L12.814 16.0967Z"
        fill="#1D2943"
      />
    </g>
    <defs>
      <clipPath id="clip0_11525_76660">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSelectionTool;
