import {
  DocumentOrientation,
  DocumentSettings,
  PossibleFlyerType,
  TDAsset,
  TDBinding,
  TDShape,
} from "pages/flyer/builder/drawer";
import { getFlyerBuilderState } from "graphQL/query/flyerBuilderStates";
import { addFlyerBuilderStates, updateFlyerBuilderStates } from "graphQL/mutations/flyerBuilderStates";
import { convertRecordToGraphQLInputArray, convertDataForLiveStorage, shallowCopyMap } from "./utils";

export class LiveStorage<T extends { roomId: string; flyerType: PossibleFlyerType }> {
  /**
   * we recover canvas status using this storageKeys
   * e.x.
   * {
   *    roomId: string,
   *    campaignId: string
   * }
   */
  private storageKeyObject: T;
  private storageKey: string;
  /**
   * sync server whenever there is change in canvas
   */
  private syncServerFlag = false;
  /**
   * the version number for future migrations
   */
  private version = 0;

  private liveShapes: Map<string, TDShape> | null = null;
  private liveBindings: Map<string, TDBinding> | null = null;
  private liveAssets: Map<string, TDAsset> | null = null;
  private liveDocumentSettings: DocumentSettings;

  private isChangedShapes = false;
  private isChangedBindings = false;
  private isChangedAssets = false;
  private isChangedSettings = false;

  constructor(_storageKeyObject: T) {
    this.storageKeyObject = { ..._storageKeyObject };
    this.storageKey = this.storageKeyObject.roomId; // JSON.stringify(this.storageKeyObject);

    this.liveDocumentSettings = {
      orientation: DocumentOrientation.Portrait,
      format: this.storageKeyObject.flyerType,
      isShowGuidelines: true,
    };
  }

  /**
   * get input data for GraphQL mutation
   * @returns convered input data
   */
  private getGraphQLInputData = () => ({
    shapes: this.liveShapes ? convertRecordToGraphQLInputArray(Object.fromEntries(this.liveShapes.entries())) : [],
    bindings: this.liveBindings
      ? convertRecordToGraphQLInputArray(Object.fromEntries(this.liveBindings.entries()))
      : [],
    assets: this.liveAssets ? convertRecordToGraphQLInputArray(Object.fromEntries(this.liveAssets.entries())) : [],
    documentSettings: this.liveDocumentSettings,
  });

  /**
   * add current states to the server initially
   * nobody can call this function.
   */
  private addToServer = async () => {
    addFlyerBuilderStates({
      key: this.storageKey,
      states: { ...this.getGraphQLInputData() },
    });
  };

  /**
   * after syncing with server, we set all flags to false
   */
  private afterSyncServer = () => {
    if (!this.syncServerFlag) {
      return;
    }

    this.isChangedShapes = false;
    this.isChangedBindings = false;
    this.isChangedAssets = false;
    this.isChangedSettings = false;
  };

  /**
   * check whether there is need to sync with server
   * @returns bool
   */
  public needToSyncServer = () => {
    if (!this.syncServerFlag) {
      return false;
    }

    return this.isChangedShapes || this.isChangedBindings || this.isChangedAssets || this.isChangedSettings;
  };

  /**
   * set sync with server and update states with Server
   */
  public setSyncServer = async () => {
    this.syncServerFlag = true;

    await this.recoverFromServer();
  };

  /**
   * update class states with Server data
   */
  public recoverFromServer = async () => {
    if (!this.syncServerFlag) {
      return;
    }

    const data = await getFlyerBuilderState(this.storageKey);
    if (!data) {
      /**
       * If there is no stored data, we added current state to the server
       */
      await this.addToServer();
      return;
    }

    const { shapes, bindings, assets, documentSettings } = data;

    this.setLiveShapes(convertDataForLiveStorage(shapes));
    this.setLiveBindings(convertDataForLiveStorage(bindings));
    this.setLiveAssets(convertDataForLiveStorage(assets));
    this.setLiveDocumentSettings(documentSettings);
  };

  /**
   * update server with current states
   * anyone can call this function
   */
  public updateServer = async () => {
    if (!this.syncServerFlag) {
      return;
    }

    if (!this.needToSyncServer()) {
      return;
    }

    try {
      await updateFlyerBuilderStates({
        key: this.storageKey,
        states: { ...this.getGraphQLInputData() },
      });

      this.afterSyncServer();
    } catch (e: any) {
      console.log("got error when update Flyer Builder States: ", e.message);
    }
  };

  /**
   * public functions for storageKeyObject
   */
  public getStorageKeyObject = () => {
    return { ...this.storageKeyObject };
  };

  public setStorageKeyObject = (_newStorageKeys: T) => {
    this.storageKeyObject = { ..._newStorageKeys };
    this.storageKey = this.storageKeyObject.roomId; // JSON.stringify(this.storageKeyObject);
  };

  public getSyncServerFlag = () => {
    return this.syncServerFlag;
  };

  public setSyncServerFlag = (_flag: boolean) => {
    this.syncServerFlag = _flag;
  };

  /**
   * public functions for version
   */
  public getVersion = () => {
    return this.version;
  };

  public setVersion = (_version: number) => {
    this.version = _version;
  };

  /**
   * public functions for liveShapes
   */
  public getLiveShapes = (): Map<string, TDShape> | null => {
    return shallowCopyMap(this.liveShapes);
  };

  public setLiveShapes = (_newLiveShapes: [string, TDShape][]) => {
    this.liveShapes = new Map(_newLiveShapes);

    this.isChangedShapes = true;
  };

  public updateLiveShapes = (_newLiveShapes: Map<string, TDShape>) => {
    this.liveShapes = shallowCopyMap(_newLiveShapes);

    this.isChangedShapes = true;
  };

  /**
   * public functions for liveBindings
   */
  public getLiveBindings = (): Map<string, TDBinding> | null => {
    return shallowCopyMap(this.liveBindings);
  };

  public setLiveBindings = (_newLiveBindings: [string, TDBinding][]) => {
    this.liveBindings = new Map(_newLiveBindings);

    this.isChangedBindings = true;
  };

  public updateLiveBindings = (_newLiveBindings: Map<string, TDBinding> | null) => {
    this.liveBindings = shallowCopyMap(_newLiveBindings);

    this.isChangedBindings = true;
  };

  /**
   * public functions for liveAssets
   */
  public getLiveAssets = (): Map<string, TDAsset> | null => {
    return shallowCopyMap(this.liveAssets);
  };

  public setLiveAssets = (_newLiveAssets: [string, TDAsset][]) => {
    this.liveAssets = new Map(_newLiveAssets);

    this.isChangedAssets = true;
  };

  public updateLiveAssets = (_newLiveAssets: Map<string, TDAsset> | null) => {
    this.liveAssets = shallowCopyMap(_newLiveAssets);

    this.isChangedAssets = true;
  };

  /**
   * public functions for liveDocumentSettings
   */
  public getLiveDocumentSettings = () => {
    return this.liveDocumentSettings;
  };

  public setLiveDocumentSettings = (_newLiveDocumentSettings: DocumentSettings) => {
    this.liveDocumentSettings = { ..._newLiveDocumentSettings };

    this.isChangedSettings = true;
  };

  public updateLiveDocumentSettings = (_newLiveDocumentSettings: DocumentSettings) => {
    this.liveDocumentSettings = { ..._newLiveDocumentSettings };

    this.isChangedSettings = true;
  };
}
