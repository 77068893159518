import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { styled } from "pages/flyer/builder/drawer/styles";
import type { TDSnapshot } from "pages/flyer/builder/drawer/types";
import * as React from "react";
import { ActionButton } from "./ActionButton";
import { BackToContent } from "./BackToContent";
import { DeleteButton } from "./DeleteButton";
import { PrimaryTools } from "./PrimaryTools";
import { StatusBar } from "./StatusBar";

const isDebugModeSelector = (s: TDSnapshot) => s.settings.isDebugMode;

interface ToolsPanelProps {
  onBlur?: React.FocusEventHandler;
}

export const ToolsPanel = React.memo(function ToolsPanel({ onBlur }: ToolsPanelProps): JSX.Element {
  const app = useTldrawApp();
  const isDebugMode = app.useStore(isDebugModeSelector);

  return (
    <StyledToolsPanelContainer onBlur={onBlur}>
      <StyledCenterWrap>
        <BackToContent />
        <StyledPrimaryTools>
          <ActionButton />
          <PrimaryTools />
          <DeleteButton />
        </StyledPrimaryTools>
      </StyledCenterWrap>
      {isDebugMode && (
        <StyledStatusWrap>
          <StatusBar />
        </StyledStatusWrap>
      )}
    </StyledToolsPanelContainer>
  );
});

const StyledToolsPanelContainer = styled("div", {
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  minWidth: 0,
  maxWidth: "100%",
  display: "grid",
  gridTemplateColumns: "auto auto auto",
  gridTemplateRows: "auto auto",
  justifyContent: "space-between",
  padding: "0",
  gap: "$4",
  zIndex: 200,
  pointerEvents: "none",
  "& > div > *": {
    pointerEvents: "all",
  },
});

const StyledCenterWrap = styled("div", {
  gridRow: 1,
  gridColumn: 2,
  display: "flex",
  width: "fit-content",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "$4",
});

const StyledStatusWrap = styled("div", {
  gridRow: 2,
  gridColumn: "1 / span 3",
});

const StyledPrimaryTools = styled("div", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "$2",
});
