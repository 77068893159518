import classNames from "classnames";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ClassesType } from "../type";

interface ISaveButtonProps extends ClassesType {
  customConfirmText?: string;
  loading: boolean;
  submitAutoWidth?: boolean;
  onSave: () => void;
}

export const SaveButton = ({ classes, customConfirmText, loading, submitAutoWidth, onSave }: ISaveButtonProps) => (
  <Button
    className={classNames(classes.button, classes.submitButton, submitAutoWidth ? classes.autoWidth : "")}
    disabled={loading}
    variant="outlined"
    onClick={onSave}
  >
    {loading && <CircularProgress size={20} color="secondary" style={{ marginRight: 10 }} />}
    {customConfirmText || "Save"}
  </Button>
);
