const SvgDelete = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.1071 7.89014C16.1071 7.89014 15.6546 13.5026 15.3921 15.8668C15.2671 16.996 14.5696 17.6576 13.4271 17.6785C11.2529 17.7176 9.07625 17.7201 6.90292 17.6743C5.80375 17.6518 5.11792 16.9818 4.99542 15.8726C4.73125 13.4876 4.28125 7.89014 4.28125 7.89014"
      stroke="#AEAFC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.2567 5.19963H3.125" stroke="#AEAFC7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.5374 5.19974C13.8832 5.19974 13.3199 4.73724 13.1916 4.0964L12.9891 3.08307C12.8641 2.61557 12.4407 2.29224 11.9582 2.29224H8.43073C7.94823 2.29224 7.5249 2.61557 7.3999 3.08307L7.1974 4.0964C7.06906 4.73724 6.50573 5.19974 5.85156 5.19974"
      stroke="#AEAFC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgDelete;
