import CONFIG from "config/config";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const fetchSelectedCitiesLocations = ({
  cityId,
  offset,
  first,
  status = "ENABLED",
  isSelfServe,
  campaignId,
  clientId,
}) => {
  const query = `
  query (
   $cityId: ID!
   $offset: Int
   $first: Int
   $status: LocationStatusEnum
   $isSelfServe: Boolean
   $campaignId: ID
   $clientId: ID
  ) {
    city(id: $cityId){
      locations(
        offset: $offset
        first: $first
        status: $status
        isSelfServe: $isSelfServe
        campaignId: $campaignId
        clientId: $clientId
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage 
        }
        edges {
          node {
            id
            name
            label
            moments{
               morning
               lunch
               afternoon
            }
            isSheltered
            hasPermit
            isEvent
            isSelfServe
            clientId
            campaignId
            status
            typeId
            type{
            name
            }
            latitude
            longitude
          }
        }
      }
    }
  }`;
  return callAuthorizedGraphQLEndpoint(
    query,
    {
      cityId,
      offset,
      first,
      status,
      isSelfServe,
      campaignId,
      clientId,
    },
    CONFIG.GRAPHQL_API_BASE_URL
  );
};
