import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { styles } from "./style";
import { MAP_BUTTONS } from "../../options";
import PolygonIcon from "../../../../static/media/polygon-icon";
import SelectLocationIcon from "../../../../static/media/select-location";
import CreateLocationIcon from "../../../../static/media/create-location";

const labels = {
  [MAP_BUTTONS.SELECT_LOCATION]: "Select location",
  [MAP_BUTTONS.SELECT_AREA]: "Select area",
  [MAP_BUTTONS.CREATE_LOCATION]: "Create location",
  [MAP_BUTTONS.CIRCLE_SELECT]: "Circle select",
  [MAP_BUTTONS.POLYGON_SELECT]: "Rectangle select",
};

const useStyles = makeStyles(() => styles);

export const MapButton = ({ type, active, onClick, disabled }) => {
  const classes = useStyles();
  const icons = {
    [MAP_BUTTONS.SELECT_LOCATION]: <SelectLocationIcon />,
    [MAP_BUTTONS.SELECT_AREA]: <SelectLocationIcon />,
    [MAP_BUTTONS.CREATE_LOCATION]: <CreateLocationIcon />,
    [MAP_BUTTONS.CIRCLE_SELECT]: <RadioButtonUncheckedIcon fontSize="small" />,
    [MAP_BUTTONS.POLYGON_SELECT]: <PolygonIcon className={classes.polygonIcon} />,
  };
  const label = labels[type];
  const icon = icons[type];
  return (
    <div
      className={classNames(classes.wrapper, {
        [classes.active]: active,
        [classes.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <div className={classes.icon}>{icon}</div>

      <div className={classes.label}>{label}</div>
    </div>
  );
};
