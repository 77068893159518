import { useMutation } from "@apollo/client";
import { withStyles } from "@material-ui/core";
import cn from "classnames";
import { Button } from "components/form";
import ROUTES from "config/routing";
import style from "pages/flyer/flyerTypePage/components/ConfirmationPopUp/style";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import React, { memo } from "react";
import { useHistory } from "react-router";
import { generateRoom as generateRoomMutation } from "shared/graphql/mutations/generateFlyerBuilderRoom";
import { selectFBWayForCreateFlyer } from "shared/graphql/mutations/selectFBWay";
import { generatePath, useParams } from "react-router-dom";

interface IProps {
  classes: any;
  isOpen: boolean;
  onClose: () => void;
}

const ConfirmationPopUp: React.FC<IProps> = ({ classes, isOpen, onClose }) => {
  const { campaignId, clientId: clientIdParam } = useParams();
  const [selectFBWay] = useMutation(selectFBWayForCreateFlyer);
  const [generateRoom] = useMutation(generateRoomMutation);
  const flyerInfo = useGetFlyerInfo();
  const generateRoomHandler = async () => {
    await generateRoom({ variables: { campaignId } });
  };

  const history = useHistory();

  if (!isOpen) {
    return null;
  }

  const onCancel = () => {
    onClose();
  };

  return (
    <div className={classes.modalWrapper}>
      <div
        className={classes.backdropFilter}
        onClick={(e) => {
          e.stopPropagation();
          onCancel();
        }}
      />
      <div className={classes.modal}>
        <p className={classes.header}>Are you sure that you want to proceed?</p>
        <p className={classes.info}>A design was previously uploaded to this campaign.</p>
        <p className={classes.info}>If you proceed to the flyer design tool, the uploaded design will be lost.</p>
        <p className={classes.info}>
          If you proceed you will still be able to come back and upload a design again should you change your mind.
        </p>
        <div className={classes.buttons}>
          <Button
            onClick={async () => {
              if (!flyerInfo.flyerInfo.activeRoomId) {
                await generateRoomHandler();
              } else {
                await selectFBWay({ variables: { campaignId } });
              }
              history.push(generatePath(ROUTES.FLYER_BUILDER, { campaignId, clientId: clientIdParam }));
            }}
            className={cn(classes.button, classes.buttonActive)}
            variant="outlined"
          >
            Proceed
          </Button>
          <Button className={classes.button} variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(style)(ConfirmationPopUp));
