export const styles = {
  wrapper: {
    padding: "0px 9px",
    borderRadius: "4px",
    height: "32px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",

    "&:hover": {
      background: "#F9F9FF",
    },
  },
  active: {
    background: "#E2E2FC",
    "&:hover": {
      background: "#E2E2FC",
    },
  },
  label: {
    color: "#1D2943",
  },
  disabled: {
    pointerEvents: "none",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px",
  },
  polygonIcon: {
    width: "1em",
    height: "1em",
  },
};
