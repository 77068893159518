import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Icon } from "components/icons";

import { style } from "./style";

const useStyles = makeStyles(() => style);

export const ZoomInPopup = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Icon type="Info" size="s" />
      <Typography className={classes.hintText}>Zoom in closer to see areas</Typography>
    </div>
  );
};
