import React, { useCallback, useContext } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { TDShapeType } from "pages/flyer/builder/drawer/types";

const ShapesComponent = () => {
  const classes = useStyles();
  const app = useTldrawApp();
  const activeTool = app.useStore((s) => s.appState.activeTool);

  const selectRectTool = useCallback(() => {
    app.selectTool(TDShapeType.Rectangle);
  }, [app]);

  const selectEllipseTool = useCallback(() => {
    app.selectTool(TDShapeType.Ellipse);
  }, [app]);

  const selectLineTool = useCallback(() => {
    app.selectTool(TDShapeType.Line);
  }, [app]);

  const selectArrowTool = useCallback(() => {
    app.selectTool(TDShapeType.Arrow);
  }, [app]);

  return (
    <div className={classes.shapesContainer}>
      <div className={classes.shapesSection}>
        <span className={classes.label}>Basic shapes</span>
        <div className={classes.basicShapes}>
          <div
            className={cn(classes.basicShape, { [classes.active]: activeTool === TDShapeType.Rectangle })}
            onClick={selectRectTool}
          >
            {/* Rectangle */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="24" height="24" rx="1" fill="#4F5B94" />
            </svg>
          </div>
          <div
            className={cn(classes.basicShape, classes.circleShapeButton, {
              [classes.active]: activeTool === TDShapeType.Ellipse,
            })}
            onClick={selectEllipseTool}
          >
            {/* Circle */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="#4F5B94" />
            </svg>
          </div>
        </div>
      </div>
      <div className={classes.shapesSection}>
        <span className={classes.label}>Lines</span>
        <div className={classes.linesContainer}>
          <div
            className={cn(classes.lineContainer, { [classes.active]: activeTool === TDShapeType.Line })}
            onClick={selectLineTool}
          >
            {/* Line */}
            <svg width="86" height="2" viewBox="0 0 86 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="1" x2="86" y2="1" stroke="#2E3860" strokeWidth="2" />
            </svg>
          </div>
          <div
            className={cn(classes.lineContainer, { [classes.active]: activeTool === TDShapeType.Arrow })}
            onClick={selectArrowTool}
          >
            {/* Arrow */}
            <svg width="87" height="16" viewBox="0 0 87 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M86.7071 8.70711C87.0976 8.31658 87.0976 7.68342 86.7071 7.29289L80.3431 0.928932C79.9526 0.538408 79.3195 0.538408 78.9289 0.928932C78.5384 1.31946 78.5384 1.95262 78.9289 2.34315L84.5858 8L78.9289 13.6569C78.5384 14.0474 78.5384 14.6805 78.9289 15.0711C79.3195 15.4616 79.9526 15.4616 80.3431 15.0711L86.7071 8.70711ZM0 9H86V7H0V9Z"
                fill="#2E3860"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  shapesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  shapesSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  label: {
    fontFamily: "Proxima Nova, sans-serif",
    fontWeight: "600",
    fontSize: "14px",
    marginBottom: "10px",
  },
  basicShapes: {
    display: "flex",
    width: "80px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  basicShape: {
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    cursor: "pointer",
  },
  circleShapeButton: {
    borderRadius: "50%",
  },
  linesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  lineContainer: {
    width: "96px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: "10px",
    borderRadius: "4px",
  },
  // arrowContainer: {
  //   width: "94px",
  //   height: "32px",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   cursor: "pointer",
  //   borderRadius: "4px",
  //   clipPath:
  //     "polygon(0% 36%, 85% 38%, 79% 20%, 79% 16%, 84% 4%,85% 4%, 100% 45%, 101% 50%, 86% 93%, 84% 93%, 81% 88%, 79% 82%, 85% 62%,  0% 66%)",
  // },
  active: {
    backgroundColor: "#E2E2FC",
  },
});

export const Shapes = React.memo(ShapesComponent);
