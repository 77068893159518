import Button from "@material-ui/core/Button";
import { ClassesType } from "../type";

interface ICancelButtonProps extends ClassesType {
  onClose: () => void;
}

export const CancelButton = ({ classes, onClose }: ICancelButtonProps) => (
  <Button className={classes.button} variant="outlined" onClick={onClose}>
    Cancel
  </Button>
);
