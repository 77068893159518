import { TextField, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  AlignBottomIcon,
  AlignCenterHorizontallyIcon,
  AlignCenterVerticallyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AlignTopIcon,
  SpaceEvenlyHorizontallyIcon,
  SpaceEvenlyVerticallyIcon,
  StretchHorizontallyIcon,
  StretchVerticallyIcon,
} from "@radix-ui/react-icons";
import { preventEvent } from "pages/flyer/builder/drawer/components/preventEvent";
import { DMContent, DMRadioItem } from "pages/flyer/builder/drawer/components/Primitives/DropdownMenu";
import {
  StrikeThroughIcon,
  TextAlignCenterIcon,
  TextAlignJustifyIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  VerticalAlignBottomIcon,
  VerticalAlignCenterIcon,
  VerticalAlignTopIcon,
} from "pages/flyer/builder/drawer/components/Primitives/icons";
import { BoldFontIcon } from "pages/flyer/builder/drawer/components/Primitives/icons/BoldFontIcon";
import { ItalicFontIcon } from "pages/flyer/builder/drawer/components/Primitives/icons/ItalicFontIcon";
import { UnderLineFontIcon } from "pages/flyer/builder/drawer/components/Primitives/icons/UnderLineFontIcon";
import { ToolButton } from "pages/flyer/builder/drawer/components/Primitives/ToolButton";
import FontSizePicker from "pages/flyer/builder/drawer/components/StyleMenu/FontSizePicker";
import StyleRow from "pages/flyer/builder/drawer/components/TopPanel/StyleMenu/StyleElements/StyleRow";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { styled } from "pages/flyer/builder/drawer/styles";
import {
  AlignStyle,
  AlignType,
  DistributeType,
  FontStyle,
  GroupShape,
  StretchType,
  TDShapeType,
  TDSnapshot,
} from "pages/flyer/builder/drawer/types";
import * as React from "react";
import { withRouter } from "react-router";
import { arraysMatch } from "utils/arraysMatch";
import style from "./style";
import { useAddAllFontsForFB } from "../../../../hooks/useFetchAllFonts";

const ALIGN_ICONS = {
  [AlignStyle.Start]: <TextAlignLeftIcon />,
  [AlignStyle.Middle]: <TextAlignCenterIcon />,
  [AlignStyle.End]: <TextAlignRightIcon />,
  [AlignStyle.Justify]: <TextAlignJustifyIcon />,
};

const VERTICAL_ALIGN_ICONS = {
  [AlignStyle.End]: <VerticalAlignBottomIcon />,
  [AlignStyle.Start]: <VerticalAlignTopIcon />,
  [AlignStyle.Middle]: <VerticalAlignCenterIcon />,
  [AlignStyle.Justify]: null,
};
const fontStylesIcons = {
  [FontStyle.Bold]: <BoldFontIcon />,
  [FontStyle.Italic]: <ItalicFontIcon />,
  [FontStyle.Underscore]: <UnderLineFontIcon />,
  [FontStyle.Strikethrough]: <StrikeThroughIcon />,
};

// const isRectangleSelectedSelector = (s: TDSnapshot) => {
//   const selectedIds =
//     s.document.pageStates[s.appState.currentPageId].selectedIds;
//   const shapesOfPage = s.document.pages[s.appState.currentPageId].shapes;
//   const isRectSelected = selectedIds.find(
//     (id) => shapesOfPage[id].type === TDShapeType.Rectangle
//   );
//   return isRectSelected;
// };

// const isCircleSelectedSelector = (s: TDSnapshot) => {
//   const selectedIds =
//     s.document.pageStates[s.appState.currentPageId].selectedIds;
//   const shapesOfPage = s.document.pages[s.appState.currentPageId].shapes;
//   const isCircleSelected = selectedIds.find(
//     (id) => shapesOfPage[id].type === TDShapeType.Ellipse
//   );
//   return isCircleSelected;
// };

// const isImageSelectedSelector = (s: TDSnapshot) => {
//   const selectedIds =
//     s.document.pageStates[s.appState.currentPageId].selectedIds;
//   const shapesOfPage = s.document.pages[s.appState.currentPageId].shapes;
//   const isImageSelected = selectedIds.find(
//     (id) => shapesOfPage[id].type === TDShapeType.Image
//   );
//   return isImageSelected;
// };

// const isQRcodeSelectedSelector = (s: TDSnapshot) => {
//   const selectedIds =
//     s.document.pageStates[s.appState.currentPageId].selectedIds;
//   const shapesOfPage = s.document.pages[s.appState.currentPageId].shapes;
//   const isQRCodeSelected = selectedIds.find(
//     (id) => shapesOfPage[id].type === TDShapeType.QRCode
//   );
//   return isQRCodeSelected;
// };

// const isLineSelectedSelector = (s: TDSnapshot) => {
//   const selectedIds =
//     s.document.pageStates[s.appState.currentPageId].selectedIds;
//   const shapesOfPage = s.document.pages[s.appState.currentPageId].shapes;
//   const isLineSelected = selectedIds.find(
//     (id) =>
//       shapesOfPage[id].type === TDShapeType.Arrow ||
//       shapesOfPage[id].type === TDShapeType.Draw
//   );
//   return isLineSelected;
// };

// const isTextSelectedSelector = (s: TDSnapshot) => {
//   const selectedIds =
//     s.document.pageStates[s.appState.currentPageId].selectedIds;
//   const shapesOfPage = s.document.pages[s.appState.currentPageId].shapes;
//   const isTextSelected = selectedIds.find(
//     (id) =>
//       shapesOfPage[id].type === TDShapeType.Code ||
//       shapesOfPage[id].type === TDShapeType.TextArea
//   );
//   return isTextSelected;
// };

const getCommonStylesOfSelectedElements = (s: TDSnapshot) => {
  const { currentPageId: pageId } = s.appState;
  const page = s.document.pages[pageId];
  const firstLevelSelectedIds = s.document.pageStates[pageId].selectedIds;
  const shapesOfPage = s.document.pages[pageId].shapes;

  const allSelectedIds: Array<string> = [];

  const handleGroupChanges = (id: string) => {
    if (shapesOfPage[id].type === TDShapeType.Group) {
      addAllSimpleElementsFromGroup(shapesOfPage[id] as GroupShape);
    } else {
      allSelectedIds.push(id);
    }
  };

  const addAllSimpleElementsFromGroup = (group: GroupShape) => {
    group.children.forEach(handleGroupChanges);
  };

  firstLevelSelectedIds.forEach(handleGroupChanges);

  const isRectSelected = allSelectedIds.find((id) => shapesOfPage[id].type === TDShapeType.Rectangle);

  const isCircleSelected = allSelectedIds.find((id) => shapesOfPage[id].type === TDShapeType.Ellipse);
  const isImageSelected = allSelectedIds.find((id) => shapesOfPage[id].type === TDShapeType.Image);
  const isQRCodeSelected = allSelectedIds.find((id) => shapesOfPage[id].type === TDShapeType.QRCode);
  const isLineSelected = allSelectedIds.find(
    (id) => shapesOfPage[id].type === TDShapeType.Arrow || shapesOfPage[id].type === TDShapeType.Draw
  );
  const isTextSelected = allSelectedIds.find(
    (id) => shapesOfPage[id].type === TDShapeType.Code || shapesOfPage[id].type === TDShapeType.TextArea
  );

  const selectedShapesWithoutGroups = allSelectedIds
    .map((id) => page.shapes[id])
    .filter((shape) => shape.type !== TDShapeType.Group);
  const stylesOfSelectedShapes = selectedShapesWithoutGroups.map((shape) => shape.style);
  const commonStylesOfSelections: Record<string, any> = {};
  stylesOfSelectedShapes.forEach((style: any) => {
    const keysOfStyle = Object.keys(style);
    keysOfStyle.forEach((property) => {
      if (!commonStylesOfSelections.hasOwnProperty(property)) {
        if (typeof style[property]! === "object" && !Array.isArray(style[property]!) && style[property]! !== null) {
          commonStylesOfSelections[property] = { ...style[property]! };
        } else if (style[property] !== undefined) {
          commonStylesOfSelections[property] = style[property];
        }
      } else if (Array.isArray(style[property])) {
        const isArrayEqual = arraysMatch(style[property], commonStylesOfSelections[property]);
        if (!isArrayEqual) {
          commonStylesOfSelections[property] = "__dif";
        }
      } else if (typeof style[property] === "object" && style[property] !== null) {
        const keysOfObject = Object.keys(style[property]);
        keysOfObject.forEach((key) => {
          if (commonStylesOfSelections[property][key] !== style[property][key]) {
            commonStylesOfSelections[property][key] = "__dif";
          }
        });
      } else if (commonStylesOfSelections[property] !== style[property] && style[property] !== undefined) {
        commonStylesOfSelections[property] = "__dif";
      }
    });
  });

  return JSON.stringify({
    commonStylesOfSelections,
    selectedIds: allSelectedIds,
    isRectSelected,
    isCircleSelected,
    isImageSelected,
    isQRCodeSelected,
    isLineSelected,
    isTextSelected,
  });
};

const StyleMenu: React.FC<any> = ({ classes }) => {
  const app = useTldrawApp();
  const [showStyles, setShowStyles] = React.useState(true);

  const {
    selectedIds,
    isRectSelected,
    isCircleSelected,
    isImageSelected,
    isQRCodeSelected,
    isLineSelected,
    isTextSelected,
    commonStylesOfSelections,
  } = JSON.parse(app.useStore(getCommonStylesOfSelectedElements));

  const fonts = useAddAllFontsForFB();

  const handleFontStyleChange = React.useCallback(
    (value) => {
      if (commonStylesOfSelections.fontStyle === "__dif") {
        const newStylesArray = [value];
        app.style({ fontStyle: newStylesArray });
        return;
      }
      if (commonStylesOfSelections.fontStyle.includes(value)) {
        const newStylesArray = commonStylesOfSelections.fontStyle.filter((fontStyle: string) => fontStyle !== value);
        app.style({ fontStyle: newStylesArray });
      } else {
        const newStylesArray = [...commonStylesOfSelections.fontStyle, value];
        app.style({ fontStyle: newStylesArray });
      }
    },
    [commonStylesOfSelections, app]
  );

  const handleFontFamilyChange = React.useCallback(
    (value) => {
      app.style({ font: value });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [app]
  );

  const handleHorizontalTextAlignChange = React.useCallback(
    (value: string) => {
      app.style({ textAlign: value as AlignStyle });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [app]
  );

  const handleVerticalTextAlignChange = React.useCallback(
    (value: string) => {
      app.style({ verticalTextAlign: value as AlignStyle });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [app]
  );

  // const handleMenuOpenChange = React.useCallback(
  //   (open: boolean) => {
  //     app.setMenuOpen(open);
  //   },
  //   [app]
  // );

  const alignTop = React.useCallback(() => {
    app.align(AlignType.Top);
  }, [app]);

  const alignCenterVertical = React.useCallback(() => {
    app.align(AlignType.CenterVertical);
  }, [app]);

  const alignBottom = React.useCallback(() => {
    app.align(AlignType.Bottom);
  }, [app]);

  const stretchVertically = React.useCallback(() => {
    app.stretch(StretchType.Vertical);
  }, [app]);

  const distributeVertically = React.useCallback(() => {
    app.distribute(DistributeType.Vertical);
  }, [app]);

  const alignLeft = React.useCallback(() => {
    app.align(AlignType.Left);
  }, [app]);

  const alignCenterHorizontal = React.useCallback(() => {
    app.align(AlignType.CenterHorizontal);
  }, [app]);

  const alignRight = React.useCallback(() => {
    app.align(AlignType.Right);
  }, [app]);

  const stretchHorizontally = React.useCallback(() => {
    app.stretch(StretchType.Horizontal);
  }, [app]);

  const distributeHorizontally = React.useCallback(() => {
    app.distribute(DistributeType.Horizontal);
  }, [app]);

  const hasThreeOrMore = selectedIds.length >= 3;
  const hasTwoOrMore = selectedIds.length >= 2;

  const shouldAlignDisplay = true;

  const shouldFillDisplay = isRectSelected || isCircleSelected || isTextSelected;

  const shouldStrokeDisplay = isRectSelected || isCircleSelected || isLineSelected || isImageSelected || isTextSelected;

  const shouldRoundingDisplay = isRectSelected;

  const shouldTransparencyDisplay = !isQRCodeSelected;

  const shouldInnerShadowDisplay = isRectSelected;

  const shouldOuterShadowDisplay =
    isTextSelected || isRectSelected || isCircleSelected || isLineSelected || isImageSelected;

  const shouldFontStyleDisplay = isTextSelected;

  const getColor = (value: string) => {
    if (value === "__dif") {
      return "#000000";
    }
    return value;
  };

  const getIsDif = (value: string) => {
    if (value === "__dif") {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (!selectedIds.length) {
      setShowStyles(true);
    }
  }, [selectedIds]);

  return selectedIds.length ? (
    <DropdownMenu.Root
      dir="ltr"
      // onOpenChange={handleMenuOpenChange}
      open
      modal={false}
    >
      <DropdownMenu.Trigger asChild>
        <ToolButton
          isActive={showStyles}
          onClick={() => setShowStyles(!showStyles)}
          variant="text"
          style={{ marginRight: "20px" }}
        >
          Styles
        </ToolButton>
      </DropdownMenu.Trigger>
      {showStyles && (
        <Content>
          <DMContent align="end" variant="styleMenu">
            {/* ____AlignIcons____ */}
            {shouldAlignDisplay && !shouldFontStyleDisplay ? (
              <AlignIconsBlock>
                <Row>
                  {/* @ts-ignore */}
                  <StyledIcon onClick={alignLeft} cypress_id={"leftPictureAlignment"}>
                    <AlignLeftIcon />
                  </StyledIcon>
                  {/* @ts-ignore */}
                  <StyledIcon onClick={alignCenterHorizontal} cypress_id={"centerHorizontalPictureAlignment"}>
                    <AlignCenterHorizontallyIcon />
                  </StyledIcon>
                  {/* @ts-ignore */}
                  <StyledIcon onClick={alignRight} cypress_id={"rightPictureAlignment"}>
                    <AlignRightIcon />
                  </StyledIcon>
                  <StyledIcon
                    variant={hasTwoOrMore && !isQRCodeSelected ? undefined : "disabled"}
                    onClick={stretchHorizontally}
                    //  @ts-ignore
                    cypress_id={"stretchHorizontallyPictureAlignment"}
                  >
                    <StretchHorizontallyIcon />
                  </StyledIcon>
                  <StyledIcon
                    variant={hasThreeOrMore ? undefined : "disabled"}
                    onClick={distributeHorizontally}
                    //  @ts-ignore
                    cypress_id={"distributeHorizontallyPictureAlignment"}
                  >
                    <SpaceEvenlyHorizontallyIcon />
                  </StyledIcon>
                </Row>
                <Row>
                  {/* @ts-ignore */}
                  <StyledIcon onClick={alignTop} cypress_id={"topPictureAlignment"}>
                    <AlignTopIcon />
                  </StyledIcon>
                  {/* @ts-ignore */}
                  <StyledIcon onClick={alignCenterVertical} cypress_id={"centerVerticalPictureAlignment"}>
                    <AlignCenterVerticallyIcon />
                  </StyledIcon>
                  {/* @ts-ignore */}
                  <StyledIcon onClick={alignBottom} cypress_id={"bottomPictureAlignment"}>
                    <AlignBottomIcon />
                  </StyledIcon>
                  <StyledIcon
                    variant={hasTwoOrMore && !isQRCodeSelected ? undefined : "disabled"}
                    onClick={stretchVertically}
                    //  @ts-ignore
                    cypress_id={"stretchVerticallyPictureAlignment"}
                  >
                    <StretchVerticallyIcon />
                  </StyledIcon>

                  <StyledIcon
                    variant={hasThreeOrMore ? undefined : "disabled"}
                    onClick={distributeVertically}
                    //  @ts-ignore
                    cypress_id={"distributeVerticallyPictureAlignment"}
                  >
                    <SpaceEvenlyVerticallyIcon />
                  </StyledIcon>
                </Row>
              </AlignIconsBlock>
            ) : null}

            {/* ____Text____ */}
            {shouldFontStyleDisplay ? (
              <>
                <StyledRow>
                  <StyleTextBlock>
                    <StyleTextItemLine>
                      <TextStyleTitle>Font</TextStyleTitle>

                      <Autocomplete
                        value={
                          commonStylesOfSelections.font === "__dif"
                            ? "Mixed Font"
                            : commonStylesOfSelections.font || "Roboto"
                        }
                        loading={!fonts.length}
                        renderOption={(props: any) => {
                          return (
                            <div
                              {...props}
                              style={{
                                fontFamily: `${props} !important`,
                                fontSize: "14px",
                              }}
                            >
                              {props}
                            </div>
                          );
                        }}
                        options={fonts}
                        onChange={(_: any, newValue: any) => {
                          handleFontFamilyChange(newValue);
                        }}
                        style={{ width: "100%" }}
                        disableClearable
                        renderInput={(params: any) => {
                          return (
                            <TextField
                              {...params}
                              color="secondary"
                              variant="standard"
                              size="small"
                              className={classes.formated}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  fontFamily: `${params.inputProps.value}`,
                                },
                                disableUnderline: true,
                              }}
                            />
                          );
                        }}
                      />
                    </StyleTextItemLine>

                    <StyleTextItemLine>
                      <TextStyleTitle>Size</TextStyleTitle>
                      <Autocomplete
                        value={
                          getIsDif(commonStylesOfSelections.fontSize)
                            ? "Mixed Size"
                            : `${commonStylesOfSelections.fontSize}px`
                        }
                        options={[
                          "9px",
                          "10px",
                          "11px",
                          "12px",
                          "13px",
                          "14px",
                          "18px",
                          "24px",
                          "36px",
                          "48px",
                          "64px",
                          "72px",
                          "96px",
                          "144px",
                        ]}
                        onChange={(_: any, value: any) => {
                          app.style({
                            fontSize: +value.split("px")[0],
                          });
                        }}
                        disableClearable
                        style={{ width: "100%" }}
                        renderInput={(params: any) => {
                          return (
                            <TextField
                              {...params}
                              color="secondary"
                              variant="standard"
                              size="small"
                              className={classes.formated}
                              style={{ width: "120px" }}
                              InputProps={{
                                style: {
                                  fontFamily: `${params.inputProps.value}`,
                                },
                                disableUnderline: true,
                                ...params.InputProps,
                              }}
                            />
                          );
                        }}
                      />
                    </StyleTextItemLine>

                    <StyleTextItemLine>
                      <TextStyleTitle>Align</TextStyleTitle>
                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <TextAlignIconsBlock
                          dir="ltr"
                          value={commonStylesOfSelections.textAlign}
                          onValueChange={handleHorizontalTextAlignChange}
                        >
                          {Object.values(AlignStyle).map((style) =>
                            style ? (
                              <DMRadioItem
                                key={style}
                                isActive={style === commonStylesOfSelections.textAlign}
                                value={style}
                                onSelect={preventEvent}
                              >
                                {ALIGN_ICONS[style]}
                              </DMRadioItem>
                            ) : null
                          )}
                        </TextAlignIconsBlock>
                        <TextAlignIconsBlock
                          dir="ltr"
                          value={commonStylesOfSelections.verticalTextAlign}
                          onValueChange={handleVerticalTextAlignChange}
                        >
                          {Object.values(AlignStyle).map((style) =>
                            VERTICAL_ALIGN_ICONS[style] ? (
                              <DMRadioItem
                                key={style}
                                isActive={style === commonStylesOfSelections.verticalTextAlign}
                                value={style}
                                onSelect={preventEvent}
                              >
                                {VERTICAL_ALIGN_ICONS[style]}
                              </DMRadioItem>
                            ) : null
                          )}
                        </TextAlignIconsBlock>
                      </div>
                    </StyleTextItemLine>
                    {commonStylesOfSelections.fontStyle ? (
                      <StyleTextItemLine>
                        <TextStyleTitle>Style</TextStyleTitle>
                        <div style={{ display: "flex", width: "100%" }}>
                          <TextAlignIconsBlock
                            dir="ltr"
                            value={commonStylesOfSelections.fontStyle}
                            onValueChange={handleFontStyleChange}
                          >
                            {Object.values(FontStyle).map((fontStyle) => (
                              <DMRadioItem
                                key={fontStyle}
                                isActive={commonStylesOfSelections.fontStyle.includes(fontStyle)}
                                value={fontStyle}
                                onSelect={preventEvent}
                              >
                                {fontStylesIcons[fontStyle]}
                              </DMRadioItem>
                            ))}
                          </TextAlignIconsBlock>
                        </div>
                      </StyleTextItemLine>
                    ) : null}
                  </StyleTextBlock>
                </StyledRow>
              </>
            ) : null}

            {/* ____Fill____ */}
            {shouldFillDisplay ? (
              <FillBlock>
                <StyleRow
                  title="Fill"
                  color={{
                    isColorMixed: getIsDif(commonStylesOfSelections.fillColor),
                    currentColor: getColor(commonStylesOfSelections.fillColor),
                    changeColor: (newColor) => {
                      app.style({ fillColor: newColor });
                    },
                  }}
                  // visibility={{
                  //   isVisible: commonStylesOfSelections.isFilled,
                  //   changeVisibility: () => {
                  //     app.style({ isFilled: !commonStylesOfSelections.isFilled });
                  //   },
                  // }}
                />
              </FillBlock>
            ) : null}

            {/* ____Rounding____ */}
            {shouldRoundingDisplay ? (
              <StyleRow
                title="Rounding"
                value={{
                  isValueMixed: getIsDif(commonStylesOfSelections.rounded),
                  step: 1,
                  minValue: 0,
                  maxValue: 500,
                  currentValue: getIsDif(commonStylesOfSelections.rounded) ? 0 : commonStylesOfSelections.rounded,
                  changeValue: (newValue: number) => {
                    app.style({
                      rounded: newValue,
                    });
                  },
                }}
                // visibility={{
                //   isVisible: commonStylesOfSelections.isRoundingVisible,
                //   changeVisibility: () => {
                //     app.style({
                //       isRoundingVisible: !commonStylesOfSelections.isRoundingVisible,
                //     });
                //   },
                // }}
              />
            ) : null}

            {/* ____Opacity____ */}
            {shouldTransparencyDisplay ? (
              <StyleRow
                title="Opacity"
                value={{
                  isValueMixed: getIsDif(commonStylesOfSelections.transparency),
                  step: 1,
                  minValue: 0,
                  maxValue: 100,
                  currentValue: getIsDif(commonStylesOfSelections.transparency)
                    ? 0
                    : commonStylesOfSelections.transparency,
                  changeValue: (newValue: number) => {
                    app.style({
                      transparency: newValue,
                    });
                  },
                }}
                // visibility={{
                //   isVisible: commonStylesOfSelections.isOpacityVisible,
                //   changeVisibility: () => {
                //     app.style({
                //       isOpacityVisible: !commonStylesOfSelections.isOpacityVisible,
                //     });
                //   },
                // }}
              />
            ) : null}

            {/* ____Stroke____ */}
            {shouldStrokeDisplay ? (
              <StyleRow
                title="Stroke"
                color={{
                  isColorMixed: getIsDif(commonStylesOfSelections.strokeColor),
                  currentColor: getColor(commonStylesOfSelections.strokeColor),
                  changeColor: (newColor) => {
                    app.style({ strokeColor: newColor });
                  },
                }}
                // visibility={{
                //   isVisible: commonStylesOfSelections.isStroked,
                //   changeVisibility: () => {
                //     app.style({
                //       isStroked: !commonStylesOfSelections.isStroked,
                //     });
                //   },
                // }}
                value={{
                  isValueMixed: getIsDif(commonStylesOfSelections.strokeWidth),
                  step: 1,
                  minValue: 0,
                  maxValue: isTextSelected ? 10 : 500,
                  currentValue: getIsDif(commonStylesOfSelections.strokeWidth)
                    ? 0
                    : commonStylesOfSelections.strokeWidth,
                  changeValue: (newValue: number) => {
                    app.style({
                      strokeWidth: newValue,
                    });
                  },
                }}
              />
            ) : null}

            {/* ____Outer Shadow____ */}
            {shouldOuterShadowDisplay ? (
              <StyleRow
                title="Outer Shadow"
                color={{
                  isColorMixed: getIsDif(commonStylesOfSelections.outerShadowColor),
                  currentColor: getColor(commonStylesOfSelections.outerShadowColor),
                  changeColor: (newColor) => {
                    app.style({ outerShadowColor: newColor });
                  },
                }}
                // visibility={{
                //   isVisible: commonStylesOfSelections.isOuterShadowVisible,
                //   changeVisibility: () => {
                //     app.style({
                //       isOuterShadowVisible: !commonStylesOfSelections.isOuterShadowVisible,
                //     });
                //   },
                // }}
                shadowParams={{
                  ...commonStylesOfSelections.outerShadow,
                  onChange: (newShadow: any) => {
                    app.style({
                      outerShadow: newShadow,
                    });
                  },
                }}
              />
            ) : null}

            {/* ____Inner Shadow____ */}
            {shouldInnerShadowDisplay ? (
              <StyleRow
                title="Inner Shadow"
                color={{
                  isColorMixed: getIsDif(commonStylesOfSelections.innerShadowColor),
                  currentColor: getColor(commonStylesOfSelections.innerShadowColor),
                  changeColor: (newColor) => {
                    app.style({ innerShadowColor: newColor });
                  },
                }}
                // visibility={{
                //   isVisible: commonStylesOfSelections.isInnerShadowVisible,
                //   changeVisibility: () => {
                //     app.style({
                //       isInnerShadowVisible: !commonStylesOfSelections.isInnerShadowVisible,
                //     });
                //   },
                // }}
                shadowParams={{
                  ...commonStylesOfSelections.innerShadow,
                  onChange: (newShadow: any) => {
                    app.style({
                      innerShadow: newShadow,
                    });
                  },
                }}
              />
            ) : null}
          </DMContent>
        </Content>
      )}
    </DropdownMenu.Root>
  ) : (
    <></>
  );
};

export const StyleTextBlock = styled("div", {
  display: "flex",
  flexDirection: "column",
});

export const StyleTextItemLine = styled("div", {
  display: "flex",
  flexDirection: "column",
  // height: "32px",
  alignItems: "center",
  marginBottom: "16px",
});

export const AlignIconsBlock = styled("div", {
  display: "flex",
  flexDirection: "column",
  // borderBottom: "1px solid #EDEDED",
});

export const FillBlock = styled("div", {
  borderBottom: "1px solid #E2E2FC",
});

export const TextAlignIconsBlock = styled(DropdownMenu.DropdownMenuRadioGroup, {
  display: "flex",
  "&:not(:last-child)": {
    marginRight: "10px",
  },
});

export const TextAlignBlock = styled("div", {
  display: "flex",
  padding: "21px",
  flexDirection: "column",
});

export const TextStyleTitle = styled("div", {
  color: "#011533",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "20px",
  width: "100%",
  padding: "4px 0",
});

export const StyleBlock = styled("div", {
  padding: "21px",
  paddingRight: "14px",
  display: "flex",
  alignItems: "center",
  borderRight: "1px solid #EDEDED",
});

export const StyledRow = styled("div", {
  display: "flex",
  alignItems: "stretch",
  // "&:not(:last-child)": {
  //   borderBottom: "1px solid #EDEDED",
  // },
});

export const EyeBlock = styled("div", {
  padding: "21px 6px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

export const StyleBlockTitle = styled("div", {
  width: "85px",
  fontSize: "12px",
  variants: {
    variant: {
      width: {
        width: "85px",
      },
    },
  },
});

export const NumberInputBlock = styled("div", {
  width: "40px",
  minHeight: "10px",
  display: "flex",
  alignItems: "center",
  marginRight: "18px",
});

export const Suffix = styled("div", {
  fontSize: "8px",
  marginLeft: "-16px",
  marginTop: "2px",
});

export const StyledIcon = styled("div", {
  width: "35px",
  height: "35px",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "$hover",
  },
  variants: {
    variant: {
      disabled: {
        pointerEvents: "none",
        opacity: 0.2,
      },
    },
  },
});

export const Row = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  boxSizing: "border-box",
  padding: "10px 0",
  "&:not(:last-child)": {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const Column = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "8px",
  borderRadius: "4px",
  boxSizing: "border-box",
});

export const MenuItem = styled("div", {
  "&:not(:last-child)": {},
});

export const Content = styled("div", {
  // padding: "4px",
  borderLeft: "1px solid #E2E2FC",
  width: "250px",
  height: "calc(100vh - 80px)",
  position: "absolute",
  right: "0",
  top: "0",
  "& > div": {
    transform: "none !important",
  },
});

export default React.memo(withStyles(style as any)(withRouter(StyleMenu)));
