export const styles = {
  buttonContainer: {
    display: "flex",
    cursor: "pointer",

    "&:hover": {
      "& > svg": {
        "& > path": {
          stroke: "#1D2943",
        },
      },
    },
  },
};
