const style = {
  button: {
    padding: "12px 12px 32px 12px",
    gap: "18px",
    border: "1px solid #EBEBEB",
    background: "#FFFFFF",
    boxShadow: "0px 2px 18px rgba(39, 55, 84, 0.08)",
    borderRadius: 20,
    width: 324,
    height: 330,
    transition: "all 0.3s ease",
    marginBottom: 30,
    "& path": {
      transition: "all 0.3s ease",
    },
    "&:not(:last-child)": {
      marginRight: 30,
    },
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.02)",
    },
  },

  icon: {},

  title: {
    height: 30,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "30px",
    marginTop: 22,
    color: "#011533",
  },

  image: {},

  description: {
    width: 299,
    height: 48,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    marginTop: 8,
    color: "#011533",
  },
};

export default style;
