import { Icon } from "components/icons";
import { makeStyles } from "@material-ui/core";
import { StyledCustomInput } from "./CustomInput.styled";

const useStyles = makeStyles((theme) => ({
  animatedItem: {
    animation: `$myEffect 1s ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes myEffect": {
    "0%, 100%": {
      "-webkit-transform": "translateZ(0)",
      transform: "translateZ(0)",
    },
    "10%, 30%, 50%, 70%, 90%": {
      "-webkit-transform": "translate3d(-5px,0,0)",
      transform: "translate3d(-5px,0,0)",
    },
    "20%, 40%, 60%, 80%": {
      "-webkit-transform": "translate3d(5px,0,0)",
      transform: "translate3d(5px,0,0)",
    },
  },
}));
interface ICustomInputProps {
  onClick: () => void;
}

export const CustomInput = ({ onClick }: ICustomInputProps) => {
  const classes = useStyles();
  return (
    <StyledCustomInput onClick={onClick} className={classes.animatedItem}>
      <Icon type="Calendar" size="xs1" fill="#FFFFFF" />
      Add date and time
    </StyledCustomInput>
  );
};

export default CustomInput;
