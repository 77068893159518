import CONFIG from "config/config";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const updateSelfServeCampaignData = (input) => {
  const gql = `mutation($input: SelfServeCampaignDataInput!) {
      updateSelfServeCampaignData(input: $input) {
          success
      }
    }`;

  return callAuthorizedGraphQLEndpoint(
    gql,
    {
      input,
    },
    CONFIG.PATHFINDER_API_BASE_URL
  );
};
