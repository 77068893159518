import { withStyles } from "@material-ui/core";
import MenuItem from "pages/flyer/builder/components/Inspector/components/Menu/MenuItem";
import { clamp, isKeyLetterOrNumber } from "pages/flyer/builder/utils";
import React from "react";
import ReactDOM from "react-dom";

const WINDOW_SAFE_AREA_EDGE = 16;
const PADDING = 4;
const HORIZONTAL_SHIFT = 2; // Enough shift so the cursor isn't hovering
const DELAY_RESET_SEARCH = 800;

class Menu extends React.Component {
  content = React.createRef();
  menu = React.createRef();
  timeout;
  timeoutSearch;

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: -1,
      isReadyForMouseUp: false,
      position: {
        top: 0,
        left: 0,
      },
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ isReadyForMouseUp: true });
    }, 400);

    // Adding a short timeout to make sure the menu
    // has time to be genereated in the DOM to set
    // position properly
    this.timeout = setTimeout(() => {
      this.setPosition();
    }, 1);

    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    const { position } = this.props;

    if (prevProps.position !== position) {
      this.setPosition();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutSearch);

    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    const { onClose } = this.props;
    onClose();
  };

  setPosition = () => {
    const { position } = this.props;

    let top = position.top - PADDING;
    let left = position.left - PADDING + HORIZONTAL_SHIFT;

    if (this.menu.current) {
      const boundingClientRect = this.menu.current.getBoundingClientRect();

      if (top + boundingClientRect.height > window.innerHeight - WINDOW_SAFE_AREA_EDGE) {
        top -= top + boundingClientRect.height - window.innerHeight + WINDOW_SAFE_AREA_EDGE;
      }

      if (left + boundingClientRect.width > window.innerWidth - WINDOW_SAFE_AREA_EDGE) {
        left -= left + boundingClientRect.width - window.innerWidth + WINDOW_SAFE_AREA_EDGE;
      }
    }

    this.setState({
      position: {
        top,
        left,
      },
    });
  };

  handleKeyDown = (event) => {
    const { items, onClose } = this.props;

    event.preventDefault();
    event.stopPropagation();

    const { search, selectedIndex } = this.state;

    let newSelectedIndex = selectedIndex;

    switch (event.key) {
      case "ArrowDown":
        newSelectedIndex = this.selectNextItem();
        break;

      case "ArrowUp":
        newSelectedIndex = this.selectPreviousItem();
        break;

      case "Enter":
        if (items[selectedIndex].action) {
          // @ts-ignore
          items[selectedIndex].action();
          return onClose();
        }
        break;

      case "Escape":
        return onClose();

      default:
        // Treat space key like an enter when search is empty
        if (event.key === "Space" && !search) {
          if (items[selectedIndex].action) {
            // @ts-ignore
            items[selectedIndex].action();
            return onClose();
          }
        }

        return this.handleSearch(event);
    }

    newSelectedIndex = clamp(newSelectedIndex, 0, items.length - 1);
    this.setState({ selectedIndex: newSelectedIndex });
  };

  selectPreviousItem = () => {
    const { items } = this.props;

    const { selectedIndex } = this.state;

    for (let i = selectedIndex - 1; i > 0; i--) {
      const item = items[i];

      if (!item.isDisabled && item.type !== "separator") {
        return i;
      }
    }

    return 0;
  };

  selectNextItem = () => {
    const { items } = this.props;

    const { selectedIndex } = this.state;

    for (let i = selectedIndex + 1; i < items.length; i++) {
      const item = items[i];

      if (!item.isDisabled && item.type !== "separator") {
        return i;
      }
    }

    return items.length - 1;
  };

  getIndexFromSearch = (search) => {
    const { items } = this.props;

    for (let i = 0; i < items.length; i++) {
      const isMatch = items[i]?.label?.toLowerCase().startsWith(search);
      if (isMatch) {
        return i;
      }
    }

    return -1;
  };

  handleSearch = (event) => {
    const { search } = this.state;

    if (!isKeyLetterOrNumber) {
      return;
    }

    clearTimeout(this.timeoutSearch);

    let newSearch = search ? search.concat(event.key) : event.key;
    newSearch = newSearch.toLowerCase();

    this.setState({
      search: newSearch,
      selectedIndex: this.getIndexFromSearch(newSearch),
    });

    this.timeoutSearch = setTimeout(() => {
      this.setState({ search: undefined });
    }, DELAY_RESET_SEARCH);
  };

  handleMouseUpBackdrop = () => {
    const { onClose } = this.props;
    const { isReadyForMouseUp } = this.state;

    if (onClose && isReadyForMouseUp) {
      onClose();
    }
  };

  handleMouseEnter = (index) => {
    this.setState({ selectedIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ selectedIndex: -1 });
  };

  getLayoutMenu() {
    const { position } = this.state;

    let top = position.top;
    let left = position.left;
    let opacity = 1;

    if (!this.menu.current || !top || !left) {
      opacity = 0;
    }

    return {
      top: `${top}px`,
      left: `${left}px`,
      opacity,
      padding: `${PADDING}px`,
    };
  }

  renderItems() {
    const { items, onClose, width } = this.props;
    const { selectedIndex, isReadyForMouseUp } = this.state;

    return items.map((item, index) => {
      const isSelected = !item.isDisabled && index === selectedIndex;

      return (
        <MenuItem
          key={index}
          index={index}
          isSelected={isSelected}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClose={onClose}
          isReadyForMouseUp={isReadyForMouseUp}
          width={width}
          {...item}
        />
      );
    });
  }

  render() {
    const { classes } = this.props;

    return ReactDOM.createPortal(
      <>
        <div className={classes.backdrop} onMouseUp={this.handleMouseUpBackdrop} />
        <div ref={this.menu} className={classes.menu} style={this.getLayoutMenu()}>
          <ul>{this.renderItems()}</ul>
        </div>
      </>,
      document.body
    );
  }
}

const styles = {
  menu: {
    position: "absolute",
    background: "var(--color-surface-popover)",
    borderRadius: 6,
    boxShadow: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 12px 24px -8px rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(8px)",
    pointerEvents: "all",
    zIndex: 1302,

    "& button:hover": {
      color: "#FFFFFF",
      outline: "none",
      background: "var(--color-brand-400)",
      "& svg": {
        fill: "#FFFFFF",
      },
      "& span": {
        color: "#FFFFFF",
      },
    },
  },
  backdrop: {
    pointerEvents: "all",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1301,
  },
};

export default withStyles(styles)(Menu);
