export const TextIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.66699 4.66675V2.66675H13.3337V4.66675"
        stroke="#1A2547"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 13.3333H10" stroke="#1A2547" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 2.66675V13.3334" stroke="#1A2547" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
