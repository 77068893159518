import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";

const style = {
  root: {
    width: "16px",
    height: "16px",
    fill: "none",
  },
};

const CalendarIcon = ({ classes, ...props }) => (
  <SvgIcon {...props} className={classes.root} viewBox="0 0 16 16">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.832031 5.34131C0.832031 3.9606 1.95132 2.84131 3.33203 2.84131H12.6654C14.0461 2.84131 15.1654 3.9606 15.1654 5.34131V12.6663C15.1654 14.047 14.0461 15.1663 12.6654 15.1663H3.33203C1.95132 15.1663 0.832031 14.047 0.832031 12.6663V5.34131ZM3.33203 3.84131C2.5036 3.84131 1.83203 4.51288 1.83203 5.34131V12.6663C1.83203 13.4947 2.5036 14.1663 3.33203 14.1663H12.6654C13.4938 14.1663 14.1654 13.4947 14.1654 12.6663V5.34131C14.1654 4.51288 13.4938 3.84131 12.6654 3.84131H3.33203Z"
        fill={props.fill ? props.fill : "#EE4360"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66406 0.833325C4.9402 0.833231 5.16414 1.05701 5.16423 1.33315L5.16492 3.34165C5.16501 3.61779 4.94123 3.84173 4.66509 3.84182C4.38895 3.84191 4.16501 3.61813 4.16492 3.34199L4.16423 1.3335C4.16414 1.05735 4.38792 0.83342 4.66406 0.833325Z"
        fill={props.fill ? props.fill : "#EE4360"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.332 0.833008C11.6082 0.833008 11.832 1.05687 11.832 1.33301V3.08739C11.832 3.36354 11.6082 3.58739 11.332 3.58739C11.0559 3.58739 10.832 3.36354 10.832 3.08739V1.33301C10.832 1.05687 11.0559 0.833008 11.332 0.833008Z"
        fill={props.fill ? props.fill : "#EE4360"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.24561C3.5 5.96946 3.72386 5.74561 4 5.74561L12 5.74561C12.2761 5.74561 12.5 5.96946 12.5 6.24561C12.5 6.52175 12.2761 6.74561 12 6.74561L4 6.74561C3.72386 6.74561 3.5 6.52175 3.5 6.24561Z"
        fill={props.fill ? props.fill : "#EE4360"}
      />
    </svg>
  </SvgIcon>
);

export default withStyles(style)(CalendarIcon);
