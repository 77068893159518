const style = {
  formated: {
    fontSize: "16px",
    left: "2px",
    right: "2px",
    top: "2px",
    bottom: "2px",
    borderRadius: "2px",
    padding: "7px 12px",
    zIndex: "1",
    backgroundColor: "white",
    cursor: "text",
    height: "34px",
  },
};

export default style;
