import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
    borderRadius: "6px",
    fontSize: 11,
  },
});

const LightTooltip = (props: TooltipProps) => {
  const classes = useStyles();

  return <Tooltip {...props} classes={{ tooltip: classes.tooltip }} enterTouchDelay={0} />;
};

export { LightTooltip };
