import { CircularProgress } from "@material-ui/core";
import { CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";
import cn from "classnames";
import { SkipDesignSubmitAndPayButton } from "./SkipDesignSubmitAndPayButton";

interface SubmitAndPayButtonProps {
  internalStatus: keyof typeof CAMPAIGN_INTERNAL_STATUSES;
  loading: boolean;
  disabled: boolean;
  handleClickSubmitAndPay: () => void;
  isPaymentSkipped: boolean;
  classes: { [key: string]: string };
  isSkipEnabled: boolean;
  isNotDesign: boolean;
}

export const SubmitAndPayButton = ({
  internalStatus,
  loading,
  disabled,
  handleClickSubmitAndPay,
  isPaymentSkipped,
  isSkipEnabled,
  classes,
  isNotDesign,
}: SubmitAndPayButtonProps) => {
  if (isSkipEnabled && isNotDesign) {
    return (
      <SkipDesignSubmitAndPayButton
        internalStatus={internalStatus}
        loading={loading}
        disabled={disabled || isNotDesign}
        handleClickSubmitAndPay={handleClickSubmitAndPay}
        isPaymentSkipped={isPaymentSkipped}
        classes={classes}
      />
    );
  } else {
    return internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT ? (
      <div
        onClick={handleClickSubmitAndPay}
        className={cn(classes.button, "mob-button-wrapper", {
          [classes.disabled]: disabled,
        })}
      >
        <div className={classes.buttonText}>{!isPaymentSkipped ? "Submit and Pay" : "Submit"}</div>
        {loading && isPaymentSkipped && <CircularProgress size={20} className={classes.progress} color="secondary" />}
      </div>
    ) : null;
  }
};
