import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type CustomTooltipContentProps = {
  title?: string;
  content?: string;
};

export const CustomTooltipContent = ({ title = "", content = "" }: CustomTooltipContentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.content}>{content}</Typography>
    </div>
  );
};
