// NOTICE: This file was copied from the oppizi-shared repository, do
// not modify it here.

/**
 * Debounce a function so it doesn't fire until waiting a set amount of time
 * @param {Function} func
 * @param {number} delay in milliseconds
 * @returns {Function}
 */
export function debounce(func, delay) {
  var timeout = null
  return function(...args) {
    clearTimeout(timeout)
    timeout = setTimeout(function() {
      func(...args)
    }, delay)
  }
}
