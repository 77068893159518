export const styles = {
  hintWrapper: {
    zIndex: "2",
    position: "absolute",
    top: "112px",
    right: "20px",
    background: "#F9F9FF",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    width: "420px",
    padding: "6px 6px 12px",
  },

  hintTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "7px",

    "& > img": {
      cursor: "pointer",
    },
  },
  hintTitleIconName: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "18px",
      marginLeft: "8px",
    },
  },
};
