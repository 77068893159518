import { useContext, useCallback, memo } from "react";
import { default as MUButton } from "@material-ui/core/Button";

import { FormContext } from "../index";

const Button = (props) => {
  const { onClick, type } = props;
  const form = useContext(FormContext);

  const handleClick = useCallback(
    (e) => {
      onClick && onClick(e, form);
      if (type === "submit") {
        form.submit();
      }
    },
    [form, onClick, type]
  );

  return <MUButton {...props} onClick={handleClick} style={{ textTransform: "none" }} />;
};

export default memo(Button);
