export const style = {
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 436,
    width: "100%",
    marginTop: "174px",
    "@media screen and (max-width: 768px)": {
      display: "flex",
      flexDirection: "column",
      height: "100px",
      width: "100%",
      marginTop: "50px",
      marginBottom: "40px",
    },
  },
  nextBtn: {
    cursor: "pointer",
    width: "200px",
    background: "#EE4360",
    border: "none",
    borderRadius: "16px",
    padding: "10px 12px",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    alignText: "center",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "@media screen and (max-width: 768px)": {
      padding: "14px 12px",
    },
  },
  nextBtnWithFile: {
    cursor: "pointer",
    marginTop: "174px",
    width: "100%",
    maxWidth: 436,
    background: "#EE4360",
    border: "none",
    borderRadius: "16px",
    padding: "10px 12px",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    alignText: "center",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  uploadLaterBtn: {
    cursor: "pointer",
    width: "200px",
    background: "transparent",
    border: "1px solid #E2E2FC",
    borderRadius: "12px",
    padding: "10px 12px",
    color: "#2E3860",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    alignText: "center",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "@media screen and (max-width: 768px)": {
      maxWidth: "100%",
      width: 436,
      padding: "14px 12px",
    },
  },
  uploadLaterBtnMobileWrapper: {
    marginTop: "55px",
  },
  nextBtnDisabled: {
    background: "#C2C8D1",
    color: "#FFFFFF",
    cursor: "default",
    "&:hover": {
      transform: "none",
    },
  },
};
