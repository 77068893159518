// NOTICE: This file was copied from the oppizi-shared repository, do
// not modify it here.

/**
 *
 * @param {array} array Array that should be converted
 * @param {string} key Key that will be used for object, like "id"
 *
 * @returns {object} Hash map
 * @example convertArrayToHashMap([{ id:463, name: "Sydney" }], "id")
 * Will return
 * {
 *   463: { id: 463, name: "Sydney" }
 * }
 */
export const convertArrayToHashMap = (array, key) => {
  return array.reduce((acc, item) => {
    acc[item[key]] = item
    return acc
  }, {})
}
