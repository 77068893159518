import { SVGContainer, Utils } from "@tldraw/core";
import { GHOSTED_OPACITY } from "pages/flyer/builder/drawer/constants/constants";
import {
  defaultRectangleStyle,
  getBoundsRectangle,
  transformRectangle,
  transformSingleRectangle,
} from "pages/flyer/builder/drawer/state/shapes/shared";
import { styled } from "pages/flyer/builder/drawer/styles";
import { DashStyle, RectangleShape, TDMeta, TDShapeType } from "pages/flyer/builder/drawer/types";
import * as React from "react";
import { TDShapeUtil } from "../TDShapeUtil";
import { BindingIndicator } from "./components/BindingIndicator";
import { DashedRectangle } from "./components/DashedRectangle";
import { DrawRectangle } from "./components/DrawRectangle";
import { getRectangleIndicatorPathTDSnapshot } from "./rectangleHelpers";

type T = RectangleShape;
type E = HTMLDivElement;

export class RectangleUtil extends TDShapeUtil<T, E> {
  type = TDShapeType.Rectangle as const;

  canBind = true;

  canClone = true;

  canEdit = true;

  getShape = (props: Partial<T>): T => {
    return Utils.deepMerge<T>(
      {
        id: "id",
        type: TDShapeType.Rectangle,
        name: "Rectangle",
        parentId: "page",
        childIndex: 1,
        point: [0, 0],
        size: [1, 1],
        rotation: 0,
        style: defaultRectangleStyle,
        label: "",
        labelPoint: [0.5, 0.5],
      },
      props
    );
  };

  Component = TDShapeUtil.Component<T, E, TDMeta>(
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { shape, isBinding, isSelected, isGhost, meta, events, onShapeChange },
      ref
    ) => {
      const { id, size, style } = shape;
      const Component = style.dash === DashStyle.Draw ? DrawRectangle : DashedRectangle;

      return (
        <FullWrapper ref={ref} {...events}>
          <SVGContainer id={shape.id + "_svg"} opacity={isGhost ? GHOSTED_OPACITY : 1}>
            {isBinding && <BindingIndicator strokeWidth={0} size={size} />}
            <Component id={id} style={style} size={size} isSelected={isSelected} isDarkMode={meta.isDarkMode} />
          </SVGContainer>
        </FullWrapper>
      );
    }
  );

  Indicator = TDShapeUtil.Indicator<T>(({ shape }) => {
    const { id, style, size } = shape;

    if (style.dash === DashStyle.Draw) {
      return <path d={getRectangleIndicatorPathTDSnapshot(id, style, size)} />;
    }

    return <rect width={Math.max(1, size[0])} height={Math.max(1, size[1])} />;
  });

  getBounds = (shape: T) => {
    return getBoundsRectangle(shape, this.boundsCache);
  };

  shouldRender = (prev: T, next: T) => {
    return next.size !== prev.size || next.style !== prev.style || next.label !== prev.label;
  };

  transform = transformRectangle;

  transformSingle = transformSingleRectangle;
}

const FullWrapper = styled("div", { width: "100%", height: "100%" });
