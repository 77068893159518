import { addDays, addWeeks, format, addMinutes } from "date-fns";
import moment from "moment";
import { READABLE_CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";
import { DEFAULT_WEEKS_COUNT } from "../../../../constants";
// import { apiClient } from "../../../../module/api";
// import { getCityLocations } from "../../../../utils/getCityLocations";

const renderStatusBackground = (status) => {
  switch (status) {
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.DRAFT:
      return "rgba(251, 132, 0, 0.14)";
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.IN_REVIEW:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.UPLOAD_CODES:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.CODE_REVIEW:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.GENERATING_DIGITAL_PROOF:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.PENDING_DIGITAL_PROOF:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.PAYMENT:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.PRINTING:
      return "#C2CFFF";
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.ACTIVE:
      return "#01BFA6";
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.COMPLETED:
      return "#e6e6e6";
    default:
      "#FFECCC";
  }
};

const renderStatusColor = (status) => {
  switch (status) {
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.DRAFT:
      return "#E47800";
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.IN_REVIEW:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.UPLOAD_CODES:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.CODE_REVIEW:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.GENERATING_DIGITAL_PROOF:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.PENDING_DIGITAL_PROOF:
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.PAYMENT:
      return "#4A4A4A";
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.ACTIVE:
      return "#FFFFFF";
    case READABLE_CAMPAIGN_INTERNAL_STATUSES.COMPLETED:
      return "#a7a7a7";
    default:
      "#4A4A4A";
  }
};

const renderStatus = (status) => (
  <div
    style={{
      background: renderStatusBackground(status),
      borderRadius: "8px",
      padding: "2px 15px",
      fontSize: "18",
      lineHeight: "22px",
      fontWeight: 500,
      minWidth: "80px",
      minHeight: "24px",
      color: renderStatusColor(status),
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {status}
  </div>
);

const calculateDates = ({ value, minStartDate, maxEndDate, campaignDuration = DEFAULT_WEEKS_COUNT }) => {
  if (!value) {
    return {
      startDate: {
        value: null,
        minDate: format(minStartDate, "yyyy-MM-dd"),
        maxDate: null,
        error: "Please select a launch date for your campaign",
      },
      endDate: {
        value: null,
        minDate: null,
        maxDate: null,
        error: null,
      },
    };
  }

  const date = value;

  const add8Weeks = addWeeks(date, campaignDuration);
  const add90Days = addDays(add8Weeks, 90);

  const add30Days = addDays(add8Weeks, 30);
  const add180Days = addDays(add8Weeks, 180);

  const startDate = format(date, "yyyy-MM-dd");

  // Wee need to validate the difference between days without taking into account the hours
  const startDateWithoutHours = moment(date).format("DD/MM/YYYY");
  const minDateWithoutHours = moment(minStartDate).format("DD/MM/YYYY");
  const daysDiff = moment(startDateWithoutHours, "DD/MM/YYYY", true).diff(
    moment(minDateWithoutHours, "DD/MM/YYYY", true),
    "days"
  );

  // const isErrorMinDate = !isFirstDayMoreOrSame(date, minStartDate);
  const isErrorMinDate = daysDiff < 0;

  return {
    startDate: {
      value: startDate,
      minDate: format(minStartDate, "yyyy-MM-dd"),
      // maxDate: format(maxEndDate, "yyyy-MM-dd"),
      maxDate: null,
      error: isErrorMinDate ? "Launch date cannot be within the next 14 days" : null,
    },
    endDate: {
      value: format(add90Days, "yyyy-MM-dd"),
      minDate: format(add30Days, "yyyy-MM-dd"),
      // maxDate: format(add180Days, "yyyy-MM-dd"),
      maxDate: null,
      error: null,
    },
  };
};

const createPage = () => {
  const page = document.createElement("div");
  page.setAttribute("style", "padding:20px 20px; height:100vh");

  return page;
};

const createPrintingPage = (refs) => {
  const arrayRefs = Array.isArray(refs) ? refs : [refs];
  const page = createPage();
  arrayRefs.forEach((ref, index) => {
    const elem = ref.current.cloneNode(true);
    if (index) {
      elem.setAttribute("style", "margin-top:20px");
    }

    page.appendChild(elem);
  });
  return page;
};

const addTrailingZeros = (num, totalLength) => {
  return String(num).padEnd(totalLength, "0");
};

const convertNumber = ({ number = 0, currency = "", fixed = 2, isUSClient = false }) => {
  const fixedNumber = String(
    Math.trunc(((Number(number) || 0) * Number(addTrailingZeros(1, fixed + 1))).toFixed(fixed)) /
      Number(addTrailingZeros(1, fixed + 1))
  );
  const [num, decimal] = fixedNumber.split(".");
  const formattedNumber = Number(num).toLocaleString("en-US");
  return `${currency}${isUSClient ? formattedNumber : formattedNumber.replace(/,/g, " ")}.${addTrailingZeros(
    decimal || 0,
    fixed
  )}`;
};

const convertNumberRounded = ({ number = 0, currency = "", fixed = 2, isUSClient = false }) => {
  const fixedNumber = String(
    Math.round(((Number(number) || 0) * Number(addTrailingZeros(1, fixed + 1))).toFixed(fixed)) /
      Number(addTrailingZeros(1, fixed + 1))
  );
  const [num, decimal] = fixedNumber.split(".");
  const formattedNumber = Number(num).toLocaleString();
  return `${currency}${isUSClient ? formattedNumber : formattedNumber.replace(/,/g, " ")}.${addTrailingZeros(
    decimal || 0,
    fixed
  )}`;
};

const calculateBlockHeightByRef = (ref, additionalHeight) =>
  ref.current ? ref.current.clientHeight + additionalHeight : additionalHeight;

const convertDateToCurrentTimezone = (date) => {
  const tzOffset = new Date().getTimezoneOffset();

  return addMinutes(new Date(date), tzOffset);
};

// const saveCustomLocations = async ({ city, clientId, campaignId }) => {
//   if (city?.addresses?.some((address) => !address.isFixedLocation)) {
//     const campaignLocations = await getCityLocations({ cityId: city.id, campaignId, isSelfServe: true, clientId });
//     // find location for custom addresses
//     const newSelfServeLocations = city.addresses.reduce((acc, curr) => {
//       if (!curr.isFixedLocation) {
//         const currentLocation = campaignLocations.find((location) => location.id === curr.id);
//         if (currentLocation && currentLocation.campaignId && !currentLocation.clientId) {
//           // separate locations that has campaign_id but no client_id
//           acc.push(currentLocation);
//         }
//         return acc;
//       } else {
//         return acc;
//       }
//     }, []);

//     if (newSelfServeLocations.length) {
//       for (const newSelfServeLocation of newSelfServeLocations) {
//         // update client_id in custom locations to show them as recommended custom location in future campaigns
//         const savedLocation = await apiClient.updateLocation({ client_id: clientId }, newSelfServeLocation.id);
//         if (savedLocation) {
//           return await savedLocation.json();
//         }
//       }
//     }
//   }
// };

export {
  renderStatus,
  createPrintingPage,
  calculateDates,
  convertNumber,
  convertNumberRounded,
  calculateBlockHeightByRef,
  convertDateToCurrentTimezone,
  // saveCustomLocations,
};
