import CONFIG from "config/config";
import React from "react";
import { uploadFile } from "react-s3";

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function useMultiplayerAssets(roomId: string) {
  const onAssetCreate = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (file: File, id: string): Promise<string | false> =>
      new Promise((resolve, reject) => {
        const filename = encodeURIComponent(file.name).replace(/[^a-zA-Z0-9 ]/g, "");
        const encodedRoomId = encodeURIComponent(roomId).replace(/[^a-zA-Z0-9 ]/g, "");
        // const fileType = encodeURIComponent(file.type);
        // console.log(
        //   "🚀 ~ file: useMultiplayerAssets.ts ~ line 30 ~ process.env.REACT_APP_SERVER_URL",
        //   process.env.REACT_APP_SERVER_URL
        // );
        // console.log("🚀 ~ file: useMultiplayerAssets.ts ~ line 30 ~ process.env.CONFIG.SERVER_URL", CONFIG.SERVER_URL);
        // const response = await fetch(CONFIG.SERVER_URL + "/campaign-builder/upload", {
        //   method: "PUT",
        //   body: JSON.stringify({
        //     path: `/fb-images/${roomId}/${filename + "id" + new Date().getTime()}`,
        //     type: fileType,
        //     data: await toBase64(file),
        //   }),
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
        // const responseData = await response.json();
        // return responseData.url;
        uploadFile(file, {
          bucketName: "oppizi-uploads",
          dirName: "campaign-builder/" + `/fb-images/${encodedRoomId}/${filename + "id" + new Date().getTime()}`,
          region: "ap-southeast-2",
          accessKeyId: CONFIG.AWS.KEY,
          secretAccessKey: CONFIG.AWS.SECRET,
        })
          .then((data: any) => {
            resolve(data.location);
          })
          .catch((err: any) => {
            console.error("errror: ", err);
            reject(err);
          });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onAssetDelete = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (id: string): Promise<boolean> => {
      // noop
      return true;
    },
    []
  );

  return { onAssetCreate, onAssetDelete };
}
