import { memo, useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import CustomSelect from "components/custom-select";
import { TIMELIST, getTimeRange, getDateOfFormat } from "components/date-picker/utils";
import { Icon } from "components/icons";
import { isInvalidMission } from "../../../utils";
import { styles } from "./styles";
import { DeleteButton } from "../DeleteButton";

const useStyles = makeStyles(() => styles);

export const Mission = memo(
  ({
    startDate,
    time,
    baNumsPerLocation,
    onUpdateTime,
    onDeleteMission,
    flyersAmountByMission,
    timezone,
    isSubmitted,
    defaultCampaignLimits,
  }) => {
    const classes = useStyles();

    const existInvalidMission = useMemo(
      () => isInvalidMission(startDate, timezone, defaultCampaignLimits),
      [startDate]
    );

    const options = TIMELIST.map((t) => ({
      value: t,
      label: getTimeRange(t),
    }));

    return (
      <Box className={classes.missionContainer}>
        <Typography className={classes.dateContainer}>
          {existInvalidMission && <Icon type="Attention" size="s" />}
          <Typography className={classes.missionItem}>{getDateOfFormat(startDate)}</Typography>
        </Typography>

        <Typography className={`${classes.missionItem} ${classes.timeSelectItem}`}>
          <CustomSelect
            selectedValue={Number(time)}
            options={options}
            disabled={isSubmitted}
            onChange={(newTime) => {
              if (newTime === Number(time)) return;
              onUpdateTime(newTime);
            }}
          />
        </Typography>

        <Typography className={classes.missionItem} style={{ justifyContent: "center" }}>
          {baNumsPerLocation}
        </Typography>

        <Typography className={classes.missionItem} style={{ justifyContent: "center" }}>
          {flyersAmountByMission * baNumsPerLocation}
        </Typography>

        {!isSubmitted && (
          <Typography className={`${classes.missionItem} ${classes.lastItem}`}>
            <DeleteButton onClick={onDeleteMission} />
          </Typography>
        )}
      </Box>
    );
  }
);
