import SvgIcon from "@material-ui/core/SvgIcon";
import cn from "classnames";
import { withStyles } from "@material-ui/core/styles";

const style = {
  root: {
    width: "20px",
    height: "21px",
  },
};

const PolygonIcon = ({ classes, className, ...props }) => (
  <SvgIcon {...props} className={cn(classes.root, className)} fill="none" viewBox="0 0 20 21">
    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.54123 14.3697L2.72165 7.58874L16.3733 6.28293L14.3382 16.1328L5.54123 14.3697Z" stroke="#011533" />
      <circle cx="16.5" cy="6.2207" r="1" fill="white" stroke="#011533" />
      <circle cx="14.5" cy="16.2207" r="1" fill="white" stroke="#011533" />
      <circle cx="2.5" cy="7.2207" r="1" fill="white" stroke="#011533" />
      <circle cx="5.5" cy="14.2207" r="1" fill="white" stroke="#011533" />
    </svg>
  </SvgIcon>
);

export default withStyles(style)(PolygonIcon);
