export const styles = {
  resendLink: {
    cursor: "pointer",
    color: "#0C172C",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  resendLabel: {
    color: "#4F5B94",
    fontWeight: "400",
  },
};
