import React, { useCallback, useContext } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { TDShapeType } from "pages/flyer/builder/drawer/types";

const TextComponent = () => {
  const classes = useStyles();
  const app = useTldrawApp();

  const handleCreateText = useCallback(
    (style, label) => {
      app.selectTool(TDShapeType.TextArea);
      const x = 215;
      const y = 260;
      app.createTextShapeAtPoint([x, y], style, label);
    },
    [app]
  );

  return (
    <div className={classes.container}>
      <div
        className={classes.textButton}
        onClick={() =>
          handleCreateText(
            {
              fontSize: 20,
              fontStyle: ["bold"],
              fillColor: "#000000",
              textAlign: "middle",
              verticalTextAlign: "middle",
            },
            "Add a heading"
          )
        }
      >
        <span className={classes.headingLabel}>Add a heading</span>
      </div>
      <div
        className={classes.textButton}
        onClick={() =>
          handleCreateText(
            {
              fontSize: 16,
              fontStyle: ["bold"],
              fillColor: "#000000",
              textAlign: "middle",
              verticalTextAlign: "middle",
            },
            "Add a subheading"
          )
        }
      >
        <span className={classes.subheadingLabel}>Add a subheading</span>
      </div>
      <div
        className={classes.textButton}
        onClick={() =>
          handleCreateText(
            { fontSize: 16, fillColor: "#000000", textAlign: "middle", verticalTextAlign: "middle" },
            "Add a body text"
          )
        }
      >
        <span className={classes.bodyTextLabel}>Add a body text</span>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F9F9FF",
    width: "100%",
    height: "40px",
    borderRadius: "8px",
    padding: "6px 15px",
    cursor: "pointer",
    marginBottom: "10px",
    color: "#011533",
  },
  headingLabel: {
    fontFamily: "Proxima Nova, sans-serif",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "20px",
  },
  subheadingLabel: {
    fontFamily: "Proxima Nova, sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
  },
  bodyTextLabel: {
    fontFamily: "Proxima Nova, sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
  },
});

export const Text = React.memo(TextComponent);
