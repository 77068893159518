export const style = {
  rootStyles: {
    height: "40px",
    backgroundColor: "white",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    width: "196px",
  },
  inputStyles: {
    color: "#707087",
    fontSize: "16px",
    paddingLeft: "5px",
  },
  hideElement: {
    visibility: "hidden" as const,
  },
  clearButton: {
    cursor: "pointer",

    "&:hover": {
      transform: "Scale(1.3)",
      backgroundColor: "transparent",
    },

    "&:active": {
      transform: "Scale(1.1)",
    },
  },
};
