import dayjs, { Dayjs } from "dayjs";
import businessDays from "business-days-js";

export const getDateFromDayjs = (date: Dayjs) => new Date(date.format("YYYY-MM-DD"));

// We should avoid using new Date() instances since will be representing user's date but not selected city's date. Aug 10 2023.
// export const getBeforeNDays = (date: Date, n: number) => new Date(dayjs(date).subtract(n, "day").format("YYYY-MM-DD"));
// export const getAfterNadays = (date: Date, n: number) => new Date(dayjs(date).add(n, "day").format("YYYY-MM-DD"));

export const addDays = (date: Date, n: number, timezone: string | undefined) => {
  if (timezone) {
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timezone }));
    return new Date(dayjs(tzDate).add(n, "day").format());
  }
  return new Date(dayjs(date).add(n, "day").format());
};

export const addBusinessDays = (date: Date, n: number, timezone: string | undefined) => {
  const bDays = businessDays();

  if (timezone) {
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timezone }));
    return new Date(bDays.addDays(tzDate, n).format());
  }
  return new Date(bDays.addDays(date, n).format());
};

// Mar 1, 2023
export const getDateOfFormat = (date: Date) => dayjs(date).format("MMM DD, YYYY");

export const getPrevMonth = (month = 0) => (month + 11) % 12;

export const getNextMonth = (month = 0) => (month + 1) % 12;

const pad2 = (n: number) => (n < 10 ? "0" : "") + n;

const getTimeName = (t: number) => (t >= 12 ? `${t === 12 ? 12 : t - 12} PM` : `${pad2(t)} AM`);

export const getTimeRange = (t: number) => `${getTimeName(t)} - ${getTimeName(t + 3)}`;
