import CONFIG from "config/config";
import { callNoAuthorizedGraphQLEndpoint, callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const authUser = (input) => {
  const mutation = `
    mutation($input: AuthUserInput) {
      authUser(input: $input){
        user {
          id
          clientId
          clientName
          firstName
          lastName
          isSelfServeEnabled
          role
          email
          isVerified
        }
        token
      }
    }`;

  return callNoAuthorizedGraphQLEndpoint(mutation, { input }, CONFIG.GRAPHQL_API_BASE_URL);
};

export const sendClientVerificationByEmail = () => {
  const mutation = `mutation sendClientVerificationEmail($input: SendClientVerificationEmailInput) {
    sendClientVerificationEmail(input: $input){
        success
        error,
      }
    }`;
  return callAuthorizedGraphQLEndpoint(mutation);
};

export const isClientEmailVerified = () => {
  const mutation = `mutation getClientEmailVerified($input: GetClientEmailVerifiedInput) {
    getClientEmailVerified(input: $input){
      success
      error,
    }
  }`;
  return callAuthorizedGraphQLEndpoint(mutation);
};

export const sendEmail = (input) => {
  const query = `mutation sendEmail(
      $input: SendEmailInput
    ) {
      sendEmail(
        input: $input,
      ) { 
        success
        recipient
      }
    }`;

  return callAuthorizedGraphQLEndpoint(query, input);
};
