export const ImageIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 22.25H9C6.3424 22.25 4.56492 21.6803 3.4423 20.5577C2.31968 19.4351 1.75 17.6576 1.75 15V9C1.75 6.3424 2.31968 4.56492 3.4423 3.4423C4.56492 2.31968 6.3424 1.75 9 1.75H15C17.6576 1.75 19.4351 2.31968 20.5577 3.4423C21.6803 4.56492 22.25 6.3424 22.25 9V15C22.25 17.6576 21.6803 19.4351 20.5577 20.5577C19.4351 21.6803 17.6576 22.25 15 22.25ZM9 2.25C6.65972 2.25 4.92432 2.66107 3.7927 3.7927C2.66107 4.92432 2.25 6.65972 2.25 9V15C2.25 17.3403 2.66107 19.0757 3.7927 20.2073C4.92432 21.3389 6.65972 21.75 9 21.75H15C17.3403 21.75 19.0757 21.3389 20.2073 20.2073C21.3389 19.0757 21.75 17.3403 21.75 15V9C21.75 6.65972 21.3389 4.92432 20.2073 3.7927C19.0757 2.66107 17.3403 2.25 15 2.25H9Z"
        stroke="white"
      />
      <path
        d="M9 10.25C7.75614 10.25 6.75 9.24386 6.75 8C6.75 6.75614 7.75614 5.75 9 5.75C10.2439 5.75 11.25 6.75614 11.25 8C11.25 9.24386 10.2439 10.25 9 10.25ZM9 6.25C8.03386 6.25 7.25 7.03386 7.25 8C7.25 8.96614 8.03386 9.75 9 9.75C9.96614 9.75 10.75 8.96614 10.75 8C10.75 7.03386 9.96614 6.25 9 6.25Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M2.46604 19.0927L2.46605 19.0927L2.46416 19.0899C2.38971 18.9798 2.41852 18.8242 2.5346 18.7479L2.53462 18.7479L2.53872 18.7452L7.46873 15.4352L7.47002 15.4343C8.36222 14.8312 9.60364 14.9055 10.4136 15.5888L10.74 15.8756L10.7399 15.8757L10.744 15.8792C11.0985 16.184 11.5574 16.3226 11.9938 16.3226C12.4289 16.3226 12.8867 16.1847 13.2376 15.8778L17.3956 12.3095L17.3957 12.3094C18.2672 11.5613 19.661 11.559 20.5451 12.3101C20.5455 12.3104 20.5458 12.3107 20.5461 12.3109L22.1716 13.7071C22.172 13.7074 22.1723 13.7077 22.1726 13.708C22.2759 13.7989 22.2903 13.9549 22.2004 14.0646C22.1088 14.1667 21.9528 14.1799 21.8437 14.089C21.8433 14.0886 21.8429 14.0883 21.8425 14.0879L20.216 12.691C20.216 12.6909 20.2159 12.6908 20.2158 12.6908C19.5284 12.0998 18.4016 12.0998 17.7142 12.6908C17.7142 12.6908 17.7141 12.6909 17.714 12.691L13.5544 16.2606L13.5543 16.2607C12.6835 17.0083 11.2911 17.0111 10.4069 16.2617L10.0801 15.9745L10.0801 15.9744L10.0734 15.9687C9.44031 15.432 8.43335 15.3816 7.73939 15.8562L2.82156 19.1581C2.77305 19.1862 2.71691 19.2001 2.67002 19.2001C2.59009 19.2001 2.50943 19.1578 2.46604 19.0927Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
