import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

//TODO getFlyerInfo delete campaign
export const getCampaignFlyerData = async (campaignId) => {
  const query = `query 
    ($campaignId:ID!) {
        campaignFlyerInfo(campaignId: $campaignId) {
          s3Link
          flyerKey
          flyerName
          isFlyerUploaded
          activeRoomId
        }
        campaign(id:$campaignId){
          selfServeData {
            qrCodeLink
          }
        }
    }`;

  const request = await callAuthorizedGraphQLEndpoint(query, {
    campaignId,
  });

  if (request.status === 200) {
    const { data } = await request.json();
    return data;
  }
};
