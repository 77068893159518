import { useHistory } from "react-router";
import { LoadingScreen } from "components/audience-map/components/LoadingScreen";
import useOverlaysFetch from "Hooks/useOverlaysFetch.js";
import { MAP_TYPES, radiusToZoom, CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";
import CONFIG from "config/config";
import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState, useRef, useMemo, useCallback, useContext } from "react";
import { NextButton } from "components/next-button";
import { CustomTooltipContent } from "components/tooltip-content";
import cn from "classnames";
import { LightTooltip } from "components/light-tooltip";
import { ProgressBarContext } from "components/progress-bar";
import { checkIsNeedUpdateLastActiveStepState } from "utils";
import { generatePath } from "react-router-dom";
import { DM_ROUTING } from "config/routing";
import { createQuote, updateCampaignExtraData, updateSelfServeCampaignData, updateCampaign } from "../../../../graphQL";

import { styles } from "./styles.js";
import { MAP_EXTRA_STYLES } from "../../options";
import { SummaryBlock } from "./components/SummaryBlock";
import { RouteStatistics } from "./components/RouteStatistics";
import { AddressBlock } from "./components/AddressesBlock";
import { useEDDMCostsCalculation } from "../../../../Hooks/useEDDMCostsCalculation";
import {
  onMapInit,
  handleScheduleMeeting,
  renderPolygonsOnEddmMap,
  handleHoverSelectedMissionUpdate,
} from "../../utils.js";

import { useStore } from "../../../../store";

const useStyles = makeStyles(() => styles);

export const AudienceMapEDDM = ({
  isSubmitted,
  radius,
  setRadius,
  selectedRoutes,
  setSelectedRoutes,
  shouldAutoSave,
  setShouldAutoSave,
}) => {
  const classes = useStyles();
  const {
    country,
    map: { center, zoom, loading, loadingPercentage },
    campaign: { campaignName, internalStatus, id: campaignId, lastActiveStep },
    client: { id: clientId, name: clientName },
    updateMap,
    hoveredRoute,
    setHoveredRoute,
    distributionLocations,
    setDistributionLocations,
    setFocusedLocation,
    costsCalculationData,
  } = useStore();
  const { recalculateEDDMCampaignCost } = useEDDMCostsCalculation();
  const { totalCost, tax } = costsCalculationData?.detailedCost || {};

  const focusedLocation = useMemo(
    () => distributionLocations.find((location) => location.isFocused),
    [distributionLocations]
  );

  const { loadData } = useOverlaysFetch({ countryCode: country.code, disabled: false });
  const drawingMode = null;
  // const [drawingManager, setDrawingManager] = useState(null);
  const [routes, setRoutes] = useState({});
  const [googleMapKey, setGoogleMapKey] = useState(new Date().getTime());
  const [googleMap, setGoogleMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY,
    libraries: ["geometry", "places", "drawing"],
    language: "en",
  });

  const runProgressBar = useContext(ProgressBarContext);
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // useRef
  const clickSaveButtonRef = useRef(false);

  // useMemo
  const isNextActive = useMemo(() => {
    return costsCalculationData.flyersAmount && costsCalculationData.flyersAmount > 1000;
  }, [costsCalculationData.flyersAmount, isSubmitted]);

  const TooltipContent = useMemo(() => {
    if (!isNextActive) {
      return (
        <CustomTooltipContent
          title="Not enough addresses!"
          content="To proceed, please select at least 1000 addresses."
        />
      );
    }

    return "";
  }, [isNextActive]);

  // useCallback
  const saveCampaign = async (isAutoSave) => {
    setIsSaving(true);
    if (!isSubmitted && distributionLocations) {
      const payloadCampaignExtraData = {
        campaignId,
        totalCosts: totalCost,
        flyersCount: costsCalculationData?.flyersAmount || 0,
        taxes: tax,
      };

      const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
        stateLastActiveStep: lastActiveStep,
        newLastActiveStep: "segments",
        isDM: true,
      });

      if (isNeedUpdateLastActiveStep) {
        payloadCampaignExtraData.lastActiveStep = "segments";
      }

      await updateCampaignExtraData(payloadCampaignExtraData);
      await updateSelfServeCampaignData({
        campaignId: campaignId,
        distributionLocations: distributionLocations,
      });
      if (isNeedUpdateLastActiveStep && !isAutoSave) {
        updateCampaign({ lastActiveStep: "segments" });
      }
      await setQuoteNumber(campaignId);
      if (!isAutoSave) {
        nextBtnClicked(campaignId);
      }
    }
    setIsSaving(false);
  };

  const setQuoteNumber = async (campaignId) => {
    const quote = await createQuote(campaignId);
    updateCampaign({ quote });
  };

  const headerActions = {
    HOME: {
      action: () => {
        console.log("redirect home");
      },
    },
    NEXT: {
      isActive: true,
      action: async () => {
        if (isSubmitted) {
          runProgressBar(60);
          nextBtnClicked(campaignId);
          runProgressBar(80);
          runProgressBar(-1);
        } else {
          clickSaveButtonRef.current = false;
          await saveCampaign();
        }
      },
      hintPlacement: "bottom",
      hint: "",
    },
  };

  const nextBtnClicked = (id) => {
    return !internalStatus || internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
      ? history.push(generatePath(DM_ROUTING.DM_UPLOAD_FLYER, { campaignId: id, clientId }))
      : history.push(generatePath(DM_ROUTING.DM_CAMPAIGN_DETAILS, { campaignId: id, clientId }));
  };

  const mapRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const geocoder = useMemo(
    () => (window?.google?.maps?.Geocoder ? new window.google.maps.Geocoder() : null),
    [window.google, isLoaded]
  );
  // useEffect(() => {
  //   if (drawingManager) {
  //     drawingManager.setDrawingMode(drawingMode);
  //   }
  // }, [drawingMode, drawingManager]);

  useEffect(() => {
    if (geocoder && googleMap && !Object.values(routes).length) {
      geocoder.geocode({ address: country.name }, (results, status) => {
        if (status === "OK") {
          googleMap.setCenter(results[0].geometry.location);
          updateMap({ zoom: 5 });
          // googleMap.fitBounds(results[0].geometry.viewport);
        }
      });
    }
  }, [geocoder, googleMap, center]);

  useEffect(() => {
    handleHoverSelectedMissionUpdate({ mapInit: googleMap, hoveredMission: hoveredRoute?.id || "" });
  }, [hoveredRoute]);

  useEffect(() => {
    if (Object.values(routes).length) {
      // LOOK FOR ALL THE SELECTED ROUTES IN NON FOCUSED LOCATION
      const selectedRoutesFromNonFocusedLocations = distributionLocations.reduce((acc, curr) => {
        if (curr.isFocused) {
          return acc;
        }
        return [...acc, ...curr.selectedRoutes];
      }, []);

      // LOOK FOR THE SELECTED ROUTES THAT ARE PART OF THE ROUTES FETCHED FOR THE CURRENT FOCUSED LOCATIONS
      // AND REMOVE THE ROUTES THAT ARE ALREADY SELECTED IN SOME OTHER LOCATION
      const selectedRoutesOnCurrentFocusedLocation = Object.values(routes)
        .reduce((acc, curr) => {
          const selectedRoute = selectedRoutes.find((r) => r.id === curr.id);
          if (selectedRoute) {
            acc.push(selectedRoute);
          }
          return acc;
        }, [])
        .filter((r) => !selectedRoutesFromNonFocusedLocations.find((route) => route.id === r.id));

      // SAVE SELECTED ROUTES ON FOCUSED LOCATION AND REMOVE THE UNSELECTED ROUTES FROM OTHER LOCATIONS
      setDistributionLocations(
        distributionLocations.map((l) => {
          if (l.isFocused) {
            return { ...l, selectedRoutes: selectedRoutesOnCurrentFocusedLocation };
          } else {
            return {
              ...l,
              selectedRoutes: l?.selectedRoutes?.filter((r) => selectedRoutes.find((route) => route.id === r.id)) || [],
            };
          }
        })
      );
    }
  }, [selectedRoutes, routes]);

  const fetchRoutes = async ({ layer = "USCarrierRoutesUnflatten", after, previousRoutes = {}, center, radius }) => {
    const includeAddressCount = false;
    const routesResponse = await loadData({
      layer,
      includeAddressCount,
      after,
      centerSphere: center,
      radius,
    });

    if (!routesResponse) {
      setRoutes({});
      return {};
    }

    updateMap({
      loadingPercentage: ((Object.keys(previousRoutes).length * 100) / routesResponse.totalCount).toFixed(),
    });
    if (routesResponse?.pageInfo?.hasNextPage) {
      return await fetchRoutes({
        center,
        radius,
        layer,
        after: routesResponse.pageInfo.endCursor,
        previousRoutes: { ...previousRoutes, ...routesResponse.data },
      });
    } else {
      updateMap({
        loadingPercentage: null,
        loading: false,
      });
      setRoutes({ ...previousRoutes, ...routesResponse.data });
      return { ...previousRoutes, ...routesResponse.data };
    }
  };

  useEffect(() => {
    // SEARCH NEW ROUTES DEPENDING ON SEARCHED LOCATION AND SELECTED RADIUS
    if (focusedLocation && radius) {
      updateMap({ loading: true, center: { lat: focusedLocation.lat, lng: focusedLocation.lng } });
      fetchRoutes({ center: [focusedLocation.lng, focusedLocation.lat], radius: radius * 1609.34 });
    }
  }, [radius, focusedLocation?.name]);

  useEffect(() => {
    if (routes && Object.values(routes).length && googleMap) {
      renderPolygonsOnEddmMap({
        googleMap,
        routes,
        handleRouteSelect,
        selectedRoutes,
        isSubmitted,
        setHoveredRoute,
      });
      updateMap({ zoom: radiusToZoom[radius] });
    }
  }, [googleMap]);

  useEffect(() => {
    // RESET GOOGLE MAP ON EVERY ROUTES FETCH
    if (focusedLocation?.selectedRoutes?.length && Object.values(routes).length) {
      const selectedLocationsToDeleteInFocusedLocation = focusedLocation.selectedRoutes.filter(
        (route) => !Object.values(routes).find((r) => r.id === route.id)
      );
      if (selectedLocationsToDeleteInFocusedLocation.length) {
        setSelectedRoutes((selectedRoutes) => {
          return selectedRoutes.filter(
            (route) => !selectedLocationsToDeleteInFocusedLocation.find((r) => r.id === route.id)
          );
        });
      }
    }
    setGoogleMap(null);
    setGoogleMapKey(new Date().getTime());
  }, [routes]);

  useEffect(() => {
    recalculateEDDMCampaignCost();
    if (!distributionLocations?.length) {
      setRoutes({});
    }
  }, [distributionLocations]);

  const handleRouteSelect = ({ routeId, paths, selected, metadata }) => {
    if (isSubmitted) {
      return;
    }
    setShouldAutoSave(true);
    setSelectedRoutes((routes) => {
      const currentMissionIndex = routes.findIndex((r) => r.id === routeId);
      if (selected && currentMissionIndex === -1) {
        return [
          ...routes,
          {
            id: routeId,
            paths,
            type: MAP_TYPES.POLYGON,
            metadata,
          },
        ];
      } else if (!selected && currentMissionIndex !== -1) {
        return routes.toSpliced(currentMissionIndex, 1);
      } else {
        return routes;
      }
    });
  };

  const onAddressCardClick = (address) => {
    setShouldAutoSave(true);
    setFocusedLocation(address);
    setRadius(address.radius);
  };

  const handleDeleteLocation = (deletedLocation) => {
    setShouldAutoSave(true);
    setDistributionLocations(
      distributionLocations
        .filter((location) => location.name !== deletedLocation.name)
        .map((location, i, array) => {
          if (i + 1 === array.length && deletedLocation.isFocused) {
            setRadius(location.radius);
            return { ...location, isFocused: true };
          } else {
            return location;
          }
        })
    );

    if (deletedLocation?.selectedRoutes?.length) {
      setSelectedRoutes((selectedRoutes) => {
        return selectedRoutes.filter((route) => !deletedLocation?.selectedRoutes.find((r) => r.id === route.id));
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.mapWrapper} ref={(ref) => (mapRef.current = ref)}>
        {loading && (
          <div className={classes.locationsLoading}>
            <LoadingScreen loadingPercentage={loadingPercentage} />
          </div>
        )}
        <GoogleMap
          key={googleMapKey}
          onLoad={(map) => {
            onMapInit({ newMap: map, mapInit: googleMap, setMapInit: setGoogleMap });
          }}
          mapContainerStyle={{
            width: "100vw",
            height: isMobile ? "calc(100vh - 80px)" : "calc(100vh - 68px)",
            // height: "calc(100vh - 68px)",
            marginLeft: 0,
          }}
          center={center}
          zoom={zoom}
          options={{
            clickableIcons: false,
            zoomControl: true,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            draggable: !drawingMode,
            styles: MAP_EXTRA_STYLES,
            gestureHandling: "greedy",
          }}
          // onClick={handleMapClick}
        >
          <div className="mob-main-map-container">
            <AddressBlock
              addresses={distributionLocations}
              onAddressCardClick={onAddressCardClick}
              handleDeleteLocation={handleDeleteLocation}
            />
            <SummaryBlock selectedRoutes={selectedRoutes} campaignName={campaignName} />
            <RouteStatistics route={hoveredRoute} selectedRoutes={selectedRoutes} />
            {!!distributionLocations.length &&
              distributionLocations.map((location) => {
                return (
                  <Marker
                    key={location.name}
                    position={{ lng: location.lng, lat: location.lat }}
                    onClick={() => {
                      setShouldAutoSave(true);
                      setFocusedLocation(location);
                      setRadius(location.radius);
                    }}
                  />
                );
              })}
            <Box
              className={cn(classes.nextBtnWrapper, "mob-next-button desk-hidden")}
              style={loading ? { pointerEvents: "none" } : {}}
            >
              {!isNextActive ? (
                <LightTooltip title={TooltipContent} placement="top">
                  <span>
                    <NextButton isNextActive={isNextActive} />
                  </span>
                </LightTooltip>
              ) : (
                <NextButton
                  loading={!clickSaveButtonRef.current && isLoading}
                  isNextActive={isNextActive}
                  onClick={headerActions.NEXT.action}
                />
              )}
            </Box>
          </div>
          {/* <DrawingManager
            onLoad={(loadedDrawingManager) => onDrawingManagerLoad({ loadedDrawingManager, setDrawingManager })}
            drawingMode={drawingMode}
            options={BROAD_AREAS_MAP_OPTIONS}
          /> */}
        </GoogleMap>
      </Box>
    </Box>
  );
};
