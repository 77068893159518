/* eslint-disable react/no-unknown-property */
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { style } from "./style";

interface ButtonsUploadLaterProps {
  files: any[];
  handleClickNext: () => void;
  disabled: boolean;
  handleUploadLater: () => void;
  readyToUpload: boolean;
  isMobile: boolean;
}

export const ButtonsUploadLater = ({
  files,
  handleClickNext,
  disabled,
  handleUploadLater,
  readyToUpload,
  isMobile,
}: ButtonsUploadLaterProps) => {
  const useStyles = makeStyles(() => style);
  const classes = useStyles();

  if (!files.length && !readyToUpload) {
    return (
      <div className={classes.buttonsContainer}>
        <button onClick={handleUploadLater} className={classes.uploadLaterBtn}>
          Upload later
        </button>
        <button
          className={cn(classes.nextBtn, "mob-next-btn-not-preview", {
            [cn(classes.nextBtnDisabled)]: disabled,
          })}
          disabled={disabled}
          onClick={handleClickNext}
          cypress_id="nextBtn"
        >
          Next
        </button>
      </div>
    );
  } else {
    return (
      <>
        {isMobile && !files.length && !readyToUpload && (
          <div className={classes.uploadLaterBtnMobileWrapper}>
            <button onClick={handleUploadLater} className={classes.uploadLaterBtn}>
              Upload later
            </button>
          </div>
        )}
        <button
          disabled={disabled}
          className={cn(classes.nextBtnWithFile, "mob-next-btn-not-preview", {
            [cn(classes.nextBtnDisabled)]: disabled,
          })}
          onClick={handleClickNext}
          cypress_id="nextBtn"
        >
          Next
        </button>
      </>
    );
  }
};
