import React, { useCallback, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useJsApiLoader } from "@react-google-maps/api";
import CONFIG from "config/config";
import { DocumentOrientation, TDSnapshot, TemplateConfig, PossibleFlyerType } from "pages/flyer/builder/drawer/types";
import { DocumentFormats } from "shared/constants";
import { isValidFlyerType, getFlyerWeightOptions } from "utils/isValidFlyerType";
import { FORMATS } from "../../../../../constants";
import { useTldrawApp } from "../../../../../drawer/hooks";
import SelectMenu from "../../../../Inspector/components/SelectMenu";
import { useStore } from "../../../../../../../../store";
import { StoreType } from "../../../../../../../../store/types";
import useCalculating from "../../../../../../../../Hooks/useCalculating";
import { updateCampaignExtraData } from "../../../../../../../../graphQL";
import { getFlyerSizeDisplay, displayInInch } from "../../../../../utils";

const libraries: any = ["geometry", "places", "drawing"];

const formatSelector = (s: TDSnapshot) => s.document.format;
const orientationsSelector = (s: TDSnapshot) => s.document.orientation;

const SettingsComponent = ({ loading }: { loading: boolean }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY as string,
    libraries: libraries as any,
    language: "en",
  });

  const classes = useStyles();
  const app = useTldrawApp();
  const currentFormat = app.useStore(formatSelector);
  const currentOrientations = app.useStore(orientationsSelector);

  const {
    campaign: { id: campaignId, channel, flyerType, flyerWeight, qrCodeLink },
    costsCalculationData: { printingCosts },
    country,
    updateCampaign,
    updateFlyerBuilder,
  }: StoreType = useStore();

  const { recalculateCampaignCost } = useCalculating();

  const flyerTypeOptions = useMemo(
    () =>
      Object.entries(FORMATS)
        .filter(([key]) =>
          isValidFlyerType({
            countryCode: country.code,
            channel,
            printingCosts,
            flyerType: key,
            flyerWeight: undefined,
            isUploadDesign: false,
          })
        )
        .map(([, value]) => value),
    [channel, country.code, printingCosts]
  );

  const flyerWeightOptions: string[] = useMemo(
    () => getFlyerWeightOptions({ flyerType, printingCosts }),
    [flyerType, printingCosts]
  );

  const getOrientationLabel = (orientation: string) => {
    const isPortrait = orientation === DocumentOrientation.Portrait;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: isPortrait ? "14px" : "18px",
            height: isPortrait ? "18px" : "14px",
            border: "2px solid black",
            borderRadius: "2px",
            marginRight: "10px",
          }}
        ></div>{" "}
        <span>{orientation}</span>
      </div>
    );
  };

  const orientationArr = useMemo(() => {
    const arr = [
      {
        label: getOrientationLabel(DocumentOrientation.Portrait),
        // label: "Portrait",
        action: () => {
          changeTemplate({
            orientation: DocumentOrientation.Portrait,
          });
        },
        isChecked: currentOrientations === DocumentOrientation.Portrait,
      },
      {
        label: getOrientationLabel(DocumentOrientation.Landscape),
        action: () => {
          changeTemplate({
            orientation: DocumentOrientation.Landscape,
          });
        },
        isChecked: currentOrientations === DocumentOrientation.Landscape,
      },
    ];
    return arr;
  }, [flyerType, currentOrientations]);

  const changeTemplate = useCallback(
    async (options: Partial<TemplateConfig>) => {
      app.changeTemplate(options);
    },
    [app]
  );

  const handleChangeFlyerType = async (newFlyerType: PossibleFlyerType) => {
    updateCampaign({ flyerType: newFlyerType });
    await updateCampaignExtraData({
      campaignId,
      flyerType: newFlyerType,
    });
  };

  const handleChangeFlyerWeight = async (newFlyerWeight: string) => {
    updateCampaign({ flyerWeight: newFlyerWeight });
    await updateCampaignExtraData({
      campaignId,
      flyerWeight: newFlyerWeight,
    });
  };

  useEffect(() => {
    if (flyerTypeOptions.length && !flyerTypeOptions.map((format) => format.format).includes(flyerType)) {
      handleChangeFlyerType(flyerTypeOptions[0].format as PossibleFlyerType);
    }
  }, [flyerType, flyerTypeOptions]);

  useEffect(() => {
    if (flyerWeightOptions.includes(flyerWeight)) {
      recalculateCampaignCost();
    } else if (flyerWeightOptions.length) {
      handleChangeFlyerWeight(flyerWeightOptions[0]);
    }
  }, [flyerType, flyerWeight, flyerWeightOptions]);

  const getDisplaySize = (format: TemplateConfig) =>
    getFlyerSizeDisplay(format.widthInMm, format.heightInMm, displayInInch(country.code));

  const displayFlyerValue = currentFormat
    ? `${FORMATS[currentFormat as PossibleFlyerType].name} (${getDisplaySize(
        FORMATS[currentFormat as PossibleFlyerType]
      )})`
    : "";

  if (!isLoaded) return null;

  return (
    <>
      <div className={classes.settings_row}>
        <label className={classes.label}>Orientation</label>
        <SelectMenu
          isDisabled={flyerType !== DocumentFormats.A6}
          items={orientationArr}
          displayValue={getOrientationLabel(currentOrientations)}
          cypressId={"orientation"}
        />
      </div>
      <div className={classes.settings_row}>
        <label className={classes.label}>Flyer format</label>
        <SelectMenu
          isDisabled={false}
          items={flyerTypeOptions.map((format) => {
            return {
              label: format.name,
              helperText: `${getDisplaySize(format)}`,
              action: async () => {
                if (flyerType === format.format) {
                  return;
                }

                updateFlyerBuilder({ loading: true });
                await handleChangeFlyerType(format.format as PossibleFlyerType);
                await changeTemplate({
                  format: format.format,
                });
                updateFlyerBuilder({ loading: false });
              },
              isChecked: currentFormat === format.format,
            };
          })}
          displayValue={displayFlyerValue}
          cypressId={"flyerType"}
        />
      </div>
      <div className={classes.settings_row}>
        <label className={classes.label}>Flyer Weight</label>
        <SelectMenu
          isDisabled={false}
          displayValue={`${flyerWeight} GSM`}
          items={flyerWeightOptions.map((w) => {
            return {
              label: `${w} GSM`,
              helperText: "",
              action: async () => {
                if (flyerWeight === w) {
                  return;
                }

                handleChangeFlyerWeight(w);
              },
              isChecked: flyerWeight === w,
            };
          })}
          cypressId={"flyerWeight"}
        />
      </div>
    </>
  );
};

export const Settings = React.memo(SettingsComponent);

const useStyles = makeStyles({
  root: {
    position: "absolute",
    left: "49px",
    top: "50px",
    bottom: "0px",
  },
  sidebar: {
    background: "var(--color-surface-panel)",
    width: 281,
    height: "100%",
    padding: 16,
    borderLeft: "1px solid var(--color-surface-canvas)",
  },
  settings_row: {
    marginBottom: 16,
  },
  container: {
    background: "var(--color-surface-panel)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 48,
    padding: 8,
  },
  items: {
    display: "grid",
    gap: 4,
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  },
  label: {
    display: "flex",
    fontSize: 14,
    lineHeight: "16px",
    color: "var(--color-deep-blue)",
    fontWeight: 600,
    marginBottom: 4,
    alignItems: "center",
  },
  required: {
    color: "red",
  },
  requiredTip: {
    color: "red",
    marginRight: "6px",
  },
  labelBlock: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoIcon: {
    marginLeft: "8px",
  },
  tooltip: {
    padding: 10,
    backgroundColor: "#eee",
    color: "#000",
    width: "250px",
  },
  tooltipArrow: {
    "&::before": {
      color: "#f9f9f9",
    },
  },
});
