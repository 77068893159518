import React, { useCallback, Fragment, useEffect, useContext, useMemo, MouseEvent } from "react";
import { withRouter } from "react-router";
import cn from "classnames";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { Anchor } from "components/Anchor";
import { Tabs } from "shared/models";
import Box from "@material-ui/core/Box";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { usePostHog } from "posthog-js/react";
import { getCurrencyCode, getLogRocketSessionURL, postHubSpot, hideEmail } from "utils";
import { DialogModalContext } from "components/dialog-modal";
import { If, IfElse } from "components/logic";
import { LightTooltip } from "components/light-tooltip";
import { CustomTooltipContent } from "components/tooltip-content";
import { getTimeRange } from "components/date-picker/utils";
import CONFIG from "config/config";
import { HEADER_TITLE, HELP_URL } from "pages/constants";
import backIcon from "static/media/back.svg";
import faqIcon from "static/media/faq.svg";
import homeIcon from "static/media/home-icon.svg";
import { FeatureFlagContext } from "context/featureFlagContext";
import { SnackBarContext } from "components/snack-bar";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { FlyerStatus } from "pages/flyer/flyerTypePage/useGetDataForPage";
import { useStore } from "../../store";
import { StoreType } from "../../store/types";
import { authorization } from "../../module/auth";
import { CurrentStepIcon } from "../../static/media/current-step";
import { NextButton } from "./components";
import { Badge } from "./components/badge";
import style from "./style.js";
import { FAQClickEDDM } from "./utils";
import { VerificationAlert } from "./components/VerificationAlert";
const Header: React.FC<any> = ({
  classes,
  isFlyerBuilderPage = false,
  flyerActions,
  tabs = [],
  activeTabIndex,
  hideBackButton,
  headerActions,
  isUploadPage = false,
  isSubmitted,
  hasError = false,
  isDM = false,
}) => {
  const isClient = authorization.isClient();
  const runDialogModal = useContext(DialogModalContext);
  const runSnackBar = useContext(SnackBarContext);
  const posthog = usePostHog();
  const params = useParams();
  const history = useHistory();
  const { pageState, isQrCodeExist, isLoading, flyerInfo } = useGetFlyerInfo();
  const handleGoToTab = (href: string) => {
    const link = href.replace(":clientId", params.clientId).replace(":campaignId", params.campaignId).replace("?", "");
    history.push(link);
  };

  const {
    user: {
      id: userId,
      clientId,
      clientName,
      firstName,
      lastName,
      email,
      accessDenied = true,
      phone,
      role,
      isVerified,
    },
    campaign,
    costsCalculationData: { detailedCost },
    country,
    missions,
    city,
    errors: { notExistDesignFile },
    updateCampaign,
    updateUser,
  }: StoreType = useStore();

  const {
    campaignName,
    id: campaignId,
    internalStatus,
    channel,
    flyerType,
    campaignFlyerInfo,
    flyerWeight,
    startDate,
    qrCodeLink,
    campaignComments,
    stripeCouponCode,
    campaignDuration,
    quote,
    missionsCount,
    hubspotDealId,
  } = campaign;

  const { subtotal, printing, distribution, totalCost, tax } = detailedCost || {};

  const homeUrl = useMemo(() => `${CONFIG.PLATFORM_BASE_URL}${isClient ? `/dashboard/home` : ""}`, [isClient]);

  const { isSkipEnabled } = useContext(FeatureFlagContext);

  const postHogHeaderInfo = {
    user_id: userId,
    campaign_id: campaignId,
    company_id: clientId,
    cb_step: tabs.find((tab: Tabs) => tab.index === activeTabIndex)?.step,
  };

  const handleFAQClick = () => {
    let url = "";
    posthog.capture("Clicked on CB header help button", postHogHeaderInfo);
    if (isDM) {
      const path = window.location.pathname.split("/");
      const activeRoute = path[path.length - 1];
      url = FAQClickEDDM(activeRoute);
    } else {
      if (isFlyerBuilderPage) {
        url = HELP_URL[HEADER_TITLE.FLYER_CANVAS];
      } else if (isSubmitted) {
        url = HELP_URL[HEADER_TITLE.SUBMIT];
      } else {
        const currentTab = tabs.find((tab: Tabs) => tab.index === activeTabIndex);
        if (!currentTab) return;

        url = HELP_URL[currentTab.title];
        if (currentTab.title === HEADER_TITLE.FLYER_BUILDER && isUploadPage) {
          url = HELP_URL[HEADER_TITLE.UPLOAD_DESIGN];
        }
      }
    }
    url = url || "https://selfserve.oppizi.com/";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleHomeClick = (e: MouseEvent) => {
    posthog.capture("Clicked on CB home button", postHogHeaderInfo);
    const isCtrlClick = e.ctrlKey || e.metaKey;
    if (isCtrlClick) {
      return;
    } else if (!isCtrlClick && !campaign.isSubmitted) {
      runDialogModal({
        title: "Exit campaign creation?",
        contentText: (
          <Box style={{ padding: "10px 40px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div />
            <div style={{ textAlign: "center" }}>
              Are you sure you want to exit the campaign creation? Any unsaved progress will be lost.
            </div>
          </Box>
        ),
        ctaLabel: "Exit",
        handleCTAClick: () => {
          window.location.href = homeUrl;
        },
        secondaryButtonLabel: "Cancel",
        hasOptions: true,
      });
    }
  };

  const flyerActionClick = useCallback(
    (item, e) => {
      if (posthog) {
        posthog.capture("Completed Flyer Building", {
          event_category: "Flyer Builder",
          campaign_qr_code: qrCodeLink || "",
          campaign_flyer_type: flyerType,
          campaign_flyer_weight: flyerWeight,
        });
      }
      if ((item?.variant && item.isActive) || item.title === "Home" || item.title === "Save") {
        item.action(e);
      }
    },
    [qrCodeLink, flyerType, flyerWeight]
  );

  const getTooltipContent = ({ isValidUrl = false, isQrCodeOnCanvas = false }) => {
    if (!isQrCodeOnCanvas) {
      return (
        <CustomTooltipContent
          title="QR code is outside the safe area!"
          content="Please, make sure the QR code is within the safe areas."
        />
      );
    }

    if (!isValidUrl) {
      return <CustomTooltipContent title="URL missing!" content="Please, provide a valid URL address." />;
    }

    return "";
  };

  const renderButtons = (item: any, index: number, isNext: boolean) => {
    if (isNext && (!item.isValidUrl || !item.isQrCodeOnCanvas)) {
      return (
        <LightTooltip
          title={getTooltipContent({
            isValidUrl: item.isValidUrl,
            isQrCodeOnCanvas: item.isQrCodeOnCanvas,
          })}
          placement="bottom"
        >
          <span>
            <NextButton key={`${item.title}-${index}`} item={item} onClick={flyerActionClick} />
          </span>
        </LightTooltip>
      );
    } else {
      return item.href ? (
        <Anchor
          href={item.href}
          onClick={(e: MouseEvent) => {
            const isCtrlClick = e.ctrlKey || e.metaKey;
            if (!isCtrlClick && !campaign.isSubmitted) {
              e.preventDefault();
            }
          }}
        >
          <NextButton key={`${item.title}-${index}`} item={item} onClick={flyerActionClick} />
        </Anchor>
      ) : (
        <NextButton key={`${item.title}-${index}`} item={item} onClick={flyerActionClick} />
      );
    }
  };

  const handleBackClick = () => {
    posthog.capture("Clicked on CB back button", postHogHeaderInfo);
    headerActions?.BACK?.action();
  };

  useEffect(() => {
    const postHubspotTimer = setTimeout(async () => {
      if (campaignId && clientId && userId && email && activeTabIndex >= 0 && internalStatus === "DRAFT") {
        const logrocket_sessions = getLogRocketSessionURL({ short: false }) || "";
        const stage_id = activeTabIndex + 1;
        const postHubSpotResponse = await postHubSpot({
          deal_id: hubspotDealId,
          pipeline: "OppiziAds",
          stage_id, // required
          campaign_id: campaignId, // required
          company_id: clientId, // required
          contact_id: userId, //required
          email, // required
          logrocket_sessions,
          first_name: firstName || "",
          last_name: lastName || "",
          country: country.name || "",
          city: city?.name || "",
          company_name: clientName || "",
          campaign_name: campaignName || "",
          channel_type: channel || "",
          flyer_type_chosen: campaignFlyerInfo?.isFlyerUploaded
            ? "upload"
            : campaignFlyerInfo?.isFlyerUploaded === false
            ? "design"
            : "" || "",
          flyer_type: flyerType || "",
          flyer_weight: flyerWeight || "",
          design_file_url: campaignFlyerInfo?.s3Link || "",
          launch_date: moment(startDate).format("YYYY-MM-DD") || "",
          campaign_duration: campaignDuration || "",
          qr_code_url: qrCodeLink || "",
          comments: campaignComments || "",
          coupon_code: stripeCouponCode || "",
          tax: tax || 0,
          total: totalCost || 0,
          invoice_number: quote || "",
          number_of_missions: missions?.length || missionsCount || 0,
          number_of_flyers: printing?.quantity || 0,
          amount: stage_id >= 4 ? totalCost : subtotal || 0,
          sub_total: subtotal || 0,
          currency: getCurrencyCode(country) || "",
          distribution_cost: distribution?.total || 0,
          distribution_unit_cost: distribution?.rate || 0,
          printing_cost: printing?.total || 0,
          printing_unit_cost: printing?.rate || 0,
          hasLocations: !!missions?.length,
          missions: missions?.length
            ? // @ts-ignore
              missions.reduce(
                (
                  acc: string,
                  { value, startDate, time }: { value: string; startDate: string; time: string },
                  index: number
                ): string =>
                  (acc += `${value} - ${moment(startDate).format("YYYY-MM-DD")}${
                    time ? " " + getTimeRange(parseInt(time)) : ""
                  }${index < missions.length - 1 ? ", " : ""}`),
                "" as string
              )
            : "",
        });
        if (postHubSpotResponse) {
          const { deal_id } = postHubSpotResponse;
          updateCampaign({ hubspotDealId: deal_id });
        }
      }
    }, 1000);
    return () => {
      clearTimeout(postHubspotTimer);
    };
  }, [activeTabIndex, missions]);

  useEffect(() => {
    if (posthog && userId) {
      posthog.identify(userId, {
        role,
        client_id: clientId,
        user_id: userId,
        firstname: firstName,
        lastname: lastName,
        country: country?.name,
        email,
        phone,
      });
    }
  }, [userId, role, clientId, firstName, lastName, country, email, phone, country]);

  const completedStep = tabs.find((item: any) => item.step === campaign.lastActiveStep);
  const disabledStepsByFlyerDesignNotExisting = notExistDesignFile ? ["details", "submit"] : [];
  const notVerified = isVerified === false;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const headerWithSkipLogic = (isActive: boolean, canBeActive: boolean, item: any, forbidDetails: boolean) => {
    if (isSkipEnabled) {
      if (forbidDetails && !isMobile) {
        return <RadioButtonUncheckedIcon color="disabled" />;
      }
      return (
        isMobile ||
        (isActive ? (
          <CurrentStepIcon />
        ) : completedStep && completedStep.index >= item.index ? (
          <CheckCircleIcon />
        ) : (
          <RadioButtonUncheckedIcon color={isActive || canBeActive ? "inherit" : "disabled"} />
        ))
      );
    } else {
      return (
        isMobile ||
        (isActive ? (
          <CurrentStepIcon />
        ) : completedStep && completedStep.index >= item.index ? (
          notExistDesignFile && item.step === "design" ? (
            <RadioButtonUncheckedIcon color={isActive || canBeActive ? "inherit" : "disabled"} />
          ) : (
            <CheckCircleIcon />
          )
        ) : (
          <RadioButtonUncheckedIcon color={isActive || canBeActive ? "inherit" : "disabled"} />
        ))
      );
    }
  };

  return (
    <IfElse condition={accessDenied}>
      <Box className={cn(classes.headerWrapper, { [classes.eddmHeaderWrapper]: isDM })} />
      <Box
        className={cn(classes.headerWrapper, "mob-header-wrapper", {
          [classes.eddmHeaderWrapper]: isDM,
          [classes.notVerifiedWrapper]: notVerified,
        })}
      >
        <VerificationAlert />
        <IfElse condition={isFlyerBuilderPage}>
          <Box className={cn(classes.flyerContainer, { [classes.notVerifiedContainer]: notVerified })}>
            <div className={classes.firstHeaderItem}>
              <Box className={classes.homeAndBackWrapper}>
                <Anchor
                  href={homeUrl}
                  className={classes.homeBtn}
                  onClick={(e: MouseEvent) => {
                    const isCtrlClick = e.ctrlKey || e.metaKey;
                    if (!isCtrlClick && !campaign.isSubmitted) {
                      e.preventDefault();
                    }
                  }}
                >
                  <img src={homeIcon} onClick={handleHomeClick} />
                </Anchor>
                <Box
                  className={cn(classes.backBtn)}
                  onClick={handleBackClick}
                  // @ts-ignore
                  cypress_id={headerActions?.BACK?.cypressId}
                >
                  <img src={backIcon} />
                  <Typography>Back</Typography>
                </Box>
              </Box>
            </div>
            <div className={classes.middleHeaderItem}>
              <Box className={classes.nameAndBadgeWrapper}>
                <Typography>{campaignName}</Typography>
                <Badge totalClassName={classes.totalCost} />
              </Box>
            </div>
            <div className={cn(classes.lastHeaderItem)}>
              <Box style={{ display: "flex", justifyContent: "end", alignItems: "center", width: "100%" }}>
                <Box className={cn(classes.flyerBuilderNavigationBtnsWrapper)}>
                  {flyerActions?.map((item: any, index: number) =>
                    renderButtons(item, index, flyerActions.length - 1 === index)
                  )}
                  <Box onClick={handleFAQClick}>
                    <img src={faqIcon} />
                    <span>Help</span>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
          <Box
            style={{ position: "relative" }}
            className={cn(classes.container, "mob-container", { [classes.notVerifiedContainer]: notVerified })}
          >
            <Grid container className={cn(classes.gridWrapper, "mob-grid-wrapper")}>
              <Box
                style={{ position: "absolute", left: 0 }}
                className={cn(classes.newNavigationBtnsWrapper, "home-back-icon mob-navigation-back-and-home")}
              >
                <Box className={cn(classes.homeBtnContainer)}>
                  <Anchor
                    href={homeUrl}
                    onClick={(e: MouseEvent) => {
                      const isCtrlClick = e.ctrlKey || e.metaKey;
                      if (!isCtrlClick && !campaign.isSubmitted) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <img src={homeIcon} onClick={handleHomeClick} />
                  </Anchor>
                </Box>

                <If condition={!hideBackButton}>
                  <Box
                    onClick={handleBackClick}
                    className={cn(classes.backBtn, "mob-navigation-height")}
                    // @ts-ignore
                    cypress_id={headerActions?.BACK?.cypressId}
                  >
                    <img src={backIcon} style={{ marginBottom: 0, marginRight: 4 }} />
                    <Typography className="mob-hidden">Back</Typography>
                  </Box>
                </If>
              </Box>
              <Grid item>
                <Box
                  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  className={cn("mob-tabs-wrapper")}
                >
                  {tabs.map((item: any) => {
                    const isActive = activeTabIndex === item.index;
                    const nonActive = activeTabIndex !== item.index;
                    const errorDeletedDesign = disabledStepsByFlyerDesignNotExisting.includes(item.step);
                    const checkProcessedChannel = completedStep?.index >= item.index;
                    //This is needed cus the user can go to create design and back that for some reason counts as creating the flyer
                    const forbidDetails =
                      item.index >= 3 &&
                      !isDM &&
                      pageState === FlyerStatus.flyerCreated &&
                      !isQrCodeExist &&
                      !flyerInfo.s3Link;

                    const canBeActive = isSkipEnabled
                      ? !hasError && checkProcessedChannel
                      : !hasError && !errorDeletedDesign && completedStep && checkProcessedChannel;

                    const isLink = canBeActive && !isActive && !forbidDetails && !isLoading;

                    return (
                      <Fragment key={item.index}>
                        <span
                          onClick={() => {
                            if (isLink) {
                              handleGoToTab(item.href);
                            }
                          }}
                          className={cn(classes.item, "mob-item", {
                            [classes.itemActive]: isActive,
                            "display-none": nonActive,
                            [classes.itemCanBeActive]: isLink && canBeActive,
                          })}
                        >
                          {headerWithSkipLogic(isActive, canBeActive, item, forbidDetails)}
                          <Box
                            key={`${item.title}-${item.index}`}
                            className={cn(classes.tabWrapper, "mob-tab-wrapper")}
                          >
                            <Typography
                              className={cn(classes.tab, {
                                [classes.activeTab]: (isActive || canBeActive) && !forbidDetails,
                                [classes.link]: isLink,
                              })}
                            >
                              {item.title}
                            </Typography>
                            <StepDots totalSteps={tabs.length} currentStep={activeTabIndex} />
                          </Box>
                        </span>
                        {item.index < 4 ? (
                          <Box
                            key={`seperator-${item.index}`}
                            className={cn(classes.separator, "mob-separator", {
                              [classes.disabled]: !canBeActive,
                            })}
                          />
                        ) : null}
                      </Fragment>
                    );
                  })}
                </Box>
              </Grid>
              <Grid className="mob-navigation-help">
                <Box className={cn(classes.navigationBtnsWrapper)}>
                  <div onClick={handleFAQClick}>
                    <img src={faqIcon} />
                    <span className="mob-hidden">Help</span>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </IfElse>
      </Box>
    </IfElse>
  );
};
export default withStyles(style as any)(withRouter(Header));

interface StepDotsProps {
  totalSteps: number;
  currentStep: number;
}

const StepDots = ({ totalSteps, currentStep }: StepDotsProps) => {
  return (
    <Box className="dots-container">
      {Array.from({ length: totalSteps }, (_, i) => (
        <span key={i + 1} className={cn("dot", { "active-dot": i + 1 === currentStep })} />
      ))}
    </Box>
  );
};
