import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const createQuote = async (campaignId) => {
  const mutation = `
      mutation createQuote ($input: CreateQuoteInput! ) {
        createQuote(input: $input) {
          id
        }
      }`;

  const variables = {
    input: { campaignId },
  };

  const res = await callAuthorizedGraphQLEndpoint(mutation, variables);
  const {
    data: {
      createQuote: { id },
    },
  } = await res.json();

  return id;
};
