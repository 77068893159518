export const style = {
  popper: {
    zIndex: 20,
    // "@media (max-width: 768px)": {
    //   display: "none",
    // },
  },
  button: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center" as const,
    color: "#0B2456",
    margin: "0 24px",
    padding: "9px 12px",
    background: "#E8E8FD",
    borderRadius: "8px",
    textTransform: "initial" as const,
    whiteSpace: "nowrap" as const,
    "&:hover": {
      background: "#E8E8FD",
    },
  },
  vibrate: {
    animation: "$heartbeat 2s cubic-bezier(.175,.885,.32,1.3) infinite",
  },

  "@keyframes heartbeat": {
    "0%,10%,20%": {
      transform: "translateX(-1px)",
    },
    "5%,15%": {
      transform: "translateX(1px)",
    },
  },
  tooltip: {
    width: "220px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
    borderRadius: "6px",
    padding: "20px 14px",
    pointerEvents: "auto" as const,
  },
  tooltipArrow: {
    marginTop: "-1.41em !important",
    width: "2.5em",
    height: "1.41em",

    "&::before": {
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      margin: "auto",
      content: '""',
      display: "block",
      transform: "rotate(45deg)",
      backgroundColor: "currentColor",
      marginRight: "0px",
    },

    "&::after": {
      color: "rgb(255 255 255 / 62%)",
      width: "100%",
      height: "100%",
      margin: "auto",
      content: '""',
      display: "block",
      transform: "rotate(45deg)",
      backgroundColor: "currentColor",
      marginRight: "-2px",
    },
  },

  tooltipRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
  },
  tooltipRowTitle: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "26px",
    color: "#011533",
  },
  tooltipRowSubTitle: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#707087",
  },
  tooltipRowValue: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#011533",
  },
  totalCostValue: {
    fontWeight: 700,
    fontSize: "16px",
  },
  divideLine: {
    border: "1px solid #E2E2FC",
    height: "0px",
    marginBottom: "8px",
    marginTop: "4px",
  },
};

export default style;
