const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  dropBox: {
    marginTop: 40,
    background: "#F9F9FF",
    border: "2px dashed #798498",
    width: 407,
    height: 93,
    marginBottom: 30,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  dropBoxV2: {
    border: "2px dotted #798498",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  dropBoxActive: {
    border: "2px dashed #081406",
    background: "#f4fff2",
  },
  uploadedFiles: {
    marginBottom: 30,
  },
  error: {
    color: "red",
    fontWeight: "bold" as const,
  },
  textTitle: {
    fontSize: "14px",
    color: "#0C172C",
    marginTop: "8px",
    marginBottom: "2px",
  },
  text: {
    fontSie: "12px",
    color: "#707087",
  },
  fileName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    width: "100%",
    padding: "0 10px",
    textAlign: "center" as const,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default style;
