import { makeStyles } from "@material-ui/core";
import cx from "classnames";

export default function IconButton({
  children,
  isActive,
  onClick,
  isSpecial = false,
  disabled = false,
  cypressId = "",
}) {
  const classes = useStyles();

  return (
    <button
      className={cx(classes.button, {
        [classes.button_active]: isActive,
        [classes.button_special]: isSpecial,
        [classes.button_disabled]: disabled,
      })}
      onClick={disabled ? () => {} : onClick}
      // eslint-disable-next-line react/no-unknown-property
      cypress_id={cypressId}
    >
      {children}
    </button>
  );
}

const useStyles = makeStyles({
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "56px",
    height: "55px",
    borderRadius: 8,
    cursor: "pointer",
    color: "var(--color-surface-panel)",
    padding: "3px",
    marginBottom: "15px",

    "&:hover, &:focus-visible": {
      background: "var(--color-deeper-300)",
    },

    "&:focus": {
      outline: "none",
    },
    "&:disabled": {
      opacity: 0.1,
    },
  },
  button_active: {
    extend: "button",
    background: "var(--color-brand-400)",
    border: "1px solid var(--color-brand-400)",

    "&:hover, &:focus-visible": {
      background: "var(--color-brand-400)",
    },
  },
  button_special: {
    extend: "button",
  },
  button_disabled: {
    extend: "button",

    opacity: 0.1,
  },
});
