export default function QRCodeIcon({ className }) {
  return (
    <svg
      className={className}
      width="10px"
      height="10px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 10 10"
    >
      <path d="M7.86 4.28h-1a1 1 0 0 1-1.08-.89 1.42 1.42 0 0 1 0-.29v-2A1.1 1.1 0 0 1 6 .32a1.08 1.08 0 0 1 .58-.3h2.29a1.11 1.11 0 0 1 .79.31 1 1 0 0 1 .3.59 2.45 2.45 0 0 1 .04.23v2a1.1 1.1 0 0 1-.46.91.89.89 0 0 1-.54.21ZM6.79 1.07v2.14h2.14V1.07ZM2.14 4.28h-1a1 1 0 0 1-.54-.13 1 1 0 0 1-.6-.77 2.64 2.64 0 0 1 0-.23v-2A1.15 1.15 0 0 1 .3.32a1.08 1.08 0 0 1 .59-.3H3.17A1.09 1.09 0 0 1 4 .31a1 1 0 0 1 .3.59 1.18 1.18 0 0 1 0 .26v2a1.06 1.06 0 0 1-.45.91.94.94 0 0 1-.54.18Zm1.07-1.07V1.07H1.07v2.14ZM9.29 6.43v-.71H10v2.85H7.86v-.71h-.72V10H5.72V5.72h2.13v.71ZM2.14 10h-1a1.12 1.12 0 0 1-.54-.11 1 1 0 0 1-.6-.74 2.64 2.64 0 0 1 0-.28v-2a1.08 1.08 0 0 1 .73-1 .89.89 0 0 1 .36-.07h2.1a1.09 1.09 0 0 1 1.07.91.93.93 0 0 1 0 .23v2a1.05 1.05 0 0 1-.54 1 1.08 1.08 0 0 1-.56.14ZM1.07 6.79v2.14h2.14V6.79Z" />
      <path d="M7.86 9.29h.71V10h-.71ZM10 10h-.71v-.71H10ZM7.86 1.6h.25a.34.34 0 0 1 .28.34 2.67 2.67 0 0 1 0 .4.29.29 0 0 1-.26.3 2.26 2.26 0 0 1-.52 0c-.17 0-.25-.13-.27-.33a2.8 2.8 0 0 1 0-.41.35.35 0 0 1 .36-.31 1 1 0 0 1 .17 0ZM2.14 1.6h.25a.33.33 0 0 1 .28.33 2.67 2.67 0 0 1 0 .4.32.32 0 0 1-.34.32H2a.32.32 0 0 1-.34-.33 2.77 2.77 0 0 1 0-.41.34.34 0 0 1 .34-.3h.16ZM2.14 7.32a1 1 0 0 1 .25 0 .32.32 0 0 1 .28.32 2.67 2.67 0 0 1 0 .4.32.32 0 0 1-.34.33H2c-.22 0-.31-.11-.34-.34a2.84 2.84 0 0 1 0-.41.35.35 0 0 1 .34-.3Z" />
    </svg>
  );
}
