import type { TDShapeUtil } from "pages/flyer/builder/drawer/state/shapes/TDShapeUtil";
import { TDShape } from "pages/flyer/builder/drawer/types";
import { ShapeUtilsSingleton } from "pages/flyer/builder/drawer/state/shapes/ShapeUtilsSingleton";
//needtorevalidatelogic

export const shapeUtils = () => ShapeUtilsSingleton.getInstance().getShapeUtils();

export const getShapeUtil = <T extends TDShape>(shape: T | T["type"]) => {
  const shapeUtils = ShapeUtilsSingleton.getInstance().getShapeUtils();

  if (typeof shape === "string") return shapeUtils[shape] as unknown as TDShapeUtil<T>;
  return shapeUtils[shape.type] as unknown as TDShapeUtil<T>;
};
