import { Icon } from "components/icons";
import { StyledHeader, StyledHeaderButton, StyledHeaderText } from "./Header.styled";

interface IHeaderProps {
  monthDate: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}

export const Header = ({ monthDate, decreaseMonth, increaseMonth }: IHeaderProps) => (
  <StyledHeader>
    <StyledHeaderButton onClick={decreaseMonth}>
      <Icon type="ChevronLeft" size="m" />
    </StyledHeaderButton>

    <StyledHeaderText>
      {monthDate.toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      })}
    </StyledHeaderText>

    <StyledHeaderButton onClick={increaseMonth}>
      <Icon type="ChevronRight" size="m" />
    </StyledHeaderButton>
  </StyledHeader>
);

export default Header;
