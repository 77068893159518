import React, { createContext, useState, useCallback, useEffect, ReactNode, memo } from "react";
import cn from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MuiSnackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import style from "./style";

interface SnackbarProps {
  classes: Record<string, string>;
  children: ReactNode;
}

interface SnackbarState {
  key?: number;
  vertical?: "top" | "bottom";
  horizontal?: "left" | "center" | "right";
  msg: string;
  type?: "success" | "error" | "warning" | "info";
  handleUndo?: () => void;
}

export const SnackBarContext = createContext<(state: SnackbarState) => void>(() => {});

const SnackBar = ({ classes, children }: SnackbarProps) => {
  const [snackPack, setSnackPack] = useState<SnackbarState[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarState | undefined>(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const runSnackBar = useCallback((state: SnackbarState) => {
    setSnackPack((prev) => [...prev, state]);
  }, []);

  const { vertical = "top", horizontal = "center", msg, type } = messageInfo || {};

  const handleClose = (event: React.SyntheticEvent<Element, Event> | null, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const renderAlert = () => {
    if (!type) return;

    return (
      <Alert onClose={handleClose} severity={type}>
        {msg}
      </Alert>
    );
  };

  return (
    <div className={cn(classes.root, vertical === "top" ? classes.paddingTop : {})}>
      <MuiSnackbar
        key={messageInfo?.key || undefined}
        anchorOrigin={{
          vertical: vertical,
          horizontal: horizontal,
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
      >
        {renderAlert()}
      </MuiSnackbar>
      <SnackBarContext.Provider value={runSnackBar}>{children}</SnackBarContext.Provider>
    </div>
  );
};

export default memo(withStyles(style)(SnackBar));
