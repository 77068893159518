import * as React from "react";

export function UndoIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="24" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.1084 6.25H5.49277L7.73465 4.00875L6.8584 3.125L3.1084 6.875L6.8584 10.625L7.73465 9.74062L5.49465 7.5H13.1084C14.103 7.5 15.0568 7.89509 15.76 8.59835C16.4633 9.30161 16.8584 10.2554 16.8584 11.25C16.8584 12.2446 16.4633 13.1984 15.76 13.9017C15.0568 14.6049 14.103 15 13.1084 15H8.1084V16.25H13.1084C14.4345 16.25 15.7063 15.7232 16.6439 14.7855C17.5816 13.8479 18.1084 12.5761 18.1084 11.25C18.1084 9.92392 17.5816 8.65215 16.6439 7.71447C15.7063 6.77678 14.4345 6.25 13.1084 6.25Z"
        fill="#212529"
      />
    </svg>
  );
}
