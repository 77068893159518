import SvgIcon from "@material-ui/core/SvgIcon";
import cn from "classnames";
import { withStyles } from "@material-ui/core/styles";

const style = {
  root: {
    width: "20px",
    height: "21px",
  },
};

const CursorIconActive = ({ classes, className, fill, ...props }) => (
  <SvgIcon {...props} className={cn(classes.root, className)} fill="none" viewBox="0 0 13 21">
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4933_73800)">
        <path
          d="M12.8232 11.7074L14.9499 17.4164L11.0349 18.8083L8.90737 13.0992L5.6582 15.0893L7.0082 2.0498L16.6224 11.1921L12.824 11.7074H12.8232ZM12.814 16.4429L10.5515 10.3709L13.0182 10.0371L8.3182 5.56853L7.65987 11.9418L9.7682 10.65L12.0307 16.7221L12.814 16.4429Z"
          fill={fill ? fill : "#0B2456"}
        />
      </g>
      <defs>
        <clipPath id="clip0_4933_73800">
          <rect width="20" height="19.5349" fill="white" transform="translate(0 0.720703)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default withStyles(style)(CursorIconActive);
