import { add, addDays, getDay, set, format, addHours, getWeek } from "date-fns";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const addNDaysToDate = (date, days) => {
  let currentDate = date ? new Date(date) : new Date();

  return addDays(currentDate, days);
};

const skipCountWorkingDays = (date, countWorkingDays) => {
  let countDays = 0;
  let countWorkDays = 0;
  let currentDate = date ? new Date(date) : new Date();

  for (let i = 0; countWorkDays < countWorkingDays; ++i) {
    const currentDay = getDay(currentDate);
    if (![6, 0].includes(currentDay)) {
      countWorkDays++;
    }

    currentDate = add(currentDate, { days: 1 });
    countDays++;
  }
  return { countDays, countWorkDays, nextDate: currentDate };
};

const isFirstDayMoreOrSame = (firstDay, secondDay) => {
  const first = set(new Date(firstDay), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  const second = set(new Date(secondDay), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  return first >= second;
};

const isDatesValid = ({ startDate, endDate }) => {
  const { nextDate } = skipCountWorkingDays(null, 15);

  const isStartDateValid = isFirstDayMoreOrSame(startDate, nextDate);
  const isEndDateValid = isFirstDayMoreOrSame(endDate, startDate);

  return { isStartDateValid, isEndDateValid };
};

const formatDate = (date, dateFormat) => {
  return format(new Date(date), dateFormat);
};

const formatDateForPreciseLocation = (startDate, time) => new Date(addHours(new Date(startDate), time));

const getWeekNumber = (date, startsOn = 1) => {
  const currentDate = date ? new Date(date) : new Date();
  return getWeek(currentDate, { weekStartsOn: startsOn });
};

const customDateFormat = (date, isUS) => {
  if (!date || typeof date !== "string") return "";
  const splittedDate = date.split("-");
  return isUS
    ? [splittedDate[1], splittedDate[2], splittedDate[0]].join(" / ")
    : [splittedDate[2], splittedDate[1], splittedDate[0]].join(" / ");
};

const getCurrentDateWithCityOffset = (timezone) => {
  return new Date(new Date().toLocaleString("en-US", { timeZone: timezone }));
};

export {
  addNDaysToDate,
  skipCountWorkingDays,
  isFirstDayMoreOrSame,
  isDatesValid,
  formatDate,
  formatDateForPreciseLocation,
  getWeekNumber,
  customDateFormat,
  getCurrentDateWithCityOffset,
};
