import React, { memo, useState, useContext, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { ChannelCard } from "components/channel-card";
import Header from "components/header";

import ROUTES from "config/routing";

import { gtagWrapper, getCitiesList, checkIsNeedUpdateLastActiveStepState } from "utils/index.js";

import { CAMPAIGN_ENUM_TYPES, CAMPAIGN_LOG_ENUM_TYPES, DMDocumentFormats } from "shared/constants";
import { REGEX_FOR_CAMPAIGN_NAME } from "../../../constants";
import { CAMPAIGN_INTERNAL_STATUSES, CHANNEL_OPTIONS, NAVIGATION } from "../../constants";
// import SuccessIcon from "../../../static/media/success-icon.js";
import style from "./style.js";
import ModalSelectChannel from "./Modal";
import {
  checkIsCampaignNameExist,
  createSSCampaign,
  updateCampaign as updateCampaignDB,
  updateCampaignExtraData,
} from "../../../graphQL";
import { SnackBarContext } from "../../../components/snack-bar";
import { ProgressBarContext } from "../../../components/progress-bar";
import { useStore } from "../../../store";
import { insertCampaignLogs } from "../../../Logs/campaign/gql";

const Channel = ({ classes }) => {
  const history = useHistory();

  const runSnackBar = useContext(SnackBarContext);
  const runProgressBar = useContext(ProgressBarContext);
  // const runDialogModal = useContext(DialogModalContext);

  const {
    user: { id: userId },
    campaign: { channel, campaignName, id: campaignId, lastActiveStep },
    errors: { campaignNameDuplicates },
    country,
    client: { name: clientName, id: clientId },
    updateCampaign,
    updateErrors,
    setAvailableCities,
  } = useStore();
  const isEddmEnabled = country.id == "14";
  const fromHome = useMemo(() => lastActiveStep === "home", [lastActiveStep]);

  const [selectedChanel, setSelectedChanel] = useState(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  const [enabledChannels, setEnabledChannels] = useState([]);
  const [avaiableChannels, setAvailableChannels] = useState([]);
  // const [showPriorityPopup, setShowPriorityPopup] = useState(false);
  // const [showSurvey, setShowSurvey] = useState(false);
  const [flyerFormat, setFlyerFormat] = useState(DMDocumentFormats.BEST_SELLER);

  const handleChooseChannel = (channel, disabled) => {
    if (!disabled) {
      gtagWrapper({
        event: "ss_campaign_type_selected",
        client_id: clientId?.toString(),
        user_id: userId,
        campaign_id: campaignId ? campaignId : "no-campaign-id",
        campaign_type: channel,
      });
      setSelectedChanel(channel);
    }
  };

  const getEnabledChannels = async () => {
    const { countryDefaultSettings } = country;

    const formattedEnabledChannels = Object.entries(countryDefaultSettings).reduce((acc, [key, value]) => {
      if (value) {
        if (fromHome || (channel && channel !== CAMPAIGN_ENUM_TYPES.UNCERTAIN)) {
          acc.push(channel);
        } else {
          if (key === "enabledChannelLetterbox") {
            acc.push(CAMPAIGN_ENUM_TYPES.LETTERBOX);
          } else if (key === "enabledChannelPreciseLocations") {
            acc.push(CAMPAIGN_ENUM_TYPES.HANDTOHAND);
          } else if (key === "enabledChannelEddm" && isEddmEnabled) {
            acc.push(CAMPAIGN_ENUM_TYPES.DIRECTMAIL);
          }
        }
      }
      return acc;
    }, []);

    setEnabledChannels(formattedEnabledChannels);
    setLoading(false);
  };

  const getAvailableChannels = async () => {
    const { countryDefaultSettings } = country;

    const availablesChannelsInCountry = Object.entries(countryDefaultSettings).reduce((acc, [key, value]) => {
      if (value) {
        if (key === "enabledChannelLetterbox") {
          acc.push(CAMPAIGN_ENUM_TYPES.LETTERBOX);
        } else if (key === "enabledChannelPreciseLocations") {
          acc.push(CAMPAIGN_ENUM_TYPES.HANDTOHAND);
        } else if (key === "enabledChannelEddm" && isEddmEnabled) {
          acc.push(CAMPAIGN_ENUM_TYPES.DIRECTMAIL);
        }
      }
      return acc;
    }, []);

    setAvailableChannels(availablesChannelsInCountry);
    setLoading(false);
  };

  useEffect(() => {
    if (country) {
      getEnabledChannels();
      getAvailableChannels();
    }
  }, [country]);

  useEffect(() => {
    setIsOpenPopup(!!selectedChanel);
  }, [selectedChanel]);

  useEffect(() => {
    if (fromHome) {
      setSelectedChanel(channel);
    }
  }, [fromHome, channel]);

  const onClose = () => {
    setSelectedChanel(null);
    setTimeout(() => {
      setSelectedChanel(null);
      updateCampaign({ campaignName: "" });
      updateErrors({ campaignNameDuplicates: false });
    }, 100);
  };

  const validateCampaignName = (name) => {
    const cleanName = name.replace(REGEX_FOR_CAMPAIGN_NAME, "");
    if (cleanName.length > 32) {
      return cleanName.slice(0, 32);
    } else {
      return cleanName;
    }
  };

  const onChangeCampaignName = async (name) => {
    updateCampaign({ campaignName: validateCampaignName(name) });
    updateErrors({ campaignNameDuplicates: false });
  };

  const handleClickNext = async () => {
    setLoading(true);
    if (campaignName) {
      const { campaignIds } = await checkIsCampaignNameExist(clientId, campaignName.trim());
      if (campaignIds.length && (!campaignId || !campaignIds.includes(campaignId))) {
        setLoading(false);
        return updateErrors({ campaignNameDuplicates: true });
      }
      updateErrors({ campaignNameDuplicates: false });
      await onSave();
      setLoading(false);
    }
  };

  const onSave = async () => {
    try {
      const trimmedCampaignName = campaignName.trim();

      const payloadCampaign = {
        campaignId,
        name: trimmedCampaignName,
        type: selectedChanel,
      };

      const flyerType =
        (country.code === "US" || country.code === "CA") && selectedChanel === CAMPAIGN_ENUM_TYPES.LETTERBOX
          ? "DOOR_HANGER"
          : selectedChanel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL
          ? flyerFormat
          : "A6";

      const flyerWeight = "250";
      const baPerLocation =
        selectedChanel === CAMPAIGN_ENUM_TYPES.HANDTOHAND ? country.countryDefaultSettings.baPerLocation : null;

      let idCreatedCampaign = null;
      if (campaignId) {
        await updateCampaignDB(payloadCampaign);

        const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
          stateLastActiveStep: lastActiveStep,
          newLastActiveStep: "audience",
          isDM: false,
        });

        if (isNeedUpdateLastActiveStep) {
          await updateCampaignExtraData({
            campaignId,
            lastActiveStep: "channel",
            flyerType,
            flyerWeight,
            baPerLocation,
          });
        }

        await insertCampaignLogs([
          {
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_NAME,
            additionalInfo: `[CB] CAMPAIGN EDIT (Modal on Channel page) value: ${campaignName.trim()},`,
          },
          {
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_TYPE,
            additionalInfo: `CAMPAIGN CREATE value: ${flyerType}`,
          },
          {
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_WEIGHT,
            additionalInfo: `CAMPAIGN CREATE value: ${flyerWeight}`,
          },
        ]);

        updateCampaign({
          channel: selectedChanel,
          campaignName: trimmedCampaignName,
          isD2D: selectedChanel === CAMPAIGN_ENUM_TYPES.LETTERBOX,
          isH2H: selectedChanel === CAMPAIGN_ENUM_TYPES.HANDTOHAND,
          internalStatus: CAMPAIGN_INTERNAL_STATUSES.DRAFT,
          flyerType,
          flyerWeight,
          baPerLocation,
        });

        const citiesList = await getCitiesList({ country, channel: selectedChanel });
        setAvailableCities(citiesList);

        if (isNeedUpdateLastActiveStep) {
          updateCampaign({ lastActiveStep: "channel" });
        }
      } else {
        const request = await createSSCampaign({
          ...payloadCampaign,
          clientId,
          lastActiveStep: "channel",
          flyerType,
          flyerWeight,
          baPerLocation,
        });
        const {
          data: {
            createSSCampaign: { campaignId },
          },
        } = await request.json();

        idCreatedCampaign = campaignId;

        updateCampaign({
          id: campaignId,
          channel: selectedChanel,
          campaignName: trimmedCampaignName,
          flyerType,
          flyerWeight,
          isD2D: selectedChanel === CAMPAIGN_ENUM_TYPES.LETTERBOX,
          internalStatus: CAMPAIGN_INTERNAL_STATUSES.DRAFT,
          isSubmitted: false,
          lastActiveStep: "channel",
        });

        const citiesList = await getCitiesList({ country, channel: selectedChanel });
        setAvailableCities(citiesList);
      }

      gtagWrapper({
        event: "ss_campaign_name_completed",
        client_id: clientId?.toString(),
        user_id: userId,
        campaign_id: campaignId ? campaignId : "no-campaign-id",
      });

      history.push(generatePath(ROUTES.CAMPAIGN_AUDIENCE, { campaignId: campaignId || idCreatedCampaign, clientId }));

      runSnackBar({
        type: "success",
        msg: `Saved.`,
        vertical: "bottom",
        horizontal: "right",
      });
      runProgressBar(80);
    } catch (error) {
      runSnackBar({
        type: "error",
        msg: `Server error failed to save company. ${error.message}`,
        vertical: "bottom",
        horizontal: "right",
      });
    }

    setSelectedChanel(null);
    runProgressBar(-1);
  };

  const checkCampaignName = useCallback(() => {
    const trimmedCampaignName = campaignName.trim();
    const noSpaceCampaignName = campaignName.replace(/\s/g, "");

    if (!trimmedCampaignName.length) {
      return "Latin characters required";
    }

    if (noSpaceCampaignName.length < 4) {
      return "Campaign name is too short (minimum 4 characters)";
    }

    if (trimmedCampaignName.length > 32) {
      return "Campaign name is too long (maximum 32 characters)";
    }

    const isLangError = REGEX_FOR_CAMPAIGN_NAME.test(campaignName);

    if (isLangError) {
      return "Campaign name contains invalid characters.";
    }

    return null;
  }, [campaignName]);

  useEffect(() => {
    if (campaignNameDuplicates) {
      setError("Duplicated campaign name. Please choose another.");
      return;
    }
  }, [campaignNameDuplicates]);

  useEffect(() => {
    if (!campaignName) {
      setError(true);
      return;
    }

    if (campaignName.includes("Draft ")) {
      return;
    }

    if (checkCampaignName() !== null) {
      setError(true);
    } else {
      setError(null);
    }

    const timerId = setTimeout(() => {
      setError(checkCampaignName());
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [campaignName, checkCampaignName]);

  const isChannelSelected = !fromHome && channel && channel !== CAMPAIGN_ENUM_TYPES.UNCERTAIN;

  const goToAudiencePage = () => {
    history.push(generatePath(ROUTES.CAMPAIGN_AUDIENCE, { campaignId, clientId }));
  };

  // const handleOpenPriorityPopup = () => {
  //   setShowPriorityPopup(false);
  //   runDialogModal({
  //     titleIcon: <SuccessIcon />,
  //     title: "Great news! You're now on our waitlist for Oppizi’s Local Direct Mail! ",
  //     contentText: (
  //       <Box>
  //         <div />
  //         <div style={{ textAlign: "center" }}>
  //           Thank you for your interest. Want to{" "}
  //           <b style={{ color: "black" }}>move up the list? Take our 1-minute survey</b> and help us tailor our product
  //           to better suit your needs. Thanks for helping us improve!
  //         </div>
  //       </Box>
  //     ),
  //     ctaLabel: "Gain priority",
  //     customCtaStyles: {
  //       width: "240px",
  //     },
  //     handleCTAClick: () => {
  //       posthog.capture("OA_clicked_eddm_prioritylist01_cta", { experiment: "eddm_test01" });
  //       console.log("Gain priority clicked");
  //       setShowSurvey(true);
  //     },
  //     secondaryButtonLabel: "Not now",
  //     hasOptions: true,
  //   });
  // };

  // const handleEDDMChannelClick = () => {
  //   runDialogModal({
  //     title: "Good news! Oppizi's Local Direct Mail is coming your way!",
  //     contentText: (
  //       <Box>
  //         <div />
  //         <div style={{ textAlign: "center" }}>
  //           We're working hard to bring this feature to you. If you're as excited as we are and want early access, just
  //           hit the button below. Can't wait?
  //         </div>
  //       </Box>
  //     ),
  //     ctaLabel: "Join the waitlist!",
  //     customCtaStyles: {
  //       width: "240px",
  //     },
  //     handleCTAClick: () => {
  //       posthog.capture("OA_clicked_eddm_waitlist01_cta", { experiment: "eddm_test01" });
  //       console.log("Join the waitlist! clicked");
  //       setShowPriorityPopup(true);
  //     },
  //   });
  // };

  // useEffect(() => {
  //   if (showPriorityPopup) {
  //     handleOpenPriorityPopup();
  //   }
  // }, [showPriorityPopup]);

  return (
    <Box className={classes.wrapper}>
      <Helmet>
        <title>Channel</title>
      </Helmet>
      <Header tabs={NAVIGATION} activeTabIndex={0} hideBackButton />
      <Box className={classes.containerWrapper}>
        <Box className={classes.container}>
          {/* {showSurvey ? <div className="delayed-survey"></div> : null} */}
          <ModalSelectChannel
            isOpen={isOpenPopup}
            onClose={onClose}
            channel={selectedChanel}
            clientName={clientName}
            onChangeCampaignName={onChangeCampaignName}
            campaignStateName={campaignName && !campaignName.includes("Draft ") ? campaignName : ""}
            error={error}
            onSave={handleClickNext}
            loading={loading}
            flyerFormat={flyerFormat}
            setFlyerFormat={setFlyerFormat}
          />
          <Box className={classes.title}>
            <Typography>Let’s create your campaign.</Typography>
            <Typography>What channel do you want to use?</Typography>
          </Box>
          <Box className={classes.cardsWrapepr}>
            {[
              CHANNEL_OPTIONS[CAMPAIGN_ENUM_TYPES.LETTERBOX],
              CHANNEL_OPTIONS[CAMPAIGN_ENUM_TYPES.HANDTOHAND],
              CHANNEL_OPTIONS[CAMPAIGN_ENUM_TYPES.DIRECTMAIL],
            ].map((item, index) => {
              return (
                <ChannelCard
                  key={`channel-option-${index}`}
                  active={item.type === channel}
                  disabled={!enabledChannels.includes(item.type)}
                  available={avaiableChannels.includes(item.type)}
                  item={item}
                  countryName={country?.name || ""}
                  isEddmEnabled={isEddmEnabled}
                  onClick={isChannelSelected && item.type === channel ? goToAudiencePage : handleChooseChannel}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(withStyles(style)(Channel));
