export const style = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    padding: "12px 14px",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "4px",
    color: "#011533",
  },
  title: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 700,
  },
  nameText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
  button: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center" as const,
    justifyContent: "center",
    backgroundColor: "#EE4360",
    color: "#FFFFFF",
    borderRadius: "12px",
    width: "104px",
    height: "32px",
    fontSize: "16px",
    fontWeight: 700,

    "&:hover": {
      transform: "scale(1.02)",
    },

    "&:active": {
      transform: "scale(1)",
    },
  },
};
