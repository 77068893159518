export const styles = {
  statisticsBlockContainer: {
    position: "absolute",
    right: "24px",
    top: "126px",
    borderRadius: "8px",
    background: "#FFFFFF",
    width: "380px",
    // height: "144px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    pointerEvents: "none",
  },
  statisticsHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontWeight: 700,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "18px",
    marginBottom: "5px",
  },
  areaStatistics: {
    display: "flex",
    alignItems: "center",
  },
  areaStatisticsTitle: {
    marginLeft: "8px",
  },
  areaStatisticsRoutesContainer: {
    height: "32px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    marginTop: "5px",
    borderTop: "1px solid #E2E2FC",
  },
  areaStatisticsRoutes: {
    display: "flex",
  },
  areaStatisticsRoutesLabel: {
    fontWeight: 700,
    marginRight: "5px",
  },
  statistics: {
    height: "76px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  statisticsRow: {
    display: "flex",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  statisticsSection: {
    height: "52px",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statisticsAmount: {
    fontSize: "16px",
    fontWeight: 700,
  },
  statisticsLabel: {
    color: "#0C172C",
  },
  satisticsNotTargeted: {
    color: "#AEAFC7",
  },
  separator: {
    width: "100%",
    height: "1px",
    background: "#AEAFC7",
    marginBottom: "5px",
  },
  verticalSeparator: {
    width: "1px",
    height: "25px",
    background: "#E2E2FC",
  },
};
