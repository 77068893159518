const style = {
  formated: {
    borderRadius: "8px",
    position: "relative",
    // height: "10px",
    border: "1px solid #E2E2FC",
    fontSize: "16px",
    color: "#00112B",
    textAlign: "center",
    outline: "none",
    padding: "8px 0px",
    "&>div": {
      paddingRight: "20px !important",
      fontSize: "16px",
      "&>input": {
        padding: "0 !important",
        paddingLeft: "8px !important",
        color: "#00112B",
      },
    },
    "&::placeholder": {
      color: "#00112B !important",
      opacity: 1,
    },
  },
  autocomplete: {
    "& li": {
      padding: "4px",
      fontSize: "16px",
      color: "#00112B !important",
    },
  },
};

export default style;
