import { Input } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { memo, useEffect, useRef } from "react";
import style from "./style.js";

const NumericInput = (props) => {
  const { inputProps, value, onChange, classes, mixed, endAdornment, ...otherProps } = props;

  const ref = otherProps?.InputProps?.ref;
  const inputRef = useRef(value);
  const onHandleChange = (event) => {
    inputRef.current = value;
    let newValue = +event.target.value;
    if (newValue) {
      newValue = Number(newValue.toString().replace(/[,.\s]/g, ""));
    }
    if (newValue > inputProps.max) {
      newValue = inputProps.max;
    }
    if (newValue < inputProps.min) {
      newValue = inputProps.min;
    }

    onChange(newValue);
  };

  useEffect(() => {
    inputRef.current = value;
  }, [value]);

  const otherParams = ref ? { ...props } : {};
  delete otherParams.classes;

  if (ref) {
    return (
      <Input
        disableUnderline
        size="small"
        color="secondary"
        variant="outlined"
        type="number"
        className={classes.formated}
        ref={ref}
        InputProps={{
          placeholder: mixed ? "Mix" : undefined,
        }}
        onKeyDown={(e) => {
          if (e.key === "," || e.key === ".") {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        placeholder={mixed ? "Mix" : undefined}
        freeSolo
        {...otherParams}
      />
    );
  }

  return (
    <Input
      disableUnderline
      size="small"
      color="secondary"
      variant="outlined"
      type="number"
      className={cn(classes.formated, endAdornment ? classes.formatedWithAdornment : "")}
      inputProps={{
        step: props.inputProps.step || 1,
        min: props.inputProps.min || 0,
        max: props.inputProps.max || 1000,
        // value: mixed ? undefined : value,
        placeholder: mixed ? "Mix" : undefined,
      }}
      ref={inputRef}
      value={mixed ? "Mix" : value?.toString() || ""}
      onChange={onHandleChange}
      onKeyDown={(e) => {
        if (e.key === "," || e.key === ".") {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      endAdornment={endAdornment}
      {...otherParams}
    />
  );
};

export default memo(withStyles(style)(NumericInput));
