import React, { useEffect, useState } from "react";
import cn from "classnames";
import BounceLoader from "components/loaders/bounce-loader";
import { makeStyles, Dialog } from "@material-ui/core";
import { calculateContentBounds } from "../../../utils";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const PDFPreview = ({ file, width, height, padding, isSmallScreen }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const loadAndRenderPDF = async (file) => {
      setLoading(true);
      // eslint-disable-next-line no-undef
      const loadingTask = pdfjsLib.getDocument(file);

      try {
        const pdf = await loadingTask.promise;
        const totalPages = pdf.numPages > 1 ? 2 : 1; //only front and back in case there's more
        const pagesArray = [];

        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          await page.render({
            canvasContext: context,
            viewport,
          }).promise;

          const formattedHeight = (viewport.height / viewport.width) * width;
          const formattedWidth = (viewport.width / viewport.height) * height;
          const newCanvasSize = {};

          if (formattedHeight > height) {
            newCanvasSize.height = height;
            newCanvasSize.width = formattedWidth;
          } else {
            newCanvasSize.width = width;
            newCanvasSize.height = formattedHeight;
          }

          pagesArray.push({
            canvas: canvas,
            width: viewport.width,
            height: viewport.height,
            styleWidth: newCanvasSize.width,
            styleHeight: newCanvasSize.height,
          });
        }

        setPages(pagesArray);
      } catch (error) {
        console.error("Error loading PDF:", error);
        window.location.reload();
      }

      setLoading(false);
    };

    if (file) {
      loadAndRenderPDF(file);
    }
  }, [file, width, height]);

  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (page, index) => {
    if (page && page.canvas) {
      setCurrentImage(page);
      setOpen(true);
    }
  };

  return (
    <>
      {loading ? (
        <div className={classes.loaderContainer}>
          <BounceLoader />
        </div>
      ) : (
        <div className={classes.pdfPreviewContainer} style={{ height: "fit-content", overflow: "unset" }}>
          <div className={classes.pdfPreviewContent}>
            <Dialog open={open} onClose={() => setOpen(false)} className={cn(classes.wrapper, "desk-hidden")}>
              {currentImage && currentImage.canvas ? (
                <img style={{ width: "calc(100vw - 32px)" }} src={currentImage.canvas.toDataURL()} alt="preview" />
              ) : null}
            </Dialog>
            {pages.map((page, index) => (
              <>
                <div
                  onClick={() => openModal(page, index)}
                  key={index}
                  style={{
                    width: width,
                    height: height + 20,
                    marginBottom: "40px",
                  }}
                >
                  <p className={classes.sideTitle}>{index < 1 ? "Front" : "Back"}</p>
                  <div className={classes.previewWrapper}>
                    <div
                      style={{
                        width: width,
                        height: height,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        border: "1px solid gray",
                        zIndex: 2,
                      }}
                    />
                    <canvas
                      ref={(el) => {
                        if (el) {
                          el.width = page.width;
                          el.height = page.height;
                          el.getContext("2d").drawImage(page.canvas, 0, 0);
                        }
                      }}
                      style={{
                        display: loading ? "none" : "block",
                        position: "absolute",
                        width: page.styleWidth,
                        height: page.styleHeight,
                        top: padding,
                        left: padding,
                      }}
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
