/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Utils, TLBounds } from "@tldraw/core";
import { Vec } from "@tldraw/vec";
import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import {
  AlignType,
  PAGE_ID,
  TDShape,
  TDShapeType,
  TemplateIdsEnum,
  TldrawCommand,
  IDeltaMap,
} from "pages/flyer/builder/drawer/types";

export function alignShapes(app: TldrawApp, ids: string[], type: AlignType): TldrawCommand {
  const { currentPageId } = app;

  const initialShapes = ids.map((id) => app.getShape(id));

  const parentId = initialShapes.some((shape) => shape.parentId !== initialShapes[0].parentId)
    ? PAGE_ID
    : initialShapes[0].parentId;

  const boundsForShapes = initialShapes.map((shape) => {
    return {
      id: shape.id,
      point: [...shape.point],
      bounds: TLDR.getBounds(shape),
    };
  });

  const commonBounds = Utils.getCommonBounds(boundsForShapes.map(({ bounds }) => bounds));
  const centerPointOfSelectedShapes = [
    commonBounds.minX + commonBounds.width / 2,
    commonBounds.minY + commonBounds.height / 2,
  ];

  let parentBounds: TLBounds;

  if (parentId === PAGE_ID) {
    const frontTemplateShape = app.getShape(TemplateIdsEnum.Front);
    const frontBounds = TLDR.getBounds(frontTemplateShape);
    const centerPointOfFront = [frontBounds.minX + frontBounds.width / 2, frontBounds.minY + frontBounds.height / 2];
    const distanceForFront = Math.hypot(
      centerPointOfSelectedShapes[0] - centerPointOfFront[0],
      centerPointOfSelectedShapes[1] - centerPointOfFront[1]
    );

    const backTemplateShape = app.getShape(TemplateIdsEnum.Back);
    const backBounds = TLDR.getBounds(backTemplateShape);
    const centerPointOfBack = [backBounds.minX + backBounds.width / 2, backBounds.minY + backBounds.height / 2];
    const distanceForBack = Math.hypot(
      centerPointOfSelectedShapes[0] - centerPointOfBack[0],
      centerPointOfSelectedShapes[1] - centerPointOfBack[1]
    );

    const nearestTemplateBounds = distanceForFront <= distanceForBack ? frontBounds : backBounds;
    parentBounds = { ...nearestTemplateBounds };
  } else {
    parentBounds = TLDR.getBounds(app.getShape(parentId));
  }

  const midX = parentBounds.minX + parentBounds.width / 2;
  const midY = parentBounds.minY + parentBounds.height / 2;

  const delta = {
    [AlignType.CenterVertical]: [0, midY - commonBounds.height / 2 - commonBounds.minY],
    [AlignType.CenterHorizontal]: [midX - commonBounds.width / 2 - commonBounds.minX, 0],
    [AlignType.Top]: [0, parentBounds.minY - commonBounds.minY],
    [AlignType.Bottom]: [0, parentBounds.maxY - commonBounds.height - commonBounds.minY],
    [AlignType.Left]: [parentBounds.minX - commonBounds.minX, 0],
    [AlignType.Right]: [parentBounds.maxX - commonBounds.width - commonBounds.minX, 0],
  }[type];

  const updatedIds: string[] = [];
  const deltaMap: IDeltaMap = {};

  const dfs = (shape: TDShape) => {
    if (shape.type === TDShapeType.Group) {
      shape.children.forEach((id) => {
        const child = app.getShape(id);
        dfs(child);
      });
    } else {
      updatedIds.push(shape.id);
      deltaMap[shape.id] = {
        prev: shape.point,
        next: Vec.add(shape.point, delta),
      };
    }
  };

  initialShapes.forEach((shape) => {
    dfs(shape);
  });

  const { before, after } = TLDR.mutateShapes(
    app.state,
    updatedIds,
    (shape) => {
      if (!deltaMap[shape.id]) return shape;
      return { point: deltaMap[shape.id].next };
    },
    currentPageId
  );

  return {
    id: "align",
    before: {
      document: {
        pages: {
          [currentPageId]: {
            shapes: before,
          },
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: ids,
          },
        },
      },
    },
    after: {
      document: {
        pages: {
          [currentPageId]: {
            shapes: after,
          },
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: ids,
          },
        },
      },
    },
  };
}
