import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { styles } from "./styles";
const useStyles = makeStyles(() => styles);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Anchor = ({ children, href = "", onClick = (e: React.MouseEvent) => {}, className = "" }) => {
  const classes = useStyles();
  return (
    <a
      className={cn(classes.anchor, { [className]: !!className })}
      href={href}
      onClick={(event) => {
        event.stopPropagation();
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {children}
    </a>
  );
};
