const SvgCalendar = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0390625 5.677C0.0390625 3.95111 1.43817 2.552 3.16406 2.552H14.8307C16.5566 2.552 17.9557 3.95111 17.9557 5.677V14.8332C17.9557 16.5591 16.5566 17.9582 14.8307 17.9582H3.16406C1.43817 17.9582 0.0390625 16.5591 0.0390625 14.8332V5.677ZM3.16406 3.802C2.12853 3.802 1.28906 4.64147 1.28906 5.677V14.8332C1.28906 15.8688 2.12853 16.7082 3.16406 16.7082H14.8307C15.8663 16.7082 16.7057 15.8688 16.7057 14.8332V5.677C16.7057 4.64147 15.8663 3.802 14.8307 3.802H3.16406Z"
      fill={props.fill || "#14142B"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.82813 0.0417789C5.1733 0.0416612 5.45322 0.321388 5.45334 0.666566L5.45419 3.17718C5.45431 3.52236 5.17459 3.80228 4.82941 3.8024C4.48423 3.80252 4.20431 3.52279 4.20419 3.17761L4.20334 0.666992C4.20322 0.321814 4.48295 0.0418967 4.82813 0.0417789Z"
      fill={props.fill || "#14142B"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1641 0.041748C13.5092 0.041748 13.7891 0.32157 13.7891 0.666748V2.85973C13.7891 3.20491 13.5092 3.48473 13.1641 3.48473C12.8189 3.48473 12.5391 3.20491 12.5391 2.85973V0.666748C12.5391 0.32157 12.8189 0.041748 13.1641 0.041748Z"
      fill={props.fill || "#14142B"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.375 6.80713C3.375 6.46195 3.65482 6.18213 4 6.18213L14 6.18213C14.3452 6.18213 14.625 6.46195 14.625 6.80713C14.625 7.15231 14.3452 7.43213 14 7.43213L4 7.43213C3.65482 7.43213 3.375 7.15231 3.375 6.80713Z"
      fill={props.fill || "#14142B"}
    />
  </svg>
);

export default SvgCalendar;
