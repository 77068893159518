export default function CodeIcon({ className }) {
  return (
    <svg
      className={className}
      width="10px"
      height="10px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 10 10"
    >
      <path d="m1.34 5 .43.43a1.57 1.57 0 0 1 .37.74 2.12 2.12 0 0 1 0 .44v.57a.56.56 0 0 0 .19.43.52.52 0 0 0 .33.12h.57a.56.56 0 0 1 .25.05.51.51 0 0 1 .31.43.54.54 0 0 1-.08.39.48.48 0 0 1-.39.23 6.62 6.62 0 0 1-.77 0 1.55 1.55 0 0 1-1.19-.65 1.49 1.49 0 0 1-.3-.76v-.86a.54.54 0 0 0-.17-.4l-.75-.74a.58.58 0 0 1 0-.84l.75-.74a.5.5 0 0 0 .17-.41v-.61a2 2 0 0 1 .14-.69 1.6 1.6 0 0 1 .38-.55 1.54 1.54 0 0 1 .54-.34 1.72 1.72 0 0 1 .5-.11h.65a.54.54 0 0 1 .56.53.55.55 0 0 1-.21.46.59.59 0 0 1-.36.11h-.53a.51.51 0 0 0-.36.12.49.49 0 0 0-.2.4v.65a1.68 1.68 0 0 1-.43 1.13c-.13.15-.28.29-.42.43ZM8.66 5l-.44-.45a1.66 1.66 0 0 1-.39-.85V2.77a.53.53 0 0 0-.2-.42.47.47 0 0 0-.32-.12h-.58a.57.57 0 0 1-.56-.62.53.53 0 0 1 .49-.48 5.36 5.36 0 0 1 .8 0 1.61 1.61 0 0 1 1.05.54 1.59 1.59 0 0 1 .4.87v.88a.54.54 0 0 0 .18.41l.75.74a.58.58 0 0 1 .06.78l-.16.09-.73.72a.52.52 0 0 0-.18.4v.58a2 2 0 0 1-.09.64 1.6 1.6 0 0 1-.32.53 1.69 1.69 0 0 1-.49.39 1.5 1.5 0 0 1-.51.15 7.49 7.49 0 0 1-.82 0 .56.56 0 0 1-.53-.52.51.51 0 0 1 .35-.55.85.85 0 0 1 .22 0h.54a.52.52 0 0 0 .42-.19.48.48 0 0 0 .14-.35v-.62a1.67 1.67 0 0 1 .39-1.11l.23-.24Z" />
    </svg>
  );
}
