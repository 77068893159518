import * as React from "react";

export function TextAlignRightIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={20} height={20} viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.28 3a.34.34 0 0 1 .34-.33h6.76a.34.34 0 1 1 0 .67H1.62A.34.34 0 0 1 1.28 3Zm3.38 2A.34.34 0 0 1 5 4.66h3.38a.34.34 0 0 1 0 .68H5A.34.34 0 0 1 4.66 5Zm-2 2A.34.34 0 0 1 3 6.69h5.38a.34.34 0 1 1 0 .67H3A.33.33 0 0 1 2.64 7Z"
        style={{
          fill: "#010101",
          fillRule: "evenodd",
        }}
      />
    </svg>
  );
}
