import React from "react";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Input } from "components/form";
import InfoIcon from "static/media/info";
import { QrCheckbox } from "components/QrCheckbox";
import { useStore } from "../../../../../../../../store";
import { StoreType } from "../../../../../../../../store/types";

const libraries: any = ["geometry", "places", "drawing"];
// const printLinesViewSelector = (s: TDSnapshot) => s.document.isShowGuidelines;

const QrCodeComponent = () => {
  const classes = useStyles();

  const {
    campaign: { qrCodeLink },
    qrCodeSettings: {
      // hideQrBackground,
      hideQrCoupon,
    },
    updateCampaign,
    updateQrCodeSettings,
  }: StoreType = useStore();

  return (
    <>
      <div className={classes.settings_row}>
        <div className={classes.labelBlock}>
          <label className={classes.label}>
            <span className={classes.required}>*</span> URL for QR code
            <Tooltip
              classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
              title={
                <div>
                  Please enter the desired URL, it can be a website address, a landing page or a link to the business
                  address in Google Maps.
                </div>
              }
              placement="bottom"
              arrow
              PopperProps={{
                disablePortal: true,
              }}
            >
              <div>
                <InfoIcon className={classes.infoIcon} size={16} color="red" />
              </div>
            </Tooltip>
          </label>
          {!qrCodeLink ? <span className={classes.requiredTip}>Required</span> : null}
        </div>

        <Input
          name="startDate"
          value={qrCodeLink || "https://"}
          variant="outlined"
          onChange={(value: any) => {
            updateCampaign({ qrCodeLink: value });
          }}
          inputProps={{ cypress_id: "urlForQRCode" }}
        />

        {/* <div
          className={classes.checkboxContainer}
          onClick={() => {
            updateQrCodeSettings({ hideQrBackground: !hideQrBackground });
          }}
        >
          <QrCheckbox active={hideQrBackground} />
          <span className={classes.checkboxLabel}>Remove the background</span>
        </div> */}

        <div
          className={classes.checkboxContainer}
          onClick={() => {
            updateQrCodeSettings({ hideQrCoupon: !hideQrCoupon });
          }}
        >
          <QrCheckbox active={hideQrCoupon} />
          <span className={classes.checkboxLabel}>Hide the coupon</span>
        </div>
      </div>
    </>
  );
};

export const QrCode = React.memo(QrCodeComponent);

const useStyles = makeStyles({
  settings_row: {
    marginBottom: 16,
  },
  container: {
    background: "var(--color-surface-panel)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 48,
    padding: 8,
  },
  items: {
    display: "grid",
    gap: 4,
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  },
  label: {
    display: "flex",
    fontSize: 14,
    lineHeight: "16px",
    color: "var(--color-foreground-300)",
    marginBottom: 4,
    alignItems: "center",
  },
  required: {
    color: "red",
  },
  requiredTip: {
    color: "red",
    marginRight: "6px",
  },
  labelBlock: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoIcon: {
    marginLeft: "8px",
  },
  tooltip: {
    padding: 10,
    backgroundColor: "#eee",
    color: "#000",
    width: "250px",
  },
  tooltipArrow: {
    "&::before": {
      color: "#f9f9f9",
    },
  },
  checkboxContainer: { marginTop: "5px", display: "flex", alignItems: "center", cursor: "pointer" },
  checkboxLabel: {
    fontFamily: "Proxima Nova, sans-serif",
    color: "#011533",
    fontSize: "12px",
    marginLeft: "5px",
    fontWeight: 400,
    cursor: "pointer",
  },
});
