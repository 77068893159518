import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const updateCampaignExtraData = (payload) =>
  callAuthorizedGraphQLEndpoint(
    `mutation updateCampaignExtraData(
      $input: CampaignExtraDataInput
    ) {
      updateCampaignExtraData(
        input: $input,
      ) { 
        success
        error
      }
    }`,
    {
      input: {
        ...payload,
      },
    }
  );
