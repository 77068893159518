import { styled } from "pages/flyer/builder/drawer/styles/index.ts";

export const StyledContainer = styled("div", {
  "&&&&": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: "24px 20px 32px",
    borderRadius: "8px",

    "@media (max-width: 768px)": {
      padding: "15px",
      alignItems: "stretch",
    },

    "& .react-datepicker__header": {
      backgroundColor: "white",
      borderBottom: "0px",
    },

    "& .react-datepicker__month": {
      height: "260px",
      margin: "0px",
    },

    "& .react-datepicker__month-container:not(:first-of-type)": {
      marginLeft: "65px",
      paddingRight: "10px",

      "@media (max-width: 768px)": {
        marginLeft: "0px",
        paddingRight: "0px",
      },
    },

    "& .react-datepicker__day-names": {
      display: "flex",
      borderBottom: "1px solid #E8E8FB",
    },

    "& .react-datepicker__week": {
      display: "flex",
    },

    "& .react-datepicker__week:not(:first-of-type)": {
      marginTop: "2px",
    },

    "& .react-datepicker__week:first-of-type": {
      marginTop: "5px",
    },

    "& .react-datepicker__day--outside-month": {
      height: "fit-content !important",
      borderWidth: "0px !important",
      color: "$greyMedium !important",
      backgroundColor: "white !important",
    },

    "& .react-datepicker__day-name, & .react-datepicker__day--keyboard-selected, & .react-datepicker__day": {
      justfiContent: "center",
      alignItems: "center",
      width: "40px",
      height: "40px",
      lineHeight: "40px",
      margin: "0px",
      marginRight: "2px",
      marginLeft: "4px",
    },

    "& .react-datepicker__day, & .react-datepicker__day--keyboard-selected": {
      backgroundColor: "white",
      color: "$blueDeepDark",
      borderRadius: "40px",
    },

    "& .react-datepicker__day--disabled": {
      height: "fit-content",
      color: "$greyMedium",
    },

    "& .react-datepicker__day--disabled:hover": {
      backgroundColor: "#F1F2F5 !important",
    },

    "& .react-datepicker__day-name": {
      fontSize: "$3",
      fontWeight: 400,
      color: "$greyMedium",
    },

    "& .react-datepicker__day:hover": {
      backgroundColor: "#FFD0D9",
    },

    "& .react-datepicker__day:active": {
      backgroundColor: "#EE4360",
    },

    "& .react-datepicker__day:focus-visible": {
      outline: "none",
    },

    "& .react-datepicker__day--today, & .react-datepicker__day--today:hover": {},

    "& .react-datepicker__day--selected-old": {
      border: `1px solid #FFA4B5`,
    },

    "& .react-datepicker__day--selected-old:hover": {
      backgroundColor: "#FFA4B5",
    },

    "& .react-datepicker__day--selected-old:active": {
      backgroundColor: "#EE4360",
    },

    "& .react-datepicker__day--selected-new": {
      backgroundColor: "$primaryRed",
      color: "white",
    },

    "& .react-datepicker__day--selected-new:hover": {
      backgroundColor: "#D72B4F",
    },

    "& .react-datepicker__day--selected-new:active": {
      backgroundColor: "#EE4360",
    },
  },
});

export const StyledTopContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100%",
});

export const StyledChildrenContainer = styled("div", {
  position: "relative",
  marginTop: "20px",
});

export const StyledBottomContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100%",
  marginTop: 0,
});

export const StyledButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alighItems: "start",
  justifyContent: "space-between",
  marginTop: "27px",

  "@media (max-width: 768px)": {
    gap: "50px",
    alignItems: "center",
  },
});

export const StyledTimeSelectContainer = styled("div", {
  width: "180px",
});

export const StyledTitle = styled("span", {
  color: "$secondaryDeepBlue",
  fontSize: "$4",
  fontWeight: 700,
});

export const StyledDescription = styled("span", {
  maxWidth: "600px",
  color: "$greyDark",
  fontSize: "$1",
  fontWeight: 400,
  marginTop: "10px",
});

export const StyledCloseButton = styled("span", {
  position: "absolute",
  cursor: "pointer",
  right: 0,
  top: 0,
  opacity: 0.5,
  "&:hover": {
    opacity: 1,
  },
});

export const StyledAddButton = styled("button", {
  width: "140px",
  height: "40px",
  color: "white",
  backgroundColor: "$primaryRed",
  fontSize: "$3",
  fontWeight: 700,
  borderRadius: "16px",
  cursor: "pointer",

  "&:disabled": {
    opacity: 0.3,
  },
});

export default StyledContainer;
