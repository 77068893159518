const style = {
  wrongDate: {
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f50057",
    letterSpacing: "-0.01em",
    textAlign: "center",
    margin: "8px 0 -16px 0",
  },
  anyDemographicsNotice: {
    padding: "16px 0",
  },
  isPostcodesKnowen: {
    margin: "8px 0",
    textAlign: "end",
    cursor: "pointer",
    color: "#2898EC",
    textDecoration: "underline",
  },
  cityBox: {
    width: "90%",
    paddingTop: "5px",
    position: "relative",
  },

  customAddress: {
    display: "flex",
    position: "absolute",
    right: "-50px",
    top: "25px",
    transform: "translateY(-55%)",
    cursor: "pointer",
    color: "#2898EC",
  },

  paper: {
    background: "#FFFFFF",
    minHeight: "calc(100vh - 50px)",
  },

  label: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#585C60",
    "& a": {
      cursor: "pointer",
      paddingLeft: "5px",
    },
  },
  description: {
    paddingBottom: "20px",
    fontStyle: "italic",
    fontSize: "12px",
    lineHeight: "12px",
    color: "#B6B6B6",
  },
  emptyDemography: {
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f50057",
    letterSpacing: "-0.01em",
    textAlign: "center",
  },
  ageRange: {
    paddingTop: "32px",
  },
  gender: {
    paddingTop: "25px",
  },
  householdIncome: {
    paddingTop: "32px",
  },
  demographics: {
    paddingTop: "24px",
  },
  container: {
    paddingBottom: "45px",
    paddingTop: "120px",
  },
  caption: {
    fontStyle: "italic",
    paddingTop: "5px",
    color: "#B6B6B6",
    fontSize: "0.70rem",
  },

  audienceFormWrapper: {
    // width: "100%",
    // height: "calc(100vh - 50px)",
    // padding: "60px 0 70px",
    // maxWidth: "620px",
    // margin: "auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    height: "100%",
  },
  audienceFormLeftBiggerWrapper: {
    height: "90%",
    margin: "auto 10px",
    width: "initial",
    maxWidth: "initial",
  },

  h2hCampaignLocationWrapper: {
    height: "calc(100% - 150px)",
    width: "fit-content",
  },
};

export default style;
