export const getCampaignLogInsertedParams = (logs) => {
  return logs.map((log) => {
    const { type, campaignId, additionalInfo } = log;

    return {
      type,
      campaignId,
      additionalInfo: additionalInfo || "",
    };
  });
};

export const replaceNullValue = (value) => (value === null ? " - " : value);
