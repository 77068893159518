import { gql } from "@apollo/client";

export const GenerateAndSaveDocument = gql`
 mutation GenerateAndSaveDocument($document: Document, $campaignId: ID!) {
    generateAndSaveDocument(
      doc: $document
      campaignId: $campaignId
    ) {
      id
      filename
      link
    }
  }
`;

