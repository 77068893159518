export default function GroupIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 8.2 10.4">
      <defs></defs>
      <g id="qfAHpA.tif_1_">
        <g>
          <g>
            <path
              d="M4.2,0c0.1,0,0.2,0.1,0.3,0.1C5,0.3,5.4,0.5,5.9,0.7c0.4,0.2,0.8,0.3,1.2,0.5c0.3,0.1,0.5,0.2,0.8,0.4
				c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2-0.1,0.4-0.3,0.4C7.7,2.6,7.5,2.7,7.3,2.8C6.9,3,6.5,3.1,6,3.3C5.6,3.5,5.2,3.7,4.9,3.8
				C4.7,3.9,4.5,4,4.3,4.1c-0.1,0.1-0.3,0.1-0.4,0c-0.5-0.2-1-0.4-1.6-0.7C2,3.2,1.6,3.1,1.2,2.9C0.9,2.8,0.6,2.6,0.3,2.5
				C0.1,2.4,0.1,2.3,0.1,2.1c0-0.2,0.1-0.4,0.3-0.4c0.2-0.1,0.4-0.2,0.6-0.2C1.4,1.2,1.8,1,2.2,0.8C2.6,0.7,3,0.5,3.4,0.3
				C3.6,0.2,3.8,0.1,4.1,0C4.1,0,4.2,0,4.2,0z M1.7,2.1c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.2,1.1,0.5,1.7,0.7c0,0,0.1,0,0.2,0
				C4.4,3,4.6,2.9,4.9,2.8c0.4-0.2,0.9-0.4,1.3-0.6c0.1-0.1,0.3-0.1,0.4-0.2C6.3,2,6.1,1.9,5.9,1.8C5.5,1.6,5.1,1.4,4.8,1.3
				C4.6,1.2,4.4,1.1,4.2,1.1c0,0-0.1,0-0.2,0C3.8,1.1,3.6,1.3,3.4,1.4C3,1.5,2.6,1.7,2.2,1.9C2,1.9,1.9,2,1.7,2.1z"
            />
            <path
              d="M8.2,8.4c0,0.3-0.2,0.4-0.5,0.4c-0.5,0.2-1,0.4-1.5,0.6C5.9,9.7,5.5,9.8,5.2,10c-0.3,0.1-0.5,0.2-0.8,0.4
				c-0.1,0.1-0.3,0.1-0.4,0c-0.2-0.1-0.4-0.2-0.6-0.3C2.9,9.9,2.5,9.8,2.1,9.6C1.7,9.4,1.3,9.3,1,9.1C0.7,9,0.5,8.9,0.3,8.8
				C0,8.7,0,8.3,0.2,8.1C0.3,7.9,0.5,7.8,0.8,8C0.9,8,1.1,8.1,1.2,8.2C1.4,8.3,1.7,8.4,2,8.5C2.4,8.7,2.8,8.8,3.2,9
				c0.3,0.1,0.6,0.3,0.9,0.4c0,0,0.1,0,0.1,0C4.5,9.3,4.7,9.2,5,9.1c0.4-0.2,0.8-0.4,1.2-0.5C6.6,8.4,7,8.2,7.3,8
				C7.4,8,7.5,8,7.6,7.9c0.3-0.1,0.6,0,0.6,0.3c0,0,0,0,0,0C8.2,8.3,8.2,8.4,8.2,8.4z"
            />
            <path
              d="M4.1,6.2c-0.1,0-0.1,0-0.2-0.1C3.4,5.9,2.8,5.7,2.3,5.5C1.9,5.3,1.5,5.1,1.1,4.9C0.8,4.8,0.6,4.7,0.3,4.6
				C0.1,4.5,0,4.2,0.1,4c0.1-0.3,0.3-0.4,0.6-0.3c0.5,0.2,1,0.4,1.4,0.6c0.4,0.2,0.7,0.3,1.1,0.5C3.5,5,3.8,5.1,4.1,5.2
				c0,0,0.1,0,0.2,0C4.4,5.1,4.6,5,4.8,4.9C5.2,4.8,5.6,4.6,6,4.4c0.4-0.2,0.8-0.3,1.2-0.5c0.1-0.1,0.3-0.1,0.4-0.2
				c0.3-0.1,0.5,0,0.6,0.3c0.1,0.2,0,0.5-0.2,0.6C7.7,4.7,7.5,4.8,7.4,4.9C6.9,5,6.5,5.2,6.1,5.4C5.7,5.6,5.3,5.7,5,5.9
				C4.8,6,4.6,6.1,4.4,6.2C4.3,6.2,4.2,6.2,4.1,6.2z"
            />
            <path
              d="M0.1,6.3c0-0.3,0.3-0.6,0.6-0.5C1.2,6,1.6,6.2,2.1,6.4c0.4,0.2,0.7,0.3,1.1,0.5C3.4,7,3.7,7.2,4,7.3c0.1,0,0.1,0,0.2,0
				C4.4,7.2,4.6,7.1,4.8,7c0.4-0.2,0.8-0.4,1.3-0.5C6.5,6.3,6.8,6.2,7.2,6C7.3,6,7.5,5.9,7.6,5.8c0.3-0.1,0.5,0,0.6,0.3
				c0.1,0.2,0,0.5-0.2,0.6C7.8,6.8,7.7,6.8,7.6,6.8C7.1,7,6.7,7.3,6.2,7.5C5.8,7.6,5.4,7.8,5.1,8C4.8,8.1,4.6,8.2,4.4,8.3
				c-0.2,0.1-0.3,0.1-0.4,0C3.7,8.2,3.6,8.1,3.4,8.1C3,7.9,2.6,7.7,2.2,7.5C1.8,7.4,1.4,7.2,1,7C0.8,6.9,0.6,6.8,0.3,6.7
				C0.1,6.6,0.1,6.5,0.1,6.3z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
