export const style = {
  navigationBtn: {
    cursor: "pointer",
    border: "1px solid #273754",
    borderRadius: "16px",
    width: "148px",
    height: "48px",
    color: "#00112B",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    margin: "0 6px",
    alignItems: "center" as const,
    display: "flex",
    justifyContent: "center",
    position: "relative" as const,

    "&:hover": {
      transform: "scale(1.02)",
    },
  },

  navigationActive: {
    background: "#EE4360",
    border: "none",
    color: "#FFFFFF",
  },

  navigationDisabled: {
    background: "#C2C8D1",
    border: "none",
    color: "#FFFFFF",
  },
};
