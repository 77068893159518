import { gql } from "@apollo/client";

export const UploadFile = gql`
  mutation singleUpload(
    $file: String!
    $fileName: String!
    $fileType: String!
    $campaignId: ID!
  ) {
    singleUpload(
      file: $file
      fileName: $fileName
      fileType: $fileType
      campaignId: $campaignId
    ) {
      id
      filename
      link
    }
  }
`;
