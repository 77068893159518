export const style = {
  navigationBtn: {
    border: "1px solid transparent",
    borderRadius: "12px",
    padding: "14px 0px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "164px",
    position: "relative" as const,

    "&:hover": {
      cursor: "pointer",
    },
  },

  saved: {
    paddingRight: "35px",
  },

  nextButton: {
    paddingRight: "35px",
    background: "#EE4360",
    color: "#FFFFFF",

    "&:hover": {
      background: "#FD3758",
    },

    "&:active": {
      background: "#D72B4F",
    },
  },

  saveButton: {
    borderColor: "#273754",
    background: "transparent",
    color: "#00112B",

    "&:hover": {
      borderColor: "transparent",
      background: "#EE4360",
      color: "#FFFFFF",
    },

    "&:active": {
      borderColor: "transparent",
      background: "#D72B4F",
      color: "#FFFFFF",
    },
  },

  saveButtonDisabled: {
    border: "1px solid #707087",
    cursor: "default !important",
    color: "#707087",
  },

  nextButtonDisabled: {
    cursor: "default !important",
    color: "#FFFFFF",
    background: "#AEAFC7",
    paddingRight: "30px",
  },
};
