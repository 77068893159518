import { Utils } from "@tldraw/core";
import { Shadow } from "pages/flyer/builder/drawer";
import {
  AlignStyle,
  ColorStyle,
  DashStyle,
  // FontStyle,
  ShapeStyles,
  SizeStyle,
  Theme,
} from "pages/flyer/builder/drawer/types";

const canvasLight = "#aaaaaa";

const canvasDark = "#343d45";

export const colors = {
  [ColorStyle.White]: "#f0f1f3",
  [ColorStyle.LightGray]: "#c6cbd1",
  [ColorStyle.Gray]: "#788492",
  [ColorStyle.Black]: "#1d1d1d",
  [ColorStyle.Green]: "#36b24d",
  [ColorStyle.Cyan]: "#0e98ad",
  [ColorStyle.Blue]: "#1c7ed6",
  [ColorStyle.Indigo]: "#4263eb",
  [ColorStyle.Violet]: "#7746f1",
  [ColorStyle.Red]: "#ff2133",
  [ColorStyle.Orange]: "#ff9433",
  [ColorStyle.Yellow]: "#ffc936",
  [ColorStyle.LightBlue]: "#4285F4",
};

export const stickyFills: Record<Theme, Record<ColorStyle, string>> = {
  light: {
    ...(Object.fromEntries(
      Object.entries(colors).map(([k, v]) => [k, Utils.lerpColor(v, canvasLight, 0.45)])
    ) as Record<ColorStyle, string>),
    [ColorStyle.White]: "#ffffff",
    [ColorStyle.Black]: "#3d3d3d",
  },
  dark: {
    ...(Object.fromEntries(
      Object.entries(colors).map(([k, v]) => [k, Utils.lerpColor(Utils.lerpColor(v, "#999999", 0.3), canvasDark, 0.4)])
    ) as Record<ColorStyle, string>),
    [ColorStyle.White]: "#1d1d1d",
    [ColorStyle.Black]: "#bbbbbb",
  },
};

export const strokes: Record<Theme, Record<ColorStyle, string>> = {
  light: {
    ...colors,
    [ColorStyle.White]: "#1d1d1d",
  },
  dark: {
    ...(Object.fromEntries(Object.entries(colors).map(([k, v]) => [k, Utils.lerpColor(v, canvasDark, 0.1)])) as Record<
      ColorStyle,
      string
    >),
    [ColorStyle.White]: "#cecece",
    [ColorStyle.Black]: "#cecece",
  },
};

export const fills: Record<Theme, Record<ColorStyle, string>> = {
  light: {
    ...(Object.fromEntries(
      Object.entries(colors).map(([k, v]) => [k, Utils.lerpColor(v, canvasLight, 0.82)])
    ) as Record<ColorStyle, string>),
    [ColorStyle.White]: "#fefefe",
  },
  dark: {
    ...(Object.fromEntries(Object.entries(colors).map(([k, v]) => [k, Utils.lerpColor(v, canvasDark, 0.82)])) as Record<
      ColorStyle,
      string
    >),
    [ColorStyle.White]: "rgb(30,33,37)",
    [ColorStyle.Black]: "#1e1e1f",
  },
};

const strokeWidths = {
  [SizeStyle.Small]: 2,
  [SizeStyle.Medium]: 3.5,
  [SizeStyle.Large]: 5,
};

const fontSizes = {
  [SizeStyle.Small]: 28,
  [SizeStyle.Medium]: 48,
  [SizeStyle.Large]: 96,
  auto: "auto",
};

const stickyFontSizes = {
  [SizeStyle.Small]: 24,
  [SizeStyle.Medium]: 36,
  [SizeStyle.Large]: 48,
  auto: "auto",
};

export function getStrokeWidth(size: SizeStyle): number {
  return strokeWidths[size];
}

export function getFontSize(
  size: SizeStyle,
  // fontStyle: FontStyle = FontStyle.Script
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fontStyle: string
): number {
  return fontSizes[size];
  // return fontSizes[size] * fontSizeModifiers[fontStyle];
}

// export function getFontFace(font: FontStyle = FontStyle.Script): string {
export function getFontFace(font: string): string {
  return font;
}

export function getStickyFontSize(size: SizeStyle): number {
  return stickyFontSizes[size];
}

export function getFontStyle(style: ShapeStyles): string {
  const fontSize = style.fontSize || 12;
  const fontFace = getFontFace(style.font || "");
  const { scale = 1 } = style;
  return `${fontSize * scale}px ${fontFace}`;
}

// export function getStickyFontStyle(style: ShapeStyles): string {
//   const fontSize = getStickyFontSize(style.size);
//   const fontFace = getFontFace(style.font || "");
//   const { scale = 1 } = style;

//   return `${fontSize * scale}px/1 ${fontFace}`;
// }

// export function getStickyShapeStyle(style: ShapeStyles, isDarkMode = false) {
//   const { strokeColor, fillColor } = style;

//   const theme: Theme = isDarkMode ? "dark" : "light";
//   const adjustedColor =
//     color === ColorStyle.White || color === ColorStyle.Black
//       ? ColorStyle.Yellow
//       : color;

//   return {
//     fill: adjustedColor,
//     stroke: adjustedColor,
//     color: isDarkMode ? "#1d1d1d" : "#0d0d0d",
//   };
// }

export function getShapeStyle(
  style: ShapeStyles,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isDarkMode?: boolean
): {
  stroke?: string;
  strokeWidth?: number;
  isStroked?: boolean;

  fill?: string;
  isFilled?: boolean;

  fontSize?: number;

  transparency?: number;
  isOpacityVisible?: boolean;

  rounded?: number;
  isRoundingVisible?: boolean;

  innerShadowColor?: string;
  innerShadow?: Shadow;
  isInnerShadowVisible?: boolean;

  outerShadow?: Shadow;
  outerShadowColor?: string;
  isOuterShadowVisible?: boolean;
} {
  const {
    fillColor,
    isFilled,

    strokeColor,
    strokeWidth,
    isStroked,

    fontSize,

    transparency,
    isOpacityVisible,

    rounded,
    isRoundingVisible,

    innerShadowColor,
    innerShadow,
    isInnerShadowVisible,

    outerShadow,
    outerShadowColor,
    isOuterShadowVisible,
  } = style;

  // const theme: Theme = isDarkMode ? "dark" : "light";

  return {
    stroke: strokeColor,
    strokeWidth,
    isStroked,

    fill: fillColor,
    isFilled,

    transparency,
    isOpacityVisible,

    rounded,
    isRoundingVisible,

    fontSize,

    isInnerShadowVisible,
    innerShadowColor,
    innerShadow,

    isOuterShadowVisible,
    outerShadow,
    outerShadowColor,
  };
}

export const defaultStyle: ShapeStyles = {
  fillColor: "#AEAFC7",
  strokeColor: "#AEAFC7",
  strokeWidth: 0,
  fontSize: 20,
  isFilled: true,
  isStroked: true,
  fontStyle: [],
  dash: DashStyle.Solid,
  scale: 1,
  transparency: 100,
  rounded: 0,
  innerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  innerShadowColor: "#AEAFC7",
  isInnerShadowVisible: true,
  outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  outerShadowColor: "#AEAFC7",
  isOuterShadowVisible: true,
  isRoundingVisible: true,
  isOpacityVisible: true,
};

export const defaultTextStyle: ShapeStyles = {
  dash: DashStyle.Solid,

  transparency: 100,
  isOpacityVisible: true,

  outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  isOuterShadowVisible: true,
  outerShadowColor: "#AEAFC7",

  fillColor: "#AEAFC7", // fontColor
  isFilled: true,

  strokeColor: "#AEAFC7",
  strokeWidth: 0,
  isStroked: true,

  font: "Open Sans",
  fontSize: 36,
  fontStyle: [],

  textAlign: AlignStyle.Start,
  verticalTextAlign: AlignStyle.Start,
};

export const defaultArrowStyle: ShapeStyles = {
  strokeColor: "#AEAFC7",
  strokeWidth: 2,
  isStroked: true,

  dash: DashStyle.Solid,

  transparency: 100,
  isOpacityVisible: true,

  outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  outerShadowColor: "#AEAFC7",
  isOuterShadowVisible: true,
};

export const defaultDrawStyle: ShapeStyles = {
  //Stroke
  strokeColor: "#AEAFC7",
  strokeWidth: 2,
  isStroked: true,

  //fill
  fillColor: "#AEAFC7",
  isFilled: true,

  //dash
  dash: DashStyle.Solid,

  //transparency
  transparency: 100,
  isOpacityVisible: true,

  //outerShadow
  // outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  // outerShadowColor: "#000000",
  // isOuterShadowVisible: false,
};

export const defaultEllipseStyle: ShapeStyles = {
  //Stroke
  strokeColor: "#AEAFC7",
  strokeWidth: 0,
  isStroked: true,

  //fill
  fillColor: "#AEAFC7",
  isFilled: true,

  //dash
  dash: DashStyle.Solid,

  //transparency
  transparency: 100,
  isOpacityVisible: true,

  // outerShadow
  outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  outerShadowColor: "#AEAFC7",
  isOuterShadowVisible: true,
};

export const defaultImageStyle: ShapeStyles = {
  //Stroke
  strokeColor: "#AEAFC7",
  strokeWidth: 0,
  isStroked: true,

  //dash
  dash: DashStyle.Solid,

  //transparency
  transparency: 100,
  isOpacityVisible: true,

  // outerShadow
  outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  outerShadowColor: "#AEAFC7",
  isOuterShadowVisible: true,
};

export const defaultQRCodeStyle: ShapeStyles = {
  //Stroke
  strokeColor: "#AEAFC7",
  strokeWidth: 2,
  isStroked: true,

  //dash
  dash: DashStyle.Solid,

  //transparency
  transparency: 100,
  isOpacityVisible: true,
};

export const defaultRectangleStyle: ShapeStyles = {
  //fill
  fillColor: "#AEAFC7",
  isFilled: true,

  //Stroke
  strokeColor: "#AEAFC7",
  strokeWidth: 0,
  isStroked: true,

  //dash
  dash: DashStyle.Solid,

  //transparency
  transparency: 100,
  isOpacityVisible: true,

  // outerShadow
  outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  outerShadowColor: "#AEAFC7",
  isOuterShadowVisible: true,

  //rounding
  rounded: 0,
  isRoundingVisible: true,

  //innerShadow
  innerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
  innerShadowColor: "#AEAFC7",
  isInnerShadowVisible: true,
};
