import React, { MouseEvent } from "react";
import cn from "classnames";
import { Typography, makeStyles } from "@material-ui/core";
import nextIcon from "static/media/next.svg";
import { style } from "./style";

const useStyles = makeStyles(() => style);

export type ItemProps = {
  title?: string | React.ReactNode;
  variant?: "active";
  cypressId?: string;
  isActive?: boolean;
  isValidUrl?: boolean;
  isQrCodeOnCanvas?: boolean;
  action?: () => void;
};

type NextButtonProps = {
  item?: ItemProps;
  onClick?: (item: ItemProps, e: MouseEvent) => void;
};

const defaultItem: ItemProps = {
  title: "Next",
  variant: undefined,
  cypressId: "goToNextStepBtn",
  isActive: false,
  isValidUrl: false,
  isQrCodeOnCanvas: false,
  action: () => {},
};

export const NextButton = ({ item = defaultItem, onClick = () => {} }: NextButtonProps): React.ReactElement => {
  const classes = useStyles();

  return (
    // @ts-ignore
    <Typography
      className={cn(
        classes.navigationBtn,
        item?.variant ? (item.isActive ? classes.navigationActive : classes.navigationDisabled) : {}
      )}
      cypress_id={item?.cypressId}
      onClick={(e: MouseEvent) => {
        onClick(item, e);
      }}
    >
      {item.title}
      {item?.variant ? <img src={nextIcon} style={{ position: "absolute", right: "15px" }} /> : null}
    </Typography>
  );
};
