import React, { memo, FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  loadingOverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)",
    zIndex: 9999,
  },
}));

const BlockingOverlay: FC = () => {
  const classes = useStyles();

  return <div className={classes.loadingOverlay} />;
};

export default memo(BlockingOverlay);
