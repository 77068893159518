import { AlignStyle } from "pages/flyer/builder/drawer/types";

const ALIGN_VALUES = {
  [AlignStyle.Start]: "left",
  [AlignStyle.Middle]: "center",
  [AlignStyle.End]: "right",
  [AlignStyle.Justify]: "justify",
} as const;

const VERTICAL_ALIGN_VALUES = {
  [AlignStyle.Start]: "start",
  [AlignStyle.Middle]: "center",
  [AlignStyle.End]: "end",
  [AlignStyle.Justify]: "center",
} as const;

export function getTextAlign(alignStyle: AlignStyle = AlignStyle.Start) {
  return ALIGN_VALUES[alignStyle];
}

export function getTextVerticalAlign(alignStyle: AlignStyle = AlignStyle.Start) {
  return VERTICAL_ALIGN_VALUES[alignStyle];
}
