import { TLBoundsCorner, TLPointerEventHandler, Utils } from "@tldraw/core";
import Vec from "@tldraw/vec";
import { getShapeUtil } from "pages/flyer/builder/drawer/state/shapes";
import { SessionType, TDShapeType } from "pages/flyer/builder/drawer/types";
import { BaseTool, Status } from "../BaseTool";

export class CodeTool extends BaseTool {
  type = TDShapeType.Code as const;

  /* ----------------- Event Handlers ----------------- */

  onPointerDown: TLPointerEventHandler = () => {
    if (this.status !== Status.Idle) return;

    const {
      currentPoint,
      currentGrid,
      settings: { showGrid },
      appState: { currentPageId, currentStyle },
    } = this.app;

    const childIndex = this.getNextChildIndex();

    const id = Utils.uniqueId();

    const Code = getShapeUtil(TDShapeType.Code);

    const newShape = Code.create({
      id,
      parentId: currentPageId,
      childIndex,
      label: "CODE123",
      point: showGrid ? Vec.snap(currentPoint, currentGrid) : currentPoint,
      style: { ...currentStyle },
    });

    this.app.patchCreate([newShape]);

    this.app.startSession(SessionType.TransformSingle, newShape.id, TLBoundsCorner.BottomRight, true);

    this.setStatus(Status.Creating);
  };
}
