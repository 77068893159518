export const Cursor = ({ stroke, ...props }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.859 10.6093L6.39756 8.14787L5.64587 8.89956C4.87637 9.66906 4.4916 10.0538 4.07805 9.963C3.66451 9.87213 3.4764 9.36156 3.10019 8.34037L1.84544 4.93469C1.09492 2.89754 0.719661 1.87897 1.24463 1.354C1.7696 0.829036 2.78816 1.20429 4.82531 1.95482L8.231 3.20956C9.25219 3.58578 9.76275 3.77389 9.85362 4.18743C9.94444 4.60097 9.55969 4.98575 8.79019 5.75525L8.0385 6.50694L10.4999 8.96838C10.7548 9.22319 10.8822 9.35062 10.9411 9.49281C11.0196 9.68237 11.0196 9.89531 10.9411 10.0849C10.8822 10.227 10.7548 10.3544 10.4999 10.6093C10.2451 10.8642 10.1176 10.9916 9.9755 11.0505C9.78594 11.129 9.573 11.129 9.38344 11.0505C9.24125 10.9916 9.11381 10.8642 8.859 10.6093Z"
        stroke={stroke || "#1A2547"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
