import { Vec } from "@tldraw/vec";
import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import {
  Patch,
  ShapeStyles,
  TDShape,
  TDShapeType,
  TextAreaShape,
  TldrawCommand,
  StaticAssetsIdsEnum,
} from "pages/flyer/builder/drawer/types";

export function styleShapes(app: TldrawApp, ids: string[], changes: Partial<ShapeStyles>): TldrawCommand {
  const { currentPageId, selectedIds } = app;

  const strokeStyleInQrCode = (shape: TDShape, key: string) => {
    if (shape.assetId === StaticAssetsIdsEnum.QrCode) {
      if (key === "isStroked" || key === "strokeWidth") {
        return true;
      }
    }

    return false;
  };

  const shapeIdsToMutate = ids
    .flatMap((id) => TLDR.getDocumentBranch(app.state, id, currentPageId))
    .filter((id) => !app.getShape(id).isLocked);

  const beforeShapes: Record<string, Patch<TDShape>> = {};
  const afterShapes: Record<string, Patch<TDShape>> = {};

  shapeIdsToMutate
    .map((id) => app.getShape(id))
    .filter((shape) => !shape.isLocked)
    .forEach((shape) => {
      beforeShapes[shape.id] = {
        style: {
          ...Object.fromEntries(
            Object.keys(changes)
              .filter(
                (key) => shape.style[key as keyof typeof shape.style] !== undefined && !strokeStyleInQrCode(shape, key)
              )
              .map((key) => [key, shape.style[key as keyof typeof shape.style]])
          ),
        },
      };

      const changesToApply: Record<string, any> = {};
      const currentStyleParams = Object.keys(shape.style);
      const changesParams = Object.keys(changes);
      changesParams.forEach((paramName) => {
        if (currentStyleParams.includes(paramName) && !strokeStyleInQrCode(shape, paramName)) {
          changesToApply[paramName] = changes[paramName as keyof ShapeStyles]!;
        }
      });

      afterShapes[shape.id] = {
        style: changesToApply,
      };

      if (shape.type === TDShapeType.TextArea) {
        beforeShapes[shape.id].point = shape.point;
        afterShapes[shape.id].point = Vec.toFixed(
          Vec.add(
            shape.point,
            Vec.sub(
              app.getShapeUtil(shape).getCenter(shape),
              app.getShapeUtil(shape).getCenter({
                ...shape,
                style: { ...shape.style, ...changesToApply },
              } as TextAreaShape)
            )
          )
        );
      }
    });

  return {
    id: "style",
    before: {
      document: {
        pages: {
          [currentPageId]: {
            shapes: beforeShapes,
          },
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: selectedIds,
          },
        },
      },
    },
    after: {
      document: {
        pages: {
          [currentPageId]: {
            shapes: afterShapes,
          },
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: ids,
          },
        },
      },
    },
  };
}
