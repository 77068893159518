import { DocumentFormats } from "shared/constants";
import IntegrationsService from "module/IntegrationsService/client";

const getCountOfMission = (addresses) =>
  addresses.reduce((acc, address) => {
    if (address.id) {
      acc.push(address);
    }

    return acc;
  }, []).length;

const getCountryCostCalculationError = (countrySettings) => {
  if (
    countrySettings.enabledChannelLetterbox &&
    (!countrySettings.d2dDistributionCosts || !countrySettings.flyerPackD2d)
  ) {
    return true;
  }

  if (
    countrySettings.enabledChannelPreciseLocations &&
    (!countrySettings.h2hDistributionCosts || !countrySettings.flyerPackH2h)
  ) {
    return true;
  }

  if (!countrySettings.minimumMissionLimit) {
    return true;
  }

  if (!countrySettings.weeklyMissionLimit && countrySettings.weeklyMissionLimit !== null) {
    return true;
  }

  return false;
};

const getInternalError = ({ countrySettings, countryCode, printingCost }) => {
  const countryCostCalculationError = getCountryCostCalculationError(countrySettings);

  const countOfFlyerTypesMap = Object.entries(printingCost).reduce(
    (acc, [key, costs]) => {
      if (key.includes(DocumentFormats.A6)) {
        acc[DocumentFormats.A6] += costs.length;
      } else if (key.includes(DocumentFormats.DOOR_HANGER)) {
        acc[DocumentFormats.DOOR_HANGER] += costs.length;
      } else if (key.includes(DocumentFormats.TEARAWAY)) {
        acc[DocumentFormats.TEARAWAY] += costs.length;
      }

      return acc;
    },
    {
      [DocumentFormats.A6]: 0,
      [DocumentFormats.DOOR_HANGER]: 0,
      [DocumentFormats.TEARAWAY]: 0,
    }
  );

  if (
    countOfFlyerTypesMap[DocumentFormats.A6] === 0 ||
    countryCostCalculationError ||
    (countryCode === "US" && countOfFlyerTypesMap[DocumentFormats.DOOR_HANGER] === 0)
  ) {
    return true;
  }
  return false;
};

const getDynamicCoupons = async () => {
  const integrations = await IntegrationsService.readIntegrations();
  const integrationsIds = integrations?.map((integration) => integration.id);
  let coupons = [];
  if (integrationsIds) {
    for (const integrationId of integrationsIds) {
      coupons = [...coupons, ...(await IntegrationsService.readCoupons({ integrationId }))];
    }
  }
  return coupons;
};

const filterCoupons = (filterCoupons, usedCouponId) =>
  filterCoupons.filter((coupon) => {
    if (!coupon.used) {
      return true;
    } else {
      return coupon.id === usedCouponId;
    }
  });

export { getCountOfMission, getInternalError, getDynamicCoupons, filterCoupons };
