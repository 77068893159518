const SvgInfo = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="11" cy="11" r="11" fill="#EE4360" />
    <path
      d="M9.12109 15.332V14.2109L10.1123 14.0059V9.26855L9.03906 9.06348V7.93555H11.9717V14.0059L12.9629 14.2109V15.332H9.12109ZM10.0439 6.31543V4.66797H11.9717V6.31543H10.0439Z"
      fill="white"
    />
  </svg>
);

export default SvgInfo;
