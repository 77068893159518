import * as React from "react";

export function UnderLineFontIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={20}
      height={20}
      viewBox="-30 -22 100 100"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.989 0H38v31.365c0 3.482-.762 6.38-2.285 8.693-1.524 2.314-3.549 4.055-6.075 5.222C27.136 46.427 24.412 47 21.47 47c-3.09 0-5.887-.573-8.391-1.72-2.484-1.167-4.457-2.908-5.918-5.222C5.72 37.744 5 34.847 5 31.365V0h5.98v31.365c0 2.42.438 4.416 1.315 5.987.877 1.57 2.098 2.738 3.663 3.502 1.587.764 3.423 1.147 5.51 1.147 2.109 0 3.946-.383 5.511-1.147 1.586-.764 2.818-1.931 3.695-3.502.876-1.571 1.315-3.567 1.315-5.987V0ZM0 53h42v3H0v-3Z"
        fill="#000"
      />
    </svg>
  );
}
