import { Cross1Icon } from "@radix-ui/react-icons";
import { NumericInput } from "components/form";
import { BoxIcon } from "pages/flyer/builder/drawer/components/Primitives/icons";
import { useOutsideAlerter } from "pages/flyer/builder/drawer/components/TopPanel/StyleMenu/useOutsideAlerter";
import { styled } from "pages/flyer/builder/drawer/styles";
import React, { useRef, useState } from "react";
import { ChromePicker } from "react-color";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

// Need to movi it ty type file for all elements styles
interface Shadow {
  x: number;
  y: number;
  blur: number | number[];
  spread: number | number[];
}

interface ShadowProps extends Shadow {
  onChange: (newShadow: Partial<Shadow>) => void;
}

interface ColorProps {
  isColorMixed: boolean;
  currentColor: string;
  changeColor: (nevColor: string) => void;
}

interface ValueProps {
  isValueMixed: boolean;
  step: number;
  minValue: number;
  maxValue: number;
  currentValue: number;
  slider: boolean;
  changeValue: (nevValue: number | number[]) => void;
}

interface VisibilityProps {
  isVisible: boolean;
  changeVisibility: (nevValue: boolean) => void;
}

interface Props {
  title: string;
  color?: ColorProps;
  value?: ValueProps;
  visibility?: VisibilityProps;
  shadowParams?: ShadowProps;
}

const StyleRow: React.FC<Props> = ({ color, value, visibility, title, shadowParams }) => {
  const [isPickerDisplay, setIsPickerDisplay] = useState(false);
  const wrapperRef = useRef(null);
  const circleRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsPickerDisplay(false), [circleRef]);

  const ValueBlock = value ? (
    <NumberInputBlock>
      <NumericInput
        inputProps={{
          step: value.step,
          min: value.minValue,
          max: value.maxValue,
          type: "number",
          // value: value.isValueMixed ? 0 : value.currentValue,
        }}
        // defaultValue={value.isValueMixed ? 0 : value.currentValue}
        mixed={value.isValueMixed}
        type="number"
        value={value.isValueMixed ? "" : value.currentValue}
        placeholder="SW"
        onChange={value.changeValue}
      />
    </NumberInputBlock>
  ) : (
    <NumberInputBlock />
  );

  const ValueSliderBlock = value ? (
    <Slider
      min={value.minValue}
      max={value.maxValue}
      step={value.step}
      value={value.isValueMixed ? 0 : value.currentValue}
      defaultValue={0}
      onChange={(event, newValue: number | number[]) => value.changeValue(newValue)}
      color="primary"
    />
  ) : null;

  const ColorBlock = color ? (
    <ChromePickerWrapper>
      <div ref={circleRef}>
        <BoxIcon
          strokeWidth={1}
          fill={color.currentColor}
          stroke={"#AEAFC7"}
          onClick={() => setIsPickerDisplay(true)}
        />
      </div>

      {isPickerDisplay ? (
        <div ref={wrapperRef}>
          <ChromePickerBlock>
            <CrossBlockIcon onClick={() => setIsPickerDisplay(false)}>
              <Cross1Icon />
            </CrossBlockIcon>
            <ChromePicker
              color={color.currentColor}
              disableAlpha
              className={"chromePicker"}
              onChange={(newColor) => {
                color.changeColor(newColor.hex);
              }}
            />
          </ChromePickerBlock>
        </div>
      ) : null}
    </ChromePickerWrapper>
  ) : (
    <ChromePickerWrapper />
  );

  const ShadowBlock = shadowParams ? (
    <Box style={{ borderBottom: "1px solid #E2E2FC" }}>
      <Box>
        <Typography style={{ fontSize: "14px" }}>Blur</Typography>
        <Slider
          min={0}
          max={200}
          step={1}
          defaultValue={0}
          value={shadowParams.blur}
          onChange={(event, value: number | number[]) => {
            shadowParams.onChange({
              blur: value,
            });
          }}
          color="primary"
        />
      </Box>
      <Box>
        <Typography style={{ fontSize: "14px" }}>Spread</Typography>
        <Slider
          min={-200}
          max={200}
          step={1}
          value={shadowParams.spread}
          defaultValue={0}
          onChange={(event, value: number | number[]) => {
            shadowParams.onChange({
              spread: value,
            });
          }}
          color="primary"
        />
      </Box>
    </Box>
  ) : null;

  return (
    <StyledRow>
      <StyleBlock>
        <StyledColumn isActive={visibility?.isVisible}>
          <StyledItemsRow>
            <StyleBlockTitle>{title}</StyleBlockTitle>
            {ValueBlock}
            {ColorBlock}
          </StyledItemsRow>
          {ValueSliderBlock}
          {ShadowBlock}
        </StyledColumn>
      </StyleBlock>
    </StyledRow>
  );
};

export const StyledItemsRow = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "218px",
});

export const StyledRow = styled("div", {
  display: "flex",
  alignItems: "stretch",
  padding: "14px 0",
  "& .MuiSlider-thumb": {
    color: "#FFFFFF",
    border: "1px solid #AEAFC7",
  },
  "& .MuiSlider-track": {
    color: "#4F5B94",
  },
  "& .MuiSlider-rail": {
    color: "#E2E2FC",
  },
  "& .MuiSlider-active": {
    color: "#FFFFFF",
    border: "1px solid #AEAFC7",
  },
  // "&:not(:last-child)": {
  //   borderBottom: "1px solid #EDEDED",
  // },
});

export const StyledColumn = styled("div", {
  display: "flex",
  flexDirection: "column",

  variants: {
    isActive: {
      false: {
        opacity: 0.4,
        pointerEvents: "none",
      },
    },
  },
});

export const StyleBlock = styled("div", {
  // display: "flex",
  // justifyContent: "space-between",
  // padding: "21px",
  // paddingRight: "14px",
  // borderRight: "1px solid #EDEDED",
});

export const StyleBlockTitle = styled("div", {
  width: "140px",
  fontSize: "14px",
  fontWeight: "600",
  color: "#011533",
});

export const NumberInputBlock = styled("div", {
  width: "32px",
  minHeight: "24px",
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
});

export const StyledEyeBlock = styled("div", {
  padding: "21px 6px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

export const CrossBlockIcon = styled("div", {
  margin: "8px",
  height: "15px",
  cursor: "pointer",
});

export const ChromePickerBlock = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  alignItems: "end",
  position: "absolute",
  right: "184px",
  top: "-26px",
  zIndex: 100,
  boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 16px -1px",
  backgroundColor: "white",
  borderRadius: "4px",
});

const ChromePickerWrapper = styled("div", {
  display: "flex",
  width: "24px",
  position: "relative",
  "& .chromePicker": {
    boxShadow: "none !important",
  },
});

export default StyleRow;
