const style = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    justifyContent: "center",
    color: "#EE4360",

    // color: "#FFFFFF",
    // background: " #EE4360",
    // width: "fit-content",
    // padding: "24px 96px",
    // borderRadius: "10px",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
  },
  logoutBtn: {
    marginTop: "32px",
  },
  btnsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "300px",
  },
};

export default style;
