const style = {
  select: {
    minWidth: "160px",
    backgroundColor: "white",
    color: "#011533",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    border: "1px solid rgba(39, 55, 84, 0.12)",
    borderRadius: "8px",
    padding: "7px",
    paddingLeft: "16px",

    "&:focus": {
      borderRadius: "8px",
      backgroundColor: "white",
    },
  },
  icon: {
    color: "#011533",
    marginRight: "10px",
  },
  paper: {
    borderRadius: "8px",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    maxHeight: "160px",
    overflow: "auto",
    "& li": {
      fontWeight: 400,
      padding: "7px",
      paddingLeft: "16px",
    },
    "& li:hover": {
      background: "#e5e5f2",
    },
    "& li.Mui-selected": {
      background: "#E2E2FC",
    },
    "& li.Mui-selected:hover": {
      background: "#c1c1f1",
    },
  },
};

export default style;
