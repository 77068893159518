import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import CONFIG from "config/config";
import { useMultiplayerState } from "pages/flyer/builder/drawer/hooks";
import { useMultiplayerAssets } from "pages/flyer/builder/drawer/hooks/useMultiplayerAssets";
import { StyledLoader } from "pages/flyer/builder/drawer/styled.ts";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { Helmet } from "react-helmet";
import ROUTES from "config/routing";
import "./disable-swipe-back.css";
import { Tldraw } from "./drawer/index";
import { TldrawApp } from "./drawer/state";
import "./index.css";
import { useStore } from "../../../store";

function Editor({ roomId, countryCode, flyerType }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, ...events } = useMultiplayerState(roomId, countryCode, flyerType);
  const { onAssetCreate, onAssetDelete } = useMultiplayerAssets(roomId);
  return (
    <div className="tldraw">
      <Tldraw onAssetCreate={onAssetCreate} onAssetDelete={onAssetDelete} {...events} />
    </div>
  );
}

function FlyerBuilder() {
  const {
    campaign: { flyerType, isSubmitted, id: campaignId },
    client: { id: clientId },
    country: { code },
  } = useStore();

  const defaultDocument = TldrawApp.defaultDocument;

  const history = useHistory();
  const flayerInfo = useGetFlyerInfo();

  if (isSubmitted) {
    history.push(generatePath(ROUTES.CAMPAIGN_AUDIENCE, { campaignId, clientId }));
  }

  if (flayerInfo.isLoading || !flayerInfo.flyerInfo.activeRoomId || !flyerType) {
    return (
      <StyledLoader>
        <CircularProgress color="secondary" />
      </StyledLoader>
    );
  }
  defaultDocument.format = flyerType;
  const roomId =
    CONFIG.ENV === "production"
      ? `029_${CONFIG.ENV}_${flayerInfo.flyerInfo.activeRoomId}`
      : `${CONFIG.ENV}_${flayerInfo.campaignId}_${flayerInfo.flyerInfo.activeRoomId}`;

  return (
    <>
      <Helmet>
        <title>Flyer Builder</title>
      </Helmet>
      <Editor roomId={roomId} countryCode={code} flyerType={flyerType} />
    </>
  );
}

export default FlyerBuilder;
