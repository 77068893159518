const style = {
  modalTitle: {
    fontSize: "30px",
    textAlign: "center",
    color: "#000000",
    paddingBottom: "40px",
    fontWeight: "600",
  },
  allRights: {
    fontWeight: "300",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#273754",
    width: "600px",
    bottom: "20px",
    left: "50%",
    transform: "translate(-50%, 0)",
    position: "absolute",
    textAlign: "center",
  },
  modalTextWarning: {
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    color: "#EE3460",
    paddingBottom: "20px",
    textAlign: "right",
    paddingRight: "2px",
    paddingLeft: "2px",
    cursor: "pointer",
  },
  modalText: {
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    color: "#ACB1B5",
    paddingTop: "7px",
    paddingRight: "2px",
    paddingLeft: "2px",
    cursor: "default",
  },
  inputGroup: {
    "& .MuiBox-root": {
      borderBottom: "0px",
      border: "1px solid #dfdfdf",

      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      },

      "&:last-child": {
        borderBottom: "1px solid #dfdfdf",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      },
    },
  },
  input: {
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: "-2px",
      left: "15px",
    },
  },
  error: {
    color: "#f50057",
    textDecoration: "underline",
  },
  root: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    height: "100vh",

    "& .MuiInputBase-root": {
      padding: "15px",

      "& input": {
        padding: "0px",
        width: "357px",
      },

      "&:before": {
        content: "none",
      },
      "&:after": {
        content: "none",
      },
    },
    "& .MuiSelect-root": {
      padding: "0px",
      backgroundColor: "#ffffff",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "0px",
      padding: "44px 66px",
    },
    "& .MuiPaper-root": {
      position: "relative",
      top: "-100px",
      width: "100%",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(39 55 84 / 85%)",
    },
  },
};

export default style;
