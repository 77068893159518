export const styles = {
  anchor: {
    cursor: "pointer",
    display: "flex",
    textDecoration: "none !important",
    "&:hover": {
      textDecoration: "none !important",
    },
    "&:active": {
      textDecoration: "none !important",
    },
  },
};
