import { formatNumber } from "utils/formators";
import { MAP_TYPES } from "pages/constants";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { capitalize } from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState, useEffect } from "react";
import { Cursor } from "components/icons/components/Cursor";
import { CircleSelectorIcon } from "components/icons/components/CircleSelectorIcon";
import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import { SettingsIcon } from "components/icons/components/Settings";
import { useEDDMCostsCalculation } from "Hooks/useEDDMCostsCalculation";
import { styles } from "./styles";
import { Accordion, AccordionTrigger, AccordionDetails } from "../../components/AddressesBlock/AddressBlock";

const useStyles = makeStyles(() => styles);

export const RouteStatistics = ({ route, selectedRoutes, costPerFlyer }) => {
  const classes = useStyles();
  const { calculateIntercectedRoutesCost } = useEDDMCostsCalculation();
  const [isOpen, setIsOpen] = useState(false);
  const [mobRoute, setMobRoute] = useState(null);

  useEffect(() => {
    if (route) {
      setMobRoute(route);
    }
  }, [route]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const mailPieces = route ? route.HOME_COUNT + route.APT_COUNT : 0;
  const totalRouteCost = route && costPerFlyer ? mailPieces * costPerFlyer : 0;

  const processedCityName = route?.ZIP_NAME
    ? route.ZIP_NAME.split(" ")
        .map((part) => capitalize(part))
        .join(" ")
    : "";

  return (
    <>
      {/* desk */}
      {route ? (
        <div className={cn(classes.statisticsBlockContainer, "mob-hidden")}>
          <>
            <div className={classes.statisticsHeaderWrapper}>
              <span>
                <Cursor /> {route.ZIP} - {route.CROUTE} - {processedCityName}
              </span>
            </div>

            <div className={classes.statistics}>
              <div className={classes.statisticsSection}>
                <span className={classes.statisticsAmount}>{route.BUSINESS_C}</span>
                <span className={classes.statisticsLabel}>Business</span>
                <span className={classes.satisticsNotTargeted}>{`(not targeted)`}</span>
              </div>
              <div className={classes.verticalSeparator} />
              <div className={classes.statisticsSection}>
                <span className={classes.statisticsAmount}>{route.HOME_COUNT + route.APT_COUNT}</span>
                <span className={classes.statisticsLabel}>Residences</span>
              </div>
              <div className={classes.verticalSeparator} />
              <div className={classes.statisticsSection}>
                <span className={classes.statisticsAmount}>$ {totalRouteCost.toFixed(2)}</span>
                <span className={classes.statisticsLabel}>Cost</span>
              </div>
            </div>
          </>
        </div>
      ) : null}

      {/* mob */}
      {selectedRoutes && selectedRoutes.length > 0 && mobRoute && (
        <div className="desk-hidden">
          <Accordion>
            <div
              style={{ position: "relative" }}
              className={cn("mob-statistics-block-container mob-address-container")}
            >
              <AccordionTrigger onClick={handleToggle}>
                <div className="mob-routestatistics-padding">
                  <p className="mob-route-title">Route statistics</p>
                  <KeyboardArrowDownIcon fontSize="medium" className={cn({ open: isOpen })} />
                </div>
              </AccordionTrigger>

              <AccordionDetails isOpen={isOpen}>
                {mobRoute ? (
                  <div style={{ padding: "5px 10px" }}>
                    <div className={classes.statisticsHeaderWrapper}>
                      <span>
                        {mobRoute.ZIP} - {mobRoute.CROUTE} {mobRoute.ZIP_NAME}
                      </span>
                    </div>
                    {/* <div className={classes.statistics}>Statistics</div> */}
                    <div className={classes.statisticsRow}>
                      <span>Addresses</span>
                      <span>{formatNumber(mobRoute.HOME_COUNT + mobRoute.APT_COUNT) || "-"}</span>
                    </div>
                    {/* <div className={classes.statisticsRow}>
                      <span>Apartments</span> <span>{formatNumber(mobRoute.APT_COUNT)}</span>
                    </div> */}
                    <div className={classes.statisticsRow}>
                      <span>Business</span> <span>{formatNumber(mobRoute.BUSINESS_C) || "-"}</span>
                    </div>
                    <div className={classes.separator} />
                    <div className={classes.statisticsRow}>
                      <span>Total addresses (exc. businesses)</span>
                      <span>{formatNumber(mobRoute.TOTAL_COUN - mobRoute.BUSINESS_C) || "-"}</span>
                    </div>
                  </div>
                ) : null}
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
      )}
    </>
  );
};
