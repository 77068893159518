import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  page: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    cursor:
      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAWCAYAAADJqhx8AAAACXBIWXMAAAsSAAALEgHS3X78AAABVklEQVQ4jWP8//8/AyWA6f+v3z/+3rhnS64ZTP+/fPvPKCq0h1xDmP6//fDjS34rGwMDw7a/j5/7kGwAiPj34g3Dl4peHkYGxlX/3n3MItkAEPj/5RvD59xmToav33tIMYQJmYNsyN8HT0tINgDFkP//O/5cvlVHsgEwQ74UtjMz8nDVETIEqwHIhjAwMtbiMwSnAXBDCtpY/r16W/3vxZulJBsAA9/aZ7H9uXo75N/bD5vIMoABZsiF6+7ohhBtALIhf+8+PgITY8GlmEVfA6v4r22H2DizoqxBhjAry9pgNYCrMu0Xi7bqhf+fvlzHasrfvwwMjIyvsboApJlZTWE+k4RIBoOECEFvwQ1g5OFi4G4p+MPw/38rs6xkE0GdUAAORJBmnv7Kvwz//zez6KoRrRninScvPv+59/jP70s360DFG6kYRPwnVzPMAANyNf///58BANgjLIKd0D0WAAAAAElFTkSuQmCC') 2 0 , auto",
    position: "relative",
    width: "100%",
  },
  toolbar: {},
  settingPanel: {},
  sidebar: {
    zIndex: 10,
  },
  drawerWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "stretch",
    flex: 1,
    position: "relative",
  },
  header: {
    background: "#FFFFFF",
    borderBottom: "solid 2px #ebebeb",
    boxShadow: "none",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  headerWrapper: {},
  nextButton: {
    display: "flex",
    alignItems: "center",
  },
  progress: {
    color: "white",
    marginRight: "4px",
  },
});

export default useStyles;
