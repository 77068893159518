import React from "react";

export const LocationIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6693 8.00726C16.6693 10.3966 14.2447 13.9626 12.3199 16.4171C11.3157 17.6976 10.8136 18.3379 10.0026 18.3379C9.19158 18.3379 8.68948 17.6976 7.68528 16.4171C5.76048 13.9626 3.33594 10.3966 3.33594 8.00726C3.33594 4.04521 6.32071 0.833344 10.0026 0.833344C13.6845 0.833344 16.6693 4.04521 16.6693 8.00726Z"
        stroke="#AEAFC7"
        strokeWidth="1.5"
      />
      <path
        d="M7.5026 8.33334C7.5026 9.71405 8.62189 10.8333 10.0026 10.8333C11.3833 10.8333 12.5026 9.71405 12.5026 8.33334C12.5026 6.95263 11.3833 5.83334 10.0026 5.83334C8.62189 5.83334 7.5026 6.95263 7.5026 8.33334Z"
        stroke="#AEAFC7"
        strokeWidth="1.5"
      />
    </svg>
  );
};
