import React from "react";
import CONFIG from "config/config";
import { Box, Button, makeStyles } from "@material-ui/core";
import { ErrorPageImage } from "static/media/error-page-image";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const Error = () => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100vw" height="100vh">
      <div className={classes.content}>
        <ErrorPageImage />
        <div>
          <div className={classes.title}>Oops, wrong turn...</div>
          <div className={classes.subtitle}>{"We are sorry, but something went wrong :("}</div>
        </div>
        <Button
          className={classes.cta}
          onClick={() => {
            window.location.href = `${CONFIG.PLATFORM_BASE_URL}`;
          }}
        >
          {"Return to homepage"}
        </Button>
      </div>
    </Box>
  );
};
