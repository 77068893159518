import { startOfWeek, format, lastDayOfYear, getISOWeek } from "date-fns";
import { isInvalidMission } from "../../components/audience-form/components/campaign-location/utils";
import { AddressType, CityLimitErrorType, CityType, MissionD2DType, MissionH2HType } from "../../store/types";

const formatH2HMission = ({ id, isVisible, type, value, position, startDate, endDate, time }: any): MissionH2HType => ({
  id,
  isVisible,
  type,
  value,
  position,
  startDate,
  endDate,
  time,
});

const formatD2DMission = ({ id, paths, isVisible, value, type }: MissionD2DType): MissionD2DType => ({
  id,
  paths,
  isVisible,
  value,
  type,
});

const multiplyMissionsByBA = (missions: MissionH2HType[], baPerLocation: number): MissionH2HType[] => {
  let totalMissions: MissionH2HType[] = [];
  for (let index = 0; index < baPerLocation; index++) {
    totalMissions = [...totalMissions, ...missions];
  }
  return totalMissions;
};

const calculateSelectedAddressesH2H = (city: CityType): MissionH2HType[] => {
  return (
    city?.addresses.reduce((acc, address) => {
      if (address.id && address.isVisible) {
        const formattedH2HMission: MissionH2HType = formatH2HMission(address);
        acc.push(formattedH2HMission);
      }
      return acc;
    }, [] as MissionH2HType[]) || []
  );
};

const isExistInvalidMissionsInH2H = (missions: MissionH2HType[], timezone: string, defaultCampaignLimits: number) =>
  missions.reduce((isExist, mission) => {
    if (isExist) return isExist;

    if (isInvalidMission(mission.startDate, timezone, defaultCampaignLimits)) {
      return true;
    }

    return isExist;
  }, false);

//ERRORS
const getMinimumMissionLimitError = ({
  isD2D,
  minimumMissionLimit,
}: {
  isD2D: boolean;
  minimumMissionLimit: number;
  cityName?: string;
}): CityLimitErrorType => ({
  title: `Not enough ${isD2D ? "areas" : "distributors"}!`,
  message: `Please, select at least ${minimumMissionLimit} ${isD2D ? "areas" : "distributors"} to continue.`,
});

const getMaximumMissionLimitError = ({
  isD2D,
  campaignMissionLimit,
}: {
  isD2D: boolean;
  campaignMissionLimit: number;
}): CityLimitErrorType => ({
  title: `Too many ${isD2D ? "areas" : "distributors per week"}!`,
  message: `${
    isD2D
      ? `The total number of areas should not exceed ${campaignMissionLimit}`
      : `Please, select fewer distributors. The total number of distributors in any given week should not exceed ${campaignMissionLimit}`
  }.`,
});

const isH2HMissionsInWeekMoreThanSettings = ({
  missionsPerWeek,
  weeklyMissionLimit,
}: {
  missionsPerWeek: Array<Array<MissionH2HType>>;
  weeklyMissionLimit: number;
}): boolean => missionsPerWeek.some((missionsInWeek) => missionsInWeek.length > weeklyMissionLimit);

const getLastWeekOfYear = (year: number): number => {
  // Get the last day of the year
  const lastDay = lastDayOfYear(new Date(year, 0, 1));
  // Get the week number of the last day of the year
  return getISOWeek(lastDay);
};

const splitH2HMissionsFromWeeks = (missions: MissionH2HType[]): Array<Array<MissionH2HType>> => {
  if (!missions.length) {
    return [];
  }
  const missionsByYear: Record<string, MissionH2HType[]> = missions.reduce((result, mission) => {
    const startDate = new Date(mission.startDate);
    const year = startDate.getFullYear();
    // Create an array for the week if it doesn't exist
    if (!result[year]) {
      result[year] = [];
    }

    result[year].push(mission);
    return result;
  }, {} as Record<string, MissionH2HType[]>);

  //WE cannot create mission for 3 years
  const earliestYear = Math.min(...Object.keys(missionsByYear).map((year) => Number(year)));
  const latestYear = Math.max(...Object.keys(missionsByYear).map((year) => Number(year)));

  const missionsByWeekFirstYear: Record<string, MissionH2HType[]> = missionsByYear[earliestYear].reduce(
    (result, mission) => {
      const startDate = new Date(mission.startDate);
      const weekStart = startOfWeek(startDate, { weekStartsOn: 1 });
      const weekKey: string = "week-" + format(weekStart, "w");

      // Create an array for the week if it doesn't exist
      if (!result[weekKey]) {
        result[weekKey] = [];
      }

      result[weekKey].push(mission);
      return result;
    },
    {} as Record<string, MissionH2HType[]>
  );

  const earliestWeekFirstYear = Math.min(
    ...Object.keys(missionsByWeekFirstYear).map((weekKey) => Number(weekKey.replace("week-", "")))
  );

  if (earliestYear === latestYear) {
    const latestWeekFirstYear = Math.max(
      ...Object.keys(missionsByWeekFirstYear).map((weekKey) => Number(weekKey.replace("week-", "")))
    );

    for (let week = earliestWeekFirstYear; week <= latestWeekFirstYear; week++) {
      const weekKey: string = "week-" + week;
      if (!missionsByWeekFirstYear[weekKey]) {
        missionsByWeekFirstYear[weekKey] = [];
      }
    }
    return Object.values(missionsByWeekFirstYear);
  } else {
    const lastWeekOfTheYear = getLastWeekOfYear(earliestYear);

    //Fill first year
    for (let week = earliestWeekFirstYear; week <= lastWeekOfTheYear; week++) {
      const weekKey: string = "week-" + week;
      if (!missionsByWeekFirstYear[weekKey]) {
        missionsByWeekFirstYear[weekKey] = [];
      }
    }

    const missionsByWeekLatestYear: Record<string, MissionH2HType[]> = missionsByYear[latestYear].reduce(
      (result, mission) => {
        const startDate = new Date(mission.startDate);
        const weekStart = startOfWeek(startDate, { weekStartsOn: 1 });
        const weekKey: string = "week-" + format(weekStart, "w");

        // Create an array for the week if it doesn't exist
        if (!result[weekKey]) {
          result[weekKey] = [];
        }

        result[weekKey].push(mission);
        return result;
      },
      {} as Record<string, MissionH2HType[]>
    );

    const latestWeekFirstYear = Math.max(
      ...Object.keys(missionsByWeekLatestYear).map((weekKey) => Number(weekKey.replace("week-", "")))
    );

    //Fill last year

    for (let week = 1; week <= latestWeekFirstYear; week++) {
      const weekKey: string = "week-" + week;
      if (!missionsByWeekLatestYear[weekKey]) {
        missionsByWeekLatestYear[weekKey] = [];
      }
    }

    return [...Object.values(missionsByWeekFirstYear), ...Object.values(missionsByWeekLatestYear)];
  }
};

const isAllLocationHasMission = (addresses: AddressType[]): boolean => {
  return addresses.reduce((acc: boolean, address: AddressType) => {
    if (!address.startDate) {
      acc = false;
    }
    return acc;
  }, true);
};

const calculateUSTaxAndTotalCost = ({
  salesTaxRate,
  printingCosts,
  outfitCosts,
  subtotal,
  amountOff,
}: {
  salesTaxRate: number | undefined;
  printingCosts: number;
  outfitCosts: number;
  subtotal: number;
  amountOff: number | null | undefined;
}) => {
  const subtotalWithDiscount = amountOff && amountOff < subtotal ? subtotal - amountOff : subtotal;
  const tax = salesTaxRate ? Number((((printingCosts + outfitCosts) * salesTaxRate) / 100).toFixed(2)) : 0;

  const totalCost: number = subtotalWithDiscount + tax;
  return { tax, totalCost };
};

const calculateTaxAndTotalCost = ({
  countryTaxRate,
  subtotal,
  amountOff,
}: {
  countryTaxRate: number;
  subtotal: number;
  amountOff: number | null | undefined;
}) => {
  const subtotalWithDiscount = amountOff && amountOff < subtotal ? subtotal - amountOff : subtotal;
  const tax = Number(((subtotalWithDiscount * countryTaxRate) / 100).toFixed(2));

  const totalCost: number = subtotalWithDiscount + tax;

  return { tax, totalCost };
};

export {
  calculateSelectedAddressesH2H,
  multiplyMissionsByBA,
  getMinimumMissionLimitError,
  getMaximumMissionLimitError,
  isExistInvalidMissionsInH2H,
  isH2HMissionsInWeekMoreThanSettings,
  formatD2DMission,
  splitH2HMissionsFromWeeks,
  isAllLocationHasMission,
  calculateUSTaxAndTotalCost,
  calculateTaxAndTotalCost,
};
