import { styled } from "@stitches/react";
import { HTMLContainer, Utils } from "@tldraw/core";
import { BINDING_DISTANCE } from "pages/flyer/builder/drawer/constants/constants";
import {
  defaultImageStyle,
  getBoundsRectangle,
  transformRectangle,
  transformSingleRectangle,
} from "pages/flyer/builder/drawer/state/shapes/shared";
import { ImageShape, TDImageAsset, TDMeta, TDShapeType, StaticAssetsIdsEnum } from "pages/flyer/builder/drawer/types";
import * as React from "react";
import { TDShapeUtil } from "../TDShapeUtil";

type T = ImageShape;
type E = HTMLDivElement;

export class ImageUtil extends TDShapeUtil<T, E> {
  type = TDShapeType.Image as const;

  canBind = true;

  canClone = true;

  showCloneHandles = true;

  getShape = (props: Partial<T>): T => {
    return Utils.deepMerge<T>(
      {
        id: "image",
        type: TDShapeType.Image,
        name: "Image",
        parentId: "page",
        childIndex: 1,
        point: [0, 0],
        size: [1, 1],
        rotation: 0,
        style: defaultImageStyle,
        assetId: "assetId",
      },
      props
    );
  };

  Component = TDShapeUtil.Component<T, E, TDMeta>(
    (
      {
        shape,
        asset = { src: "" },
        isBinding,
        isGhost,
        meta,
        events,
        isSelected,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onShapeChange,
      },
      ref
    ) => {
      const { size, style, id } = shape;
      const {
        strokeColor: stroke,
        fillColor: fill,
        transparency,
        rounded,
        strokeWidth,
        isStroked,
        isFilled,
        isOpacityVisible,
        isRoundingVisible,
        isInnerShadowVisible,
        isOuterShadowVisible,
        innerShadowColor,
        innerShadow,
        outerShadow,
        outerShadowColor,
      } = style;
      const sw = strokeWidth!;
      const isRoundingEnable = false;

      const w = Math.max(0, size[0]);
      const h = Math.max(0, size[1]);
      const rImage = React.useRef<HTMLImageElement>(null);
      const rWrapper = React.useRef<HTMLDivElement>(null);

      React.useLayoutEffect(() => {
        const wrapper = rWrapper.current;
        if (!wrapper) return;
        wrapper.style.width = `${w}px`;
        wrapper.style.height = `${h}px`;

        const image = rImage.current;
        if (!image) return;
        image.style.width = `${w}px`;
        image.style.height = `${h}px`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [size, sw]);

      if (
        (
          [
            StaticAssetsIdsEnum.CutLineFront,
            StaticAssetsIdsEnum.CutLineBack,
            StaticAssetsIdsEnum.DiecutLineFront,
            StaticAssetsIdsEnum.DiecutLineBack,
            StaticAssetsIdsEnum.TearawayCutLineFront,
            StaticAssetsIdsEnum.TearawayCutLineBack,
          ] as string[]
        ).includes(shape.id)
      )
        return <></>;

      return (
        <HTMLContainer ref={ref} {...events}>
          {isBinding && (
            <div
              className="tl-binding-indicator"
              style={{
                position: "absolute",
                top: `calc(${-this.bindingDistance}px * var(--tl-zoom))`,
                left: `calc(${-this.bindingDistance}px * var(--tl-zoom))`,
                width: `calc(100% + ${this.bindingDistance * 2}px * var(--tl-zoom))`,
                height: `calc(100% + ${this.bindingDistance * 2}px * var(--tl-zoom))`,
                backgroundColor: "var(--tl-selectFill)",
              }}
            />
          )}
          <Wrapper
            ref={rWrapper}
            isDarkMode={meta.isDarkMode} //
            isFilled={style.isFilled}
            isGhost={isGhost}
            style={{
              width: `calc(100% - ${style.strokeWidth}px)`,
              height: `calc(100% - ${style.strokeWidth}px)`,
            }}
          >
            <svg
              width={w}
              height={h}
              // style={{
              //   pointerEvents: isGhost ? "none" : "auto",
              // }}
            >
              <defs>
                {outerShadow && isOuterShadowVisible ? (
                  <filter id={`shadow_${id}`} x="-400%" y="-400%" height="1000%" width="1000%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation={outerShadow.blur} />
                  </filter>
                ) : null}
                {innerShadow && isInnerShadowVisible ? (
                  <filter id={`inset-shadow_${id}`} x="-50%" y="-50%" width="200%" height="200%">
                    <feComponentTransfer in="SourceAlpha">
                      <feFuncA type="table" tableValues="1 0" />
                    </feComponentTransfer>
                    <feGaussianBlur stdDeviation={innerShadow.blur} />
                    <feMorphology operator="dilate" radius={innerShadow.spread} />

                    <feOffset dx={innerShadow.x} dy={innerShadow.y} result="offsetblur" />
                    <feFlood floodColor={innerShadowColor} result="color" />
                    <feComposite in2="offsetblur" operator="in" />
                    <feComposite in2="SourceAlpha" operator="in" />
                    <feMerge>
                      <feMergeNode in="SourceGraphic" />
                      <feMergeNode />
                    </feMerge>
                  </filter>
                ) : null}
              </defs>

              {outerShadow && isOuterShadowVisible ? (
                <rect
                  x={outerShadow?.x - outerShadow.spread / 2 - sw / 2}
                  y={outerShadow?.y - outerShadow.spread / 2 - sw / 2}
                  width={w + sw + outerShadow.spread}
                  height={h + sw + outerShadow.spread}
                  fill={outerShadowColor}
                  stroke={outerShadowColor}
                  strokeWidth={isStroked ? sw : undefined}
                  pointerEvents="none"
                  opacity={isOpacityVisible ? transparency! / 100 : 1}
                  rx={isRoundingEnable && isRoundingVisible ? rounded : 0}
                  filter={outerShadow && isOuterShadowVisible ? `url(#shadow_${id})` : undefined}
                />
              ) : null}
              <rect
                className={isSelected || style.isFilled ? "tl-fill-hitarea" : "tl-stroke-hitarea"}
                x={-sw / 2}
                y={-sw / 2}
                width={w}
                height={h}
                strokeWidth={BINDING_DISTANCE}
                rx={isRoundingEnable ? rounded : undefined}
                style={{
                  pointerEvents: isGhost ? "none" : "auto",
                }}
              />
              <rect
                x={-sw / 2}
                y={-sw / 2}
                width={w + sw}
                height={h + sw}
                fill={isFilled ? fill : "rgba(0,0,0,0)"}
                stroke={isStroked ? stroke : undefined}
                strokeWidth={isStroked ? sw : undefined}
                pointerEvents="none"
                opacity={isOpacityVisible ? transparency! / 100 : 1}
                rx={isRoundingEnable && isRoundingVisible ? rounded : 0}
              />
            </svg>

            <ImageElement
              id={shape.id + "_image"}
              ref={rImage}
              src={(asset as TDImageAsset).src}
              alt="tl_image_asset"
              draggable={false}
              style={{
                opacity: style.transparency! / 100,
                width: `calc(100% - ${style.strokeWidth}px)`,
                height: `calc(100% - ${style.strokeWidth}px)`,
                pointerEvents: isGhost ? "none" : "auto",
              }}
              // onLoad={onImageLoad}
            />
          </Wrapper>
        </HTMLContainer>
      );
    }
  );

  Indicator = TDShapeUtil.Indicator<T>(({ shape }) => {
    const { size } = shape;

    return <rect width={Math.max(1, size[0])} height={Math.max(1, size[1])} />;
  });

  getBounds = (shape: T) => {
    return getBoundsRectangle(shape, this.boundsCache);
  };

  shouldRender = (prev: T, next: T) => {
    return next.size !== prev.size || next.style !== prev.style;
  };

  transform = transformRectangle;

  transformSingle = transformSingleRectangle;

  getSvgElement = (shape: ImageShape) => {
    const bounds = this.getBounds(shape);
    const elm = document.createElementNS("http://www.w3.org/2000/svg", "image");
    elm.setAttribute("width", `${bounds.width}`);
    elm.setAttribute("height", `${bounds.height}`);
    elm.setAttribute("xmlns:xlink", `http://www.w3.org/1999/xlink`);
    return elm;
  };
}

const Wrapper = styled("div", {
  pointerEvents: "all",
  position: "relative",
  fontFamily: "sans-serif",
  fontSize: "2em",
  height: "100%",
  width: "100%",
  borderRadius: "3px",
  perspective: "800px",
  // overflow: "hidden",
  p: {
    userSelect: "none",
  },
  img: {
    userSelect: "none",
  },
  variants: {
    isGhost: {
      false: { opacity: 1 },
      // true: { transition: "opacity .2s", opacity: GHOSTED_OPACITY },
      true: { pointerEvents: "none" },
    },
    isFilled: {
      true: {},
      false: {},
    },
    isDarkMode: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isFilled: true,
      isDarkMode: true,
      css: {
        boxShadow: "2px 3px 12px -2px rgba(0,0,0,.3), 1px 1px 4px rgba(0,0,0,.3), 1px 1px 2px rgba(0,0,0,.3)",
      },
    },
  ],
});

const ImageElement = styled("img", {
  position: "absolute",
  top: 0,
  left: 0,
  // width: "100%",
  height: "100%",
  maxWidth: "100%",
  // minWidth: "100%",
  pointerEvents: "none",
  objectFit: "cover",
  userSelect: "none",
  zIndex: 0,
});
