import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type ApplyButtonProps = {
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  onClick?: () => void;
};

export const ApplyButton = ({
  disabled = false,
  label = "Apply",
  loading = false,
  onClick = () => {},
}: ApplyButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} disabled={disabled} onClick={onClick}>
      {loading && <CircularProgress size={20} color="secondary" style={{ marginRight: 10 }} />}
      {label}
    </Button>
  );
};

export default ApplyButton;
