import { styled } from "pages/flyer/builder/drawer/styles/index.ts";

export const StyledAlertContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  padding: "12px",
  backgroundColor: "#FFE7EB",
  marginBottom: "16px",
  borderRadius: "4px",
});

export const StyledAlertHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const StyledAlertHeaderMulty = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  justifyContent: "space-between",
});

export const StyledAlertTitle = styled("span", {
  color: "$primaryRed",
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "18px",
});

export const StyledAlertText = styled("span", {
  color: "$blueDeepDark",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "15px",
});

export const StyledDeleteButtonContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
});

export const StyledDeleteButton = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "180px",
  padding: "5px 8px",
  border: "1px solid",
  borderRadius: "12px",
  color: "$primaryRed",
  backgroundColor: "transparent",
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "18px",
  cursor: "pointer",

  "&:active": {
    color: "$pinkDeep",
  },
});

export const StyledEditButton = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "5px 8px",
  border: "1px solid",
  borderRadius: "12px",
  color: "$primaryRed",
  backgroundColor: "transparent",
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "18px",
  cursor: "pointer",

  "&:active": {
    color: "$pinkDeep",
  },
});

export default StyledAlertContainer;
