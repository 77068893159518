import { withStyles } from "@material-ui/core";
import React from "react";

class MenuItem extends React.Component {
  button = React.createRef();

  componentDidUpdate(prevProps) {
    const { isSelected } = this.props;
    if (prevProps.isSelected !== isSelected) {
      if (isSelected) {
        this.button.current?.focus();
      } else {
        this.button.current?.blur();
      }
    }
  }

  handleMouseUp = () => {
    const { action, onClose, isReadyForMouseUp } = this.props;

    if (isReadyForMouseUp) {
      if (action) {
        action();
      }

      onClose();
    }
  };

  handleMouseEnter = () => {
    const { index, onMouseEnter } = this.props;
    onMouseEnter(index);
  };

  handleMouseLeave = () => {
    const { index, onMouseLeave } = this.props;
    onMouseLeave(index);
  };

  getStylesButton() {
    const { width } = this.props;

    if (!width) {
      return {};
    }

    return {
      minWidth: `${width}px`,
    };
  }

  render() {
    const { classes, label, type, helperText, isDisabled, isChecked } = this.props;

    switch (type) {
      case "separator":
        return <li className={classes.separator} />;

      default:
        return (
          <li>
            <button
              ref={this.button}
              className={classes.button}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseUp={this.handleMouseUp}
              disabled={isDisabled}
              style={this.getStylesButton()}
            >
              {isChecked && (
                <svg
                  className={classes.check}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.554 5.5051L7.02493 11.5871L3.46875 8.03093L4.52941 6.97027L6.97323 9.41408L11.4441 4.49609L12.554 5.5051Z"
                  />
                </svg>
              )}

              <span className={classes.label}>{label}</span>

              {helperText && <span className={classes.helper_text}>{helperText}</span>}
            </button>
          </li>
        );
    }
  }
}

const styles = {
  separator: {
    margin: "4px 0",
    height: 1,
    background: "var(--color-deeper-300)",
    opacity: 0.1,
  },
  button: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "var(--color-foreground-600)",
    lineHeight: "28px",
    padding: "1px 12px 0 28px",
    minWidth: 120,
    width: "100%",
    textAlign: "left",
    borderRadius: 4,

    "&:focus": {
      outline: "none",
      background: "var(--color-brand-400)",
      color: "#FFFFFF",

      "& $helper_text": {
        color: "#FFFFFF",
        opacity: 0.8,
      },

      "& $check": {
        "& path, & rect": {
          fill: "#FFFFFF !important",
        },
      },
    },

    "&:disabled": {
      opacity: 0.3,
    },
  },

  label: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  check: {
    position: "absolute",
    left: 6,

    "& path, & rect": {
      fill: "var(--color-foreground-600)",
    },
  },

  helper_text: {
    fontSize: 12,
    marginLeft: 16,
    color: "var(--color-foreground-400)",
  },
};

export default withStyles(styles)(MenuItem);
