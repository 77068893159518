import { TrashIcon } from "pages/flyer/builder/drawer/components/Primitives/icons";
import { ToolButton } from "pages/flyer/builder/drawer/components/Primitives/ToolButton";
import { Tooltip } from "pages/flyer/builder/drawer/components/Primitives/Tooltip";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import * as React from "react";

export function DeleteButton(): JSX.Element {
  const app = useTldrawApp();

  const handleDelete = React.useCallback(() => {
    app.delete();
  }, [app]);

  const hasSelection = app.useStore(
    (s) => s.appState.status === "idle" && s.document.pageStates[s.appState.currentPageId].selectedIds.length > 0
  );

  return (
    <Tooltip label="Delete" kbd="⌫">
      <ToolButton variant="circle" disabled={!hasSelection} onSelect={handleDelete}>
        <TrashIcon />
      </ToolButton>
    </Tooltip>
  );
}
