import { breakpoints } from "pages/flyer/builder/drawer/components/breakpoints";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { styled } from "pages/flyer/builder/drawer/styles";
import type { TDSnapshot } from "pages/flyer/builder/drawer/types";
import * as React from "react";

const statusSelector = (s: TDSnapshot) => s.appState.status;
const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;

export function StatusBar(): JSX.Element | null {
  const app = useTldrawApp();
  const status = app.useStore(statusSelector);
  const activeTool = app.useStore(activeToolSelector);

  return (
    <StyledStatusBar bp={breakpoints}>
      <StyledSection>
        {activeTool} | {status}
      </StyledSection>
    </StyledStatusBar>
  );
}

const StyledStatusBar = styled("div", {
  height: 40,
  userSelect: "none",
  borderTop: "1px solid $panelContrast",
  gridArea: "status",
  display: "flex",
  color: "$text",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "$panel",
  gap: 8,
  fontFamily: "$ui",
  fontSize: "$0",
  padding: "0 16px",

  variants: {
    bp: {
      small: {
        fontSize: "$1",
      },
    },
  },
});

const StyledSection = styled("div", {
  whiteSpace: "nowrap",
  overflow: "hidden",
});
