export const style = {
  wrapper: {
    "& .MuiDialog-container": {
      alignItems: "flex-end",
      color: "#011533",
      fontSize: "16px",
      lineHeight: "20px",
      gap: "5px",

      "& > div": {
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        borderRadius: "8px",
      },
    },

    "& .MuiBackdrop-root": {
      opacity: 0.5,
    },
  },

  belowText: {
    display: "flex",
    gap: "12px",
  },
};
