export function isKeyLetterOrNumber(event: KeyboardEvent): boolean {
  return /[a-zA-Z0-9-_ ]/.test(event.key);
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export const convertToInch = (num: number) => Math.round((num / 25.4) * 100) / 100;

export const getFlyerSizeDisplay = (width: number, height: number, isInInch: boolean) => {
  if (isInInch) {
    return `${convertToInch(width)}” x ${convertToInch(height)}”`;
  } else {
    return `${width} x ${height} mm`;
  }
};

export const getFlyerWidthDisplay = (width: number, isInInch: boolean) => {
  if (isInInch) {
    return `${convertToInch(width)}”`;
  } else {
    return `${width} mm`;
  }
};

export const getFlyerHeightDisplay = (height: number, isInInch: boolean) => {
  if (isInInch) {
    return `${convertToInch(height)}”`;
  } else {
    return `${height} mm`;
  }
};

export const displayInInch = (countryCode: string) => countryCode === "US";

// import { useMemo } from "react";

// import { FORMATS, GAP_BETWEEN_PAGES } from "./constants";
// import { Point, Layer, Project, Bounds, Pages, Color, Size, User } from "./types";
// import { Record } from "@liveblocks/react";

// export function usePageSize(project: Project): Size {
//   return useMemo(() => {
//     if (!project) {
//       return null
//     }

//     const format = FORMATS[project.format] || FORMATS.TEARAWAY;

//     return {
//       width: project.isPortrait ? format.width : format.height,
//       height: project.isPortrait ? format.height : format.width,
//     };
//   }, [project]);
// }

// // TODO: Initialize presence based on current user
// export function initialPresence(): User {
//   return {
//     cursor: { x: -1, y: -1 },
//     color: "#6FCD78",
//     name: "Steven Fabre",
//     initials: "SF",
//     image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJvy5LC0TqRNGetD0fmoHJ8Jbfr3o8x9T5OqwoQpLnZ8BnRpcHnwvZ5K227cErrG62heo&usqp=CAU",
//     selectedId: null,
//   };
// }

// export function isHittingLayer(point: Point, layer: Record<Layer>, pageSize: Size) {
//   const bounds = layerAbsoluteBounds(layer, pageSize);

//   return isHitting(point, bounds);
// }

// export function isHitting(point: Point, bounds: Bounds) {
//   return (
//     point.x >= bounds.x &&
//     point.x <= bounds.x + bounds.width &&
//     point.y >= bounds.y &&
//     point.y <= bounds.y + bounds.height
//   );
// }

// function getPageAtPoint(point: Point, pageSize: Size, isDoubleSided: boolean): Pages | null {
//   if (
//     isHitting(point, {
//       width: pageSize.width,
//       height: pageSize.height,
//       x: 0,
//       y: 0,
//     })
//   ) {
//     return Pages.First;
//   }

//   if (
//     isDoubleSided &&
//     isHitting(point, {
//       width: pageSize.width,
//       height: pageSize.height,
//       x: pageSize.width + GAP_BETWEEN_PAGES,
//       y: 0,
//     })
//   ) {
//     return Pages.Second;
//   }

//   return null;
// }

// export function pointToPageCoordinate(point: Point, project: Project, pageSize: Size) {
//   const page = getPageAtPoint(point, pageSize, project.isDoubleSided);

//   if (page === Pages.First) {
//     return {
//       page: Pages.First,
//       x: point.x,
//       y: point.y,
//     };
//   }

//   if (page === Pages.Second) {
//     return {
//       page: Pages.Second,
//       x: point.x - (pageSize.width + GAP_BETWEEN_PAGES),
//       y: point.y,
//     };
//   }

//   return null;
// }

// export function absoluteToRelativePoint(point: Point, pageSize: Size, page: Pages) {
//   if (page === Pages.First) {
//     return point;
//   } else {
//     return {
//       x: point.x - (pageSize.width + GAP_BETWEEN_PAGES),
//       y: point.y,
//     };
//   }
// }

// export function relativeToAbsolute(point: Point, pageSize: Size, page: Pages) {
//   if (page === Pages.First) {
//     return point;
//   }

//   return {
//     x: point.x + (pageSize.width + GAP_BETWEEN_PAGES),
//     y: point.y,
//   };
// }

// export function layerAbsoluteBounds(layer: Layer, pageSize: Size) {
//   if (layer.page === Pages.First) {
//     return {
//       x: layer.x,
//       y: layer.y,
//       width: layer.width,
//       height: layer.height,
//     };
//   }

//   return {
//     x: layer.x + pageSize.width + GAP_BETWEEN_PAGES,
//     y: layer.y,
//     width: layer.width,
//     height: layer.height,
//   };
// }

// export function absoluteBoundsToRelative(bounds: Bounds, pageSize: Size, page: Pages) {
//   if (page === Pages.First) {
//     return bounds;
//   }

//   return {
//     ...bounds,
//     x: bounds.x - (pageSize.width + GAP_BETWEEN_PAGES),
//   };
// }

// export function isIntersectingWithPage(layer: Layer, pageSize: Size) {
//   const layerBounds = layerAbsoluteBounds(layer, pageSize);
//   const pageBounds = getPageBounds(layer.page, pageSize);

//   return areBoundsIntersecting(layerBounds, pageBounds);
// }

// export function isIntersectingWithOtherPage(layer: Layer, pageSize: Size) {
//   const layerBounds = layerAbsoluteBounds(layer, pageSize);
//   const pageBounds = getPageBounds(
//     layer.page === Pages.First ? Pages.Second : Pages.First,
//     pageSize
//   );

//   return areBoundsIntersecting(layerBounds, pageBounds);
// }

// function getPageBounds(page: Pages, pageSize: Size) {
//   if (page === Pages.First) {
//     return {
//       x: 0,
//       y: 0,
//       width: pageSize.width,
//       height: pageSize.height,
//     };
//   }

//   return {
//     x: pageSize.width + GAP_BETWEEN_PAGES,
//     y: 0,
//     width: pageSize.width,
//     height: pageSize.height,
//   };
// }

// function areBoundsIntersecting(a: Bounds, b: Bounds) {
//   return a.x + a.width > b.x && a.x < b.x + b.width && a.y + a.height > b.y && a.y < b.y + b.height;
// }

// export function colorToCssHex({ r, g, b, a = '' }: Color): string {
//   return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
//     .toString(16)
//     .padStart(2, "0")}${a.toString(16).padStart(2, "0")}`;
// }

// type RGBAData = {
//   r: number;
//   g: number;
//   b: number;
//   a: number;
// }

// type BoxShadowData = {
//   xAxis: number;
//   yAxis: number;
//   blur: number;
//   spread: number;
//   inset: boolean;
//   fill: RGBAData;
// };

// export function convertShadowDataToString(data: [BoxShadowData], type: string): string  {
//   let transformator : (data: BoxShadowData, index: number) => string

//   switch (type) {
//     case 'boxShadow':
//       transformator = convertBoxShadow
//       break;

//     case 'textShadow':
//       transformator = convertTextShadow
//       break;

//     case 'dropShadow':
//       transformator = convertDropShadow
//       break;

//     default:
//       throw new Error('type is required');
//   }

//   const rule = data.reduce((acc, item, index) => {
//     acc += transformator(item, index)
//     return acc
//   }, '')
//   return rule;
// };

// export function convertBoxShadow(data: BoxShadowData, index: number): string  {
//   const { xAxis, yAxis, blur, spread, fill, inset } = data;
//   const { r, g, b, a } = fill;

//   return (index > 0 ? ',' : '') + `${inset ? "inset" : ''} ${xAxis}px ${yAxis}px ${blur}px ${spread}px rgba(${r}, ${g}, ${b}, ${a})`;
// };

// export function convertTextShadow(data: BoxShadowData, index: number): string  {
//   const { xAxis, yAxis, blur, fill } = data;
//   const { r, g, b, a } = fill;

//   return (index > 0 ? ',' : '') + `${xAxis}px ${yAxis}px ${blur}px rgba(${r}, ${g}, ${b}, ${a})`;
// };

// export function convertDropShadow(data: BoxShadowData, index: number): string  {
//   const { xAxis, yAxis, blur, fill } = data;
//   const { r, g, b, a } = fill;

//   return `drop-shadow(${xAxis}px ${yAxis}px ${blur}px rgba(${r}, ${g}, ${b}, ${a}))`;
// };

// export function cssHexToColor(hexColor: string): Color {
//   if (hexColor.length !== 7 && hexColor[0] !== "#") {
//     throw new Error(`Invalid css hexadecimal color "${hexColor}"`);
//   }

//   return {
//     r: parseInt(hexColor.slice(1, 3), 16),
//     g: parseInt(hexColor.slice(3, 5), 16),
//     b: parseInt(hexColor.slice(5, 7), 16),
//     a: parseInt(hexColor.slice(7, 9), 16),
//   };
// }

// /**
//  * Takes a string or number value, strips any non-mathematic characters from it
//  * then tries to evaluate it if it the string contains any operators.
//  *
//  * @param {value} string or number
//  * @return {Number | undefined}
//  */
// export function parseNumber(value: string | number): number | undefined {
//   // if value is already a number just return it
//   if (typeof value === "number") {
//     return value;
//   }

//   // Parse out any characters that aren't 0-9 * / + - . ( )
//   let parsedValue: string | number = value.replace(/[^0-9/+*-.()]/g, "");

//   if (parsedValue === undefined || parsedValue === "") {
//     return undefined;
//   }

//   // If parsed value is defined, try evaluating it
//   try {
//     // eslint-disable-next-line
//     parsedValue = parseFloat(eval(parsedValue));
//   } catch (e) {
//     return undefined;
//   }

//   // If number evaluated without error, and it is finite, return it
//   if (isFinite(parsedValue)) {
//     return parsedValue;
//   }
//   // Otherwise return undefined
//   return undefined;
// }

// export const getElemZoom = (z: number) : number => {
//   const zoomLvL: number = zoomMap[(z * 10).toFixed()]

//   if (zoomLvL) {
//     return zoomLvL
//   } else {
//     return getElemZoom(z > 1 ? z + 0.1 : z - 0.1)
//   }
// }

// const zoomMap = {
//   1: 9,
//   2: 5,
//   3: 4,
//   4: 3,
//   5: 2.5,
//   6: 2,
//   7: 1.8,
//   8: 1.5,
//   9: 1,
//   10: 1,
//   15: 0.8,
//   20: 0.65,
//   25: 0.55,
//   30: 0.5,
//   35: 0.4,
//   40: 0.3,
//   50: 0.2,
//   60: 0.2,
//   70: 0.2,
//   80: 0.2,
//   90: 0.1,
//   100: 0.1,
//   110: 0.1,
//   120: 0.1,
//   130: 0.1,
//   140: 0.1,
//   150: 0.1,
//   160: 0.1,
//   170: 0.1,
//   180: 0.1,
//   190: 0.09,
//   200: 0.09,
//   210: 0.08,
//   220: 0.08,
//   230: 0.07,
//   240: 0.06,
//   250: 0.06,
// }
