const getSelectedRoutesFromDistributionLocations = (distributionLocations) => {
  if (!distributionLocations || !distributionLocations.length) {
    return [];
  }
  const selectedRoutes = [];
  distributionLocations.forEach((location) => selectedRoutes.push(...location.selectedRoutes));
  return selectedRoutes;
};

export { getSelectedRoutesFromDistributionLocations };
