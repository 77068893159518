import { makeStyles } from "@material-ui/core";
import React from "react";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { SECTIONS } from "pages/flyer/builder/constants";
import NodesList from "./NodesList";
import { Settings, QrCode, Image, Shapes, Text } from "./components";

const Aside = ({ loading, selectedSection, setSelectedSection }) => {
  const classes = useStyles();

  const sections = {
    [SECTIONS.SETTINGS]: <Settings loading={loading} />,
    [SECTIONS.IMAGES]: <Image />,
    [SECTIONS.TEXT]: <Text />,
    [SECTIONS.SHAPES]: <Shapes />,
    [SECTIONS.QR_CODE]: <QrCode />,
    [SECTIONS.LAYERS]: <NodesList />,
  };

  return (
    <div className={classes.sidebar}>
      {sections[selectedSection] || null}
      <div
        className={classes.flap}
        onClick={() => {
          setSelectedSection(null);
        }}
      >
        <NavigateBeforeIcon className={classes.flapIcon} fontSize="small" />
      </div>
    </div>
  );
};

export default React.memo(Aside);

const useStyles = makeStyles({
  sidebar: {
    display: "flex",
    flexDirection: "column",
    background: "var(--color-surface-panel)",
    width: 281,
    height: "100%",
    padding: 16,
    maxHeight: "calc(100vh - 60px)",
    borderLeft: "1px solid var(--color-surface-canvas)",
    position: "relative",
  },
  flap: {
    position: "absolute",
    top: "calc(50% - 48px)",
    right: "-14px",
    width: "14px",
    height: "96px",
    clipPath: "polygon(0 0, 100% 10%, 100% 90%, 0% 100%)",
    backgroundColor: "var(--color-surface-panel)",
    // borderTop: "14px solid transparent",
    // borderBottom: "14px solid transparent",
    // borderLeft: "14px solid var(--color-surface-panel)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  flapIcon: {
    marginRight: "-3px",
  },
});
