// import { GoogleFontsStatus, useGoogleFonts } from "@flayyer/use-googlefonts";
import { FontStyle, Shadow } from "pages/flyer/builder/drawer";
import { stopPropagation } from "pages/flyer/builder/drawer/components/stopPropagation";
import { GHOSTED_OPACITY, LETTER_SPACING } from "pages/flyer/builder/drawer/constants/constants";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import { styled } from "pages/flyer/builder/drawer/styles";
import * as React from "react";
import { useTextKeyboardEvents } from "./useTextKeyboardEvents";
export interface TextLabelProps {
  font: string;
  fontStyle?: string[];
  text: string;
  color?: string;
  opacity?: number;
  onBlur?: () => void;
  onChange: (text: string) => void;
  offsetY?: number;
  offsetX?: number;
  scale?: number;
  isEditing?: boolean;
  fontStrokeWidth?: number;
  fontStrokeColor?: string;
  textAlign?: string;
  verticalAlign?: string;
  elementSize?: any;
  outerShadow?: Shadow;
  outerShadowColor?: string;
  isOuterShadowVisible?: boolean;
}

export const TextLabel = React.memo(function TextLabel({
  font,
  text,
  color = "#000000",
  offsetX = 0,
  offsetY = 0,
  scale = 1,
  opacity = 1,
  isEditing = false,
  textAlign = "center",
  verticalAlign = "top",
  fontStrokeWidth = 0,
  fontStrokeColor = "#000000",
  fontStyle = [],
  onBlur,
  onChange,
  elementSize,
  outerShadow,
  outerShadowColor,
  isOuterShadowVisible,
}: TextLabelProps) {
  const rInput = React.useRef<HTMLTextAreaElement>(null);
  const rTextBox = React.useRef<HTMLSpanElement>(null);
  const rIsMounted = React.useRef(false);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(TLDR.normalizeText(e.currentTarget.value));
    },
    [onChange]
  );

  const getTextAreaSelection = React.useCallback((textarea) => {
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    return {
      // start: start,
      end: end,
      length: end - start,
      // text: textarea.value.slice(start, end),
      stringBeforeSelection: textarea.value.slice(0, start),
      stringAfterSelection: textarea.value.slice(end),
    };
  }, []);

  const onPasteHandler = React.useCallback(
    (event) => {
      const { stringBeforeSelection, stringAfterSelection, end } = getTextAreaSelection(event.currentTarget);

      event.preventDefault();
      const textFormClipboard = event.clipboardData.getData("text");
      try {
        const result = JSON.parse(textFormClipboard);
        if (result.type === "tldr/clipboard" && result.shapes.length === 1 && result.shapes[0].type === "textarea") {
          const textToPaste = result.shapes[0].label;

          const newValue = `${stringBeforeSelection}${textToPaste}${stringAfterSelection}`;
          onChange(TLDR.normalizeText(newValue));
          event.currentTarget.select();
          document.execCommand("insertText", false, newValue);
          // event.currentTarget.selectionEnd = end;
        }
      } catch (e) {
        const newValue = `${stringBeforeSelection}${textFormClipboard}${stringAfterSelection}`;
        onChange(TLDR.normalizeText(newValue));
        event.currentTarget.select();
        document.execCommand("insertText", false, newValue);
        event.currentTarget.selectionEnd = end;
        // event.currentTarget.selectionStart = end + textFormClipboard.length;
      }
    },
    [onChange, getTextAreaSelection]
  );

  const handleKeyDown = useTextKeyboardEvents(onChange);

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      e.currentTarget.setSelectionRange(0, 0);
      onBlur?.();
    },
    [onBlur]
  );

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      if (!isEditing) return;
      if (!rIsMounted.current) return;

      if (document.activeElement === e.currentTarget) {
        e.currentTarget.select();
      }
    },
    [isEditing]
  );

  const handlePointerDown = React.useCallback(
    (e) => {
      if (isEditing) {
        e.stopPropagation();
      }
    },
    [isEditing]
  );

  React.useEffect(() => {
    if (isEditing) {
      requestAnimationFrame(() => {
        rIsMounted.current = true;
        const elm = rInput.current;
        if (elm) {
          elm.focus();
          elm.select();
        }
      });
    } else {
      onBlur?.();
    }
  }, [isEditing, onBlur]);

  const rInnerWrapper = React.useRef<HTMLDivElement>(null);
  const isItalic = fontStyle.includes(FontStyle.Italic);
  const isBold = fontStyle.includes(FontStyle.Bold);
  const isUnderline = fontStyle.includes(FontStyle.Underscore);
  const isLineThought = fontStyle.includes(FontStyle.Strikethrough);

  // const fetchedFont = useGoogleFonts([
  //   {
  //     family: font.split(" ")[1], // Family Name
  //     styles: [400],
  //   },
  // ]);

  // if (fetchedFont.status === GoogleFontsStatus.FAILED) {
  //   console.log(fetchedFont.error);
  // } else {
  //   console.log(fetchedFont.href);
  //   // https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,444;0,500;0,600..700;1,100..200;1,300;1,400;1,600..700&family=Roboto:ital,wght@0,100;0,400;0,500;1,300;1,400&display=auto
  // }

  React.useLayoutEffect(() => {
    const elm = rInnerWrapper.current;
    if (!elm) return;
    elm.style.transform = `scale(${scale}, ${scale}) translate(${offsetX}px, ${offsetY}px)`;
    elm.style.width = `calc(100% - ${offsetX * 2}px)`;
    elm.style.height = `calc(100% - ${offsetY}px)`;
    elm.style.fontSize = font;
    elm.style.opacity = `${opacity}`;
    elm.style.textAlign = `${textAlign}`;
    elm.style.fontStyle = `${isItalic ? "italic" : "normal"}`;
    elm.style.fontWeight = `${isBold ? "bold" : "normal"}`;
    elm.style.textDecorationLine = `${isUnderline ? "underline " : ""}${isLineThought ? "line-through" : ""}`;
    elm.style.webkitTextStrokeColor = `${fontStrokeColor}`;
    elm.style.webkitTextStrokeWidth = `${fontStrokeWidth}px`;
    // elm.style.textShadow = `${outerShadow?.x} ${outerShadow?.y} ${outerShadow?.blur} ${outerShadowColor}`;
    // elm.style.display = `flex`;
    elm.style.alignItems = `${verticalAlign}`;
    elm.style.textShadow = isOuterShadowVisible
      ? `${outerShadow?.x}px ${outerShadow?.y}px ${outerShadow?.blur}px ${outerShadowColor}`
      : "none";
  }, [
    opacity,
    font,
    offsetY,
    offsetX,
    scale,
    textAlign,
    verticalAlign,
    isItalic,
    isBold,
    isUnderline,
    isLineThought,
    fontStrokeColor,
    fontStrokeWidth,
    outerShadow,
    outerShadowColor,
    isOuterShadowVisible,
  ]);

  React.useLayoutEffect(() => {
    rTextBox.current?.style.setProperty("font", font, "important");
    rTextBox.current?.style.setProperty("font-weight", `${isBold ? "bold" : "normal"}`, "important");
    rTextBox.current?.style.setProperty("font-style", `${isItalic ? "italic" : "normal"}`, "important");
  }, [font, isItalic, isBold, isEditing]);
  // React.useLayoutEffect(() => {
  //   // Reset height - important to shrink on delete
  //   const elm = rInput.current;
  //   if (!elm) return;
  //   elm.style.height = "inherit";
  //   // Set height
  //   elm.style.height = `${Math.max(elm.scrollHeight, 32)}px`;
  // }, [isEditing, text]);

  React.useLayoutEffect(() => {
    const elm = rInput.current;

    if (!elm) return;
    // Set height
    elm.style.height = `${Math.max(elm.scrollHeight, 32)}px`;
    elm.style.fontStyle = `${isItalic ? "italic" : "normal"}`;
    elm.style.fontWeight = `${isBold ? "bold" : "normal"}`;
    elm.style.textDecorationLine = `${isUnderline ? "underline " : ""}${isLineThought ? "line-through" : ""}`;
    elm.style.webkitTextStrokeColor = `${fontStrokeColor}`;
    elm.style.textShadow = isOuterShadowVisible
      ? `${outerShadow?.x}px ${outerShadow?.y}px ${outerShadow?.blur}px ${outerShadowColor}`
      : "none";
    elm.style.webkitTextStrokeWidth = `${fontStrokeWidth}px`;
  }, [
    text,
    isEditing,
    opacity,
    font,
    offsetY,
    offsetX,
    scale,
    textAlign,
    verticalAlign,
    isItalic,
    isBold,
    isUnderline,
    isLineThought,
    fontStrokeColor,
    fontStrokeWidth,
    elementSize,
    isOuterShadowVisible,
    outerShadow,
    outerShadowColor,
  ]);

  return (
    <TextWrapper>
      <InnerWrapper
        ref={rInnerWrapper}
        hasText={!!text}
        isEditing={isEditing}
        style={{
          font,
          color,
        }}
      >
        {isEditing ? (
          <TextArea
            ref={rInput}
            style={{
              font,
              color,
              alignItems: verticalAlign as unknown as any,
              textAlign: textAlign as unknown as any,
            }}
            name="text"
            tabIndex={-1}
            autoComplete="false"
            autoCapitalize="false"
            autoCorrect="false"
            autoSave="false"
            autoFocus
            placeholder=""
            spellCheck="true"
            dir="auto"
            datatype="wysiwyg"
            defaultValue={text}
            color={color}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onPointerDown={handlePointerDown}
            onContextMenu={stopPropagation}
            onPaste={onPasteHandler}
          />
        ) : (
          <TextBoxWrapper
            ref={rTextBox}
            style={{
              textAlign: textAlign as unknown as any,
            }}
          >
            {text}
          </TextBoxWrapper>
        )}
      </InnerWrapper>
    </TextWrapper>
  );
});

const TextWrapper = styled("div", {
  position: "absolute",
  top: "var(--tl-padding)",
  left: "var(--tl-padding)",
  width: "calc(100% - (var(--tl-padding) * 2))",
  height: "calc(100% - (var(--tl-padding) * 2))",
  display: "flex",
  pointerEvents: "none",
  userSelect: "none",
  variants: {
    isGhost: {
      false: { opacity: 1 },
      true: { transition: "opacity .2s", opacity: GHOSTED_OPACITY },
    },
  },
});

const commonTextWrapping = {
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  letterSpacing: LETTER_SPACING,
};

const TextBoxWrapper = styled("span", {
  width: "100%",
  ...commonTextWrapping,
});

const InnerWrapper = styled("div", {
  position: "absolute",
  // padding: "4px",
  zIndex: 1,
  minHeight: 1,
  minWidth: 1,
  lineHeight: 1,
  textShadow: " 1px 1px 0 black",
  outline: 0,
  flexBasis: "100%",
  textOverflow: "break-word",
  wordBreak: "break-word",
  fontWeight: "500",
  display: "flex",
  backfaceVisibility: "hidden",
  userSelect: "none",
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  variants: {
    hasText: {
      false: {
        pointerEvents: "none",
      },
      true: {
        pointerEvents: "all",
      },
    },
    isEditing: {
      false: {
        userSelect: "none",
      },
      true: {
        // background: "$boundsBg",
        userSelect: "text",
        WebkitUserSelect: "text",
      },
    },
  },
  ...commonTextWrapping,
});

const TextArea = styled("textarea", {
  position: "absolute",
  // top: 0,
  // left: 0,
  zIndex: 1,
  width: "100%",
  // height: "1000%",
  border: "none",
  resize: "none",
  display: "flex",
  padding: "0 !important",
  // textAlign: "inherit",
  minHeight: "inherit",
  minWidth: "inherit",
  lineHeight: "inherit",
  outline: 0,
  fontWeight: "inherit",
  overflow: "visible",
  backfaceVisibility: "hidden",
  // display: "inline-block",
  pointerEvents: "all",
  background: "transparent",
  userSelect: "text",
  WebkitUserSelect: "text",
  ...commonTextWrapping,
});
