export const style = {
  wrapper: {
    color: "##011533",
    display: "flex",
    flexDirection: "column" as const,
    gap: "4px",
    padding: "16px 10px",
  },
  title: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  content: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
  },
};
