import CONFIG from "config/config";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getDistributionZones = (cityId) => {
  const gql = `query($cityId: ID!) {
    distributionZones(cityId: $cityId) {
        h2h {
            areaType
            center {
                lat
                lng
            }
            editMode
            id
            imported
            radius
            type
            value
            paths
        }
        d2d {
            areaType
            center {
                lat
                lng
            }
            editMode
            id
            imported
            radius
            type
            value
            paths
        }
    }
  }`;

  return callAuthorizedGraphQLEndpoint(
    gql,
    {
      cityId,
    },
    CONFIG.PATHFINDER_API_BASE_URL
  );
};
