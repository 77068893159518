import { RedoIcon, UndoIcon } from "pages/flyer/builder/drawer/components/Primitives/icons";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { Panel } from "pages/flyer/builder/drawer/components/Primitives/Panel";
import { ToolButton } from "pages/flyer/builder/drawer/components/Primitives/ToolButton";
import { useTldrawApp } from "pages/flyer/builder/drawer/hooks";
import { styled } from "pages/flyer/builder/drawer/styles";
import * as React from "react";
import { Menu } from "./Menu/Menu";
import { PageMenu } from "./PageMenu";
import StyleMenu from "./StyleMenu/StyleMenu";
import { ZoomMenu } from "./ZoomMenu";

interface TopPanelProps {
  readOnly: boolean;
  showPages: boolean;
  showMenu: boolean;
  showStyles: boolean;
  showZoom: boolean;
  showSponsorLink: boolean;
}

const TopPanel = ({ readOnly, showPages, showMenu, showStyles, showZoom, showSponsorLink }: TopPanelProps) => {
  const app = useTldrawApp();

  return (
    <StyledTopPanel>
      {(showMenu || showPages) && (
        <Panel side="left">
          {showMenu && <Menu showSponsorLink={showSponsorLink} readOnly={readOnly} />}
          {showPages && <PageMenu />}
        </Panel>
      )}

      {(showStyles || showZoom) && (
        <Panel side="center" style={{ minWidth: "194px", height: "34px" }}>
          {showStyles && !readOnly && <StyleMenu />}
          <ToolButton>
            <RemoveIcon onClick={app.zoomOut} />
          </ToolButton>
          {showZoom && <ZoomMenu />}
          <ToolButton>
            <AddIcon onClick={app.zoomIn} />
          </ToolButton>
          <StyledSpacer />
          <MobileOnly>
            <ToolButton>
              <UndoIcon onClick={app.undo} />
            </ToolButton>
            <ToolButton style={{ marginLeft: "10px" }}>
              <RedoIcon onClick={app.redo} />
            </ToolButton>
          </MobileOnly>
        </Panel>
      )}
    </StyledTopPanel>
  );
};

export default React.memo(TopPanel);

const StyledTopPanel = styled("div", {
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "row",
  pointerEvents: "none",
  justifyContent: "center",
  paddingTop: "10px",
  "& > *": {
    pointerEvents: "all",
  },
});

const StyledSpacer = styled("div", {
  width: "20px",
});

const MobileOnly = styled("div", {
  display: "flex",
  flexDirection: "row",
  variants: {
    bp: {
      small: {
        display: "inherit",
      },
      large: {
        display: "none",
      },
    },
  },
});
