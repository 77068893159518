export const styles = {
  content: {
    width: "567px",
    height: "510px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontFamily: '"Proxima Nova, sans-serif"',
    fontSize: "40px",
    fontWeight: 800,
    marginBottom: "16px",
  },
  subtitle: {
    fontFamily: '"Proxima Nova, sans-serif"',
    fontSize: "18px",
    fontWeight: 400,
    marginBottom: "40px",
  },
  cta: {
    background: "#EE4360",
    border: "1px solid #EE4360",
    borderRadius: "16px",
    padding: "9px 0",
    width: "337px",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "initial",
    "&:hover": {
      background: "#FFFFFF",
      border: "1px solid #EE4360",
      color: "#EE4360",
    },
  },
};
