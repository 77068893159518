import { memo } from "react";
import LogRocket from "logrocket";

import CONFIG from "config/config";
import { authorization } from "module/auth";

import Login from "./components/login";
import { authorize } from "./utils";

const Auth = ({ children }) => {
  authorize();

  if (CONFIG.ENV === "production") {
    LogRocket.init("rdcngf/oppizi");
  }

  if (authorization.isLogged()) {
    return children;
  } else {
    return <Login />;
  }
};

export default memo(Auth);
