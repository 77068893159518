import { makeStyles, IconButton, InputBase, Paper } from "@material-ui/core";
import cn from "classnames";
import { Icon } from "components/icons";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type CouponCodeInputProps = {
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (val: string) => void;
  onClear?: () => void;
};

export const CouponCodeInput = ({
  disabled = false,
  placeholder = "Enter coupon",
  value = "",
  onChange = () => {},
  onClear = () => {},
}: CouponCodeInputProps) => {
  const classes = useStyles();

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    onChange(e.target.value);
  };

  const handleClear = () => {
    onClear();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Paper component="form" className={classes.rootStyles}>
      <InputBase
        className={cn(classes.inputStyles, "mob-coupon-input")}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <IconButton className={cn(classes.clearButton, { [classes.hideElement]: disabled || !value })}>
        <Icon type="Close" size="m" onClick={handleClear} />
      </IconButton>
    </Paper>
  );
};

export default CouponCodeInput;
