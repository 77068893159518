import { withStyles } from "@material-ui/core/styles";

import style from "./style.js";

const BounceLoader = ({ classes }) => {
  return (
    <span className={classes.spinner}>
      <div className={classes.bounce1}></div>
      <div className={classes.bounce2}></div>
      <div className={classes.bounce3}></div>
    </span>
  );
};

export default withStyles(style)(BounceLoader);
