import { staticFonts } from "pages/flyer/builder/constants";
import { useGoogleFonts } from "pages/flyer/builder/hooks/useGoogleFonts";
import { useEffect, useState } from "react";

export const useAddAllFontsForFB = () => {
  const [fonts, setFonts] = useState<Array<any>>([]);
  const [fontsInfo, setFontsInfo] = useState([]);

  useEffect(() => {
    const fetchFonts = async () => {
      const resp = await fetch(
        "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCP-MMiSDcn-BtWpZC6woNnfoE8P54EoT8"
      );
      const res = await resp.json();

      const fonts = res.items.map((font: any) => font.family);
      const googleFontNames = res.items.map((font: any) => font.family);
      const staticFontsNames = Object.keys(staticFonts);
      const allFontsNames = [...googleFontNames, ...staticFontsNames];
      const sortedFontsNames = allFontsNames.sort((a, b) => a - b);
      setFonts(sortedFontsNames);
      setFontsInfo(
        fonts.map((font: any) => ({
          family: font, // Family Name
          styles: [400],
        }))
      );
    };
    fetchFonts();
  }, []);

  useGoogleFonts(fontsInfo);
  // useAddStaticFonts();
  return fonts;
};
