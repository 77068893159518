export const styles = {
  placesWrapper: {
    position: "relative" as const,
  },

  placesAutocomplete: {
    width: "100%",
  },

  placesInput: {
    width: `100%`,
    height: "35px",
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "6px 12px",
    fontSize: "1rem",
    outline: `none`,
    textOverflow: `ellipses`,
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",

    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },

  placesRemoveBtn: {
    position: "absolute" as const,
    top: "50%",
    right: "34%",
    transform: "translateY(-50%)",
    borderRadius: "50%",
    padding: "2px",
    minWidth: "24px",

    "&:hover": {
      cursor: "pointer",
    },
  },

  icon: {
    color: "#EE4360",
    marginRight: "16px",
  },

  // textField: {
  //   width: "388px",
  //   borderRadius: "8px",
  //   background: "#FFFFFF",

  //   "& input": {
  //     color: "#00112B",
  //   },

  //   "& fieldset": {
  //     border: "1px solid #E2E2FC",
  //     borderRadius: "8px",

  //     "& span": {
  //       whiteSpace: "nowrap",
  //     },
  //   },
  //   "& label.Mui-focused": {
  //     color: "rgba(0, 0, 0, 0.87)",
  //   },
  //   "& .MuiInput-underline:after": {
  //     borderBottomColor: "#EE4360",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "&.Mui-focused fieldset": {
  //       borderColor: "#EE4360",
  //     },
  //   },
  // },

  textFieldV2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    flexDirection: "row" as const,
    boxSizing: "border-box" as const,
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    "& fieldset": {
      border: "0px",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      alignItems: "baseline",
      padding: "2px !important",
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
  },
};

export default styles;
