import { styled } from "pages/flyer/builder/drawer/styles/index.ts";

export const StyledHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: "24px",
});

export const StyledLeftHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  height: "24px",
  gap: "24px",
});

export const StyledRightHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  height: "24px",
  justifyContent: "space-between",
});

export const StyledHeaderButton = styled("span", {
  cursor: "pointer",
});

export const StyledHeaderText = styled("span", {
  fontSize: "$3",
  fontWeight: 700,
  color: "$blueDeepDark",
  lineHeight: "24px",
});

export default StyledHeader;
