export const style = {
  wrapper: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    position: "absolute" as const,
    right: "60px",
    bottom: "67px",
    padding: "9px 17px 9px 8px",
    backgroundColor: "#FFFFFF",
    backShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
    borderRadius: "8px",
  },
  hintText: {
    color: "#011533",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
  },
};
