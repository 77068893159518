export const style = {
  background: {
    width: "100%",
    height: "100%",
    position: "absolute" as const,
    background: "#ffffff",
    opacity: 0.8,
    top: 0,
    zIndex: 100000,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    zIndex: 100001,
  },
  loadingWrapper: {
    animation: "$rotateImg 2s linear infinite",
    marginBottom: "20px",
  },
  title: {
    color: "#0C172C",
    fontSize: "24px",
    fontWeight: 700,
  },
  description: {
    color: "#2E3860",
    fontSize: "16px",
    fontWeight: 700,
  },

  "@keyframes rotateImg": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
};
