import React from "react";
import cn from "classnames";
import { Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import nextIcon from "static/media/next.svg";
import checkIcon from "static/media/check.svg";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type NextButtonProps = {
  label?: string;
  loading?: boolean;
  isNextActive?: boolean;
  isSaveButton?: boolean;
  isSaveActive?: boolean;
  isSaved?: boolean;
  onClick?: () => void;
};

export const NextButton = ({
  label = "Next",
  loading = false,
  isNextActive = false,
  isSaveButton = false,
  isSaveActive = false,
  isSaved = false,
  onClick = () => {},
}: NextButtonProps): React.ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // <= 768px

  const classNameForButton = isSaveButton
    ? isSaveActive
      ? classes.saveButton
      : classes.saveButtonDisabled
    : isNextActive
    ? classes.nextButton
    : classes.nextButtonDisabled;

  return (
    <Typography
      className={cn(classes.navigationBtn, classNameForButton, { [classes.saved]: isSaved })}
      onClick={isNextActive || isSaveButton ? onClick : () => {}}
    >
      {isMobile ? (
        loading ? (
          "Loading..."
        ) : (
          label
        )
      ) : loading ? (
        <CircularProgress
          size={20}
          style={{ color: isSaveButton ? "#273754" : "white", marginLeft: isSaveButton ? 0 : "30px" }}
        />
      ) : (
        <>
          {label}
          {!isSaveButton ? <img src={nextIcon} style={{ position: "absolute", right: "40px" }} /> : null}
          {isSaved ? <img src={checkIcon} style={{ position: "absolute", right: "40px" }} /> : null}
        </>
      )}
    </Typography>
  );
};
