import * as React from "react";
import { debounce } from "shared/libraries/utils/func";
import {
  FORMATS,
  GAP_BETWEEN_PAGES,
  CUT_MARK_LENGTH,
  DOOR_HANGER_TRIANGLE_SIZE,
  DOOR_HANGER_TRIANGLE_POS,
  DOOR_HANGER_CIRCLE_SIZE,
  DOOR_HANGER_CIRCLE_POS,
  INF_VAL,
  INTERVAL_SIZE,
  BLEED_AREA_SIZE,
  SAFE_AREA_SIZE,
  WHITE_BG_SIZE as DEFAULT_BG_SIZE,
} from "pages/flyer/builder/constants";
import { TDAsset, TDAssetType, TDBinding, TDShape, TDUser, TldrawApp } from "pages/flyer/builder/drawer";
import { qrCodeSrc } from "pages/flyer/builder/drawer/constants/qrCode";
import {
  doorHangerTriangleFrontSrc,
  doorHangerCircleFrontSrc,
  doorHangerTriangleBackSrc,
  doorHangerCircleBackSrc,
  diecutLineFrontSrc,
  diecutLineBackSrc,
  cutLineFrontSrc,
  cutLineBackSrc,
  safetyLineFrontSrc,
  safetyLineBackSrc,
} from "pages/flyer/builder/drawer/constants/doorHanger";
import {
  tearawayCutLineSrc,
  tearawaySafetyLineSrc,
  tearawayFlyerBuilderCutLineSrc,
} from "pages/flyer/builder/drawer/constants/tearaway";
import { templateBackgroundSrc, templateLogoSrc, a6SafetyLineSrc } from "pages/flyer/builder/drawer/constants/a6";
import {
  TemplateIdsEnum,
  GuidelinesIdsEnum,
  CutMarkIdsEnum,
  StaticAssetsIdsEnum,
  DoorHangerAssetsIdsEnum,
  TearawayAssetsIdsEnum,
  A6AssetsIdsEnum,
  PageSizeIdsEnum,
  PageSideIdsEnum,
  PossibleFlyerType,
  TemplateAssetsIdsEnum,
  TemplateShapesIdsEnum,
  AlignStyle,
  DocumentSettings,
} from "pages/flyer/builder/drawer/types";
import {
  calculateCutMarkPosition,
  getPageShape,
  getQrCodeShape,
  getArrowShape,
  getCutMarkShape,
  getImageShape,
  getDoorHangerRectangleShape,
  getTextShape,
  getEllipseShape,
} from "pages/flyer/builder/drawer/utils";
import { DocumentFormats } from "shared/constants";
import { LiveStorage, StorageKeyObjectType } from "../livestorage";
import { displayInInch, getFlyerHeightDisplay, getFlyerWidthDisplay } from "../../utils";

declare const window: Window & { app: TldrawApp };

export function useMultiplayerState(
  roomId: string,
  countryCode: string,
  flyerType: PossibleFlyerType,
  isSyncServer = true
) {
  const [app, setApp] = React.useState<TldrawApp>();
  const [liveStorage, setLiveStorage] = React.useState<LiveStorage<StorageKeyObjectType>>();
  const [loading, setLoading] = React.useState(true);
  const [isChangedFBStates, setIsChangedFBStates] = React.useState(false);

  const onUndo = React.useCallback(() => {}, []);
  const onRedo = React.useCallback(() => {}, []);

  const rLiveShapes = React.useRef<Map<string, TDShape> | null>(null);
  const rLiveBindings = React.useRef<Map<string, TDBinding> | null>(null);
  const rLiveAssets = React.useRef<Map<string, TDAsset> | null>(null);
  const rLiveDocumentSettings = React.useRef<DocumentSettings>();

  // Callbacks --------------

  // Put the state into the window, for debugging.
  const onMount = React.useCallback(
    (app: TldrawApp) => {
      app.loadRoom(roomId);
      // app.pause(); // Turn off the app's own undo / redo stack
      window.app = app;
      setApp(app);
    },
    [roomId]
  );

  // Update the live shapes when the app's shapes change.
  const onChangePage = React.useCallback(
    (
      app: TldrawApp,
      shapes: Record<string, TDShape | undefined>,
      bindings: Record<string, TDBinding | undefined>,
      assets: Record<string, TDAsset | undefined>
    ) => {
      if (!liveStorage) {
        return;
      }

      const lShapes = rLiveShapes.current;
      const lBindings = rLiveBindings.current;
      const lAssets = rLiveAssets.current;

      liveStorage.updateLiveDocumentSettings({
        orientation: app.document.orientation,
        format: app.document.format,
        isShowGuidelines: app.document.isShowGuidelines,
      });

      if (!(lShapes && lBindings && lAssets)) return;

      Object.entries(shapes).forEach(([id, shape]) => {
        if (!shape) {
          lShapes.delete(id);
        } else {
          lShapes.set(shape.id, shape);
        }
      });
      liveStorage.updateLiveShapes(lShapes);

      Object.entries(bindings).forEach(([id, binding]) => {
        if (!binding) {
          lBindings.delete(id);
        } else {
          lBindings.set(binding.id, binding);
        }
      });
      liveStorage.updateLiveBindings(lBindings);

      Object.entries(assets).forEach(([id, asset]) => {
        if (!asset) {
          lAssets.delete(id);
        } else {
          lAssets.set(asset.id, asset);
        }
      });
      liveStorage.updateLiveAssets(lAssets);

      setIsChangedFBStates(liveStorage.needToSyncServer());
    },
    [liveStorage]
  );

  const debouncedOnChange = React.useMemo(() => debounce(onChangePage, 500) as unknown as any, [onChangePage]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangePresence = React.useCallback((_app: TldrawApp, _user: TDUser) => {}, []);

  const convertArrayToRecord = <T>(arr: [string, T][]): Record<string, T | undefined> =>
    arr.reduce(
      (obj, item) => ({
        ...obj,
        [item[0]]: item[1],
      }),
      {}
    );

  const onChangeDocumentFormat = React.useCallback((newFormat: PossibleFlyerType) => {
    const shapesTmp = getDefaultShapes(newFormat);
    const liveAssetsTmp = getDefaultAssets(newFormat);

    return {
      newAssets: convertArrayToRecord(liveAssetsTmp),
      newShapes: convertArrayToRecord(shapesTmp),
    };
  }, []);

  const saveFlyerBuilderStates = React.useCallback(() => {
    liveStorage?.updateServer();
    setIsChangedFBStates(false);
  }, [liveStorage]);

  const getDefaultShapes = (documentFormat: PossibleFlyerType): [string, TDShape][] => {
    const isInInch = displayInInch(countryCode);
    const bleedSize = BLEED_AREA_SIZE[documentFormat];
    const safeSize = SAFE_AREA_SIZE[documentFormat];

    const CANVAS_WIDTH = FORMATS[documentFormat].width;
    const CANVAS_HEIGHT = FORMATS[documentFormat].height;

    const shapesTmp: [string, TDShape][] = [];

    if (documentFormat === DocumentFormats.A6) {
      shapesTmp.push(
        [TemplateIdsEnum.Front, getPageShape(TemplateIdsEnum.Front, [0, 0], [CANVAS_WIDTH, CANVAS_HEIGHT])],
        [
          TemplateIdsEnum.Back,
          getPageShape(TemplateIdsEnum.Back, [CANVAS_WIDTH + GAP_BETWEEN_PAGES, 0], [CANVAS_WIDTH, CANVAS_HEIGHT]),
        ],
        [
          StaticAssetsIdsEnum.A6SafetyLineFront,
          getImageShape(
            StaticAssetsIdsEnum.A6SafetyLineFront,
            [bleedSize + safeSize, bleedSize + safeSize],
            [CANVAS_WIDTH - 2 * (bleedSize + safeSize), CANVAS_HEIGHT - 2 * (bleedSize + safeSize)],
            0,
            A6AssetsIdsEnum.A6SafetyLineSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.A6SafetyLineBack,
          getImageShape(
            StaticAssetsIdsEnum.A6SafetyLineBack,
            [CANVAS_WIDTH + GAP_BETWEEN_PAGES + bleedSize + safeSize, bleedSize + safeSize],
            [CANVAS_WIDTH - 2 * (safeSize + bleedSize), CANVAS_HEIGHT - 2 * (safeSize + bleedSize)],
            0,
            A6AssetsIdsEnum.A6SafetyLineSrc
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkLTT,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkLTT,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkLTT }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkLTL,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkLTL,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkLTL }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkRTT,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkRTT,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkRTT }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkRTR,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkRTR,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkRTR }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkLBB,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkLBB,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkLBB }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkLBL,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkLBL,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkLBL }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkRBB,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkRBB,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkRBB }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.FrontCutMarkRBR,
          getCutMarkShape(
            CutMarkIdsEnum.FrontCutMarkRBR,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.FrontCutMarkRBR }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkLTT,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkLTT,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkLTT }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkLTL,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkLTL,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkLTL }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkRTT,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkRTT,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkRTT }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkRTR,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkRTR,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkRTR }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkLBB,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkLBB,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkLBB }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkLBL,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkLBL,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkLBL }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkRBB,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkRBB,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkRBB }),
            [0, CUT_MARK_LENGTH],
            [0, CUT_MARK_LENGTH / 2]
          ),
        ],
        [
          CutMarkIdsEnum.BackCutMarkRBR,
          getCutMarkShape(
            CutMarkIdsEnum.BackCutMarkRBR,
            calculateCutMarkPosition({ format: documentFormat, cutMarkId: CutMarkIdsEnum.BackCutMarkRBR }),
            [CUT_MARK_LENGTH, 0],
            [CUT_MARK_LENGTH / 2, 0]
          ),
        ]
      );
    }

    if (documentFormat === DocumentFormats.DOOR_HANGER) {
      shapesTmp.push(
        [TemplateIdsEnum.Front, getPageShape(TemplateIdsEnum.Front, [0, 0], [CANVAS_WIDTH, CANVAS_HEIGHT])],
        [
          TemplateIdsEnum.Back,
          getPageShape(TemplateIdsEnum.Back, [CANVAS_WIDTH + GAP_BETWEEN_PAGES, 0], [CANVAS_WIDTH, CANVAS_HEIGHT]),
        ],
        [
          StaticAssetsIdsEnum.FrontDoorHangerTriangle,
          getImageShape(
            StaticAssetsIdsEnum.FrontDoorHangerTriangle,
            [DOOR_HANGER_TRIANGLE_POS[0], DOOR_HANGER_TRIANGLE_POS[1]],
            DOOR_HANGER_TRIANGLE_SIZE,
            0,
            DoorHangerAssetsIdsEnum.DoorHangerTriangleFrontSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.FrontDoorHangerCircle,
          getImageShape(
            StaticAssetsIdsEnum.FrontDoorHangerCircle,
            DOOR_HANGER_CIRCLE_POS,
            DOOR_HANGER_CIRCLE_SIZE,
            0,
            DoorHangerAssetsIdsEnum.DoorHangerCircleFrontSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.BackDoorHangerTriangle,
          getImageShape(
            StaticAssetsIdsEnum.BackDoorHangerTriangle,
            [
              CANVAS_WIDTH * 2 + GAP_BETWEEN_PAGES - DOOR_HANGER_TRIANGLE_POS[0] - DOOR_HANGER_TRIANGLE_SIZE[0],
              DOOR_HANGER_TRIANGLE_POS[1],
            ],
            DOOR_HANGER_TRIANGLE_SIZE,
            0,
            DoorHangerAssetsIdsEnum.DoorHangerTriangleBackSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.BackDoorHangerCircle,
          getImageShape(
            StaticAssetsIdsEnum.BackDoorHangerCircle,
            [
              CANVAS_WIDTH * 2 + GAP_BETWEEN_PAGES - DOOR_HANGER_CIRCLE_POS[0] - DOOR_HANGER_CIRCLE_SIZE[0],
              DOOR_HANGER_CIRCLE_POS[1],
            ],
            DOOR_HANGER_CIRCLE_SIZE,
            0,
            DoorHangerAssetsIdsEnum.DoorHangerCircleBackSrc
          ),
        ],
        [
          GuidelinesIdsEnum.FrontGL,
          getImageShape(
            GuidelinesIdsEnum.FrontGL,
            [bleedSize + safeSize, bleedSize + safeSize],
            [CANVAS_WIDTH - 2 * bleedSize - 2 * safeSize, CANVAS_HEIGHT - 2 * bleedSize - 2 * safeSize],
            0,
            DoorHangerAssetsIdsEnum.SafetyLineFrontSrc
          ),
        ],
        [
          GuidelinesIdsEnum.BackGL,
          getImageShape(
            GuidelinesIdsEnum.BackGL,
            [CANVAS_WIDTH + GAP_BETWEEN_PAGES + bleedSize + safeSize, bleedSize + safeSize],
            [CANVAS_WIDTH - 2 * bleedSize - 2 * safeSize, CANVAS_HEIGHT - 2 * bleedSize - 2 * safeSize],
            0,
            DoorHangerAssetsIdsEnum.SafetyLineBackSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.CutLineFront,
          getImageShape(
            StaticAssetsIdsEnum.CutLineFront,
            [bleedSize, bleedSize],
            [CANVAS_WIDTH - 2 * bleedSize, CANVAS_HEIGHT - 2 * bleedSize],
            0,
            DoorHangerAssetsIdsEnum.CutLineFrontSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.CutLineBack,
          getImageShape(
            StaticAssetsIdsEnum.CutLineBack,
            [CANVAS_WIDTH + GAP_BETWEEN_PAGES + bleedSize, bleedSize],
            [CANVAS_WIDTH - 2 * bleedSize, CANVAS_HEIGHT - 2 * bleedSize],
            0,
            DoorHangerAssetsIdsEnum.CutLineBackSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.DiecutLineFront,
          getImageShape(
            StaticAssetsIdsEnum.DiecutLineFront,
            [0, 0],
            [CANVAS_WIDTH, CANVAS_HEIGHT],
            0,
            DoorHangerAssetsIdsEnum.DiecutLineFrontSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.DiecutLineBack,
          getImageShape(
            StaticAssetsIdsEnum.DiecutLineBack,
            [CANVAS_WIDTH + GAP_BETWEEN_PAGES, 0],
            [CANVAS_WIDTH, CANVAS_HEIGHT],
            0,
            DoorHangerAssetsIdsEnum.DiecutLineBackSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.LeftTriangleRectangle,
          getDoorHangerRectangleShape(
            StaticAssetsIdsEnum.LeftTriangleRectangle,
            [DOOR_HANGER_TRIANGLE_POS[0] - 1, DOOR_HANGER_TRIANGLE_POS[1] + 2],
            [2, DOOR_HANGER_TRIANGLE_SIZE[1] - 4]
          ),
        ],
        [
          StaticAssetsIdsEnum.RightTriangleRectangle,
          getDoorHangerRectangleShape(
            StaticAssetsIdsEnum.RightTriangleRectangle,
            [2 * CANVAS_WIDTH + GAP_BETWEEN_PAGES - 1, DOOR_HANGER_TRIANGLE_POS[1] + 2],
            [2, DOOR_HANGER_TRIANGLE_SIZE[1] - 4]
          ),
        ]
      );
    }

    if (
      documentFormat === DocumentFormats.A6 ||
      documentFormat === DocumentFormats.DOOR_HANGER ||
      documentFormat === DocumentFormats.TEARAWAY
    ) {
      const bgStart = 0;
      shapesTmp.push(
        [
          TemplateShapesIdsEnum.TemplateFrontBackground,
          {
            ...getImageShape(
              TemplateShapesIdsEnum.TemplateFrontBackground,
              [bgStart, bgStart],
              [CANVAS_WIDTH - 2 * bgStart, CANVAS_HEIGHT - 2 * bgStart],
              0,
              TemplateAssetsIdsEnum.TemplateBackgroundSrc
            ),
            childIndex: 1,
            isGhost: false,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateBackBackground,
          {
            ...getImageShape(
              TemplateShapesIdsEnum.TemplateBackBackground,
              [CANVAS_WIDTH + GAP_BETWEEN_PAGES + bgStart, bgStart],
              [CANVAS_WIDTH - 2 * bgStart, CANVAS_HEIGHT - 2 * bgStart],
              0,
              TemplateAssetsIdsEnum.TemplateBackgroundSrc
            ),
            childIndex: 2,
            isGhost: false,
            isTemplate: false,
            isLocked: false,
          },
        ]
      );

      const WHITE_BG_SIZE =
        documentFormat === DocumentFormats.TEARAWAY
          ? [DEFAULT_BG_SIZE[0] - 20, DEFAULT_BG_SIZE[1] + 20]
          : [...DEFAULT_BG_SIZE];
      const WHITE_BG_DELTA = documentFormat === DocumentFormats.DOOR_HANGER ? 50 : 0;
      const FRONT_WHITE_BG_START = [
        (CANVAS_WIDTH - WHITE_BG_SIZE[0]) / 2,
        (CANVAS_HEIGHT - WHITE_BG_SIZE[1]) / 2 + WHITE_BG_DELTA,
      ];
      const BACK_WHITE_BG_START = [FRONT_WHITE_BG_START[0] + CANVAS_WIDTH + GAP_BETWEEN_PAGES, FRONT_WHITE_BG_START[1]];
      shapesTmp.push(
        [
          TemplateShapesIdsEnum.TemplateFrontWhiteRectangle,
          {
            ...getPageShape(TemplateShapesIdsEnum.TemplateFrontWhiteRectangle, FRONT_WHITE_BG_START, WHITE_BG_SIZE),
            childIndex: 2,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateBackWhiteRectangle,
          {
            ...getPageShape(TemplateShapesIdsEnum.TemplateBackWhiteRectangle, BACK_WHITE_BG_START, WHITE_BG_SIZE),
            childIndex: 3,
            isTemplate: false,
            isLocked: false,
          },
        ]
      );

      const LOGO_SIZE = [80, 53];
      const LOGO_Y_POS = documentFormat === DocumentFormats.TEARAWAY ? 30 : 50;
      shapesTmp.push([
        TemplateShapesIdsEnum.TemplateLogo,
        {
          ...getImageShape(
            TemplateShapesIdsEnum.TemplateLogo,
            [FRONT_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - LOGO_SIZE[0]) / 2, FRONT_WHITE_BG_START[1] + LOGO_Y_POS],
            LOGO_SIZE,
            0,
            TemplateAssetsIdsEnum.TemplateLogoSrc
          ),
          childIndex: 4,
          isGhost: false,
          isTemplate: false,
          isLocked: false,
        },
      ]);

      const TEXT_1_SIZE = [152, 74];
      shapesTmp.push([
        TemplateShapesIdsEnum.TemplateText_1,
        {
          ...getTextShape(
            TemplateShapesIdsEnum.TemplateText_1,
            [
              FRONT_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - TEXT_1_SIZE[0]) / 2,
              FRONT_WHITE_BG_START[1] + LOGO_Y_POS + 80,
            ],
            TEXT_1_SIZE,
            0,
            "-10%",
            "Purple Purse",
            72,
            "#e2842f"
          ),
          childIndex: 4,
          isTemplate: false,
          isLocked: false,
        },
      ]);

      const TEXT_1_1_SIZE = [120, 53];
      shapesTmp.push([
        TemplateShapesIdsEnum.TemplateText_1_1,
        {
          ...getTextShape(
            TemplateShapesIdsEnum.TemplateText_1_1,
            [
              FRONT_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - TEXT_1_1_SIZE[0]) / 2,
              FRONT_WHITE_BG_START[1] + LOGO_Y_POS + 160,
            ],
            TEXT_1_1_SIZE,
            0,
            "Off your first online order",
            "Open Sans",
            20,
            "#000000"
          ),
          childIndex: 4,
          isTemplate: false,
          isLocked: false,
        },
      ]);

      const TEXT_1_2_SIZE = [144, 50];
      shapesTmp.push([
        TemplateShapesIdsEnum.TemplateText_1_2,
        {
          ...getTextShape(
            TemplateShapesIdsEnum.TemplateText_1_2,
            [FRONT_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - TEXT_1_2_SIZE[0]) / 2, FRONT_WHITE_BG_START[1] + 330],
            TEXT_1_2_SIZE,
            0,
            "Scan to get the discount",
            "Open Sans",
            13,
            "#000000"
          ),
          childIndex: 4,
          isTemplate: false,
          isLocked: false,
        },
      ]);

      shapesTmp.push([
        StaticAssetsIdsEnum.QrCode,
        getQrCodeShape(
          StaticAssetsIdsEnum.QrCode,
          [FRONT_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - 50) / 2, FRONT_WHITE_BG_START[1] + 370],
          DoorHangerAssetsIdsEnum.QrCode
        ),
      ]);

      if (documentFormat === DocumentFormats.TEARAWAY) {
        shapesTmp.push([
          TemplateShapesIdsEnum.TemplateText_1_2_For_Tearyway,
          {
            ...getTextShape(
              TemplateShapesIdsEnum.TemplateText_1_2_For_Tearyway,
              [BACK_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - TEXT_1_2_SIZE[0]) / 2, FRONT_WHITE_BG_START[1] + 330],
              TEXT_1_2_SIZE,
              0,
              "Scan to get the discount",
              "Open Sans",
              13,
              "#000000"
            ),
            childIndex: 4,
            isTemplate: false,
            isLocked: false,
          },
        ]);

        shapesTmp.push([
          StaticAssetsIdsEnum.QrCodeForTearaway,
          getQrCodeShape(
            StaticAssetsIdsEnum.QrCodeForTearaway,
            [BACK_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - 50) / 2, FRONT_WHITE_BG_START[1] + 370],
            DoorHangerAssetsIdsEnum.QrCode
          ),
        ]);

        shapesTmp.push(
          [
            TemplateShapesIdsEnum.TemplateFrontCircle,
            {
              ...getEllipseShape(TemplateShapesIdsEnum.TemplateFrontCircle, [10, 10], [50, 50], "#EE4360"),
              childIndex: 8,
              isTemplate: false,
              isLocked: false,
            },
          ],
          [
            TemplateShapesIdsEnum.TemplateBackCircle,
            {
              ...getEllipseShape(
                TemplateShapesIdsEnum.TemplateBackCircle,
                [CANVAS_WIDTH + GAP_BETWEEN_PAGES + 10, 10],
                [50, 50],
                "#EE4360"
              ),
              childIndex: 8,
              isTemplate: false,
              isLocked: false,
            },
          ],
          [
            TemplateShapesIdsEnum.TemplateTextInFrontCircle,
            {
              ...getTextShape(
                TemplateShapesIdsEnum.TemplateTextInFrontCircle,
                [20, 20],
                [80, 80],
                0,
                "This is a sample design",
                "Proxima Nova",
                16,
                "#ffffff"
              ),
              childIndex: 9,
              isTemplate: false,
              isLocked: false,
            },
          ],
          [
            TemplateShapesIdsEnum.TemplateTextInBackCircle,
            {
              ...getTextShape(
                TemplateShapesIdsEnum.TemplateTextInBackCircle,
                [CANVAS_WIDTH + GAP_BETWEEN_PAGES + 20, 20],
                [80, 80],
                0,
                "This is a sample design",
                "Proxima Nova",
                16,
                "#ffffff"
              ),
              childIndex: 9,
              isTemplate: false,
              isLocked: false,
            },
          ]
        );
      }

      const TEXT_2_SIZE = [135, 53];
      const TEXT_2_V_POS = documentFormat === DocumentFormats.TEARAWAY ? 10 : 30;
      shapesTmp.push([
        TemplateShapesIdsEnum.TemplateText_2,
        {
          ...getTextShape(
            TemplateShapesIdsEnum.TemplateText_2,
            [BACK_WHITE_BG_START[0] + (WHITE_BG_SIZE[0] - TEXT_2_SIZE[0]) / 2, BACK_WHITE_BG_START[1] + TEXT_2_V_POS],
            TEXT_2_SIZE,
            0,
            "Why us?",
            "Purple Purse",
            36,
            "#e2842f"
          ),
          childIndex: 4,
          isTemplate: false,
          isLocked: false,
        },
      ]);

      const BULLET_POINTS_H_POS = documentFormat === DocumentFormats.TEARAWAY ? 10 : 20;
      const BULLET_POINTS_V_POS = documentFormat === DocumentFormats.TEARAWAY ? 60 : 100;
      const BULLET_POINTS_V_GAP = documentFormat === DocumentFormats.TEARAWAY ? 80 : 105;
      const BULLET_POINTS = [
        [BACK_WHITE_BG_START[0] + BULLET_POINTS_H_POS, BACK_WHITE_BG_START[1] + BULLET_POINTS_V_POS],
        [
          BACK_WHITE_BG_START[0] + BULLET_POINTS_H_POS,
          BACK_WHITE_BG_START[1] + BULLET_POINTS_V_POS + BULLET_POINTS_V_GAP,
        ],
        [
          BACK_WHITE_BG_START[0] + BULLET_POINTS_H_POS,
          BACK_WHITE_BG_START[1] + BULLET_POINTS_V_POS + 2 * BULLET_POINTS_V_GAP,
        ],
      ];
      shapesTmp.push(
        [
          TemplateShapesIdsEnum.TemplateBulletPoint_1,
          {
            ...getEllipseShape(TemplateShapesIdsEnum.TemplateBulletPoint_1, BULLET_POINTS[0], [6, 6]),
            childIndex: 8,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateBulletPoint_2,
          {
            ...getEllipseShape(TemplateShapesIdsEnum.TemplateBulletPoint_2, BULLET_POINTS[1], [6, 6]),
            childIndex: 8,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateBulletPoint_3,
          {
            ...getEllipseShape(TemplateShapesIdsEnum.TemplateBulletPoint_3, BULLET_POINTS[2], [6, 6]),
            childIndex: 8,
            isTemplate: false,
            isLocked: false,
          },
        ]
      );

      const TEXT_2_WIDTH_MORE = documentFormat === DocumentFormats.TEARAWAY ? 20 : 0;
      const TEXT_2_1_SIZE = [185 + TEXT_2_WIDTH_MORE, 50];
      const TEXT_2_1_1_SIZE = [180 + TEXT_2_WIDTH_MORE, 50];
      const TEXT_2_2_SIZE = [123 + TEXT_2_WIDTH_MORE, 50];
      const TEXT_2_2_1_SIZE = [195 + TEXT_2_WIDTH_MORE, 50];
      const TEXT_2_3_SIZE = [200 + TEXT_2_WIDTH_MORE, 50];
      const TEXT_2_3_1_SIZE = [195 + TEXT_2_WIDTH_MORE, 50];
      const TEXT_START = [20, 8];
      const TEXT_BETWEEN_GAP = 30;

      shapesTmp.push(
        [
          TemplateShapesIdsEnum.TemplateText_2_1,
          {
            ...getTextShape(
              TemplateShapesIdsEnum.TemplateText_2_1,
              [BULLET_POINTS[0][0] + TEXT_START[0], BULLET_POINTS[0][1] - TEXT_START[1]],
              TEXT_2_1_SIZE,
              0,
              "From garden to table",
              "Open Sans",
              20,
              "#e2842f",
              AlignStyle.Start,
              AlignStyle.Start
            ),
            childIndex: 5,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateText_2_1_1,
          {
            ...getTextShape(
              TemplateShapesIdsEnum.TemplateText_2_1_1,
              [BULLET_POINTS[0][0] + TEXT_START[0], BULLET_POINTS[0][1] - TEXT_START[1] + TEXT_BETWEEN_GAP],
              TEXT_2_1_1_SIZE,
              0,
              "We care about you - that's why we cook from the most fresh ingredients.",
              "Open Sans",
              12,
              "#000000",
              AlignStyle.Start,
              AlignStyle.Start
            ),
            childIndex: 5,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateText_2_2,
          {
            ...getTextShape(
              TemplateShapesIdsEnum.TemplateText_2_2,
              [BULLET_POINTS[1][0] + TEXT_START[0], BULLET_POINTS[1][1] - TEXT_START[1]],
              TEXT_2_2_SIZE,
              0,
              "Quick delivery",
              "Open Sans",
              20,
              "#e2842f",
              AlignStyle.Start,
              AlignStyle.Start
            ),
            childIndex: 5,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateText_2_2_1,
          {
            ...getTextShape(
              TemplateShapesIdsEnum.TemplateText_2_2_1,
              [BULLET_POINTS[1][0] + TEXT_START[0], BULLET_POINTS[1][1] - TEXT_START[1] + TEXT_BETWEEN_GAP],
              TEXT_2_2_1_SIZE,
              0,
              "We guarantee delivery within 1 hour from order. If not delivered within the specified time - your food is on us!",
              "Open Sans",
              12,
              "#000000",
              AlignStyle.Start,
              AlignStyle.Start
            ),
            childIndex: 5,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateText_2_3,
          {
            ...getTextShape(
              TemplateShapesIdsEnum.TemplateText_2_3,
              [BULLET_POINTS[2][0] + TEXT_START[0], BULLET_POINTS[2][1] - TEXT_START[1]],
              TEXT_2_3_SIZE,
              0,
              "True italian experience",
              "Open Sans",
              20,
              "#e2842f",
              AlignStyle.Start,
              AlignStyle.Start
            ),
            childIndex: 5,
            isTemplate: false,
            isLocked: false,
          },
        ],
        [
          TemplateShapesIdsEnum.TemplateText_2_3_1,
          {
            ...getTextShape(
              TemplateShapesIdsEnum.TemplateText_2_3_1,
              [BULLET_POINTS[2][0] + TEXT_START[0], BULLET_POINTS[2][1] - TEXT_START[1] + TEXT_BETWEEN_GAP],
              TEXT_2_3_1_SIZE,
              0,
              "Family traditions carried through centuries. We are happy to share the best food with you, cooked by the recipes of our italian grannies.",
              "Open Sans",
              12,
              "#000000",
              AlignStyle.Start,
              AlignStyle.Start
            ),
            childIndex: 5,
            isTemplate: false,
            isLocked: false,
          },
        ]
      );
    }

    if (documentFormat === DocumentFormats.TEARAWAY) {
      shapesTmp.push(
        [TemplateIdsEnum.Front, getPageShape(TemplateIdsEnum.Front, [0, 0], [CANVAS_WIDTH, CANVAS_HEIGHT])],
        [
          TemplateIdsEnum.Back,
          getPageShape(TemplateIdsEnum.Back, [CANVAS_WIDTH + GAP_BETWEEN_PAGES, 0], [CANVAS_WIDTH, CANVAS_HEIGHT]),
        ],
        [
          StaticAssetsIdsEnum.TearawayCutLineFront,
          getImageShape(
            StaticAssetsIdsEnum.TearawayCutLineFront,
            [bleedSize, bleedSize],
            [CANVAS_WIDTH - 2 * bleedSize, CANVAS_HEIGHT - 2 * bleedSize],
            0,
            TearawayAssetsIdsEnum.TearawayCutLineSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.TearawayCutLineBack,
          getImageShape(
            StaticAssetsIdsEnum.TearawayCutLineBack,
            [CANVAS_WIDTH + GAP_BETWEEN_PAGES + bleedSize, bleedSize],
            [CANVAS_WIDTH - 2 * bleedSize, CANVAS_HEIGHT - 2 * bleedSize],
            0,
            TearawayAssetsIdsEnum.TearawayCutLineSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.TearawayFlyerBuilderCutlineFront,
          getImageShape(
            StaticAssetsIdsEnum.TearawayFlyerBuilderCutlineFront,
            [0, 368],
            [CANVAS_WIDTH, 1],
            0,
            TearawayAssetsIdsEnum.TearawayFlyerBuilderCutLineSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.TearawayFlyerBuilderCutlineBack,
          getImageShape(
            StaticAssetsIdsEnum.TearawayFlyerBuilderCutlineBack,
            [CANVAS_WIDTH + GAP_BETWEEN_PAGES, 368],
            [CANVAS_WIDTH, 1],
            0,
            TearawayAssetsIdsEnum.TearawayFlyerBuilderCutLineSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.TearawaySafetyLineFront,
          getImageShape(
            StaticAssetsIdsEnum.TearawaySafetyLineFront,
            [bleedSize + safeSize, bleedSize + safeSize],
            [CANVAS_WIDTH - 2 * (bleedSize + safeSize), CANVAS_HEIGHT - 2 * (bleedSize + safeSize)],
            0,
            TearawayAssetsIdsEnum.TearawaySafetyLineSrc
          ),
        ],
        [
          StaticAssetsIdsEnum.TearawaySafetyLineBack,
          getImageShape(
            StaticAssetsIdsEnum.TearawaySafetyLineBack,
            [CANVAS_WIDTH + GAP_BETWEEN_PAGES + bleedSize + safeSize, bleedSize + safeSize],
            [CANVAS_WIDTH - 2 * (bleedSize + safeSize), CANVAS_HEIGHT - 2 * (bleedSize + safeSize)],
            0,
            TearawayAssetsIdsEnum.TearawaySafetyLineSrc
          ),
        ]
      );
    }

    shapesTmp.push(
      [
        PageSizeIdsEnum.PageHeight,
        getArrowShape(
          PageSizeIdsEnum.PageHeight,
          [-INTERVAL_SIZE, bleedSize],
          [0, CANVAS_HEIGHT - 2 * bleedSize],
          [0, (CANVAS_HEIGHT - 2 * bleedSize) / 2],
          true,
          true
        ),
      ],
      [
        PageSizeIdsEnum.PageWidth,
        getArrowShape(
          PageSizeIdsEnum.PageWidth,
          [bleedSize, CANVAS_HEIGHT - bleedSize + INTERVAL_SIZE],
          [CANVAS_WIDTH - 2 * bleedSize, 0],
          [(CANVAS_WIDTH - 2 * bleedSize) / 2, 0],
          true,
          true
        ),
      ],
      [
        PageSizeIdsEnum.PageHeightText,
        getTextShape(
          PageSizeIdsEnum.PageHeightText,
          [-(CANVAS_HEIGHT + 3 * INTERVAL_SIZE) / 2, CANVAS_HEIGHT / 2 - INTERVAL_SIZE],
          [CANVAS_HEIGHT, 30],
          (Math.PI * 3) / 2,
          getFlyerHeightDisplay(FORMATS[documentFormat].heightInMm, isInInch)
        ),
      ],
      [
        PageSizeIdsEnum.PageWidthText,
        getTextShape(
          PageSizeIdsEnum.PageWidthText,
          [0, CANVAS_HEIGHT + INTERVAL_SIZE - 10],
          [CANVAS_WIDTH, 30],
          0,
          getFlyerWidthDisplay(FORMATS[documentFormat].widthInMm, isInInch)
        ),
      ],
      [
        PageSideIdsEnum.PageFront,
        getTextShape(
          PageSideIdsEnum.PageFront,
          [0, -50],
          [CANVAS_WIDTH, 30],
          0,
          "Front",
          "Proxima Nova",
          14,
          "#798498"
        ),
      ],
      [
        PageSideIdsEnum.PageBack,
        getTextShape(
          PageSideIdsEnum.PageBack,
          [CANVAS_WIDTH + GAP_BETWEEN_PAGES, -50],
          [CANVAS_WIDTH, 30],
          0,
          "Back",
          "Proxima Nova",
          14,
          "#798498"
        ),
      ]
    );

    const delta = 0;

    shapesTmp.push(
      [
        StaticAssetsIdsEnum.TopRectangle,
        getDoorHangerRectangleShape(
          StaticAssetsIdsEnum.TopRectangle,
          [-INF_VAL, -INF_VAL],
          [2 * INF_VAL + 2 * CANVAS_WIDTH + GAP_BETWEEN_PAGES, INF_VAL + delta]
        ),
      ],
      [
        StaticAssetsIdsEnum.BottomRectangle,
        getDoorHangerRectangleShape(
          StaticAssetsIdsEnum.BottomRectangle,
          [-INF_VAL, CANVAS_HEIGHT - delta],
          [2 * INF_VAL + 2 * CANVAS_WIDTH + GAP_BETWEEN_PAGES, INF_VAL]
        ),
      ],
      [
        StaticAssetsIdsEnum.LeftRectangle,
        getDoorHangerRectangleShape(
          StaticAssetsIdsEnum.LeftRectangle,
          [-INF_VAL, -INF_VAL],
          [INF_VAL + delta, CANVAS_HEIGHT + 2 * INF_VAL]
        ),
      ],
      [
        StaticAssetsIdsEnum.RightRectangle,
        getDoorHangerRectangleShape(
          StaticAssetsIdsEnum.RightRectangle,
          [2 * CANVAS_WIDTH + GAP_BETWEEN_PAGES - delta, -INF_VAL],
          [INF_VAL, CANVAS_HEIGHT + 2 * INF_VAL]
        ),
      ],
      [
        StaticAssetsIdsEnum.MiddleRectangle,
        getDoorHangerRectangleShape(
          StaticAssetsIdsEnum.MiddleRectangle,
          [CANVAS_WIDTH - delta, -INF_VAL],
          [GAP_BETWEEN_PAGES + 2 * delta, CANVAS_HEIGHT + 2 * INF_VAL]
        ),
      ]
    );

    return shapesTmp;
  };

  const getDefaultAssets = (documentFormat: PossibleFlyerType): [string, TDAsset][] => {
    const liveAssetsTmp: [string, TDAsset][] = [
      [
        DoorHangerAssetsIdsEnum.QrCode,
        {
          id: DoorHangerAssetsIdsEnum.QrCode,
          src: qrCodeSrc,
          size: [1000, 1000],
          type: TDAssetType.Image,
        },
      ],
    ];

    if (documentFormat === DocumentFormats.A6) {
      liveAssetsTmp.push([
        A6AssetsIdsEnum.A6SafetyLineSrc,
        {
          id: A6AssetsIdsEnum.A6SafetyLineSrc,
          src: a6SafetyLineSrc,
          size: [100, 100],
          type: TDAssetType.Image,
        },
      ]);
    }

    if (documentFormat === DocumentFormats.DOOR_HANGER) {
      liveAssetsTmp.push(
        [
          DoorHangerAssetsIdsEnum.DoorHangerTriangleFrontSrc,
          {
            id: DoorHangerAssetsIdsEnum.DoorHangerTriangleFrontSrc,
            src: doorHangerTriangleFrontSrc,
            size: [180, 72],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.DoorHangerCircleFrontSrc,
          {
            id: DoorHangerAssetsIdsEnum.DoorHangerCircleFrontSrc,
            src: doorHangerCircleFrontSrc,
            size: [180, 72],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.DoorHangerTriangleBackSrc,
          {
            id: DoorHangerAssetsIdsEnum.DoorHangerTriangleBackSrc,
            src: doorHangerTriangleBackSrc,
            size: [180, 72],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.DoorHangerCircleBackSrc,
          {
            id: DoorHangerAssetsIdsEnum.DoorHangerCircleBackSrc,
            src: doorHangerCircleBackSrc,
            size: [180, 72],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.DiecutLineFrontSrc,
          {
            id: DoorHangerAssetsIdsEnum.DiecutLineFrontSrc,
            src: diecutLineFrontSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.DiecutLineBackSrc,
          {
            id: DoorHangerAssetsIdsEnum.DiecutLineBackSrc,
            src: diecutLineBackSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.CutLineFrontSrc,
          {
            id: DoorHangerAssetsIdsEnum.CutLineFrontSrc,
            src: cutLineFrontSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.CutLineBackSrc,
          {
            id: DoorHangerAssetsIdsEnum.CutLineBackSrc,
            src: cutLineBackSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.SafetyLineFrontSrc,
          {
            id: DoorHangerAssetsIdsEnum.SafetyLineFrontSrc,
            src: safetyLineFrontSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          DoorHangerAssetsIdsEnum.SafetyLineBackSrc,
          {
            id: DoorHangerAssetsIdsEnum.SafetyLineBackSrc,
            src: safetyLineBackSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ]
      );
    }

    if (
      documentFormat === DocumentFormats.A6 ||
      documentFormat === DocumentFormats.DOOR_HANGER ||
      documentFormat === DocumentFormats.TEARAWAY
    ) {
      liveAssetsTmp.push(
        [
          TemplateAssetsIdsEnum.TemplateBackgroundSrc,
          {
            id: TemplateAssetsIdsEnum.TemplateBackgroundSrc,
            src: templateBackgroundSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          TemplateAssetsIdsEnum.TemplateLogoSrc,
          {
            id: TemplateAssetsIdsEnum.TemplateLogoSrc,
            src: templateLogoSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ]
      );
    }

    if (documentFormat === DocumentFormats.TEARAWAY) {
      liveAssetsTmp.push(
        [
          TearawayAssetsIdsEnum.TearawayCutLineSrc,
          {
            id: TearawayAssetsIdsEnum.TearawayCutLineSrc,
            src: tearawayCutLineSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          TearawayAssetsIdsEnum.TearawaySafetyLineSrc,
          {
            id: TearawayAssetsIdsEnum.TearawaySafetyLineSrc,
            src: tearawaySafetyLineSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ],
        [
          TearawayAssetsIdsEnum.TearawayFlyerBuilderCutLineSrc,
          {
            id: TearawayAssetsIdsEnum.TearawayFlyerBuilderCutLineSrc,
            src: tearawayFlyerBuilderCutLineSrc,
            size: [100, 100],
            type: TDAssetType.Image,
          },
        ]
      );
    }

    return liveAssetsTmp;
  };
  // Document Changes --------

  React.useEffect(() => {
    if (!app || !liveStorage) {
      return;
    }

    // Setup the document's storage and subscriptions
    async function setupDocument() {
      if (!liveStorage) {
        return;
      }

      if (isSyncServer) {
        /**
         * we need to sync with the server states
         */
        await liveStorage.setSyncServer();
      }

      let lShapes = liveStorage.getLiveShapes();
      let lAssets = liveStorage.getLiveAssets();
      let lBindings = liveStorage.getLiveBindings();
      const lDocumentSettings = liveStorage.getLiveDocumentSettings();
      const storageFormat = lDocumentSettings.format;

      rLiveDocumentSettings.current = lDocumentSettings;

      if (!lShapes) {
        const shapesTmp = getDefaultShapes(storageFormat);
        liveStorage.setLiveShapes(shapesTmp);
        lShapes = liveStorage.getLiveShapes();
      }
      rLiveShapes.current = lShapes;

      if (!lAssets) {
        const liveAssetsTmp = getDefaultAssets(storageFormat);
        liveStorage.setLiveAssets(liveAssetsTmp);
        lAssets = liveStorage.getLiveAssets();
      }
      rLiveAssets.current = lAssets;

      if (!lBindings) {
        liveStorage.setLiveBindings([]);
        lBindings = liveStorage.getLiveBindings();
      }
      rLiveBindings.current = lBindings;

      liveStorage.setVersion(2);

      if (isSyncServer) {
        await liveStorage.updateServer();
      }

      const handleChanges = () => {
        if (!app || !lShapes || !lBindings || !lAssets) {
          return;
        }

        app.replacePageContent(
          Object.fromEntries(lShapes.entries()),
          Object.fromEntries(lBindings.entries()),
          Object.fromEntries(lAssets.entries()),
          lDocumentSettings
        );
      };

      handleChanges();
      setLoading(false);
    }

    setupDocument();
  }, [app, liveStorage, isSyncServer]);

  React.useEffect(() => {
    setLiveStorage(new LiveStorage({ roomId, flyerType }));
  }, []);

  return {
    onUndo,
    onRedo,
    onMount,
    onChangePage: debouncedOnChange,
    onChangePresence,
    onChangeDocumentFormat,
    saveFlyerBuilderStates,
    loading,
    isChangedFBStates,
  };
}
