const style = {
  logoWrap: {
    margin: "auto",
    position: "relative",
    width: "150px",
    height: "130px",
  },
};

export default style;
