export default function ArrowIcon({ className }) {
  return (
    <svg
      className={className}
      width="10px"
      height="10px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 10 10"
    >
      <path d="M9.72 4.96c-.08.02-.17.05-.25.05-.32.02-.56-.22-.56-.56 0-.81 0-1.61.01-2.42 0-.05.01-.11.01-.16-.01 0-.02-.01-.03-.01l-.1.1c-2.6 2.6-5.2 5.19-7.79 7.79-.23.23-.46.32-.76.19l-.19-.19v-.04c-.11-.27-.07-.45.13-.65C2.8 6.45 5.41 3.83 8.03 1.22c.03-.04.07-.07.13-.14H8c-.82 0-1.64.01-2.46.01-.44 0-.7-.43-.49-.8.11-.19.28-.27.5-.27C6.28.02 7 .01 7.73.01H9.4c.38-.01.6.21.6.59 0 1.27-.01 2.55-.02 3.82 0 .11-.04.22-.05.33l-.21.21z" />
    </svg>
  );
}
