import { memo, useCallback, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { default as MUAutocomplete } from "@material-ui/lab/Autocomplete";

import style from "./style.js";

const Autocomplete = (props) => {
  const {
    value,
    label,
    getOptionLabel,
    getOptionSelected,
    getOptionDisabled,
    renderOption,
    loading,
    onOpen,
    options = [],
    onChange,
    disabled,
    hideLabel,
    classes = {},
  } = props;
  const [isFocus, setIsFocus] = useState(false);

  const handleChange = useCallback(
    (e, newValue) => {
      onChange && onChange(newValue);
    },
    [onChange]
  );

  const handleFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocus(false);
  }, []);
  return (
    <MUAutocomplete
      fullWidth
      color="secondary"
      size="small"
      value={value}
      onOpen={onOpen}
      options={options}
      classes={classes}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      renderOption={renderOption}
      loading={loading}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!value && !isFocus && hideLabel ? label : ""}
          color="secondary"
          variant="outlined"
        />
      )}
    />
  );
};

export default memo(withStyles(style)(Autocomplete));
