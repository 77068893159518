import * as React from "react";

export function VerticalAlignTopIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={20} height={20} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.2 7.7a.28.28 0 0 1-.27.3.27.27 0 0 1-.27-.27V3l-.89.89a.27.27 0 0 1-.39 0 .28.28 0 0 1 0-.38l1.35-1.4a.3.3 0 0 1 .2-.11.27.27 0 0 1 .19.08l1.35 1.38a.28.28 0 0 1 0 .38.26.26 0 0 1-.38 0L2.2 3Zm3.1-5.06a.34.34 0 0 0 0 .67h4.06a.34.34 0 1 0 0-.67Zm0 2a.34.34 0 0 0 0 .68h4.06a.34.34 0 0 0 0-.68ZM5 7a.34.34 0 0 1 .33-.34h4.03a.34.34 0 1 1 0 .67H5.3A.33.33 0 0 1 5 7Z"
        style={{
          fill: "#010101",
          fillRule: "evenodd",
        }}
      />
    </svg>
  );
}
