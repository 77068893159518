import { Content } from "@radix-ui/react-dropdown-menu";
import { MenuContent } from "pages/flyer/builder/drawer/components/Primitives/MenuContent";
import { stopPropagation } from "pages/flyer/builder/drawer/components/stopPropagation";
import { styled } from "pages/flyer/builder/drawer/styles/stitches.config";
import * as React from "react";

export interface DMContentProps {
  variant?: "menu" | "horizontal" | "styleMenu";
  align?: "start" | "center" | "end";
  sideOffset?: number;
  alignOffset?: number;
  children: React.ReactNode;
}

export function DMContent({ sideOffset = 8, children, align, alignOffset, variant }: DMContentProps): JSX.Element {
  return (
    <StyledWrapper
      dir="ltr"
      align={align}
      sideOffset={sideOffset}
      alignOffset={alignOffset}
      onEscapeKeyDown={stopPropagation}
      asChild
      portalled={false}
    >
      <StyledContent variant={variant}>{children}</StyledContent>
    </StyledWrapper>
  );
}

export const StyledContent = styled(MenuContent, {
  width: "250px",
  height: "calc(100vh - 80px)",
  minWidth: 0,
  borderRadius: 0,
  variants: {
    variant: {
      horizontal: {
        flexDirection: "row",
      },
      menu: {
        minWidth: 128,
      },
      styleMenu: {
        padding: 16,
      },
    },
  },
});

export const StyledWrapper = styled(Content, {
  transform: "none",
});
