import CONFIG from "../../config/config";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const searchFeaturesPFGQL = (variables) => {
  const gql = `
    query(
      $area: GeoJSONGeometryInput
      $layer: LayerEnum
      $countryCode: String!
      $includeAddressCount: Boolean
      $after: String
      $excludeGeometry: GeoJSONGeometryInput
      $centerSphere: [Float]
      $radius: Float
      ){ searchFeatures(
          layer: $layer 
          countryCode: $countryCode
          area: $area 
          excludeGeometry: $excludeGeometry
          includeAddressCount: $includeAddressCount
          after: $after
          centerSphere: $centerSphere
          radius: $radius
      ) {
          totalCount
          pageInfo {endCursor hasNextPage}
          collection {
              type
              features {
                  type
                  properties
                  geometry {
                      type
                      coordinates
                  }
              }
          }
      }
    }
  `;
  return callAuthorizedGraphQLEndpoint(gql, variables, CONFIG.PATHFINDER_API_BASE_URL);
};

export const getCarrierRoutesByCity = (variables) => {
  const gql = `
    query(
      $cityId: ID!
      $cityName: String!
      $iterationCount: Int
      $after: String
      ){ getCarrierRoutesByCity(
          cityId: $cityId 
          cityName: $cityName
          after: $after
          iterationCount: $iterationCount
      ) {
          totalCount
          pageInfo {endCursor hasNextPage}
          cache
          collection {
              type
              features {
                  type
                  properties
                  geometry {
                      type
                      coordinates
                  }
              }
          }
      }
    }
  `;
  return callAuthorizedGraphQLEndpoint(gql, variables, CONFIG.PATHFINDER_API_BASE_URL);
};
