import { useContext, useEffect } from "react";
import cn from "classnames";
import { useStore } from "store";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { hideEmail } from "utils";
import { If } from "components/logic";
import { SnackBarContext } from "components/snack-bar";
import { sendClientVerificationByEmail, isClientEmailVerified } from "../../../../graphQL";
import { StoreType } from "../../../../store/types";
import ResendEmailTimer from "./ResendEmailTimer";

const useStyles = makeStyles(() => ({
  link: {
    fontSize: "16px",
    color: "#0C172C",
    fontFamily: "Proxima Nova, sans-serif",
    textAlign: "center",
  },
  text: {
    fontSize: "14px",
  },
  action: {
    textAlign: "center",
    marginRight: "45px",
  },
  alert: {
    "& .MuiAlert-message": {
      width: "100% !important",
      justifyContent: "space-between",
    },
    "& .MuiAlert-icon": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxHeight: "55px",
    },
  },
  container: {
    justifyContent: "space-between",
  },
}));

export const VerificationAlert = () => {
  const runSnackBar = useContext(SnackBarContext);
  const {
    user: { email, isVerified },
    updateUser,
  }: StoreType = useStore();
  const notVerified = isVerified === false;
  const classes = useStyles();

  const sendVerificationEmail = async (e: any) => {
    if (e) e.preventDefault();
    try {
      const response = await sendClientVerificationByEmail();
      const {
        data: { sendClientVerificationEmail },
      } = await (response as Response).json();
      if (sendClientVerificationEmail.success) {
        runSnackBar({
          type: "success",
          vertical: "top",
          msg: "Email sent successfully. Please check your mailbox.",
        });
        return true;
      } else if (sendClientVerificationEmail.error === "for the moment you are limited to this action") {
        runSnackBar({
          type: "error",
          vertical: "top",
          msg: "Wait at least 2 minutes. You have already sent a request",
        });
        return false;
      } else {
        runSnackBar({
          type: "error",
          vertical: "top",
          msg: "Email could not be sent. Please try again later.",
        });
        return false;
      }
    } catch (e) {
      console.warn("Error:", e);
    }
  };

  useEffect(() => {
    if (isVerified) return;
    const getUserEmailStatus = async () => {
      try {
        const response = await isClientEmailVerified();
        const {
          data: { getClientEmailVerified },
        } = await (response as Response).json();
        if (getClientEmailVerified.success) {
          if (isVerified === false) {
            runSnackBar({
              type: "success",
              vertical: "top",
              msg: "Email verified successfully. You can now save your campaign.",
            });
          }
          updateUser({ isVerified: true });
          clearInterval(isClientVerifiedInterval);
          return true;
        } else {
          updateUser({ isVerified: false });
        }
      } catch (e) {
        console.warn("Error:", e);
      }
    };
    const isClientVerifiedInterval = setInterval(async () => getUserEmailStatus(), 10000);
    getUserEmailStatus();
    return () => {
      clearInterval(isClientVerifiedInterval);
    };
  }, [isVerified]);

  return (
    <If condition={notVerified}>
      <Alert severity="error" className={cn(classes.alert)}>
        <Grid container xl={12} className={cn(classes.container)}>
          <Grid item xs={12} sm={"auto"} className={cn(classes.text)}>
            Please check your mailbox {hideEmail(email)} (including spam) and validate your email to save your campaign.{" "}
          </Grid>
          <Grid item xs={12} sm={"auto"} className={classes.action}>
            <ResendEmailTimer sendVerificationEmail={sendVerificationEmail} />
          </Grid>
        </Grid>
      </Alert>
    </If>
  );
};
