import {
  FORMATS,
  GAP_BETWEEN_PAGES,
  BLEED_AREA_SIZE,
  SAFE_AREA_SIZE,
  INTERVAL_SIZE,
} from "pages/flyer/builder/constants";
import { TemplateIds, CutMarkIds } from "pages/flyer/builder/drawer/constants/constants";
import {
  DocumentOrientation,
  TemplateConfig,
  TldrawCommand,
  TemplateIdsEnum,
  CutMarkIdsEnum,
  PageSizeIdsEnum,
  PageSideIdsEnum,
  StaticAssetsIdsEnum,
  TDShapeType,
} from "../../../types";
import {
  calculateCutMarkPosition,
  calculateOutsideRectanglePosition,
  calculateQRCodePosition,
  getArrowShape,
} from "../../../utils";
import type { TldrawApp } from "../../internal";
import { TLDR } from "../../TLDR";

const convertObject = <T>(obj: Record<string, T>): Record<string, undefined> =>
  Object.keys(obj).reduce(
    (acc, id) => ({
      ...acc,
      [id]: undefined,
    }),
    {}
  );

export function changeTemplate(app: TldrawApp, templateInfo: Partial<TemplateConfig>): TldrawCommand | void {
  const { currentPageId, document } = app;

  const oldFormat = document.format;
  const oldOrientation = document.orientation;

  const newFormat = templateInfo.format || document.format;
  const newOrientation = templateInfo.orientation || document.orientation;

  let backPosition = [0, 0];
  let a6SatetyLineBackPosition = [0, 0];
  let rotation = 0;

  const bleedSize = BLEED_AREA_SIZE[newFormat];
  const safeSize = SAFE_AREA_SIZE[newFormat];

  const beforeAssets = app.document.assets;
  const beforeShapes = app.document.pages[currentPageId].shapes;
  const beforeBindings = app.document.pages[currentPageId].bindings;

  if (newFormat !== oldFormat) {
    const { newAssets, newShapes } = app.callbacks.onChangeDocumentFormat?.(newFormat) || {
      newAssets: {},
      newShapes: {},
    };

    const afterShapes = { ...convertObject(beforeShapes), ...newShapes };
    const afterAssets = { ...convertObject(beforeAssets), ...newAssets };
    const afterBindings = { ...convertObject(beforeBindings) };

    return {
      id: "changeFormat",
      before: {
        document: {
          assets: { ...convertObject(newAssets), ...beforeAssets },
          format: oldFormat,
          orientation: oldOrientation,
          pages: {
            [currentPageId]: {
              shapes: { ...convertObject(newShapes), ...beforeShapes },
              bindings: beforeBindings,
            },
          },
          pageStates: {
            [currentPageId]: {
              selectedIds: app.document.pageStates[currentPageId].selectedIds,
            },
          },
        },
      },
      after: {
        document: {
          assets: afterAssets,
          format: newFormat,
          orientation: DocumentOrientation.Portrait,
          pages: {
            [currentPageId]: {
              shapes: afterShapes,
              bindings: afterBindings,
            },
          },
          pageStates: {
            [currentPageId]: {
              selectedIds: [],
            },
          },
        },
      },
    };
  }

  const CANVAS_WIDTH = FORMATS[newFormat].width;
  const CANVAS_HEIGHT = FORMATS[newFormat].height;

  const shapes = app.getShapes(currentPageId);

  const afterAssets = { ...beforeAssets };

  const staticAssetsIds = [
    StaticAssetsIdsEnum.TopRectangle,
    StaticAssetsIdsEnum.BottomRectangle,
    StaticAssetsIdsEnum.LeftRectangle,
    StaticAssetsIdsEnum.RightRectangle,
    StaticAssetsIdsEnum.MiddleRectangle,
  ] as string[];

  const a6SafetyLineIds = [StaticAssetsIdsEnum.A6SafetyLineFront, StaticAssetsIdsEnum.A6SafetyLineBack];

  const pageSizeIds = [
    PageSizeIdsEnum.PageHeight,
    PageSizeIdsEnum.PageWidth,
    PageSizeIdsEnum.PageHeightText,
    PageSizeIdsEnum.PageWidthText,
    PageSideIdsEnum.PageFront,
    PageSideIdsEnum.PageBack,
  ];

  const allTemplatesIds = [...TemplateIds, ...CutMarkIds, ...staticAssetsIds, ...a6SafetyLineIds, ...pageSizeIds];

  const templateShapesIds = shapes
    .filter((shape) => {
      if (allTemplatesIds.includes(shape.id as any) || shape.type === TDShapeType.QRCode) {
        return true;
      }
      return false;
    })
    .map((shape) => {
      return shape.id;
    });

  if (newOrientation === DocumentOrientation.Portrait) {
    backPosition = [CANVAS_WIDTH + GAP_BETWEEN_PAGES, 0];
    a6SatetyLineBackPosition = [CANVAS_WIDTH + GAP_BETWEEN_PAGES + bleedSize + safeSize, bleedSize + safeSize];
    rotation = 0;
  }

  if (newOrientation === DocumentOrientation.Landscape) {
    backPosition = [CANVAS_HEIGHT + GAP_BETWEEN_PAGES, 0];
    a6SatetyLineBackPosition = [CANVAS_HEIGHT + GAP_BETWEEN_PAGES + bleedSize + safeSize, bleedSize + safeSize];
    rotation = Math.PI / 2;
  }

  let pageHeight = getArrowShape(
    PageSizeIdsEnum.PageHeight,
    [-INTERVAL_SIZE, bleedSize],
    [0, CANVAS_HEIGHT - 2 * bleedSize],
    [0, (CANVAS_HEIGHT - 2 * bleedSize) / 2],
    true,
    true
  );

  let pageWidth = getArrowShape(
    PageSizeIdsEnum.PageWidth,
    [bleedSize, CANVAS_HEIGHT - bleedSize + INTERVAL_SIZE],
    [CANVAS_WIDTH - 2 * bleedSize, 0],
    [(CANVAS_WIDTH - 2 * bleedSize) / 2, 0],
    true,
    true
  );

  let pageHeightText = {
    point: [-(CANVAS_HEIGHT + 3 * INTERVAL_SIZE) / 2, CANVAS_HEIGHT / 2 - INTERVAL_SIZE],
    rotation: (Math.PI * 3) / 2,
  };

  let pageWidthText = {
    point: [0, CANVAS_HEIGHT + INTERVAL_SIZE - 10],
    rotation: 0,
  };

  let pageFront = {
    point: [0, -50],
  };

  let pageBack = {
    point: [CANVAS_WIDTH + GAP_BETWEEN_PAGES, -50],
  };

  if (newOrientation === DocumentOrientation.Landscape) {
    pageHeight = getArrowShape(
      PageSizeIdsEnum.PageHeight,
      [
        -(CANVAS_HEIGHT - CANVAS_WIDTH) / 2 + bleedSize,
        CANVAS_HEIGHT - bleedSize + INTERVAL_SIZE - (CANVAS_HEIGHT - CANVAS_WIDTH) / 2,
      ],
      [CANVAS_HEIGHT - 2 * bleedSize, 0],
      [(CANVAS_HEIGHT - 2 * bleedSize) / 2, 0],
      true,
      true
    );
    pageWidth = getArrowShape(
      PageSizeIdsEnum.PageWidth,
      [-(CANVAS_HEIGHT - CANVAS_WIDTH) / 2 - INTERVAL_SIZE, (CANVAS_HEIGHT - CANVAS_WIDTH) / 2 + bleedSize],
      [0, CANVAS_WIDTH - 2 * bleedSize],
      [0, (CANVAS_WIDTH - 2 * bleedSize) / 2],
      true,
      true
    );
    pageHeightText = {
      point: [
        -(CANVAS_HEIGHT - CANVAS_WIDTH) / 2,
        CANVAS_HEIGHT + INTERVAL_SIZE - 10 - (CANVAS_HEIGHT - CANVAS_WIDTH) / 2,
      ],
      rotation: 0,
    };
    pageWidthText = {
      point: [-(CANVAS_HEIGHT + 3 * INTERVAL_SIZE) / 2, CANVAS_HEIGHT / 2 - INTERVAL_SIZE / 2],
      rotation: (Math.PI * 3) / 2,
    };
    pageFront = {
      point: [0, -50 + (CANVAS_HEIGHT - CANVAS_WIDTH) / 2],
    };
    pageBack = {
      point: [CANVAS_HEIGHT + GAP_BETWEEN_PAGES, -50 + (CANVAS_HEIGHT - CANVAS_WIDTH) / 2],
    };
  }

  const change = TLDR.mutateShapes(
    app.state,
    templateShapesIds,
    (shape) => {
      if (shape.id === TemplateIdsEnum.Front) {
        return {
          point: [0, 0],
          rotation: rotation,
          size: [CANVAS_WIDTH, CANVAS_HEIGHT],
        };
      }

      if (shape.id === TemplateIdsEnum.Back) {
        return {
          point: backPosition,
          rotation: rotation,
          size: [CANVAS_WIDTH, CANVAS_HEIGHT],
        };
      }

      if (shape.id === StaticAssetsIdsEnum.A6SafetyLineFront) {
        return {
          point: [bleedSize + safeSize, bleedSize + safeSize],
          rotation: rotation,
          size: [CANVAS_WIDTH - 2 * (bleedSize + safeSize), CANVAS_HEIGHT - 2 * (bleedSize + safeSize)],
        };
      }

      if (shape.id === StaticAssetsIdsEnum.A6SafetyLineBack) {
        return {
          point: a6SatetyLineBackPosition,
          rotation: rotation,
          size: [CANVAS_WIDTH - 2 * (bleedSize + safeSize), CANVAS_HEIGHT - 2 * (bleedSize + safeSize)],
        };
      }

      if (shape.id === PageSizeIdsEnum.PageHeight) {
        return { ...pageHeight };
      }

      if (shape.id === PageSizeIdsEnum.PageWidth) {
        return { ...pageWidth };
      }

      if (shape.id === PageSizeIdsEnum.PageHeightText) {
        return { ...pageHeightText };
      }

      if (shape.id === PageSizeIdsEnum.PageWidthText) {
        return { ...pageWidthText };
      }

      if (shape.id === PageSideIdsEnum.PageFront) {
        return { ...pageFront };
      }

      if (shape.id === PageSideIdsEnum.PageBack) {
        return { ...pageBack };
      }

      if ((Object.values(CutMarkIdsEnum) as string[]).includes(shape.id)) {
        return {
          point: calculateCutMarkPosition({
            format: newFormat,
            orientation: newOrientation,
            cutMarkId: shape.id as CutMarkIdsEnum,
          }),
        };
      }

      if (staticAssetsIds.includes(shape.id)) {
        return {
          point: calculateOutsideRectanglePosition({
            format: newFormat,
            orientation: newOrientation,
            rectangleId: shape.id as StaticAssetsIdsEnum,
          }),
        };
      }

      if (oldOrientation !== newOrientation && shape.type === TDShapeType.QRCode) {
        return {
          point: calculateQRCodePosition({
            format: newFormat,
            orientation: oldOrientation,
            originalPoint: [...shape.point],
            size: [...shape.size],
          }),
        };
      }
    },
    currentPageId
  );

  return {
    id: "rotateTemplate",
    before: {
      document: {
        assets: beforeAssets,
        format: oldFormat,
        orientation: oldOrientation,
        pages: {
          [currentPageId]: {
            shapes: change.before,
          },
        },
      },
    },
    after: {
      document: {
        assets: afterAssets,
        format: newFormat,
        orientation: newOrientation,
        pages: {
          [currentPageId]: {
            shapes: change.after,
          },
        },
      },
    },
  };
}
