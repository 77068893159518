import React, { useEffect, useState } from "react";
import { WithStyles, withStyles, MenuProps } from "@material-ui/core";
import cn from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import style from "./style";

type OptionListType<T> = {
  value: T;
  label: string;
};

interface ICustomSelectProps<T> extends WithStyles<typeof style> {
  selectedValue: T;
  options: OptionListType<T>[];
  disabled?: boolean;
  onChange: (val: T) => void;
  className?: string;
}

const CustomSelect = <T extends string | number | undefined>({
  classes,
  selectedValue,
  options,
  disabled = false,
  onChange,
  className = "",
}: ICustomSelectProps<T>) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize(); // Check on initial load
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const path = window.location.pathname;
  const shouldAddMobileClass = path.includes("eddm") && path.includes("segments") && isMobile;

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    onChange(event.target.value as T);
  };

  const iconComponent: React.ElementType = (props) => (
    <ExpandMoreIcon className={cn(props.className, classes.icon, { "mob-icon": shouldAddMobileClass })} />
  );

  const menuProps: Partial<MenuProps> = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl>
      <Select
        disableUnderline
        classes={{ root: cn(classes.select, { [className]: !!className }) }}
        MenuProps={menuProps}
        IconComponent={iconComponent}
        value={selectedValue}
        onChange={handleChange}
        disabled={disabled}
      >
        {options.map(({ value, label }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(style)(CustomSelect);
