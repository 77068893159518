import { useState, useEffect } from "react";
import { SegmentsV1 } from "./SegmentsV1";
import { SegmentsV2 } from "./SegmentsV2";

export const Segments = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile ? <SegmentsV1 {...props} /> : <SegmentsV2 {...props} />;
};
