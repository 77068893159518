import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { MAP_TYPES } from "pages/constants";
import { debounce } from "shared/libraries/utils/func";
// import { LOCATION_STATUS } from "shared/constants";
import { styles } from "./styles.js";
import { MAP_EXTRA_STYLES } from "../../options";
import { skipCountWorkingDays } from "../../../../utils/date";
import { updateSelectedCitiesLocations } from "../../../audience-form/components/campaign-location/utils";
import {
  getOptions,
  typeMarker,
  createFigure,
  removeEditMode,
  handleMapItemClick,
  onMapInit,
  renderDistributionZones,
  isPositionInsideDistributionZone,
  handleScheduleMeeting,
  fetchAndUpdatePostCodes,
  getZone,
} from "../../utils";
import PreciseLocationUnselected from "../../../../static/media/precise-location-unselected.svg";
import SelfServePreciseLocationSelectedFocused from "../../../../static/media/precise-location-ss-selected-focused.svg";
import SelfServeLocationUnselected from "../../../../static/media/precise-location-ss-unselected.svg";
import { MapHeader } from "../MapHeader";
import { AddCreateLocationPopUp } from "../AddCreateLocationPopUp";
import { ServiceAreaLimitationPopUp } from "../ServiceAreaLimitationPopUp";
import { useStore } from "../../../../store";
import { getCityLocations } from "../../../../utils/getCityLocations";
import useCalculating from "../../../../Hooks/useCalculating";
import { RESTRICTED_H2H_CREATE_LOCATION_CITIES } from "../../../../constants";
import useOverlaysFetch from "../../../../Hooks/useOverlaysFetch";

const useStyles = makeStyles(() => styles);

const CLICKED_STATE = {
  CANCELED: "canceled",
  CLICKED: "single clicked",
  DOUBLECLICKED: "double clicked",
};

// const defaultType = { value: 1, label: "Other" };

// const INCLUDED_TYPES = [
//   "Bus Station",
//   "Cinema",
//   "College / University",
//   "Hospital",
//   "Library",
//   "Market",
//   "Museum",
//   "Park",
//   "Shopping Mall",
//   "Street",
//   "Supermarket",
//   "Train Station",
//   "Venue",
//   "Other",
// ];

export const AudienceMapH2H = ({ isSubmitted }) => {
  const classes = useStyles();

  const {
    errors: { costsCalculationError },
    campaign: { id: campaignId },
    client,
    country,
    city,
    map: { center, zoom, shouldShowDefaultPin, loading = true },
    setAddresses,
    updateCity,
    updateMap,
  } = useStore();

  const { recalculateCampaignCost } = useCalculating();

  const distributionZones = city?.distributionZones;
  const activeSelectedCityDistributionZones = city?.distributionZones;
  const activeSelectedCity = city;
  const activeSelectedCityAddresses = city?.addresses;
  const selectedCitiesLocations = city?.cityLocationsInsideDZ || [];

  const isRestrictedCity = RESTRICTED_H2H_CREATE_LOCATION_CITIES.includes(city?.id ? Number(city?.id) : undefined);

  const [serviceAreaLimitationPopUpOpen, setServiceAreaLimitationPopUpOpen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [distributionZonePolygons, setDistributionZonePolygons] = useState([]);
  const [mapInit, setMapInit] = useState(null);
  // const [isNeedToUndoWithoutRedo, setIsNeedToUndoWithoutRedo] = useState(false);
  // const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);
  const [createPinState, setCreatePinState] = useState(null);
  const [candidateLocationAddress, setCandidateLocationAddress] = useState("");
  const [clickedLocation, setClickedLocation] = useState(false);

  const geocoder = useMemo(
    () => (window?.google?.maps?.Geocoder ? new window.google.maps.Geocoder() : null),
    [window.google]
  );

  const { loadData } = useOverlaysFetch({ countryCode: country.code, disabled: false });

  const clusterStylesOptions = useMemo(
    () =>
      [1, 2, 3, 4, 5].map(() => ({
        width: 40,
        height: 40,
        backgroundPosition: "7 5",
        textColor: "#FFFFFF",
        className: classes.clusters,
      })),
    [classes]
  );

  const fetchPostcodes = async () => {
    const distributionZones = getZone(city, "distribution");
    const exclusionZones = getZone(city, "exclusion");
    await fetchAndUpdatePostCodes(distributionZones, loadData, exclusionZones, updateMap);
  };

  useEffect(() => {
    if (city) {
      fetchPostcodes();
    }
  }, [city]);

  const CreateLocationPopup = useMemo(
    () =>
      createPinState ? (
        <Marker
          position={createPinState.position}
          icon={{
            url: SelfServePreciseLocationSelectedFocused,
            anchor: new window.google.maps.Point(16, 43),
          }}
        >
          {createPinState.isInside && !isRestrictedCity && candidateLocationAddress ? (
            <InfoWindow
              position={createPinState.position}
              options={{ pixelOffset: new window.google.maps.Size(0, 138) }}
            >
              <AddCreateLocationPopUp
                isAdd={false}
                addressName={candidateLocationAddress}
                onClick={() => {
                  onMarkerComplete(
                    { position: createPinState.position },
                    candidateLocationAddress,
                    candidateLocationAddress,
                    false
                  );
                }}
              />
            </InfoWindow>
          ) : null}
        </Marker>
      ) : null,
    [createPinState, candidateLocationAddress, isRestrictedCity]
  );

  // REFS
  let refMarker = useRef([]);
  let mapRef = useRef(null);
  const clickedMapRef = useRef(CLICKED_STATE.CANCELED);
  const clickedPositionRef = useRef(null);
  const openedInfoWindowRef = useRef(null);

  // Callback
  const handleCloseCreatePopup = () => {
    // clear create location popup if opened
    setCreatePinState(null);
    setCandidateLocationAddress("");
    // clear add location popup if opened
    setClickedLocation(false);
    setActiveMarker(null);
  };

  const handleClickOutsideDistributionZones = useCallback(() => {
    if (createPinState || clickedLocation) {
      handleCloseCreatePopup();
    } else {
      // we clicked outside of distribute zones and there is no opened dialog, so we should show service area limitation popup
      if (!isSubmitted) {
        setServiceAreaLimitationPopUpOpen(true);
      }
    }
  }, [createPinState, clickedLocation, handleCloseCreatePopup]);

  useEffect(() => {
    recalculateCampaignCost();
  }, [city]);

  useEffect(() => {
    if (distributionZonePolygons && distributionZonePolygons.length && city && !city.cityLocationsInsideDZ) {
      fetchPreciseLocations({
        city,
        // setCreatedLocationNames,
        campaignId,
        distributionZonePolygons,
      });
    }
  }, [distributionZonePolygons, city]);

  const fetchPreciseLocations = async ({ city, campaignId, distributionZonePolygons }) => {
    updateMap({ loading: true });
    const cityLocations = await getCityLocations({ cityId: city.id, isSelfServe: false });
    const campaignLocations = await getCityLocations({
      cityId: city.id,
      campaignId,
      isSelfServe: true,
      clientId: client.id,
    });
    // setCreatedLocationNames((prev) => [...prev, ...campaignLocations.map(({ name }) => name)]);

    const cityLocationsInsideDZ = [...cityLocations, ...campaignLocations].reduce((acc, location) => {
      distributionZonePolygons.forEach((distributionZonePolygon) => {
        const isContainsInDistributionZone = window.google.maps.geometry.poly.containsLocation(
          { lat: location.latitude, lng: location.longitude },
          distributionZonePolygon
        );
        if (isContainsInDistributionZone) {
          acc.push({ ...location, selected: false });
        }
      });
      return acc;
    }, []);

    if (cityLocationsInsideDZ) {
      updateCity({ cityLocationsInsideDZ });
    }
    updateMap({ loading: false });
  };

  useEffect(() => {
    if (activeSelectedCityAddresses.length) {
      setTimeout(() => {
        updateMap({
          center: {
            lat: activeSelectedCityAddresses[0].position?.lat || activeSelectedCity?.lat,
            lng: activeSelectedCityAddresses[0].position?.lng || activeSelectedCity?.lng,
          },
          zoom: 17,
        });
      }, 100);
    } else {
      updateMap({
        center: {
          lat: activeSelectedCity?.lat,
          lng: activeSelectedCity?.lng,
        },
        zoom: 12,
      });
    }
  }, [activeSelectedCity?.name]);

  useEffect(() => {
    if (shouldShowDefaultPin) {
      handleShowCreatePopup(center);
      updateMap({
        shouldShowDefaultPin: false,
      });
    }
  }, [shouldShowDefaultPin]);

  useEffect(() => {
    renderDistributionZones({
      mapInit,
      activeSelectedCityDistributionZones,
      distributionZones,
      setDistributionZonePolygons,
    });
  }, [mapInit, activeSelectedCityDistributionZones]);

  useEffect(() => {
    if (!mapInit) {
      return;
    }

    const listener = mapInit.data.addListener("click", handleClickOutsideDistributionZones);

    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, [mapInit, handleClickOutsideDistributionZones]);

  const handleLocationClick = (location) => {
    if (isSubmitted) {
      return;
    }
    onMarkerComplete(
      { position: { lat: location.latitude, lng: location.longitude } },
      null,
      location.name,
      !location.isSelfServe,
      location.id,
      location
    );
    updateMap({
      center: { lat: location.latitude, lng: location.longitude },
    });
    /**
     * 1. reflect the locations.selected
     * 2. if selecting current location will replace location X without any mission,
     *      then we should also change X.selected to false (absolutely X.selected = true at this point)
     */
    const firstUpdatedLocations = updateSelectedCitiesLocations({
      address: location,
      cityLocationsInsideDZ: activeSelectedCity.cityLocationsInsideDZ,
    });

    // if (!addressInEditMode) {
    updateCity({ cityLocationsInsideDZ: firstUpdatedLocations });
    // } else {
    //   const secondUpdatedLocations = updateSelectedCitiesLocations({
    //     address: addressInEditMode,
    //     cityLocationsInsideDZ: firstUpdatedLocations,
    //   });

    //   updateCity({ cityLocationsInsideDZ: secondUpdatedLocations });
    // }
  };

  const onMarkerComplete = (marker, locationAddress, name, isFixedLocation, locationId) => {
    const { position } = marker;
    const { nextDate } = skipCountWorkingDays(null, 15);
    const initDate = format(nextDate, "yyyy-MM-dd");
    // setDrawingMode(null);
    const lat = position.lat;
    const lng = position.lng;

    const initValues = {
      endDate: initDate,
    };

    const figureObj = {
      id: locationId || `temp-${lat + lng}-marker`,
      value: name,
      position: {
        lat,
        lng,
      },
      type: MAP_TYPES.MARKER,
      editMode: true,
      ...initValues,
      isVisible: true,
      isCustomLocationName: true,
      isFixedLocation,
    };

    createFigure({
      figure: marker,
      figureObj,
      activeSelectedCity,
      updateCity,
    });

    if (!isFixedLocation) {
      updateMap({
        center: { lat, lng },
      });

      handleCloseCreatePopup();
    }

    // return addressInEditMode;
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  let timerId = null;
  const delayedCloseInfoWindow = () => {
    if (timerId) return;
    timerId = setTimeout(() => {
      if (openedInfoWindowRef.current === activeMarker) {
        return;
      }

      handleActiveMarker(null);
    }, 1000);
  };

  const getMarker = ({
    key,
    title,
    clusterer,
    onMouseOver,
    onMouseOut,
    onClick,
    showAddPopup,
    isFixedLocation,
    ...props
  }) => (
    <Marker
      key={key}
      onMouseOver={() => {
        // if any create or add location pop up is already opened, we ignore hover
        clearTimeout(timerId);
        if (!(createPinState || clickedLocation)) {
          handleActiveMarker(key);
        }

        if (onMouseOver) {
          onMouseOver();
        }
      }}
      onMouseOut={() => {
        // if any add location pup up is already opened, we ignore this
        if (!clickedLocation) {
          // we allow 1s delay time
          timerId = null;
          delayedCloseInfoWindow(key);
        }

        if (onMouseOut) {
          onMouseOut();
        }
      }}
      onClick={() => {
        // if create popup is opened, we close it
        if (createPinState) {
          handleCloseCreatePopup();
        } else if (showAddPopup) {
          handleActiveMarker(key);
          setClickedLocation(true);
        } else {
          handleActiveMarker(null);
        }

        if (!showAddPopup && onClick) {
          onClick();
        }
      }}
      clusterer={clusterer}
      {...props}
    >
      {activeMarker === key ? (
        <InfoWindow options={{ pixelOffset: new window.google.maps.Size(0, showAddPopup ? 128 : 100) }}>
          {!isSubmitted && showAddPopup ? (
            <AddCreateLocationPopUp
              isAdd={true}
              isFixedLocation={isFixedLocation}
              addressName={title}
              onClick={() => {
                handleCloseCreatePopup();
                onClick();
              }}
              onMouseEnter={() => {
                openedInfoWindowRef.current = key;
              }}
              onMouseLeave={() => {
                openedInfoWindowRef.current = null;
                if (!clickedLocation) {
                  handleActiveMarker(null);
                }
              }}
            />
          ) : (
            <Typography className={classes.locationNameLabel}>{title}</Typography>
          )}
        </InfoWindow>
      ) : null}
    </Marker>
  );

  const renderMapItems = ({ address, isFetchedLocation, clusterer }) => {
    // this block applies only for fetched locations from postgres
    if (isFetchedLocation && !address.selected) {
      return getMarker({
        key: address.id,
        ref: (ref) => (refMarker.current[address.id] = ref),
        options: getOptions({
          editMode: false,
        }),
        position: {
          lat: address.latitude,
          lng: address.longitude,
        },
        title: address.name,
        onMouseOver: () => {},
        onClick: () => {
          handleLocationClick(address);
        },
        icon: {
          url: address.isSelfServe ? SelfServeLocationUnselected : PreciseLocationUnselected,
          anchor: new window.google.maps.Point(16, 43),
        },
        clusterer,
        showAddPopup: true,
        isFixedLocation: !address.isSelfServe,
      });
    }
    // this block applies only for locations selectedCities from mongo
    if (address.isVisible && address.type === MAP_TYPES.MARKER) {
      return getMarker({
        key: address.id,
        ref: (ref) => (refMarker.current[address.id] = ref),
        onClick: () => {
          handleMapItemClick({ item: address, activeSelectedCity, updateMap, setAddresses });
        },
        options: getOptions({
          draggable: false,
          editMode: address.editMode && !isSubmitted,
        }),
        position: {
          lat: address.position.lat,
          lng: address.position.lng,
        },
        title: address.value,
        icon: typeMarker(address.editMode, address.isFixedLocation),
        showAddPopup: false,
        isFixedLocation: address.isFixedLocation,
      });
    }
  };

  const changeClickedMapState = (state) => {
    clickedMapRef.current = state;
  };

  /**
   * user clicked somewhere on map or search some location.
   * we are gonna get address name for selected position.
   * @param pos { lat, lng }: latitude and longitude of position
   */
  const handleShowCreatePopup = (pos) => {
    const isInsideDistributionZone = isPositionInsideDistributionZone(distributionZonePolygons, pos);

    if (!isInsideDistributionZone) {
      // we searched outside distribution zones
      setServiceAreaLimitationPopUpOpen(true);
    }

    setCreatePinState({
      position: pos,
      isInside: isInsideDistributionZone,
    });

    /**
     * get address name from position { lat, lng }
     */
    if (geocoder) {
      geocoder.geocode(
        {
          latLng: pos,
        },
        function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            setCandidateLocationAddress(results[0].formatted_address);
          }
        }
      );
    }
  };

  const handleSingleClickMap = () => {
    if (isRestrictedCity) return;

    const pos = clickedPositionRef.current;
    if (clickedMapRef.current === CLICKED_STATE.CLICKED && pos) {
      if (!(costsCalculationError || isSubmitted)) {
        /**
         * This means we clicked map not double clicked.
         * In only this case, we show create location pop up
         * and of course this campaign is not submitted and there is no calculation error.
         */
        handleShowCreatePopup({
          lat: pos.lat(),
          lng: pos.lng(),
        });
      }
    }

    changeClickedMapState(CLICKED_STATE.CANCELED);
  };

  const debouncedHandleSingleClickMap = debounce(handleSingleClickMap, 200);

  const handleClickMap = (e) => {
    if (isSubmitted) {
      return;
    }
    changeClickedMapState(CLICKED_STATE.CLICKED);
    removeEditMode({ city, setAddresses });

    if (createPinState || clickedLocation) {
      /**
       * one create location popup is opened, so we should close this.
       */
      changeClickedMapState(CLICKED_STATE.CANCELED);
      handleCloseCreatePopup();
    } else {
      clickedPositionRef.current = e.latLng;
      debouncedHandleSingleClickMap();
    }
  };

  const handleDbClickMap = () => {
    changeClickedMapState(CLICKED_STATE.DOUBLECLICKED);
  };

  const handleCloseServiceAreaLimitationPopUp = () => {
    setServiceAreaLimitationPopUpOpen(false);
  };

  const handleGetInTouch = () => {
    handleScheduleMeeting(country.code);
  };

  return (
    <Box className={classes.container}>
      {/* {createdLocationData && (
        <ModalCreateLocation
          createdLocationData={createdLocationData}
          onClose={handleCloseModalCreateLocation}
          onSaved={handleLocationSaved}
          createdLocationNames={createdLocationNames}
        />
      )} */}
      <ServiceAreaLimitationPopUp
        open={serviceAreaLimitationPopUpOpen}
        onClickGetInTouch={handleGetInTouch}
        closeModal={handleCloseServiceAreaLimitationPopUp}
      />
      <Box className={classes.mapWrapper} ref={(ref) => (mapRef.current = ref)}>
        <GoogleMap
          onClick={handleClickMap}
          onDblClick={handleDbClickMap}
          onLoad={(map) => onMapInit({ newMap: map, mapInit, setMapInit })}
          mapContainerStyle={{
            width: "100vw",
            marginLeft: 0,
          }}
          center={center}
          clickableIcons={false}
          zoom={zoom}
          onZoomChanged={() => {
            if (mapInit && mapInit.getZoom() !== zoom) {
              updateMap({
                zoom: mapInit.getZoom(),
              });
            }
          }}
          onCenterChanged={() => {}}
          options={{
            clickableIcons: false,
            zoomControl: true,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            styles: MAP_EXTRA_STYLES,
          }}
        >
          {!isSubmitted ? (
            <MapHeader
              city={city}
              countryCode={country.code}
              isSubmitted={isSubmitted}
              postCodeSearch={true}
              isLoadingMap={loading}
            />
          ) : null}

          {CreateLocationPopup}

          {activeSelectedCityAddresses?.map((address) => renderMapItems({ address }))}
          <MarkerClusterer
            ignoreHidden
            gridSize={60}
            zoomOnClick={false}
            minimumClusterSize={3}
            onClick={(event) => {
              updateMap({
                center: { lat: event.center.lat(), lng: event.center.lng() },
                zoom: zoom + 1,
              });
            }}
            options={{
              styles: clusterStylesOptions,
            }}
          >
            {(clusterer) => (
              <div>
                {selectedCitiesLocations.map((address) => {
                  const isAddressActive = activeSelectedCityAddresses.find(
                    (activeAddress) => activeAddress.id === address.id
                  );
                  return !isAddressActive ? renderMapItems({ address, isFetchedLocation: true, clusterer }) : null;
                })}
              </div>
            )}
          </MarkerClusterer>
        </GoogleMap>
      </Box>
    </Box>
  );
};
