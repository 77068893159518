import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";

const style = {
  root: {
    width: "15px",
    height: "20px",
    fill: "none",
  },
};

const CreateLocationIcon = ({ classes, ...props }) => (
  <SvgIcon {...props} className={classes.root} viewBox="0 0 15 20">
    <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1693 8.00741C14.1693 10.3968 11.7447 13.9627 9.81993 16.4172C8.81573 17.6978 8.31363 18.3381 7.5026 18.3381C6.69158 18.3381 6.18948 17.6978 5.18528 16.4172C3.26048 13.9627 0.835938 10.3968 0.835938 8.00741C0.835938 4.04537 3.82071 0.833496 7.5026 0.833496C11.1845 0.833496 14.1693 4.04537 14.1693 8.00741Z"
        stroke="#011533"
        strokeWidth="1.5"
      />
      <path
        d="M5.0026 8.3335C5.0026 9.71421 6.12189 10.8335 7.5026 10.8335C8.88332 10.8335 10.0026 9.71421 10.0026 8.3335C10.0026 6.95278 8.88332 5.8335 7.5026 5.8335C6.12189 5.8335 5.0026 6.95278 5.0026 8.3335Z"
        stroke="#011533"
        strokeWidth="1.5"
      />
    </svg>
  </SvgIcon>
);

export default withStyles(style)(CreateLocationIcon);
