/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Utils } from "@tldraw/core";
import { Vec } from "@tldraw/vec";
import type { TldrawApp } from "pages/flyer/builder/drawer/state/internal";
import { TLDR } from "pages/flyer/builder/drawer/state/TLDR";
import { PagePartial, TDShape, TldrawCommand, TDShapeType, ChildIndexEnum } from "pages/flyer/builder/drawer/types";

export function duplicateShapes(
  app: TldrawApp,
  ids: string[],
  shapesToDuplicate: TDShape[],
  point?: number[]
): TldrawCommand {
  const { selectedIds, currentPageId, page } = app;

  const before: PagePartial = {
    shapes: {},
    bindings: {},
  };

  const after: PagePartial = {
    shapes: {},
    bindings: {},
  };

  const duplicateMap: Record<string, string> = {};

  const duplicateDfs = (shapes: TDShape[], parentId: string, isTopLevel: boolean) => {
    shapes.forEach((shape) => {
      const duplicatedId = Utils.uniqueId();
      duplicateMap[shape.id] = duplicatedId;
      before.shapes[duplicatedId] = undefined;

      const childIndex =
        shape.type === TDShapeType.QRCode
          ? ChildIndexEnum.Middle
          : TLDR.getChildIndexAbove(app.state, shape.id, currentPageId);

      after.shapes[duplicatedId] = {
        ...Utils.deepClone(shape),
        id: duplicatedId,
        childIndex: childIndex,
      };

      if (isTopLevel) {
        if (shape.parentId !== currentPageId) {
          const parent = app.getShape(shape.parentId);

          before.shapes[parent.id] = {
            ...before.shapes[parent.id],
            children: parent.children,
          };

          after.shapes[parent.id] = {
            ...after.shapes[parent.id],
            children: [...(after.shapes[parent.id] || parent).children!, duplicatedId],
          };
        }
      } else {
        after.shapes[parentId]?.children?.push(duplicatedId);
        after.shapes[duplicatedId] = {
          ...after.shapes[duplicatedId],
          parentId,
        };
      }

      if (shape.children) {
        after.shapes[duplicatedId]!.children = [];
        const childShapes = shape.children.map((childId) => app.getShape(childId));
        duplicateDfs(childShapes, duplicatedId, false);
      }
    });
  };

  duplicateDfs(shapesToDuplicate, "", true);

  // Which ids did we end up duplicating?
  const dupedShapeIds = new Set(Object.keys(duplicateMap));

  // Handle bindings that effect duplicated shapes
  Object.values(page.bindings)
    .filter((binding) => dupedShapeIds.has(binding.fromId) || dupedShapeIds.has(binding.toId))
    .forEach((binding) => {
      if (dupedShapeIds.has(binding.fromId)) {
        if (dupedShapeIds.has(binding.toId)) {
          // If the binding is between two duplicating shapes then
          // duplicate the binding, too
          const duplicatedBindingId = Utils.uniqueId();

          const duplicatedBinding = {
            ...Utils.deepClone(binding),
            id: duplicatedBindingId,
            fromId: duplicateMap[binding.fromId],
            toId: duplicateMap[binding.toId],
          };

          before.bindings[duplicatedBindingId] = undefined;
          after.bindings[duplicatedBindingId] = duplicatedBinding;

          // Change the duplicated shape's handle so that it reference
          // the duplicated binding
          const boundShape = after.shapes[duplicatedBinding.fromId];
          Object.values(boundShape!.handles!).forEach((handle) => {
            if (handle!.bindingId === binding.id) {
              handle!.bindingId = duplicatedBindingId;
            }
          });
        } else {
          // If only the fromId is selected, delete the binding on
          // the duplicated shape's handles
          const boundShape = after.shapes[duplicateMap[binding.fromId]];
          Object.values(boundShape!.handles!).forEach((handle) => {
            if (handle!.bindingId === binding.id) {
              handle!.bindingId = undefined;
            }
          });
        }
      }
    });

  // Now move the shapes

  const shapesToMove = Object.values(after.shapes) as TDShape[];

  if (point) {
    const commonBounds = Utils.getCommonBounds(shapesToMove.map((shape) => TLDR.getBounds(shape)));
    const center = Utils.getBoundsCenter(commonBounds);
    shapesToMove.forEach((shape) => {
      // Could be a group
      if (!shape.point) return;
      shape.point = Vec.sub(point, Vec.sub(center, shape.point));
    });
  } else {
    const offset = [16, 16];
    shapesToMove.forEach((shape) => {
      // Could be a group
      if (!shape.point) return;
      shape.point = Vec.add(shape.point, offset);
    });
  }

  // Unlock any locked shapes
  shapesToMove.forEach((shape) => {
    if (shape.isLocked) {
      shape.isLocked = false;
    }
  });

  return {
    id: "duplicate",
    before: {
      document: {
        pages: {
          [currentPageId]: before,
        },
        pageStates: {
          [currentPageId]: { selectedIds },
        },
      },
    },
    after: {
      document: {
        pages: {
          [currentPageId]: after,
        },
        pageStates: {
          [currentPageId]: {
            selectedIds: Array.from(dupedShapeIds.values()).map((id) => duplicateMap[id]),
          },
        },
      },
    },
  };
}
