export const convertRecordToGraphQLInputArray = <T>(val: Record<string, T>) =>
  Object.entries(val).map(([key, value]) => ({
    key,
    value: JSON.stringify(value),
  }));

export const convertDataForLiveStorage = <T>(arr: { key: string; value: T }[]): [string, T][] =>
  arr.map(({ key, value }) => [key, value]);

export const shallowCopyMap = (a: Map<string, any> | null) => {
  if (!a) {
    return null;
  }

  const b = new Map<string, any>();
  for (const key of a.keys()) {
    b.set(key, a.get(key));
  }
  return b;
};
