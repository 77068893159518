const SvgLoadingBigIcon = (props) => (
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.0054 16.3601C39.993 17.1922 39.6569 17.9868 39.0685 18.5752C38.48 19.1637 37.6855 19.4997 36.8534 19.5121C36.0199 19.5018 35.2233 19.1666 34.6331 18.578C34.043 17.9893 33.7058 17.1936 33.6934 16.3601V3.15202C33.7058 2.31855 34.043 1.52282 34.6331 0.934152C35.2233 0.34548 36.0199 0.0103267 36.8534 0C37.6855 0.0123907 38.48 0.348457 39.0685 0.936911C39.6569 1.52537 39.993 2.31991 40.0054 3.15202V16.3601Z"
      fill="#EE4360"
    />
    <path
      opacity="0.1"
      d="M55.2602 29.2161C54.9017 29.4236 54.5057 29.5582 54.095 29.6122C53.6843 29.6662 53.267 29.6385 52.8671 29.5307C52.4671 29.4229 52.0924 29.2371 51.7645 28.9841C51.4365 28.731 51.1618 28.4156 50.9562 28.0561C50.7483 27.6981 50.6133 27.3025 50.5587 26.8921C50.5041 26.4818 50.5311 26.0647 50.6382 25.6648C50.7452 25.2649 50.9302 24.8901 51.1824 24.5618C51.4347 24.2336 51.7493 23.9584 52.1082 23.7521L63.5403 17.152C63.8989 16.944 64.295 16.8088 64.706 16.7541C65.117 16.6994 65.5347 16.7264 65.9353 16.8334C66.3358 16.9404 66.7113 17.1254 67.0403 17.3777C67.3692 17.6301 67.6452 17.9449 67.8523 18.304C68.2597 19.0312 68.3673 19.8887 68.1521 20.694C67.937 21.4993 67.4161 22.1889 66.7003 22.616L55.2602 29.2161Z"
      fill="#EE4360"
    />
    <path
      opacity="0.2"
      d="M56.1403 39.5044C55.7261 39.5054 55.3157 39.4246 54.9328 39.2666C54.5499 39.1086 54.202 38.8764 53.9091 38.5835C53.6162 38.2906 53.3841 37.9427 53.2261 37.5598C53.068 37.1769 52.9872 36.7666 52.9883 36.3524C52.9882 35.5119 53.3187 34.7052 53.9085 34.1064C54.4983 33.5076 55.3 33.165 56.1403 33.1523H69.3484C70.1844 33.1523 70.9861 33.4844 71.5772 34.0755C72.1683 34.6667 72.5004 35.4684 72.5004 36.3044C72.5004 37.1403 72.1683 37.9421 71.5772 38.5332C70.9861 39.1243 70.1844 39.4564 69.3484 39.4564L56.1403 39.5044Z"
      fill="#EE4360"
    />
    <path
      opacity="0.3"
      d="M51.7556 48.8553C51.0324 48.437 50.5048 47.7487 50.2888 46.9417C50.0728 46.1346 50.186 45.2748 50.6036 44.5512C50.8101 44.1914 51.0855 43.8759 51.4142 43.6227C51.7428 43.3696 52.1182 43.1838 52.5188 43.076C52.9194 42.9682 53.3374 42.9406 53.7487 42.9947C54.16 43.0488 54.5566 43.1835 54.9157 43.3912L66.3557 49.9913C66.7149 50.1984 67.0297 50.4743 67.282 50.8033C67.5344 51.1322 67.7194 51.5077 67.8264 51.9083C67.9334 52.3088 67.9603 52.7265 67.9056 53.1375C67.851 53.5485 67.7158 53.9447 67.5077 54.3033C67.3006 54.6625 67.0247 54.9772 66.6958 55.2296C66.3668 55.482 65.9913 55.6669 65.5907 55.7739C65.1902 55.881 64.7725 55.9079 64.3615 55.8532C63.9505 55.7985 63.5543 55.6633 63.1957 55.4553L51.7556 48.8553Z"
      fill="#EE4360"
    />
    <path
      opacity="0.4"
      d="M43.2842 54.7612C43.0767 54.4027 42.9421 54.0067 42.8881 53.596C42.8342 53.1853 42.8619 52.768 42.9696 52.368C43.0774 51.9681 43.2632 51.5934 43.5163 51.2654C43.7693 50.9375 44.0847 50.6628 44.4442 50.4571C45.1678 50.0396 46.0276 49.9263 46.8347 50.1423C47.6417 50.3584 48.33 50.8859 48.7483 51.6092L55.3483 63.0412C55.5563 63.3999 55.6916 63.796 55.7462 64.207C55.8009 64.618 55.774 65.0357 55.667 65.4363C55.56 65.8368 55.375 66.2123 55.1226 66.5413C54.8702 66.8702 54.5555 67.1461 54.1963 67.3533C53.4691 67.7607 52.6116 67.8682 51.8063 67.6531C51.001 67.438 50.3114 66.9171 49.8843 66.2012L43.2842 54.7612Z"
      fill="#EE4360"
    />
    <path
      opacity="0.5"
      d="M32.9961 55.6393C32.9961 54.8034 33.3282 54.0016 33.9193 53.4105C34.5104 52.8194 35.3121 52.4873 36.1481 52.4873C36.9816 52.4976 37.7782 52.8328 38.3684 53.4215C38.9585 54.0101 39.2957 54.8059 39.3081 55.6393V68.8474C39.3081 69.262 39.2264 69.6725 39.0674 70.0555C38.9085 70.4384 38.6757 70.7863 38.3821 71.0791C38.0886 71.3719 37.7402 71.6039 37.3568 71.7618C36.9735 71.9197 36.5627 72.0005 36.1481 71.9994C35.3121 71.9994 34.5104 71.6674 33.9193 71.0762C33.3282 70.4851 32.9961 69.6834 32.9961 68.8474V55.6393Z"
      fill="#EE4360"
    />
    <path
      opacity="0.58"
      d="M23.6441 51.2566C24.0712 50.5408 24.7608 50.0199 25.5661 49.8047C26.3714 49.5896 27.2289 49.6972 27.9561 50.1046C28.6719 50.5317 29.1928 51.2213 29.408 52.0266C29.6231 52.8319 29.5156 53.6894 29.1081 54.4166L22.5081 65.8487C22.301 66.2078 22.0251 66.5226 21.6961 66.775C21.3672 67.0273 20.9916 67.2123 20.5911 67.3193C20.1905 67.4263 19.7728 67.4533 19.3618 67.3986C18.9509 67.3439 18.5547 67.2087 18.1961 67.0007C17.8372 66.7943 17.5226 66.5192 17.2703 66.1909C17.018 65.8627 16.8331 65.4879 16.726 65.088C16.619 64.6881 16.592 64.271 16.6466 63.8606C16.7012 63.4502 16.8362 63.0547 17.044 62.6967L23.6441 51.2566Z"
      fill="#EE4360"
    />
    <path
      opacity="0.66"
      d="M17.7326 42.7858C18.0917 42.5781 18.4883 42.4433 18.8996 42.3892C19.3109 42.3351 19.7288 42.3627 20.1294 42.4705C20.53 42.5783 20.9054 42.7641 21.2341 43.0172C21.5627 43.2704 21.8382 43.5859 22.0446 43.9458C22.4622 44.6694 22.5754 45.5292 22.3594 46.3362C22.1434 47.1433 21.6158 47.8315 20.8926 48.2498L9.46055 54.8498C9.10191 55.0578 8.70574 55.1931 8.29477 55.2477C7.88379 55.3024 7.46606 55.2755 7.06551 55.1685C6.66496 55.0615 6.28945 54.8765 5.9605 54.6241C5.63155 54.3718 5.35561 54.057 5.1485 53.6978C4.74109 52.9706 4.63352 52.1131 4.84867 51.3078C5.06382 50.5025 5.58471 49.8129 6.30051 49.3858L17.7326 42.7858Z"
      fill="#EE4360"
    />
    <path
      opacity="0.74"
      d="M16.8601 32.4941C17.6922 32.5065 18.4868 32.8426 19.0752 33.431C19.6637 34.0195 19.9998 34.8141 20.0121 35.6462C20.0122 36.4866 19.6817 37.2934 19.0919 37.8921C18.5021 38.4909 17.7005 38.8336 16.8601 38.8462H3.65202C2.81167 38.8336 2.01002 38.4909 1.42025 37.8921C0.830467 37.2934 0.499905 36.4866 0.5 35.6462C0.512391 34.8141 0.848466 34.0195 1.43692 33.431C2.02537 32.8426 2.81992 32.5065 3.65202 32.4941H16.8601Z"
      fill="#EE4360"
    />
    <path
      opacity="0.82"
      d="M21.2457 23.1441C21.9615 23.5712 22.4824 24.2608 22.6976 25.0661C22.9127 25.8714 22.8051 26.7289 22.3977 27.4561C21.9706 28.1719 21.281 28.6928 20.4757 28.908C19.6704 29.1231 18.8129 29.0156 18.0857 28.6081L6.65362 22.0081C6.29445 21.801 5.97967 21.5251 5.72732 21.1961C5.47496 20.8672 5.28999 20.4916 5.18298 20.0911C5.07596 19.6905 5.04903 19.2728 5.1037 18.8618C5.15838 18.4509 5.29359 18.0547 5.50161 17.6961C5.91987 16.9729 6.60815 16.4453 7.41519 16.2293C8.22224 16.0132 9.08203 16.1265 9.80564 16.5441L21.2457 23.1441Z"
      fill="#EE4360"
    />
    <path
      opacity="0.9"
      d="M29.7161 17.24C29.9236 17.5985 30.0582 17.9945 30.1122 18.4052C30.1662 18.8159 30.1385 19.2332 30.0307 19.6331C29.9229 20.0331 29.7371 20.4078 29.4841 20.7357C29.231 21.0637 28.9156 21.3384 28.5561 21.544C27.8325 21.9616 26.9727 22.0748 26.1656 21.8588C25.3586 21.6428 24.6703 21.1152 24.252 20.392L17.652 8.95995C17.444 8.60131 17.3088 8.20515 17.2541 7.79417C17.1994 7.38319 17.2264 6.96547 17.3334 6.56492C17.4404 6.16437 17.6253 5.78886 17.8777 5.45991C18.1301 5.13096 18.4448 4.85503 18.804 4.64792C19.5349 4.22804 20.402 4.11351 21.2169 4.32921C22.0317 4.54491 22.7286 5.07342 23.156 5.79992L29.7161 17.24Z"
      fill="#EE4360"
    />
  </svg>
);

export default SvgLoadingBigIcon;
