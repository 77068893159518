const style = {
  formated: {
    left: "2px",
    right: "2px",
    top: "2px",
    bottom: "2px",
    borderRadius: "2px",
    padding: "10px 7px",
    zIndex: "100",
    backgroundColor: "white",
  },
};

export default style;
