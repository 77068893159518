export const SavingIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.81296 7.33331C1.78738 7.33337 1.76182 7.3315 1.73653 7.3277C1.61138 7.30831 1.49922 7.24111 1.4247 7.14087C1.35017 7.04064 1.31937 6.91556 1.33906 6.79313C1.56132 5.40432 2.24073 4.12435 3.27417 3.14752C4.3076 2.17068 5.63864 1.55031 7.06524 1.38058C8.49183 1.21084 9.9361 1.50101 11.1788 2.20703C12.4215 2.91305 13.3948 3.99638 13.9509 5.29257C13.9796 5.34969 13.9961 5.41195 13.9993 5.47552C14.0026 5.53909 13.9925 5.60263 13.9697 5.66226C13.9469 5.72188 13.9119 5.77632 13.8669 5.82224C13.8218 5.86816 13.7676 5.9046 13.7077 5.92931C13.6477 5.95402 13.5832 5.96649 13.5181 5.96595C13.4531 5.96541 13.3888 5.95187 13.3293 5.92617C13.2698 5.90047 13.2162 5.86314 13.172 5.81648C13.1277 5.76982 13.0937 5.7148 13.0719 5.65481C12.5965 4.54583 11.7642 3.61888 10.7012 3.0147C9.63826 2.41052 8.40278 2.16213 7.18238 2.30723C5.96198 2.45233 4.82332 2.983 3.93929 3.81866C3.05525 4.65433 2.47414 5.74934 2.28419 6.93743C2.26679 7.04753 2.20974 7.14792 2.12334 7.22051C2.03693 7.2931 1.92686 7.3331 1.81296 7.33331Z"
        fill="#2E3860"
      />
      <path
        d="M14.1682 5.99998H14.1644L10.4687 5.97157C10.3439 5.97107 10.2244 5.92069 10.1365 5.83154C10.0486 5.74238 9.9995 5.62174 10 5.49615C10.0005 5.37056 10.0506 5.25032 10.1392 5.16187C10.2278 5.07343 10.3477 5.02402 10.4725 5.02452H10.4763L13.7021 5.04914L13.7265 1.80343C13.7274 1.67863 13.7773 1.55923 13.8652 1.4712C13.9531 1.38316 14.072 1.33361 14.196 1.33331H14.1994C14.3243 1.33431 14.4436 1.38515 14.5312 1.47466C14.6188 1.56416 14.6676 1.685 14.6667 1.81062L14.6388 5.52987C14.6378 5.65483 14.5878 5.77434 14.4997 5.86239C14.4115 5.95043 14.2924 5.99988 14.1682 5.99998Z"
        fill="#2E3860"
      />
      <path
        d="M8.13816 14.6666C6.83758 14.6645 5.56646 14.2879 4.48352 13.5837C3.40058 12.8795 2.55382 11.879 2.04899 10.7071C2.02028 10.65 2.00381 10.5877 2.00059 10.5242C1.99736 10.4606 2.00745 10.3971 2.03023 10.3375C2.05302 10.2778 2.08802 10.2234 2.13308 10.1775C2.17815 10.1316 2.23234 10.0952 2.29231 10.0705C2.35229 10.0458 2.4168 10.0333 2.48189 10.0338C2.54697 10.0344 2.61125 10.0479 2.6708 10.0736C2.73034 10.0993 2.78388 10.1366 2.82815 10.1833C2.87241 10.2299 2.90646 10.2849 2.92821 10.3449C3.40433 11.4528 4.23706 12.3787 5.30007 12.982C6.36309 13.5854 7.59836 13.8333 8.8185 13.6881C10.0386 13.5429 11.177 13.0125 12.061 12.1775C12.945 11.3425 13.5262 10.2483 13.7166 9.06105C13.7264 9.00044 13.7483 8.9423 13.7811 8.88996C13.8138 8.83762 13.8568 8.7921 13.9076 8.75601C14.0101 8.68311 14.1381 8.65302 14.2633 8.67235C14.3253 8.68192 14.3847 8.70333 14.4383 8.73537C14.4918 8.76741 14.5383 8.80945 14.5753 8.85908C14.6122 8.90871 14.6387 8.96496 14.6534 9.02463C14.6681 9.08429 14.6706 9.1462 14.6608 9.20682C14.4149 10.7264 13.6233 12.1107 12.4279 13.1112C11.2326 14.1118 9.71172 14.6632 8.13816 14.6666Z"
        fill="#2E3860"
      />
      <path
        d="M1.80377 14.6667H1.80038C1.73856 14.6662 1.67744 14.6535 1.6205 14.6293C1.56356 14.6051 1.51192 14.5698 1.46854 14.5254C1.42516 14.4811 1.39088 14.4286 1.36767 14.3709C1.34446 14.3133 1.33277 14.2516 1.33327 14.1894L1.3615 10.4701C1.36249 10.3446 1.41296 10.2245 1.50181 10.1364C1.59066 10.0483 1.71063 9.9992 1.83539 10L5.53129 10.0288C5.65608 10.0293 5.77555 10.0797 5.86344 10.1688C5.95132 10.258 6.00041 10.3786 5.99991 10.5042C5.99942 10.6298 5.94937 10.75 5.86078 10.8385C5.77219 10.9269 5.65231 10.9763 5.52753 10.9758H5.52376L2.29874 10.9508L2.27428 14.1966C2.27328 14.3215 2.22329 14.441 2.13517 14.5291C2.04705 14.6171 1.92794 14.6666 1.80377 14.6667Z"
        fill="#2E3860"
      />
    </svg>
  );
};
