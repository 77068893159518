const style = {
  formated: {
    width: "100%",
    borderRadius: "4px",
    position: "relative",
    // height: "10px",
    border: "1px solid #E4E4EA",
    fontSize: "8px !important",
    "& input": {
      padding: "4px 2px !important",
      fontSize: "14px !important",
      lineHeight: "20px",
      textAlign: "center",
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      "&::placeholder": {
        color: "#000 !important",
        opacity: 1,
      },
    },
    "& span": {
      padding: "4px 2px !important",
      textAlign: "center",
    },
  },
  formatedWithAdornment: {
    paddingRight: "6px",
  },
};

export default style;
