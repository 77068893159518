import CONFIG from "config/config";

import { localStore } from "module/browser/local-storage";

export const isClientRole = (role) =>
  [CONFIG.USER_ROLE.CLIENT_ADMIN, CONFIG.USER_ROLE.CLIENT, CONFIG.USER_ROLE.DISTRIBUTOR].indexOf(role) !== -1;

export const isManagerRole = (role) =>
  [CONFIG.USER_ROLE.ADMIN, CONFIG.USER_ROLE.CITY_MANAGER, CONFIG.USER_ROLE.SPY].indexOf(role) !== -1;

class Authorization {
  /**
   * Browser storage
   */
  storage;

  /**
   *
   * @param storage
   */
  constructor(storage) {
    this.storage = storage;
  }

  /**
   * Login user to app
   * @param user
   * @param apiKey
   */
  login(user, apiKey) {
    this.storage.setData(CONFIG.STORE.USER_KEY, user);
    this.storage.setData(CONFIG.STORE.API_KEY, apiKey);
  }

  /**
   * Logout user from app
   */
  logout() {
    try {
      this.storage.removeData(CONFIG.STORE.USER_KEY);
      this.storage.removeData(CONFIG.STORE.API_KEY);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Is user logged
   * @returns boolean
   */
  isLogged() {
    const user = this.getUser();
    const apiKey = this.getAPIKey();
    return user && apiKey;
  }

  /**
   * User is admin
   * @returns boolean
   */
  isAdmin() {
    const user = this.getUser();
    return this.isLogged() && user.role === CONFIG.USER_ROLE.ADMIN;
  }

  /**
   * User is city manager
   * @returns boolean
   */
  isCityManager() {
    const user = this.getUser();
    return this.isLogged() && isManagerRole(user.role);
  }

  /**
   * User is client
   * @param role string In case that need to check before save the user role
   * @returns boolean
   */
  isClient() {
    const user = this.getUser();
    return this.isLogged() && isClientRole(user.role);
  }

  /**
   * User is client admin
   * @param role string In case that need to check before save the user role
   * @returns boolean
   */
  isClientAdmin() {
    const user = this.getUser();
    return this.isLogged() && user.role === CONFIG.USER_ROLE.CLIENT_ADMIN;
  }

  /**
   * User is client role
   * @returns boolean
   */
  isClientRole() {
    return this.isClientAdmin() || this.isClient();
  }

  /**
   * User is recruiter
   * @param role string In case that need to check before save the user role
   * @returns boolean
   */
  isRecruiter() {
    const user = this.getUser();
    return this.isLogged() && user.role === CONFIG.USER_ROLE.RECRUITER;
  }

  /**
   * User is spy
   * @param role string In case that need to check before save the user role
   * @returns boolean
   */
  isSpy() {
    const user = this.getUser();
    return this.isLogged() && user.role === CONFIG.USER_ROLE.SPY;
  }
  /**
   * Get user
   */
  getUser() {
    return this.storage.getData(CONFIG.STORE.USER_KEY);
  }

  /**
   * Update user
   */
  updateUser(user) {
    this.storage.setData(CONFIG.STORE.USER_KEY, user);
  }

  /**
   * Get API key
   */
  getAPIKey() {
    return this.storage.getData(CONFIG.STORE.API_KEY);
  }
}

export const authorization = new Authorization(localStore);
