const style = {
  root: {
    "& > .MuiSnackbar-root": {
      borderRadius: "4px",
      border: "1px solid #E2E2FC",
      boxShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
    },

    "& > .MuiAlert-root": {
      height: "64px",
      color: "#011533",
      padding: "0 16px",
      background: "#FFFFFF",
      alignItems: "center",
    },
  },

  noBorder: {
    "& > .MuiSnackbar-root": {
      border: "none",
    },
  },

  paddingTop: {
    "& > .MuiSnackbar-root": {
      top: "81px",
    },
  },

  undoIcon: {
    "& > .MuiButton-root": {
      "& > span": {
        textTransform: "none",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
      },
    },
  },

  closeIcon: {
    "& > .MuiButton-root": {
      minWidth: "20px",
      padding: "0 8px",
      height: "100%",
    },
  },

  btnsSeparator: {
    width: "1px",
    height: "26px",
    background: "#526181",
    margin: "auto",
  },
};

export default style;
