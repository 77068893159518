const SvgPolygonTool = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.54123 13.649L2.72165 6.86804L16.3733 5.56223L14.3382 15.4121L5.54123 13.649Z" stroke="#011533" />
    <circle cx="16.5" cy="5.5" r="1" fill="white" stroke="#011533" />
    <circle cx="14.5" cy="15.5" r="1" fill="white" stroke="#011533" />
    <circle cx="2.5" cy="6.5" r="1" fill="white" stroke="#011533" />
    <circle cx="5.5" cy="13.5" r="1" fill="white" stroke="#011533" />
  </svg>
);

export default SvgPolygonTool;
