import classnames from "classnames";
import { withStyles } from "@material-ui/core";

import LinkToFile from "pages/flyer/flyerTypePage/components/LinkToFile";
import { ReactComponent as CloseIcon } from "static/media/close.svg";
import { FORMATS } from "pages/flyer/builder/constants";
import { PossibleFlyerType } from "pages/flyer/builder/drawer/types";
import { DocumentFormats } from "shared/constants";

import style from "./style";
import { displayInInch } from "../../utils";
import { useStore } from "../../../../../store";
import { StoreType } from "../../../../../store/types";

interface IProps {
  classes: any;
  onCancel: () => void;
  disableMargin?: boolean;
}

const PrintRuleModal: React.FC<IProps> = ({ classes, onCancel, disableMargin = false }) => {
  const {
    campaign: { flyerType },
    country: { code },
  }: StoreType = useStore();

  const format = FORMATS[flyerType as PossibleFlyerType];
  const printingRulesLink = displayInInch(code) ? format.printingRulesInches : format.printingRulesMm;

  return (
    <div className={classnames(classes.modalWrapper, { [classes.disableMargin]: disableMargin })}>
      <div
        className={classes.backdropFilter}
        onClick={(e) => {
          e.stopPropagation();
          onCancel();
        }}
      />
      <div className={classes.modal}>
        <div className={classes.closeButton} onClick={onCancel}>
          <CloseIcon />
        </div>
        <p className={classes.header}>Printing rules</p>
        <div
          className={classnames(classes.pdfPages, {
            [classes.additionalMargin]: flyerType === DocumentFormats.DOOR_HANGER,
          })}
        >
          <img width={436} height={562} src={format.frontSide}></img>
          <img width={436} height={562} src={format.backSide}></img>
        </div>
        <div className={classes.container}>
          <LinkToFile href={printingRulesLink} linkText="Download printing rules in PDF" icon={true}></LinkToFile>
        </div>
      </div>
    </div>
  );
};

export default withStyles(style)(PrintRuleModal);
